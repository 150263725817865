import {useEffect, useRef} from "react";
import {initPdfSdk} from "../../../initPdfViewer";
import ReactDOM from "react-dom";

function PdfViewerContainer(props) {

    const { file, getPdfDocInfoFn } = props;

    const viewerRef = useRef(null);

    const cleanViewerEle = (ele) => {
        ele.innerHTML = '';
        ele.removeAttribute('style');
    }

    useEffect(() => {
        if (file && viewerRef.current) {
            cleanViewerEle(viewerRef.current);
            initPdfSdk({ viewer: viewerRef.current, file })
            .then(({workerFetch, pdfViewer, pdfDocRender}) => {
                getPdfDocInfoFn && getPdfDocInfoFn(workerFetch, pdfViewer, pdfDocRender)
            })
        }
    }, [file, getPdfDocInfoFn])

    return ReactDOM.createPortal(
        <div style={{ position: 'absolute', transform: 'translate(-1000px, -10000px)', visibility: 'hidden' }}>
            <div ref={viewerRef}></div>
        </div>,
        document.body
    )
}

export default PdfViewerContainer;