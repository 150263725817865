import {Button, Checkbox, Form, Input, Select} from "antd";
import {useEffect, useState, useRef, useCallback, useMemo} from "react";
import {observer} from "mobx-react";
import './index.less'
import {getStore, setStore} from "@/store";
import Draggable from "../../utils/dragable";
import ReceiptsItems from "./ReceiptsItems";
import {
    deleteFieldsForPhantom,
    setInitFlowParamsForPhantom,
    showESignCNDialogAPI
} from "@/request/phantom";
import {toJS} from "mobx";
import {cleanInitFlowInfoCacheFn, cleanReceiptItemFormDataCacheFn, getReceiptItemFormDataCacheFn} from "./service";
import {INIT_FLOW_SAVE_TYPE} from "../../constant";
import {sleep} from "../../service";

function ConfigForm(props) {

    const receiptsItemsRef = useRef(null)
    const { docInfo, onFinishForm, handleEditSignerInfoForm } = props;
    const saveTypeOption = [
        {
            value: 'online',
            label: '生成分享链接'
        },
        {
            value: 'local',
            label: '手动发送文档'
        }
    ]

    const saveTypeTipList = useMemo(() => {
        return {
            online: ["上传文档生成链接，便于分享", "自动发送签署通知，时效性高", "支持电脑手机在线签署，方便快捷（文档最大不超过30MB）"],
            local: ["文档不上传服务器，保护隐私", "发起人手动将文档发送给各签署参与人", "所有人员线下完成签署"]
        }
    }, [])

    const [form] = Form.useForm();


    const [tipMsg, setTipMsg] = useState(saveTypeTipList['online'])

    const [fileSize, setFileSize] = useState(0)

    const orderedFlow = getStore('plugin').initFlowInfo.orderedFlow
    const baseTitleIconExpand = getStore('plugin').initFlowInfo.baseTitleIconExpand;  // true 展开; false 折叠
    const flowTitleIconExpand = getStore('plugin').initFlowInfo.flowTitleIconExpand;  // true 展开; false 折叠

    const initFlowInfo = getStore('plugin').initFlowInfo
    const wardType = getStore('plugin').wardType


    let formData = initFlowInfo.configInfo;


    const onFormValuesChange = useCallback(() => {
        const data = form.getFieldsValue();
        data.taskName = data.taskName.trim();
        const initFlowInfo = { ...getStore('plugin').initFlowInfo, configInfo: data, hasInitStore: true }
        setStore('plugin', { ...getStore('plugin'), initFlowInfo })
    }, [form])

    const handleSaveTypeChange = useCallback((value) => {
        form.setFieldValue('saveType', value)
        onFormValuesChange()  // setFieldsValue 不会触发onValuesChange，所以此处手动调用下onFormValuesChange。https://github.com/ant-design/ant-design/issues/22168
        setTipMsg(saveTypeTipList[value])
        const initFlowInfo = getStore('plugin').initFlowInfo;
        initFlowInfo.orderedFlow = initFlowInfo.configInfo.saveType === INIT_FLOW_SAVE_TYPE.LOCAL;
        setStore('plugin', { ...getStore('plugin'), initFlowInfo })
    }, [form, onFormValuesChange, saveTypeTipList])

    const checkTaskName = (rules, value) => {
        if (!value.trim()) {
            return Promise.reject("请填写任务名称")
        } else if (value.trim().length > 20) {
            return Promise.reject("最多可输入20个字符")
        } else {
            return Promise.resolve()
        }
    }
    const checkSaveType = (rules, value, fileSize) => {
        if (value === 'online') {
            if (fileSize > 30) {
                return Promise.reject("上传文档大小请限制在30MB以内")
            } else {
                return Promise.resolve()
            }
        } else {
            return Promise.resolve()
        }
    }

    const handleEnter2Form = (signerType) => {
        if(initFlowInfo.receipts.length > 19) {
            showESignCNDialogAPI({ title: "福昕电子签章服务", type: "Dialog", id: "INIT_SIGN_ERROR_TIPS", msg: "最多可以添加20位签章参与人"})
        } else {
            form.validateFields().then(() => {
                onFinishForm(signerType)
            }).catch(e => {
                console.error('form valid fail', e)
            })
        }
    }

    useEffect(() => {
        if(docInfo.fileSize) {
            setFileSize(docInfo.fileSize / ( 1024 * 1024 ))
            if (docInfo.fileSize / ( 1024 * 1024 ) > 30) {
                handleSaveTypeChange('local')
                onFormValuesChange()
            }
        }

        initFlowInfo.uuid = docInfo.uuid;
        setStore('plugin', { ...getStore('plugin'), initFlowInfo })

        const cancelInitFlow = async () => {
            const annots = []
            initFlowInfo.receipts.forEach(receipt => {
                receipt.annots.forEach(annot => {
                    const obj = { objNumber: annot.objNumber, pageIndex: annot.pageIndex }
                    annots.push(obj)
                })
            })
            const receiptsItems = getReceiptItemFormDataCacheFn(initFlowInfo.uuid);
            receiptsItems && receiptsItems.annots && receiptsItems.annots.forEach(annot => {
                const obj = { objNumber: annot.objNumber, pageIndex: annot.pageIndex }
                annots.push(obj)
            })
            await deleteFieldsForPhantom(annots)
            cleanReceiptItemFormDataCacheFn(initFlowInfo.uuid)
            cleanInitFlowInfoCacheFn(initFlowInfo.uuid)
            const data = {
                hasInitStore: false,
                curReceiptsOrder: -1,
                showFormType: "configForm",
                configInfo: {
                    taskName: '',
                    saveType: 'online'
                },
                receipts: []
            }
            setStore('plugin', { ...getStore('plugin'), initFlowInfo: data })
            form.setFieldsValue({
                taskName: '',
                saveType: 'online'
            })
        }
        window.eSignCN_cancelInitFlowForESignCN = cancelInitFlow;
    }, [form, handleSaveTypeChange, initFlowInfo, onFormValuesChange, docInfo])

    useEffect(() => {
        const _dragdrop = (from, to) => {
            const temps = initFlowInfo.receipts;
            const cnt = temps.length;

            if (!cnt || cnt < 2) { //一个以上项才能排序
                return;
            }
            if (to === -1) {
                to = cnt;
            }
            if (from === to) {
                return;
            }
            if (to - from === 1) {
                return;
            }
            const items = [];
            let fromItem = temps[from];

            temps.forEach((item, index) => {
                if (index === from) {
                    return;
                }
                if (index === to) {
                    items.push(fromItem);
                    fromItem = null;
                }
                items.push(item);
            });
            if (fromItem) { // 移到最后
                items.push(fromItem);
            }

            initFlowInfo.receipts = items;
            setStore('plugin', { ...getStore('plugin'), initFlowInfo })

            // this.controller.list = items;
            // this.controller.digest();
        }
        let dragDropIndex = -2;

        let dragableEle;
        if (receiptsItemsRef.current && orderedFlow) {
            dragableEle = Draggable.create(receiptsItemsRef.current, {
                draggable: "drag",  // 允许拖拽的节点类名
                dragClass: "draggable-drag", // 正在被拖拽中的css类名
                chosenClass: "item-sortable-ghost",  // 被选中项的css 类名
                // inventedNode: dragLastNode,
                endStrict: false,
                dragOverStrict: true,
                // 隐藏子菜单
                onStart: (data) => {
                    dragDropIndex = -2;
                },
                onHover: (data) => {
                },
                onDrop: (data) => {
                    if(!data || !data.endAttrs) {
                        return;
                    }
                    dragDropIndex = parseInt(data.endAttrs.index);
                },
                onEnd: (data) => {
                    if(!data || !data.endAttrs || !data.fromAttrs) {
                        return;
                    }
                    if (dragDropIndex === -2) {
                        return;
                    }
                    const fromIndex = parseInt(data.fromAttrs.index);
                    const endIndex = parseInt(data.endAttrs.index);
                    if (fromIndex === endIndex) {
                        return;
                    }
                    if (dragDropIndex !== endIndex) {
                        return;
                    }
                    _dragdrop(fromIndex, endIndex);
                }
            });
        }
        return () => {
            dragableEle && dragableEle.destory();
        }
    }, [initFlowInfo, orderedFlow])

    useEffect(() => {
        form.setFieldsValue({
            taskName: initFlowInfo.configInfo.taskName,
            saveType: initFlowInfo.configInfo.saveType
        })
    }, [form, initFlowInfo])

    useEffect(() => {
        const userHasExpandBaseTitleArea = initFlowInfo.userHasExpandBaseTitleArea;
        if (initFlowInfo.receipts.length > 0 && !userHasExpandBaseTitleArea) {
            initFlowInfo.baseTitleIconExpand = false;
            setStore('plugin', { ...getStore('plugin'), initFlowInfo })
        }
    }, [initFlowInfo])

    const constructInitParams = () => {
        const params = {
            configInfo: toJS(initFlowInfo.configInfo),
            receipts: initFlowInfo.receipts.map((item, index) => {
                const obj = {
                    name: item.signerName,
                    email: item.isEmail ? item.signerEmailOrPhone : "",
                    mobile: item.isEmail ? "" : item.signerEmailOrPhone,
                    companyName: wardType === 0 ? item.companyName : "",
                    color: item.color,
                    wardType,
                    annots: toJS(item.annots),
                    companyId: item.companyId,
                    signOrder: orderedFlow || initFlowInfo.configInfo.saveType === INIT_FLOW_SAVE_TYPE.LOCAL ? index + 1 : 1
                }
                // if (!obj.companyId) {
                //     delete obj.companyId
                // }
                return obj
            })
        }
        return params;
    }

    const submit = () => {
        const preCheck = (initFlowInfo) => {
            let task = Promise.resolve();
            if (!initFlowInfo.baseTitleIconExpand && !initFlowInfo.configInfo.taskName) {
                initFlowInfo.baseTitleIconExpand = true;
                setStore('plugin', { ...getStore('plugin'), initFlowInfo });
                task = sleep(1000);
            }
            return task.then(() => {
                return form.validateFields()
                        .then(() => {
                            initFlowInfo.receipts.forEach(it => {
                                if (!it.signerEmailOrPhone) {
                                    return Promise.reject({ msg: "参与人邮箱或手机号不能为空"})
                                }
                                if (!it.annots || it.annots.length < 1) {
                                    return Promise.reject({ msg: "至少需要创建一个签名域" })
                                }
                            })
                            return Promise.resolve();
                        })
            })
        }
        preCheck(initFlowInfo).then(() => {
            const initFlowParams = constructInitParams()
            return setInitFlowParamsForPhantom(initFlowParams)
        }).then(() => {
            cleanInitFlowInfoCacheFn(initFlowInfo.uuid)
        })
        .catch(e => {
            console.error('init fail', e)
            if (e && typeof e === 'object' && e.msg) {
                if (e.msg !== 'INIT_SIGN_FAIL') {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "INIT_SIGN_ERROR_TIPS",
                        msg: e.msg
                    })
                }
            }
        })

    }

    const cancelInitFlowConfirm = () => {
        // window.eSignCN_cancelInitFlowForESignCN()
        showESignCNDialogAPI({ title: "福昕电子签章服务", type: "Dialog", id: "CANCEL_INIT_FLOW_FORM_CONFIRM", msg: "确定取消发起签署吗？"})
    }

    const onOrderedChange = (e) => {
        // setOrderedFlow(e.target.checked)
        const orderedFlow = e.target.checked;
        const initFlowInfo = getStore('plugin').initFlowInfo;
        initFlowInfo.orderedFlow = orderedFlow;
        setStore('plugin', { ...getStore('plugin'), initFlowInfo })
    }

    const handleClickTitleIcon = (type) => {
        if (type === 'base') {
            initFlowInfo.baseTitleIconExpand = !baseTitleIconExpand;
            setStore('plugin', { ...getStore('plugin'), initFlowInfo })
            if (!baseTitleIconExpand) {
                const initFlowInfo = getStore('plugin').initFlowInfo;
                initFlowInfo.userHasExpandBaseTitleArea = true;
                setStore('plugin', { ...getStore('plugin'), initFlowInfo })
            }
        } else if (type === 'flow') {
            initFlowInfo.flowTitleIconExpand = !flowTitleIconExpand;
            setStore('plugin', { ...getStore('plugin'), initFlowInfo })
        }
    }

    const showAddFlowButtons = ({ mini } = { mini: false }) => {
        return (
            <div>
                {
                    wardType === 0 &&
                    <>
                        <Button type="dashed" block={!mini} style={{ marginBottom: '10px', marginRight: mini ? '10px' : '0'}} onClick={() => handleEnter2Form('personSigner')}>{mini ? '+ 个人' : '+ 添加个人参与人'}</Button>

                        <Button type="dashed" block={!mini} onClick={() => handleEnter2Form('orgSigner')}>{mini ? '+ 企业' : '+ 添加企业参与人'}</Button>
                    </>
                }
                {
                    wardType === 1 &&
                    <>
                        <Button type="dashed" block={!mini} style={{ marginBottom: '10px'}} onClick={() => handleEnter2Form('inSigner')}>{mini ? '+ 参与人' : '+ 添加签章参与人'}</Button>
                    </>
                }
            </div>
        )
    }

    return <div className='container'>
        {/* 基本信息*/}
        <div className={'panelV2'}>
            <div className='panel-title'>
                <div className='panel-title-left'>
                    <span className={baseTitleIconExpand ? 'title-icon arrow-icon-down-white' : 'title-icon arrow-icon-right-white' } onClick={handleClickTitleIcon.bind(this, 'base')}></span>
                    基本信息
                </div>
                <div></div>
            </div>
            {
                baseTitleIconExpand &&
                <div>
                    <Form
                        name="configForm"
                        layout="Vertical"
                        wrapperCol={{ span: 24 }}
                        form={form}
                        initialValues={formData}
                        onValuesChange={onFormValuesChange}
                        autoComplete="off"
                        labelAlign="left"
                        colon={false}
                    >
                        <Form.Item
                            label="任务名称"
                            name="taskName"
                            rules={[{ required: true, message: '' }, {
                                validator: (rules, value) => checkTaskName(rules, value)
                            }]}
                        >
                            <Input placeholder='请输入任务名称，如"采购合同"'/>
                        </Form.Item>

                        <Form.Item
                            label="文档发送方式"
                            name="saveType"
                            rules={[{ required: true, message: '' }, {
                                validator: (rules, value) => checkSaveType(rules, value, fileSize)
                            }]}
                        >
                            <Select
                                onChange={handleSaveTypeChange}
                                options={saveTypeOption}
                            />
                        </Form.Item>
                    </Form>
                    <ul className={'tipsList'}>
                        {
                            tipMsg && tipMsg.map(tip => {
                                return <li key={tip}>{tip}</li>
                            })
                        }
                    </ul>
                </div>
            }
        </div>
        {/* 签署流程*/}
        <div className='panelV2'>
            <div className='panel-title'>
                <div className='panel-title-left'>
                    <span className={flowTitleIconExpand ? 'title-icon arrow-icon-down-white' : 'title-icon arrow-icon-right-white' } onClick={handleClickTitleIcon.bind(this, 'flow')}></span>
                    签署流程 {!!initFlowInfo.receipts.length && `(${initFlowInfo.receipts.length})`}
                </div>
                <div>
                    {
                        initFlowInfo.receipts.length > 0 &&
                        <>
                            {showAddFlowButtons({mini: true})}
                        </>
                    }
                </div>
            </div>
            {
                flowTitleIconExpand && initFlowInfo.receipts.length < 1 &&
                showAddFlowButtons({ mini: false })
            }
        </div>
        {
            flowTitleIconExpand &&
            <div className='flow-area'>
                {
                    formData.saveType === INIT_FLOW_SAVE_TYPE.ONLINE && initFlowInfo.receipts && initFlowInfo.receipts.length > 1 &&
                    <div style={{margin: "10px 0"}}><Checkbox defaultChecked={orderedFlow} checked={orderedFlow} onChange={onOrderedChange}><span style={{userSelect: "none"}}>按顺序签署</span></Checkbox></div>
                }
                {
                    initFlowInfo.receipts && initFlowInfo.receipts.length > 0 &&
                    <>
                        {/*参与人*/}
                        <ReceiptsItems
                            receiptsItemsRef={receiptsItemsRef}
                            form={form}
                            onFinishForm={onFinishForm}
                            handleEditSignerInfoForm={handleEditSignerInfoForm}
                            orderedFlow={orderedFlow}>
                        </ReceiptsItems>
                    </>
                }
            </div>
        }
        {/*发起按钮*/}
        {
            !!initFlowInfo.receipts.length &&
            <div className='btn-group'>
                <Button type="primary" shape="round" style={{width: '42%'}} onClick={submit} className='primary-btn'>发起</Button>
                <Button shape="round"  style={{width: '42%'}} onClick={cancelInitFlowConfirm}>取消</Button>
            </div>
        }
    </div>
}

export default observer(ConfigForm);