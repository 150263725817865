import React, {useEffect, useRef, useState} from 'react';
import {Select} from "antd";
import {searchEmailForSign} from "../../../../request/user";
import cuid from "cuid";
import {searchFlowManFn} from "../../service";
import {debounce} from "../../utils/util";

const { Option } = Select;


const OUT_PERSON_NAME = 'outPersonName';
const OUT_EMAIL_OR_PHONE = 'outEmailOrPhone';
const OUT_ORG_NAME = 'outOrgName';
const IN_PERSON_NAME = 'inPersonName';
const IN_EMAIL_OR_PHONE = 'inEmailOrPhone';


export function UserInfoSelector(props) {

    const { fieldType, companyNameChange, companyName, placeholder } = props; // fieldType: outPersonName: 对外用户姓名，outEmail，outOrgName: 对外企业名称 inPersonName: 对内用户名称, inEmail: 对内用户邮箱

    const [value, setValue] = useState();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    // 用来表示用户是否正在通过输入法输入
    const isInputMethodEditorActive = useRef(false);
    const searchText = useRef("");

    useEffect(() => {
        if (fieldType === OUT_ORG_NAME) {
            setValue(companyName)
        }
    }, [fieldType, companyName])

    function _searchEmail(str, type = OUT_PERSON_NAME) {
        if (!str) {
            return;
        }
        if ((type === IN_EMAIL_OR_PHONE || type === OUT_EMAIL_OR_PHONE) && str.length < 3 && /^\d+$/.test(str)) { // 少于3位的数字输入，不做查询
            return;
        }

        const params = {
            page: 1,
            pageSize: 50,
        };
        if (type === IN_PERSON_NAME || type === IN_EMAIL_OR_PHONE) {
            if (type === IN_PERSON_NAME) {
                params.username = str;
            } else {
                params.useremailOrMobile = str;
            }
            return searchEmailForSign(params);
        } else {
            switch (type) {
                case OUT_EMAIL_OR_PHONE:
                    params.type = 'contact';
                    break;
                case OUT_ORG_NAME:
                    params.type = 'company';
                    break;
                default:
                    params.type = 'name';
            }
            params.keyword = str;
            return searchFlowManFn(params);
        }
    }

    const renderOutFieldOption = (emailList) => {
        return emailList.map((item) => {
            const existEmailAndPhone = item.email && item.phone;
            const contact = item.email ? item.email : item.phone;

            return {
                value: `${item.name}#${contact}#${item.company}#${cuid()}`,
                label: (
                    <div
                        className='out-sign-field-options-container'
                        support-outline='true'
                    >
                        {
                            existEmailAndPhone ?
                                <>
                                    <div className='person-info'>
                                        <div className={fieldType === OUT_PERSON_NAME ? 'name light-height' : 'name'}>{item.name}</div>
                                        <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{item.phone}</div>
                                    </div>
                                    <div className={fieldType === OUT_ORG_NAME ? 'company light-height' : 'company'}>{item.company}</div>
                                    <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{item.email}</div>
                                </> :
                                <>
                                    <div className='person-info'>
                                        <div className={fieldType === OUT_PERSON_NAME ? 'name light-height' : 'name'}>{item.name}</div>
                                        {
                                            item.company &&
                                            <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{contact}</div>
                                        }
                                    </div>
                                    <div className={fieldType === OUT_ORG_NAME ? 'company' : 'company'}>{item.company}</div>
                                    {
                                        !item.company &&
                                        <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{contact}</div>
                                    }
                                </>
                        }
                    </div>
                ),
                userName: item.name,
                companyName: item.company,
                contact: contact
            }
        });
    }
    const renderInFieldOption = (emailList) => {
        return emailList.map((item) => {
            return {
                value: `${item.userName}${item.userEmail}${cuid()}`,
                label: (
                    <div
                        className='in-sign-field-options-container'
                        support-outline='true'
                    >
                        <div className={fieldType === IN_PERSON_NAME ? 'name light-height' : 'name'}>{item.userName}</div>
                        <div className={fieldType === IN_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{item.userEmail}</div>
                    </div>
                ),
                contact: item.userEmail,
                userName: item.userName
            }
        });
    }

    const renderOptions = (data, fieldType) => {
        if (fieldType === OUT_ORG_NAME || fieldType === OUT_PERSON_NAME || fieldType === OUT_EMAIL_OR_PHONE) {
            const emailList = data ? data : [];
            return renderOutFieldOption(emailList);
        } else {
            const emailList = data && data.users ? data.users : [];
            return renderInFieldOption(emailList);
        }
    }

    const handleSearch = async (value) => {
        searchText.current = value;
        const searchFn = async () => {
            try {
                setLoading(true);
                if (isInputMethodEditorActive.current) {
                    return;
                }
                if (value.trim().length < 3) {
                    return;
                }
                const data = await _searchEmail(value, fieldType);
                const formatOptions = renderOptions(data, fieldType);
                setOptions(formatOptions);
            } catch (e) {
                setOptions([])
            } finally {
                setLoading(false);
            }
        }
        debounce(searchFn, 500)();
    }

    const handleChange = (newValue) => {
        setValue(newValue);
        companyNameChange && companyNameChange(newValue ? newValue.split('#')[2] : newValue);
    }

    const handleCompositionStart = () => {
        isInputMethodEditorActive.current = true;
    }
    const handleCompositionEnd = () => {
        isInputMethodEditorActive.current = false;
        handleSearch(searchText.current)
    }

    const handleClear = () => {
        setValue(null);
        companyNameChange && companyNameChange(null);
    }

    const noDataContent = (loading) => {
        if (loading) {
            return <div style={{textAlign: 'center'}}><span>加载中...</span></div>
        } else {
            return <div style={{textAlign: 'center'}}><span>暂无数据</span></div>
        }
    }

    return <Select
        showSearch
        allowClear
        value={value}
        placeholder={placeholder}
        style={props.style}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={noDataContent(loading)}
        optionLabelProp="label"
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
        onClear={handleClear}
    >
        {
            !loading &&
            options.map((item, index) => {
                const valueArr = item.value.split('#');
                let label = '';
                if ([OUT_PERSON_NAME, IN_PERSON_NAME].includes(fieldType)) {
                    label = valueArr[0];
                } else if ([OUT_EMAIL_OR_PHONE, IN_EMAIL_OR_PHONE].includes(fieldType)) {
                    label = valueArr[1];
                } else if ([OUT_ORG_NAME].includes(fieldType)) {
                    label = valueArr[2];
                }

                return (
                    <Option value={item.value} label={label} key={index} name={index}>
                        {item.label}
                    </Option>
                )
            })
        }
    </Select>
}