import {useState, useRef} from "react";
import {Button, Form, Input} from "antd";
import {closeESignCNDialogAPI, closeESignCNDialogAPIV2} from "../../../../request/phantom";
import styles from "../index.module.less";
import {joinOrg} from "../../../../request/user";
import {getStore} from "../../../../store";
import {errorMessage, successMessage} from "../../../components/Message";
import {afterPluginVersion2023_3} from "../../utils/util";


const { TextArea } = Input;

export function NotHasJoinView(props) {

    const { orgName, orgId } = props;

    const [showApplyForm, setShowApplyForm] = useState(false);
    const formRef = useRef(null);

    const onOk = () => {
        setShowApplyForm(true);
    }
    const onCancel = () => {
        if (afterPluginVersion2023_3()) {
            const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
            const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
            const closeDialogIds = [ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG];
            closeESignCNDialogAPIV2(closeDialogIds);
        } else {
            closeESignCNDialogAPI()
        }
    }

    const onApplyMsgChange = (e) => {
        console.log(e.target.value);
    }

    const handleApply = () => {
        formRef.current.validateFields()
            .then((values) => {
                const { comment } = values;
                return joinOrg(true, orgId, comment,getStore('userInfo').token);
            })
            .then(() => {
                successMessage('申请已提交，请耐心等待组织管理员审批邮件通知。');
                setTimeout(() => {
                    if (afterPluginVersion2023_3()) {
                        const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
                        const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
                        const closeDialogIds = [ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG];
                        closeESignCNDialogAPIV2(closeDialogIds);
                    } else {
                        closeESignCNDialogAPI()
                    }
                }, 2000)
            })
            .catch(e => {
                errorMessage(`申请加入企业组织失败`);
                console.log('handleApply error', e);
        })
    }

    return <>
        <div className={styles.orgIcon}></div>
        <div className={styles.content1}>
            {
                !showApplyForm && <>
                    <div className={styles.msg}>
                        <div>{`您填写的”${orgName}“企业组织已存在,是否申请加入此企业组织?`}</div>
                    </div>
                    <div className={styles.btnGroup}>
                        <Button className={styles.primaryBtn} type="primary" htmlType="submit" onClick={onOk}>
                            申请加入
                        </Button>
                        <Button htmlType="button" onClick={onCancel}>
                            取消
                        </Button>
                    </div>
                </>
            }
            {
                showApplyForm && <>
                    <div className={styles.applyFormTitle}>申请加入企业组织</div>
                    <Form
                        className={styles.applyForm}
                        autoComplete="off"
                        ref={formRef}
                    >
                        <Form.Item
                            label="企业名称："
                            name="orgName"
                        >
                            {orgName}
                        </Form.Item>
                        <Form.Item
                            label="申请人："
                            name="applyUser"
                        >
                            {getStore('userInfo').userEmail}
                        </Form.Item>
                        <Form.Item
                            label="申请原因："
                            name="comment"
                            rules={[{ required: true, message: '申请原因不能为空' }]}
                        >
                            <TextArea showCount maxLength={50} onChange={onApplyMsgChange} placeholder="申请原因"/>
                        </Form.Item>
                    </Form>
                    <div className={styles.btnGroup}>
                        <Button className={styles.primaryBtn} type="primary" htmlType="submit" onClick={handleApply}>
                            确认
                        </Button>
                        <Button htmlType="button" onClick={onCancel}>
                            取消
                        </Button>
                    </div>
                </>
            }
        </div>
    </>
}