import {useEffect, useState} from "react";
import styles from './index.module.less';
import {InitStatusView} from "./components/initStatusView";
import {NotExistView} from "./components/notExistView";
import {CreateOrgSuccessView} from "./components/createOrgSuccessView";
import {ExistMultipleOrgView} from "./components/existMultipleOrgView";
import {CREATE_ORG_SUCCESS, EXIST_MULTIPLE_ORG, HAS_JOIN, INIT, NOT_EXIST, NOT_HAS_JOIN} from "./const";
import {HasJoinView} from "./components/hasJoinView";
import {NotHasJoinView} from "./components/NotHasJoinView";
import {getQueryVariable} from "../service";


function OutSignConfigOrg() {

    const [status, setStatus] = useState(INIT);

    const [orgName, setOrgName] = useState('');
    const [orgId, setOrgId] = useState('');
    const [defaultOrgName, setDefaultOrgName] = useState('');

    useEffect(() => {
        const orgName = getQueryVariable('orgName');
        setDefaultOrgName(orgName);
    }, [])

    const updateStatus = (status) => {
        setStatus(status);
    }
    const updateOrgName = (orgName) => {
        setOrgName(orgName);
    }
    const updateOrgId = (orgId) => {
        setOrgId(orgId);
    }

    return <div className={styles.container}>
        <div className={styles.orgConfigInfoContainer}>
            {
                status === INIT &&
                <InitStatusView defaultOrgName={defaultOrgName} updateStatus={updateStatus} updateOrgName={updateOrgName} updateOrgId={updateOrgId}/>
            }
            {
                status === NOT_EXIST &&
                <NotExistView orgName={orgName}/>
            }
            {
                status === CREATE_ORG_SUCCESS &&
                <CreateOrgSuccessView/>
            }
            {
                status === EXIST_MULTIPLE_ORG &&
                <ExistMultipleOrgView orgName={orgName}/>
            }
            {
                status === HAS_JOIN &&
                <HasJoinView orgName={orgName} orgId={orgId}/>
            }
            {
                status === NOT_HAS_JOIN &&
                <NotHasJoinView orgName={orgName} orgId={orgId}/>
            }
        </div>
    </div>
}

export default OutSignConfigOrg;