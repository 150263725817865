import React, {useEffect, useState} from "react";
import PcViewer from "./pcViewer";
import MobileViewer from "./mobileViewer";
import PhantomViewer from "./phantomViewer";
import AddMobile from "../addMobile";
import CertAuth from "../certAuth";
import JoinOrg from "../joinOrg";
import {getParams} from "../../../utils";
import SetOrgName from "../setOrgName";

export default function MainComponent(props) {
    const {client, type, stat} = props.querys;
    const {isLoading, tips, isError, codes, sendMsg, querys, userInfo} = props;
    const [showMain, setShowMain] = useState(true);
    const [showCert, setShowCert] = useState(0);
    const [showAddorg, setShowAddOrg] = useState(false);
    const [showSetOrg, setShowSetOrg] = useState(false);
    const [showMobile, setShowMobile] = useState(false);

    useEffect(() => {
        if (type === 10) { // 易签宝回调
            setShowCert(stat);
            setShowMain(false);
        } else if (type === 3 && codes.length === 1) { // 仅个人实名（广告推广）
            setShowCert(codes[0] === -3 ? 4 : 5);
            setShowMain(false);
        } else if (codes.length === 1 && codes[0] === -18) { // 输入企业名称
            setShowSetOrg(true);
            setShowMain(false);
        }
    }, [stat, type, codes]);


    const mobileFn = () => {
        setShowMobile(true);
        setShowMain(false);
    };
    const certFn = (stat) => {
        setShowCert(stat);
        setShowMain(false);
    };
    const addOrgFn = () => {
        setShowAddOrg(true);
        setShowMain(false);
    }

    const childProps = {
        querys,
        userInfo,
        sendMsg,
    }
    const viewerProps = {
        isLoading,
        tips,
        isError,
        codes,

        mobileFn,
        certFn,
        addOrgFn,
        ...childProps
    }

    let mainViewer,tempClient;
    if (!client) {
        const queryParams = getParams() || {};
        tempClient = Number(queryParams.client) || 0;
    } else {
        tempClient = client;
    }
    switch (tempClient) {
        case 0:
            mainViewer = <PcViewer {...viewerProps} />;
            break;
        case 1:
            mainViewer = <MobileViewer {...viewerProps} />;
            break;
        case 2:
            mainViewer = <PhantomViewer {...viewerProps} />;
            break;
        default:
    }
    return (
        <>
            {showMain && mainViewer}
            {showMobile && <AddMobile {...childProps}></AddMobile>}
            {showCert > 0 && <CertAuth {...childProps} stat={showCert} ></CertAuth>}
            {showAddorg && <JoinOrg {...childProps}></JoinOrg>}
            {showSetOrg && <SetOrgName {...viewerProps}></SetOrgName>}
        </>
    );
}
