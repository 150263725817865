import React, {useEffect} from 'react';
import styles from "./index.module.less";
import {Button, Progress, Spin} from "antd";
import dayjs from "dayjs";
import {getStore} from "../../../store";
import {getNewSubscription} from "../../../request/esign";

export function AccountContent() {

    const [tabType, setTabType] = React.useState(0);
    const [data, setData] = React.useState({});
    const [hasOut, setHasOut] = React.useState(false);
    const [hasIn, setHasIn] = React.useState(false);

    useEffect(() => {
        const tenantId = getStore('userInfo').enterpriseId;
        let param = {};
        if (tenantId) {
            param.tenantId = tenantId;
        }
        getNewSubscription(param).then(res => {
            const { orgExternal, orgInternal, personExternal } = res;
            if (orgExternal && orgExternal.cert) {
                setTabType(3);
            } else if (personExternal && personExternal.cert) {
                setTabType(2);
            } else if (orgInternal && orgInternal.cert) {
                setTabType(1);
            } else {
                setTabType(-1);
            }
            if ((orgExternal && orgExternal.cert) || (personExternal && personExternal.cert)) {
                setHasOut(true);
            }
            if (orgInternal && orgInternal.cert) {
                setHasIn(true);
            }
            setData(res);
        });
    }, []);




    const changeTab = (type) => {
        if (type > 1 && hasOut) {
            const { orgExternal, personExternal } = data;
            if (orgExternal && orgExternal.cert) {
                setTabType(3);
            } else if (personExternal && personExternal.cert) {
                setTabType(2);
            }
        } else {
            hasIn && setTabType(type);
        }
    };

    let percent = 0;
    let total = 0;
    let date = '';
    let isOver = false;
    let text = '';

    if (tabType > 0) {

    }
    switch (tabType) {
        case 1:
            total = '无限制';
            date = data.orgInternal?.expreTime || '';
            isOver = +data.orgInternal?.expreTime - (+data.serverTime) < 0;
            percent = isOver ? 0 : 100;
            text = (<div className={styles.text}>
                <div><span style={{ color: isOver ? 'red' : '#5522E6' }}>无限制</span></div>
            </div>);
            break;
        case 2:
        case 3:
            const extData = tabType === 2 ? data.personExternal : data.orgExternal;
            const usedNum = +extData.usedDocNumber || 0;
            const remainNum = +extData.docNumber || 0;
            total = usedNum + remainNum;
            if (total === 0 || remainNum === 0) {
                percent = 0;
            } else {
                percent = remainNum / total * 100;
            }

            date = extData?.expreTime || '';
            isOver = +extData?.expreTime - (+data.serverTime) < 0;
            text = (<div className={styles.text}>
                <div>剩余</div>
                <div><span style={{ color: isOver || remainNum === 0? 'red' : '#5522E6' }}>{remainNum.toLocaleString('en-US')}</span> 个</div>
            </div>);
            break;
        default:
            break;
    }

    return (
        <div>
            {tabType < 1 ?
                <div className={styles.empty}>
                    {tabType === 0 && <Spin size={'large'} />}
                    {tabType< 0 && <span>抱歉，未查询到签署额度信息。</span>}
                </div>
                : <>
                    <div className={styles.tabs}>
                        <div className={`${styles.tab} ${tabType > 1 ? styles.active : ''} ${!hasOut ? styles.disabled : ''}`}
                             onClick={() => changeTab(2)}>对外签署
                        </div>
                        <div className={`${styles.tab} ${tabType < 2 ? styles.active : ''} ${!hasIn ? styles.disabled : ''}`}
                             onClick={() => changeTab(1)}>对内签署
                        </div>
                    </div>
                    <div className={styles.process}>
                        <Progress type="circle" percent={percent}
                                  format={() => text}
                                  strokeColor={{
                                      '100%': '#A236B2',
                                      '0%': '#8B18E6',
                                  }}
                                  strokeWidth={10} />
                    </div>
                    <div className={styles.date}>
                        <span>{dayjs(date * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>
                        <span style={{ color: isOver ? 'red' : '' }}> {isOver ? '已到期' : '到期'}</span>
                    </div>
                </>}

            <div esigncn-point-modal="true">
                <Button ghost={true} type={'primary'}
                        onClick={() => {
                            window.open('https://www.foxitsoftware.cn/esign#sales');
                        }}
                >联系销售购买</Button>
            </div>


            <ul className={styles.tips}>
                <li>每签署一份文档消耗一个签署额度，支持同时添加多个印章；</li>
                <li>邀请他人签署时仅发起人消耗一个额度，参与签署人无需消耗额度。</li>
            </ul>

            {tabType > 1 && <div className={styles.outType}>
                {tabType === 2 ? '个人' : '企业'}
            </div>}
        </div>
    )
}