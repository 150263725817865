import {Tooltip} from "antd";
import React from 'react'

export default function TableRowWrapper(props) {
    const { children, ...rest } = props;
    const { val, placement = 'left' } = rest;
    return (
        <Tooltip placement={placement} title={val}>
            {children}
        </Tooltip>
    )
}