import {Modal} from "antd";
import {getOrgAdminUrl, getPersonAdminUrl} from "../../../request/esign";
import {memo, useEffect, useRef, useState} from "react";
import {CloseCircleFilled, SyncOutlined} from "@ant-design/icons";
import styles from './StampManage.module.less';
import StampInnerList from "./StampInnerList";
import {getStore} from "../../../store";
import CreateSeal from "./createInnerSeals/CreateSeal";
import {
    ERROR_AC_ORG_PERMISSION_FAIL,
    ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED, ERROR_EQB_ORG_TOKEN_EXPIRED, ERROR_EQB_SEAL_PERMISSION_FAIL
} from "../../../request/error_container";

const BAD_REQEST = 'bad request';

export default memo(StampManage);
function StampManage(props) {
    const {serverInfo, pdfInfo, visible, seals, isOutTemp, accountId: accountIdFromContainer, orgId: orgIdFromContainer} = props;
    const [url, setUrl] = useState('');
    const title = useRef(null);
    const msg = useRef('');
    const wardType = parseInt(serverInfo.ward_type)

    useEffect(() => {
        if (!pdfInfo.myItem) {
            return;
        }

        if (!visible) {
            return ;
        }

        // const {orgId, accountId} = getStore('userInfo');
        const orgId = orgIdFromContainer ? orgIdFromContainer : getStore('userInfo') && getStore('userInfo').orgId;
        const accountId = accountIdFromContainer ? accountIdFromContainer : getStore('userInfo') && getStore('userInfo').accountId;
        const {company} = pdfInfo.myItem;
        if ( wardType === 0 && !isOutTemp) {
            title.current = company ? '企业印章管理':'个人印章管理';
            const request = company ? getOrgAdminUrl({orgId}) : getPersonAdminUrl({accountId});
            request.then(url => {
                setUrl(url);
            }).catch(key => {
                setUrl(BAD_REQEST);
                const result = {};
                if (ERROR_EQB_AUTH_EXPIRED === key) {
                    if (company) {
                        result.txt = '提示：您的授权过期，请重新完成企业认证/授权。';
                        result.link = "/certification?orgName=" + company;
                        result.linkTxt = '点击认证/授权';
                    } else {
                        result.txt = '提示：您的授权过期，请重新完成个人实名认证。';
                        result.link = "/certification";
                        result.linkTxt = '点击认证';
                    }
                }
                else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    result.txt = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    result.txt = '您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。';
                    result.link = 'https://www.foxitsoftware.cn/esign#sales';
                    result.linkTxt = '联系销售';
                }
                else if (ERROR_AC_PERMISSION_FAIL === key) {
                    result.txt = '提示：您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。';
                } else if(ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    result.txt = '提示：您暂无印章管理权限，请联系IT管理员分配授权后重试。';
                }
                msg.current = result;
            })
        } else {
            setUrl('inner')
        }
    }, [serverInfo, pdfInfo, visible, accountIdFromContainer, orgIdFromContainer, wardType, isOutTemp])


    const handleCancel = () => {
        setUrl(null);
        props.close();
    };

    // 添加临时可用的对外签章
    const closeAdd = (data) => {
        if (!data) {
            data = {id: 0, base64: null};
        } else {
           data =  {id: 1, base64: data};
        }
        props.close(data);
    }

    if (!visible) {
        return
    }

    if (wardType === 0) {
        if (isOutTemp) {
            return <CreateSeal closeAdd={closeAdd} isOutTemp={true}></CreateSeal>
        }
        return <Modal   title={title.current} open={true}  onCancel={handleCancel}
                        footer={null} centered={true} maskClosable={false} width={'1200px'} bodyStyle={{padding: '0', margin: 0}}
                >
                    {
                        url ? (
                            url === BAD_REQEST ?
                                <div className={styles.error}>
                                    <CloseCircleFilled /> &emsp;{msg.current.txt}
                                    {msg.current.link && msg.current.linkTxt ? (
                                        <a href={msg.current.link}>{msg.current.linkTxt}</a>
                                    ) : ''}
                                </div> :
                                <iframe title="eSignCn" src={url} className={styles.iframe} height='600px'></iframe>
                            ) :
                            <div className={styles.loading}><SyncOutlined spin />&emsp;正在获取管理链接…</div>
                    }
                </Modal>
    }

    if (wardType === 1) {
        return <StampInnerList visible={true} handleCancel={handleCancel} seals={seals} />
    }
}
