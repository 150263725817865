/**
 * 公共插件，用于online和pc的自签，发起，参与的意愿验证
 * 插件通过iframe的方式嵌入到页面中，通过query params向插件发送消息，通过postMessage返回结果
 * 验证完成后，会在界面上展示成功或错误信息；同时验证成功后，会通过消息返回success；

 * 请求路径 pcDomain + /willingness

 * 传入必填参数
 * 登录令牌 tk, token
 * 调用方 c, client = 0/1/2 0默认：pc端 1：移动端 2：客户端

 * 传入可选参数
 * 自定义header中x-client-from字段，用于区分不同的调用方 cf, clientFrom 如不传则通过client值判断
 * 调用方自传字段，会原样返回 from


 * 返回的消息结构体
 * name: esignCNWillAuthPlugin 用于标识消息来源
 * type: close|resize       返回值类型，close表示结束，resize表示调整iframe大小
 * success: boolean         type为close时有效，true表示验证通过，false表示验证失败
 * willData: object             type为close，且sucess为true时有效，意愿认证成功时的数据
 *  {
 *      status
 *      willAuthId
 *      willAuthTime
 *      willAuthType
 *      willingnessBizId
 *  }
 *
 * width: string            type为resize时有效，提示调用方调整iframe宽度
 * height: string           type为resize时有效，提示调用方调整iframe高度

 */

import React, {useCallback, useEffect, useRef} from "react";
import styles from "./index.module.less";
import {useState} from "react";
import {getWillAuthResult, getWillAuthUrl, setHeaderClient} from "../../request/esign";
import iconOk from "../../assets/images/auth-ok.png";
import iconError from "../../assets/images/error.png";
import {Button} from "antd";
import {getParams, getSupplierId} from "../../utils";

export const WILLAUTH_PLUGIN_URI = `/willingness`;
const MsgName = 'esignCNWillAuthPlugin';
const CacheName = 'willAuthPluginParams';

export default function WillAuthPlugin() {
    const [loading, setLoading] = useState('');
    const [resStat, setResStat] = useState(0);
    const [querys, setQuerys] = useState({});
    const [info, setInfo] = useState({});
    const [sec, setSec] = useState(5);
    const timer = useRef(0);

    const getUrl = useCallback((accountId, token) =>  {
        setLoading('正在获取意愿认证链接…');
        const redirectUrl = window.location.origin + WILLAUTH_PLUGIN_URI + `?supplierId=${getSupplierId()}`;
        getWillAuthUrl({accountId, token, redirectUrl}).then(data => {
            _sendMsg({type: 'resize', height: '427px'});
            const {shortUrl, bizId} = data;
            const tempParams = JSON.parse(sessionStorage.getItem(CacheName) || '{}');
            tempParams.bizId = bizId;
            sessionStorage.setItem(CacheName, JSON.stringify(tempParams));
            window.location.href = shortUrl;
        })
    }, []);

    const checkAuth = useCallback((bizId, token) => {
        _sendMsg({type: 'resize', height: '345px'});
        setLoading('正在验证意愿认证…');
        getWillAuthResult({bizId, token}).then(res => {
            const { status, willAuthType, endTime, willAuthId } = res;
            if (status === 1 ) {
                setResStat(1);
                const data = {status, willAuthType, willAuthTime: endTime, willingnessBizId: bizId, willAuthId};
                timer.current = setInterval(() => setSec(prev => prev  -1), 1000);
                setInfo(data);
            } else {
                setInfo({status});
            }
        }).catch(e => {

        }).finally(() => {
            setLoading('');
        });
    }, []);

    useEffect(() => {
        const queryParams = _formatQuery();
        if (!queryParams) {
            return;
        }
        const {accountId, token, willAuthId, clientFrom, client, bizId} = queryParams;
        setHeaderClient({client, value: clientFrom});
        setQuerys(queryParams);
        if (willAuthId) {
            checkAuth(bizId, token);
        } else {
            getUrl(accountId, token);
        }
        return () => {
            timer.current && clearInterval(timer.current);
        }
    }, [getUrl, checkAuth]);


    const close = () => {
        _sendMsg({
            type: 'close',
            success: info.status > 0,
            willData: info.status > 0 ? info : null
        }, querys);
    }

    if (sec < 1 && timer.current) {
        clearInterval(timer.current);
        timer.current = 0
        close();
    }

    return (<>
        {loading.length > 0 && <div className={styles.loadTxt}>{loading}</div>}
        {!loading && <div className={styles.main} >
            <img src={resStat === 1 ? iconOk : iconError} alt={"icon"} width="355" />
            <div className={styles.title}>{resStat === 1 ? '意愿认证成功' : '意愿认证失败'}</div>
            <div className={styles.tips}>
                {resStat === 1 ? '请点击按钮继续完成签署' : '请关闭后重新发起意愿认证'}
            </div>
            {resStat === 1 ?
                <Button onClick={close} shape={"round"} type={"primary"} >完成（{sec}s）</Button>
                :
                <Button onClick={close} shape={"round"} >关闭</Button>
            }
        </div>}
    </>)

}

function _formatQuery() {
    const query = {};
    let queryParams = getParams() || {};
    if (!queryParams.token) {
        const tempParams = JSON.parse(sessionStorage.getItem(CacheName) || '{}');
        queryParams = {...tempParams, ...queryParams};
    }
    if (!queryParams.token) {
        return null;
    }
    if (queryParams.willAuthId) {
        if (!queryParams.bizId) {
            return null;
        }
        query.bizId = queryParams.bizId;

        query.willAuthId = queryParams.willAuthId;
        sessionStorage.removeItem(CacheName);
    } else {
        sessionStorage.setItem(CacheName, JSON.stringify(queryParams));
    }
    query.accountId = queryParams.accountId;
    query.clientFrom = queryParams.clientFrom;
    query.from = queryParams.from;

    query.token = queryParams.token;
    query.client = Number(queryParams.client) || 0;

    return query;
}

function _sendMsg(data = {}, queryParams = {}) {
    if (!data) {
        return;
    }
    if (!data.type) {
        return;
    }
    if (!['close', 'resize'].includes(data.type)) {
        return;
    }
    data.name = MsgName;
    queryParams && (data.from = queryParams.from);
    window.parent.postMessage(data, '*');
}