import {Button, ConfigProvider, DatePicker} from "antd";
import dayjs from "dayjs";
import locale from 'antd/es/locale/zh_CN';
import {useEffect, useState} from "react";
import styles from './index.module.less';
import {closeESignCNDialogAPI, setDeadlineCCInfoForPhantom} from "../../../../../request/phantom";
import {getQueryVariable} from "../../../service";

const disabledDate = (current) => {
    return current && current.endOf('day') < dayjs().endOf('day');
};
export function DeadlineDialog() {
    const [value, setValue] = useState(null);

    const confirm = () => {
        let deadlineStamp = setDeadline();
        const params = {
            type: 'deadline',
            expireTime: deadlineStamp,
        }
        console.log(params)
        setDeadlineCCInfoForPhantom(params)
        .then(() => {
           return closeESignCNDialogAPI();
        })
    }

    const setDeadline = () => {
        let val = 0;
        if (value) {
            val = dayjs(value.format("YYYY-MM-DD 23:59:59"))
            val = val.unix()
        }
        return val;
    }

    const cancel = () => {
        closeESignCNDialogAPI();
    }

    useEffect(() => {
        const defaultDeadline = getQueryVariable('dl');
        if (defaultDeadline) {
           setValue(dayjs(new Date(parseInt(defaultDeadline) * 1000)));
        }
    }, [])

    return (
        <ConfigProvider locale={locale}>
            <div className={styles.container}>
                <div className={styles.main}>
                    <DatePicker
                        size="middle"
                        format={"YYYY-MM-DD 23:59:59"}
                        disabledDate={disabledDate}
                        style={{ width: "100%" }}
                        value={value}
                        onChange={(val) => setValue(val)}
                    />
                    <div
                        style={{
                            background: "#F5F6FA",
                            padding: "10px",
                            fontSize: "12px",
                            color: "#515151",
                            marginTop: "18px",
                        }}
                    >
                        <div>1.如果日期为空，则认为无签署截止日期</div>
                        <div>2.系统会在到期前1天，提醒各参与人</div>
                    </div>
                </div>
                <div style={{ textAlign: "right", marginTop: "30px" }}>
                    <Button
                        onClick={() => confirm()}
                        style={{
                            height: "28px",
                            padding: "0 25px",
                            background: "#982E91",
                            border: "#982E91",
                            color: "#fff",
                            marginRight: "10px",
                        }}
                        type="primary"
                        shape="round"
                    >
                        确认
                    </Button>
                    <Button
                        onClick={() => cancel()}
                        style={{
                            height: "28px",
                            padding: "0 25px",
                        }}
                        shape="round"
                    >
                        取消
                    </Button>
                </div>
            </div>
        </ConfigProvider>
    )
}