import {AutoComplete, Button} from "antd";
import styles from "../index.module.less";
import nameStyles from "./pcView.module.less";
import React, {useState} from "react";
import {searchOrgName} from "../../../request/esign";
import {debounce} from "../../../utils";

export default function PcViewer(props) {
    const {sendMsg} = props;
    const [loading, setLoading] = useState(false);
    const [inputStatus, setInputStatus] = useState('');
    const [orgName, setOrgName] = useState('');
    const [searchLoading, setSearchLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const closeFn = () => {
        sendMsg({type: 'close'});
    }

    const onSearch = (str) => {
        if (!str || str.length < 2) {
            return;
        }
        debounce(() => {
            setSearchLoading(true);
            getOptions(props.querys.token,str).then(list => {
                setOptions(list);
            }).catch(() => {
                setOptions([]);
            }).finally(() => {
                setSearchLoading(false);
            });
        }, 500)();
    };


    const changeValue = (value = '') => {
        value = value.trim();
        if (!value) {
            setOrgName('');
            setInputStatus('warning');
        } else {
            setOrgName(value);
            setInputStatus('');
        }
    };

    const submit = () => {
        setLoading(true);
        let search = window.location.search;
        if (search.includes('cn=setting')) {
            search = search.replace('cn=setting', `cn=${orgName}`);
        } else if (search.includes('cn=')) {
            search = search.replace('cn=', `cn=${orgName}`);
        } else {
            search += `&cn=${orgName}`;
        }
        window.location.search = search;
        // window.location.reload();
    };

    return (
        <>
            <div className={styles.result}>
                <div className={styles.icon}>
                    <i className={styles.img + ' ' + styles.org}></i>
                    <p className={styles.stat}>
                        <i className={styles.img + ' ' + styles.plus}></i></p>
                </div>

                <div className={styles.container}>
                    <div className={styles.title}>填写企业组织信息</div>
                    {/*<Input value={orgName} status={inputStatus} onChange={changeValue} placeholder="请输入企业名称"*/}
                    {/*       maxLength={50} allowClear/>*/}

                    <AutoComplete
                        options={options}
                        style={{ width: '100%' }}
                        onSelect={changeValue}
                        onSearch={onSearch}
                        onChange={changeValue}
                        popupClassName={nameStyles.nameOptions}
                        value={orgName}
                        maxLength={50}
                    status={inputStatus}
                                      placeholder={`请输入企业全称`}
                                      allowClear={!searchLoading}
                                      loading={searchLoading}>
                    </AutoComplete>

                </div>
            </div>
            <div className={styles.buttons}>
                <Button loading={loading} onClick={submit} type='primary' style={{width: '90px'}}>确认</Button>
                <Button key="back" onClick={() => closeFn()} style={{width: '90px', marginLeft: '10px'}}>关闭</Button>
            </div>
        </>

    )
}

async function getOptions(token,keyword) {
    const params = {
        token,
        page: 1,
        pageSize: 20,
        keyword
    };
    const list = await searchOrgName(params);
    if (!list || list.length < 1) {
        return [];
    }
    return list.map(item => {
        const name = item.name;
        return {
            value: name,
            label: (<div title={name} className={nameStyles.item}>
                <div className={nameStyles.elli}>{name}</div>
            </div>),
        };
    });
}