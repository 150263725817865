import {observer} from "mobx-react";
import {getStore} from "@/store";
import {useEffect, useState} from "react";
import styles from './StampDropDown.module.less'
import {setSigAPForPhantom, showESignCNDialogAPI} from "@/request/phantom";
import {getOrgAdminUrl, getPersonAdminUrl} from "@/request/esign";
import {loadingMessage} from "@/pages/components/Message";
import {requestSealsToStore} from "../../service";

function PhantomStampDropDownV2() {

    const [seals, setSeals] = useState([])
    const [sealsLoading, setSealsLoading] = useState(false)
    const [adminLoading, setAdminLoading] = useState(false)
    const [sealsLoadingError, setSealsLoadingError] = useState(false)
    const [settingIconClassList, setSettingIconClassList] = useState([styles.settingIcon])
    const [noDataReason, setNoDataReason] = useState('')

    const initFn = async (refresh = true) => {
        const wardType = parseInt(getQueryVariable('wardType'))
        const isOrg = getQueryVariable('isOrg')
        setNoDataReason('');
        try {
            setSealsLoadingError(false)
            if (refresh) {
                setSealsLoading(true)
                await requestSealsToStore({company: getStore('userInfo').enterpriseName, notLoadOutOrgSeal: isOrg === 'false'})
            }
            if(getStore('plugin').sealsError) {
                setSealsLoadingError(true)
                setSealsLoading(false)
                return;
            }
            let data;
            if (wardType === 1) {
                data = getStore('plugin').inSeals
            } else {
                if (isOrg === 'true') {
                    data = getStore('plugin').outOrgSeals ? getStore('plugin').outOrgSeals.filter(stamp => stamp.usePermission) : []
                    if ((!data || data.length === 0) && getStore('plugin').outOrgSeals && getStore('plugin').outOrgSeals.length > 0) {
                        setNoDataReason('您暂无权限使用该企业任何公章')
                    }
                } else {
                    data = getStore('plugin').outPersonSeals
                }
            }
            setSeals(data);
            setSealsLoading(false)
        } catch (e) {
            console.error('phantom stamp dropdown init error', e)
            setSealsLoadingError(true)
            setSealsLoading(false)
        }
    }

    useEffect(() => {
        initFn(true)
        window.eSignCN_initStampDropDownFn = initFn;
    }, [])

    const enterPersonAdmin = async () => {
        try {
            if (adminLoading) {
                return;
            }
            setAdminLoading(true)
            setSettingIconClassList([styles.settingIcon, styles.adminLoading])
            const url = await getPersonAdminUrl({ accountId: getStore('userInfo').accountId });
            const webUrl = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(url)}&wardType=0&width=1217&height=600`
            showESignCNDialogAPI({ title: '个人印章管理', id: "ESIGNCN_PERSON_STAMP_MANAGE", type: 'Window', webUrl, width: 600, height: 600 })
        } catch (e) {
            if (e.data && e.data.msg) {
                loadingMessage(e.data.msg, 2)
            }
        }
        finally {
            setAdminLoading(false)
            setSettingIconClassList([styles.settingIcon])
        }
    }
    const enterOrgAdmin = async () => {
        try {
            if (adminLoading) {
                return;
            }
            setAdminLoading(true)
            setSettingIconClassList([styles.settingIcon, styles.adminLoading])
            const url = await getOrgAdminUrl({ orgId: getStore('userInfo').orgId });
            const webUrl = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(url)}&wardType=0`
            showESignCNDialogAPI({ title: '企业印章管理', id: "ESIGNCN_ORG_STAMP_MANAGE", type: 'Window', webUrl, width: 600, height: 600 })
        }
        catch (key) {
            console.error(key)
        }
        finally {
            setAdminLoading(false)
            setSettingIconClassList([styles.settingIcon])
        }

    }

    const setSignatureAP = (base64AP, sealId, sealName) => {
        const base64 = base64AP.replace(/^data:.*?base64,/, '')
        setSigAPForPhantom({ base64AP: base64, sealId, sealName })
    }

    const loadStampAdminPage = async () => {
        const wardType = parseInt(getQueryVariable('wardType'))
        const isOrg = getQueryVariable('isOrg')
        if (wardType === 1) {
            showESignCNDialogAPI({ title: "管理签名", type: "Window", id: "ESIGNCN_INSTAMP_MANAGE", webUrl: `${window.location.origin}/stamp-manage?wardType=1`, width: 600, height: 600 })
        } else {
            if (isOrg === 'true') {
                await enterOrgAdmin()
            } else {
                await enterPersonAdmin()
            }
        }
    }

    const sealList = (seals) => {
        if (!seals || seals.length < 1) {
            return <div className={styles.noData}>
                <div className={styles.msg}>
                    <div>暂无数据</div>
                    <div>{noDataReason}</div>
                </div>
            </div>
        }
        return seals.map((seal) => {
            return <div className={styles.signatureItem} support-outline='true' key={`${seal.sealId}-${seal.id}`}>
                <img src={seal.base64} className={styles.eSignCNStamp} alt='eSignCNStamp' onClick={() => {setSignatureAP(seal.base64, seal.sealId || seal.id, seal.alias)}}/>
            </div>
        })
    }

    return <div className={styles.stampDropDownContainer}>
        <div className={styles.toolBarContainer} name="toolBarContainer">
            <div name='settingIconBtn' className={styles.settingIconBtn} onClick={loadStampAdminPage}>
                <span name='settingIconMsg'>印章管理</span><i className={settingIconClassList.join(' ')} name='settingIcon'></i>
            </div>
        </div>
        {
            !sealsLoading && !sealsLoadingError &&
            <div className={styles.stampListContainer}>
                {sealList(seals)}
            </div>
        }
        {  sealsLoading && !sealsLoadingError &&
            <div className={styles.dropdownBoxLoading} name="dropdown-box-loading">
              <span className={styles['e-sign-spin-dot-spin']}>
                <i className={styles['e-sign-spin-dot-item']}></i>
                <i className={styles['e-sign-spin-dot-item']}></i>
                <i className={styles['e-sign-spin-dot-item']}></i>
                <i className={styles['e-sign-spin-dot-item']}></i>
              </span>
            </div>
        }
        {   sealsLoadingError &&
            <div className={styles.dropdownBoxMsgContainer} name="msg-panel">
                <div name="msg-content">网络异常，</div>
                <div className={styles.refreshBtn} name="e-sign-cn__refresh-btn" onClick={() => initFn()}>点击刷新</div>
            </div>
        }
    </div>
}

function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (pair[0] === variable) {
            let value = decodeURIComponent(pair[1]);
            return value;
        }
    }
    return null;
}

export default observer(PhantomStampDropDownV2)