import React, {useEffect, useState} from "react";
import {
    getAuthUrl,
} from "../../../request/esign";
import styles from "../phantom.module.less";
import icon from "../../../assets/images/authPlugin/esignbao.png";
import {Button, Skeleton} from "antd";

export default function PhantomViewer(props) {
    const {flowId, type, from } = props.querys;
    const { enterpriseId } = props;
    return <>
        {type === 10 &&  <Result {...props} flowId={flowId} from={from}></Result>}
        {type !== 10 &&  props.stat !== 5 && <Agree {...props}></Agree>}
        {type === 3 && props.stat === 5 && <ResultUI stat={props.stat} sendMsg={props.sendMsg} from={from} enterpriseId={enterpriseId}></ResultUI>}
    </>
}

function Agree(props) {
    const {token, authParams, sendMsg} = props;
    const [btnLoading, setBtnLoading] = useState(false);
    const [showUI, setShowUI] = useState(false);

    useEffect(() => {
        sendMsg({type: 'resize', height: '480px', width: '800px'});
        if (authParams.orgName) {
            _getUrl({token, authParams, sendMsg});
        } else {
            setShowUI(true);
        }
    }, [token, sendMsg, authParams]);


    const toEsignbao = () =>{
        setBtnLoading(true);
        _getUrl({token, authParams, sendMsg});
    }

    return showUI && (
        <div className={styles.agree} style={{margin: 0, padding:0}}>
            <div className={styles.head}>
                <div className={styles.title} style={{width: '50%'}}>
                    请您知悉，本电子签章服务的部分功能由第三方机构e签宝为您提供。
                </div>
                <img src={icon} alt={"icon"} height="59" />
            </div>
            <div className={styles.box}>
                为向您提供电子签章服务，您需授权使用您的福昕账号登录e签宝平台，或将您的福昕账号与e签宝账号绑定。
                <br/><br/>
                为保障您的文档内容的私密性，避免您或他人的个人隐私、商业秘密等内容泄露，福昕会将您的文档进行哈希加密，并由e签宝基于文档哈希值为您提供电子签章服务。
                福昕和e签宝平台不会获取、保存您的签署文档原文。因此，请您妥善保存签署文档。
                <br/><br/>
                在您使用本电子签章服务过程中，e签宝对您个人信息的收集、使用将遵循
                <a href="https://www.esign.cn/gw/fwxy/?current=1" rel="noreferrer" target='_blank'>e签宝隐私政策</a>，福昕对e签宝收集、使用您的个人信息不承担责任。
                请您在使用本电子签章服务前认真阅读e签宝隐私政策以了解e签宝如何收集、使用您的个人信息。
            </div>
            <div className={styles.buttons}>
                <Button loading={btnLoading} onClick={toEsignbao}  type={"primary"} style={{width: '125px'}}>同意并继续</Button>
                <Button key="back" onClick={() => sendMsg({type: 'close'})} style={{width: '90px', marginLeft: '15px'}}>取消</Button>
            </div>
        </div>
    )
}

function _getUrl({authParams, token, sendMsg}){
    getAuthUrl(authParams, token).then(url => {
        if (url) {
            sendMsg({type: 'resize', width: '1100px'});
            window.location.replace(url);
        }
    });
}

function Result(props) {
    const {token, flowId, enterpriseId, email, mobile, authParams, sendMsg, stat, seller, initFn, from } = props;
    const [info, setInfo] = useState(    {});
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        sendMsg({type: 'resize', height: '250px'});
        setIsLoading(true);
        initFn({token, flowId, authParams, enterpriseId, email, mobile}).then(info => {
            if (!info.summary) {
                info.summary = `点击【${stat === 4 ? '立即体验' : '关闭'}】，继续完成后续签署相关操作。`;
            }
            setInfo(info);
            setSuccess(!info.isError);
        }).catch(e => {
            setInfo({
                title: '实名认证/授权失败',
                summary: `点击【关闭】，可重新尝试实名认证/授权或${seller}处理。`
            });
            setSuccess(false);
            throw e;
        }).finally(() => {
            setIsLoading(false);
        });
    }, [sendMsg, token, flowId, authParams, enterpriseId, email, mobile, initFn, seller, stat]);


    return <ResultUI stat={stat} isLoading={isLoading} success={success} info={info} sendMsg={sendMsg} from={from} enterpriseId={enterpriseId}></ResultUI>
}


function ResultUI(props) {
    let {isLoading= true, stat, success = false, info, sendMsg, from, enterpriseId} = props;
    sendMsg({type: 'resize', height: '250px'});
    if (stat === 5) {
        isLoading = false;
        success = true;
        info = {
            title: '您已完成个人实名认证',
            summary: '点击【立即体验】，继续完成后续签署相关操作。'
        }
    }

    const closeFn = (setName = false) => {
        if (from === 'phantom-promotion' && !setName) {
            const params = { type: 'close', success }
            if (enterpriseId) params.enterpriseId = enterpriseId;
            sendMsg(params);
        } else {
            if (stat > 3) {
                sendMsg({type:'close', success});
            } else {
                sendMsg({type:'close', reload: success, setName, fc: 1});
            }
        }
    }
    const type = +JSON.parse(sessionStorage.getItem("authPluginParams") || '{}').type;
    return (
        <>
            <Skeleton active loading={isLoading}  className={styles.skeleton}>
                <div className={styles.result}>
                    <div className={styles.icon}>
                        <i className={styles.img + ' ' + (stat === 2 || stat === 3 ? styles.org : styles.cert ) }></i>
                        <p className={styles.stat + ' ' + (success ? styles.statOk : (info.title === '企业正在创建中' ? '' : styles.statErr))}>
                            <i className={styles.img + ' ' + (success ? styles.ok : (info.title === '企业正在创建中' ? styles.waiting : styles.error))}></i></p>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.title} dangerouslySetInnerHTML={{__html: info.title}}></div>
                        <div className={styles.tips} dangerouslySetInnerHTML={{__html: info.summary}}></div>
                    </div>
                </div>
                <div className={styles.buttons}>
                    {type === 1 && stat === 1 && success && <Button type="primary" key="orgIn" onClick={() => closeFn(true) }
                                                     style={{width: '120px'}}>企业实名认证</Button>}
                    <Button type={stat > 3 ? "primary": "default"} key="back" onClick={() => closeFn() }
                            style={{width: '90px', marginLeft: '10px'}}>{stat > 3 ? '立即体验' : '关闭'}</Button>
                </div>
            </Skeleton>
        </>
    );
}
