import CreateSeal from "./createInSeals/CreateSeal";
import {getParams} from "../../../../utils";
import {PUBLIC_SEAL_TYPE} from "../../constant";
import PureOutSealAdminComp from "./outSealAdmin/pureOutSealAdminComp";

export default function PublicSeal() {

    function close(seals, reAuth = false) {
        window.parent.postMessage({
            name: 'PUBLIC_SEAL',
            type: 'close', success: !!seals, sealData: seals, reAuth
        }, window.location.origin);
    }

    const params = getParams();
    const publicSealType = params ? params.publicSealType : '';
    return (
        <div style={{ padding: '10px' }}>
            {
                publicSealType === PUBLIC_SEAL_TYPE.CREATE_SEAL &&
                <CreateSeal close={close}></CreateSeal>
            }
            {
                publicSealType === PUBLIC_SEAL_TYPE.OUT_SEAL_ADMIN &&
                <PureOutSealAdminComp />
            }
        </div>
    )
}