import {getStore, setStore} from "@/store";
import {observer} from "mobx-react";
import {deleteFieldsForPhantom, jumpToTheFieldForPhantom, showESignCNDialogAPI} from "@/request/phantom";
import {useCallback, useEffect} from "react";
const fieldTypeMap = {
    FullSignature: "签名域",
    Date: "日期域",
    Title: "文本域"
}

let willCancelReceiptItemColor = "";

function ReceiptsItems(props) {
    const { receiptsItemsRef, handleEditSignerInfoForm, orderedFlow } = props;
    const initFlowInfo = getStore('plugin').initFlowInfo;

    const deleteCurSignerConfirm = (e, color) => {
        willCancelReceiptItemColor = color;
        e && e.stopPropagation && e.stopPropagation()
        showESignCNDialogAPI({
            title: "福昕电子签章服务",
            type: "Dialog",
            id: "DELETE_INIT_FLOW_RECEIPT_ITEM_CONFIRM",
            msg: "确定删除签章参与人吗？",
            okCallback: 'eSignCN_deleteCurSigner',
            extData: {
                color
            }
        })
        // deleteCurSigner(color)
    }

    const deleteCurSigner = useCallback((color) => {
        color = color || willCancelReceiptItemColor;
        const receipts = []
        let deleteAnnots = []
        initFlowInfo.receipts.forEach(item => {
            if(item.color === color) {
                if (item.annots && item.annots.length > 0) {
                    deleteAnnots = item.annots.map(annot => ({ objNumber: annot.objNumber, pageIndex: annot.pageIndex }))
                }
            } else {
                receipts.push(item);
            }
        })
        initFlowInfo.receipts = receipts;
        setStore('plugin', { ...getStore('plugin'), initFlowInfo:  { ...initFlowInfo } })
        if (deleteAnnots && deleteAnnots.length > 0) {
            deleteFieldsForPhantom(deleteAnnots);
        }
        return () => {
            willCancelReceiptItemColor = "";
        }
    }, [initFlowInfo])

    const showFieldDetail = (color) => {
        initFlowInfo.receipts.forEach(item => {
            if (item.color === color && item.annots && item.annots.length > 0) {
                item.showField = !item.showField;
            }
        })
        setStore('plugin', { ...getStore('plugin'), initFlowInfo })
    }

    const handleClickSelectedFieldItem = (objNumber, pageIndex) => {
        jumpToTheFieldForPhantom({ objNumber, pageIndex })
    }

    useEffect(() => {
        window.eSignCN_deleteCurSigner = deleteCurSigner;
    }, [deleteCurSigner])

    return <div>
        {
            orderedFlow && initFlowInfo.receipts && initFlowInfo.receipts.length > 1 &&
            <span className='gray-msg'>将按以下顺序签章，可拖动重新排序</span>
        }
        <div id='eSignCN-initFlow-receipts-container' ref={receiptsItemsRef}>
            {
                initFlowInfo.receipts.map((item, index) => {
                    return (
                        <div className={orderedFlow ? 'drag receiptsItem' : 'receiptsItem'}
                             style={{outlineColor: item.color}}
                             support-outline='true'
                             draggable={orderedFlow}
                             data-index={index} key={`${item.color}${Math.random()}`}>
                            <div className={orderedFlow ? 'drag-line' : ''}></div>
                            <div className={'receiptsItemContent'}>
                                <div className={'left'}  onClick={() => handleEditSignerInfoForm(index)}>
                                    <div className={orderedFlow ? 'dragIcon' : ''}></div>
                                    <div className='info-area'>
                                        <div className={'primaryInfo'}>
                                            {
                                                !initFlowInfo.configInfo.freeRectItem &&
                                                <span className={'annotCount'} style={{backgroundColor: item.color}}>{item.annots.length}</span>
                                            }
                                            <span className={'signerName'}>{item.signerName}</span>
                                        </div>
                                        <div className={'secondInfo'}>
                                            <span>{item.signerType === 'personSigner' ? "个人" : item.companyName}</span>
                                        </div>
                                    </div>
                                    <i className='icon del-icon' onClick={(e) => deleteCurSignerConfirm(e, item.color)}></i>
                                </div>
                                {
                                    !initFlowInfo.configInfo.freeRectItem &&
                                    <div className={'right'}>
                                        <div className={`rightArrow ${item.showField ? 'rotate90' : ''}`} onClick={() => showFieldDetail(item.color)}></div>
                                    </div>
                                }
                            </div>
                            {
                                item.annots && item.annots.length > 0 && item.showField &&
                                <div className='field-detail-container'>
                                    {
                                        item.annots.map((annot, index) => {
                                            return <div className='field-detail-item'
                                                        onClick={() => handleClickSelectedFieldItem(annot.objNumber, annot.pageIndex)}
                                                        key={annot.objNumber}>
                                                <span>{index + 1}、</span>
                                                <span>{fieldTypeMap[annot.fieldType]}</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    )
                })
            }
        </div>
    </div>
}

export default observer(ReceiptsItems)