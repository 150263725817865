import styles from "../index.module.less";
import {CheckOutlined, ReloadOutlined} from "@ant-design/icons";
import {Button, Space} from "antd";
import {useCallback, useEffect, useRef, useState} from "react";
import {generateQrCode} from "../../PublicPage/utils/util";
import {getParams} from "../../../utils";
import {qrDrawCheck, qrDrawCreate} from "../../../request/esign";
import {ERROR_ESIGN_QRDRAW_TIMEOUT} from "../../../request/error_container";
import {successMessage} from "../../components/Message";

export default function CreateMobileDraw(props) {
    const {token, client = 'pc'} = getParams();
    const [status, setStatus] = useState(-100);
    const [qrImage, setQrImage] = useState('');
    const interTimer = useRef(null);
    const base64 = useRef(null);
    const id = useRef('');


    useEffect(() => {
        return () => {
            clearInterval(interTimer.current);
        }
    }, []);

    const getQrCode = useCallback(() => {
        qrDrawCreate({token, client}).then(res => {
            if (res.workflow_id) {
                id.current = res.workflow_id;
                const url = `${origin}/sealPlugin?type=2&token=${token}&id=${id.current}`;
                generateQrCode(url).then(data => {
                    setQrImage(data);
                    setStatus(0);
                    base64.current = '';
                    props.setDrawImg(base64.current);
                });
            }
        }).catch(err => {
            console.error(err);
        });
    }, []);

    useEffect(() => {
        getQrCode();
    }, [getQrCode]);

    useEffect(() => {
        if (status === 0) {
            clearInterval(interTimer.current);
            interTimer.current = null;

            interTimer.current = setInterval(() => {
                qrDrawCheck({token, id: id.current}).then(res => {
                    if (res.status === 1) {
                        setStatus(1);
                    } else if (res.status === 2) {
                        clearInterval(interTimer.current);
                        interTimer.current = null;
                        base64.current = res.temp_seal.picture_base64;
                        props.setDrawImg(base64.current);
                        successMessage('成功获取手绘签名');
                        setStatus(2);
                    }
                }).catch(err => {
                    console.error(err);
                    clearInterval(interTimer.current);
                    interTimer.current = null;
                    if (ERROR_ESIGN_QRDRAW_TIMEOUT === err) {
                        setStatus(-1);
                    }
                });
            }, 2000);
        }
    }, [status]);

    return (
        <>
            <div className={styles.tabContainer + ' ' + styles.tabDraw}>
                <div className={styles.qrContainer}>
                    {status !== 2 ? <>
                        <p>请打开手机扫描下方二维码，手绘签名</p>
                        <div className={styles.qrCode} style={{backgroundImage: `url(${qrImage})`}}>
                            {status !== 0 && <div className={styles.success}>
                                {status === 1 && <>
                                    <CheckOutlined className={styles.icon}/>
                                    <p>扫码成功</p>
                                </>}
                                {status === -1 && <Button onClick={getQrCode}
                                                          icon={<ReloadOutlined/>} type={'default'}
                                                          style={{borderRadius: 4}}>刷新</Button>}
                            </div>}
                        </div>
                        {status === 0 &&
                            <p>请在<span style={{color: '#A236B2', fontWeight: 600}}>15</span>分钟中内完成扫码和绘制</p>}
                        {status === 1 && <Button onClick={getQrCode} icon={<ReloadOutlined/>} type={'link'}>刷新</Button>}
                        {status === -1 && <p>二维码已过期，请点击刷新</p>}
                    </>: <>
                        <img src={base64.current} alt="手绘签名" style={{width: '100%', height: '100%'}} />
                    </>}
                </div>
            </div>
            {status === 2 && <div style={{marginTop: 20, display: 'flex', justifyContent: 'space-between', marginRight: 270}}>
            <Space size={"middle"}>
                <Button onClick={getQrCode} >重新手绘</Button>
            </Space>
            </div>}
        </>
    );
}
