import React, {useEffect, useState} from "react";
import {getOrgAdminUrl, getPersonAdminUrl, getPersonCreateUrl} from "../../request/esign";
import {sendMsg} from "./index";
import {
    ERROR_AC_ORG_PERMISSION_FAIL, ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED,
    ERROR_EQB_ORG_TOKEN_EXPIRED,
    ERROR_EQB_SEAL_PERMISSION_FAIL
} from "../../request/error_container";
import styles from "./index.module.less";
import {CloseCircleFilled, SyncOutlined} from "@ant-design/icons";
import ApplyOrgSeals from "./ApplyOrgSeals";

export default function OutPanel({isCreated, isAdmin, orgId, accountId, token, enterpriseId, trueName, hasTab}) {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const msg = React.useRef(null);

    useEffect(() => {
        setLoading(true);
        let request;
        if (!hasTab) {
            sendMsg({type: 'resize', width: '890px', height: '606px'});
        }
        if (isCreated) {
            if (isCreated === 1) {
                request = getPersonCreateUrl({token, accountId});
            } else {
                request = getOrgAdminUrl({token,orgId});
            }
        } else {
            request = orgId ? getOrgAdminUrl({token,orgId}) : getPersonAdminUrl({token, accountId});
        }
        request.then(url => {
            msg.current = null;
            setUrl(url);
        }).catch(key => {
            setUrl('');
            const result = {};
            if (key === ERROR_EQB_SEAL_PERMISSION_FAIL) {
                console.warn('seal permission fail', isAdmin);
                if (isAdmin) {
                    result.txt = '提示：您暂无该管理权限，请联系企业管理员授权。';
                    msg.current = result;
                } else {
                    msg.current = null;
                }
            } else {
                 if (ERROR_EQB_AUTH_EXPIRED === key) {
                    if (orgId) {
                        result.txt = '提示：您的授权过期，请重新完成企业认证/授权。';
                        // result.link = "/certification?orgName=" + company;
                        // result.linkTxt = '点击认证/授权';
                    } else {
                        result.txt = '提示：您的授权过期，请重新完成个人实名认证。';
                        // result.link = "/certification";
                        // result.linkTxt = '点击认证';
                    }
                }
                else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    result.txt = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    result.txt = '您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。';
                    result.link = 'https://www.foxitsoftware.cn/esign#sales';
                    result.linkTxt = '联系销售';
                }
                else if (ERROR_AC_PERMISSION_FAIL === key) {
                    result.txt = '提示：您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。';
                } else if(ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    result.txt = '提示：您暂无印章管理权限，请联系IT管理员分配授权后重试。';
                } else {
                    result.txt = '提示：获取授权链接失败，请稍后再试。';
                }
                msg.current = result;
            }
        }).finally(() => {
            setLoading(false);
        });

    }, [orgId, accountId, token, isCreated, hasTab]);

    return (
        <>
            {loading && <div className={styles.loading}><SyncOutlined spin/>&emsp;正在获取链接…</div>}
            {!loading && url && <iframe title="eSignCn" src={url} className={styles.iframe} height='600px'></iframe>}
            {!loading && !url && msg.current && <ErrorTip {...msg.current} />}
            {!loading && !url && !msg.current && <ApplyOrgSeals trueName={trueName} enterpriseId={enterpriseId} token={token} accountId={accountId} orgId={orgId} />}
        </>
    );
}

function ErrorTip({txt, link, linkTxt}) {
    return (
        <div className={styles.error}>
            <CloseCircleFilled/> &emsp;{txt}
            {link && linkTxt && <a href={link} target="_blank" rel="noreferrer">{linkTxt}</a>}
        </div>
    );
}