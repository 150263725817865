import {Modal, Space} from "antd";
import styles from "./Header.module.less";
import React, {useRef, useState} from "react";
import TextArea from "antd/es/input/TextArea";
import {InfoCircleOutlined} from "@ant-design/icons";
import {errorMessage, successMessage} from "./Message";
import {refuseSign} from "../../request/esign";
import {getStore} from "../../store";
import {findServItem} from "../main/Main";

export function RefuseModal({close}) {
    const [inputStatus, setInputStatus] = useState('');
    const [btnLoading, setLoading] = useState(false);

    const handleSave = () => {
        const comment = inputRef.current.resizableTextArea.textArea.value;
        setInputStatus('');
        setLoading(true);
        submitFn(comment).then(code => {
            switch (code) {
                case -1:
                    setInputStatus('error');
                    break;
                case -2:
                    errorMessage(`拒签提交失败，请重试！`);
                    break;
                case -3:
                    errorMessage(`未能获取签署id，请刷新后重试！`);
                    break;
                default:
                    successMessage(`拒签提交成功`);
                    close(true);
            }
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleCancel = () => {
        close(false);
    }

    const inputRef = useRef();

    const changeValue = (e) => {
        const value = e.target.value;
        if (!value || value.length > 50) {
            setInputStatus('warning');
        } else {
            setInputStatus('');
        }
    };

    return <Modal title="拒绝签署"
                  style={{}}
                  open={true}
                  centered
                  confirmLoading={btnLoading}
                  maskClosable={false}
                  width={400}
                  okText={'确认'}
                  cancelText={'取消'}
                  onOk={handleSave}
                  onCancel={handleCancel}>
        <div className={styles.refuseContainer}>
            <div style={{color: 'gray', display: 'flex', alignItems: 'center',marginBottom: '10px'}}>
                <Space><InfoCircleOutlined style={{color: "rgba(250, 173, 21, 1)", fontSize: '20px'}} /> 拒签后，签署流程将不能再继续签署</Space>
            </div>
            <TextArea placeholder="请填写拒签原因，将发送给各签署参与人…" autoSize={{ minRows: 4, maxRows: 4 }}
                      ref={inputRef} allowClear showCount status={inputStatus} maxLength={200} onChange={changeValue} />
            {inputStatus === 'error' && <span className={styles.error}>请输入拒绝原因</span>}
        </div>
    </Modal>
}

function submitFn(rejectReason) {
    if (!rejectReason || rejectReason.length > 200) {
        return Promise.resolve(-1);
    }
    const {userEmail, userMobile, orgName} = getStore('userInfo');
    const {process_id: processId, recipients} = getStore('serverPdfInfo');
    const {index: recipientOrder} = findServItem(userEmail, userMobile, orgName, recipients);
    if (recipientOrder < 0) {
        return Promise.reject(-3);
    }
    return refuseSign({processId, recipientOrder, rejectReason,}).then(() => {
        return 1;
    }).catch(() => {
        return -2;
    });
};