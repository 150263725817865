import styles from './index.module.less'
import {getParams} from "../../../utils";
import {useEffect} from "react";
import {px2pt, sizeAdapter} from "../utils/util";
import {resizeDialog} from "../Model";
import {
    closeESignCNDialogAPI,
    notPromptAuthPromotionAgain,
    hideDialogPositionForPhantom, showESignCNDialogAPI
} from "../../../request/phantom";

function PhantomAuthPromotion() {

    useEffect(() => {
        const width = 476; const height = 290;
        const widthPt = px2pt(parseInt(width));
        const heightPt = px2pt(parseInt(height));
        resizeDialog({ width: sizeAdapter(widthPt), height: sizeAdapter(heightPt) });
        setBodyCss();
    }, [])

    const setBodyCss = () => {
        document.body.style.backgroundColor = "transparent";
    }

    const handleClick = () => {
        const params = getParams();
        const devParams = params && params.phantom_web_mock_env ? `&phantom_web_mock_env=${params.phantom_web_mock_env}` : "";
        const url = `${window.location.origin}/auth-for-phantom?authPromotion=1&time=${Date.now()}&${devParams}`
        hideDialogPositionForPhantom().then(() => {
            return showESignCNDialogAPI({ title: '实名认证', id: 'ESIGNCN_COMMON_DIALOG', type: 'Window', webUrl: url})
        }).then(() => {
           return closeESignCNDialogAPI()
        })
    }

    const handleClose = () => {
        closeESignCNDialogAPI()
    }

    const notPromptFn = () => {
        notPromptAuthPromotionAgain().then(() => {
            closeESignCNDialogAPI()
        })
    }

    return (
        <div className={styles.container}>
            <div className={styles.closeBtn} onClick={handleClose}></div>
            <span className={styles.noRemain} onClick={notPromptFn}>不再显示</span>
            <div className={styles.btn} onClick={handleClick}>立即体验</div>
        </div>
    )
}

export default PhantomAuthPromotion;