import styles from "../index.module.less";
import {FileImageOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {errorMessage} from "../../../pages/components/Message";
import {getBase64, getBlackBase64, isIE} from "../../../utils";
import {Button, Checkbox} from "antd";


export function ExtPaste(props) {
    const {setPasteClear, hasImg} = props;
    const clearData = () => {
        setPasteClear(new Date().getTime());
    }
    return (
        <Button hidden={!hasImg} type={"link"} onClick={clearData}>粘贴</Button>
    )
}

export default function CreatePaste(props) {
    const [isBlack, setIsBlack] = useState(true);
    const {setPasteImg, pasteClear} = props
    const [imgUrl, setImgUrl] = useState('');
    const [file, setFile] = useState(undefined);

    useEffect(() => {
        if (!file) {
            return
        }
        if (isBlack) {
            getBlackBase64(file).then(base64 => {
                setImgUrl(base64);
                setPasteImg(base64);
            });
        } else {
            getBase64(file).then(base64 => {
                setImgUrl(base64);
                setPasteImg(base64);
            });
        }
    }, [file, isBlack]);

    useEffect(() => {
        if (pasteClear) {
            pasteImage();
        }
    }, [pasteClear]);

    const pasteImage = () => {
        // navigator.permissions.query({ name: 'clipboard-read' }).then( async permissionStatus => {
        //     if (['granted', 'prompt'].includes(permissionStatus.state)) {
        //         getClipboardData().then( blob => {
        //             if (!blob) {
        //                 errorMessage('剪贴板内无图片！');
        //                 return;
        //             }
        //             lastFile.current = blob;
        //             setImg();
        //         })
        //     } else {
        //         errorMessage('您未完成浏览器授权或当前浏览器不支持此功能！');
        //     }
        // })
        getClipboardData().then(blob => {
            if (!blob) {
                errorMessage('剪贴板内无图片！');
                return;
            }
            if (blob.size > 1024 * 200) {
                errorMessage('图片尺寸超过限制！');
                return;
            }
            setFile(blob)
        })
    }

    return (<>
            <div className={styles.tabContainer + ' ' + styles.tabUpload} onClick={pasteImage}>

                <div className={styles.uploadPaste}>
                    {imgUrl ? (
                        <img src={imgUrl} alt=""/>
                    ) : (
                        <>
                            <p>
                                <FileImageOutlined/>
                            </p>
                            <p>截图后点击此区域，将完成图片的粘贴</p>
                            <p style={{color: '#ccc'}}>
                                此功能需要浏览器授权，图片大小限制为200kb
                            </p>
                        </>
                    )}
                </div>

            </div>
            <div style={{marginTop: 20, userSelect: 'none'}}>
                <Checkbox checked={isBlack} onChange={e => setIsBlack(e.target.checked)}>
                    将图片转换为黑白色</Checkbox>
            </div>
        </>
    )
}

function getClipboardData() {
    if (!navigator.clipboard || typeof navigator.clipboard.read !== 'function' || isIE) {
        errorMessage('您未完成浏览器授权或当前浏览器不支持此功能！');
        return Promise.resolve(null);
    }
    if (!window.isSecureContext) {
        errorMessage('您未完成浏览器授权或当前浏览器不支持此功能！');
        return Promise.resolve(null);
    }

    return navigator.clipboard.read().then(clipboardData => {
        const item = (clipboardData || [])[0];
        if (!item) {
            return null;
        }
        const imageIndex = item.types.findIndex(item => item.indexOf('image/') === 0);
        if (imageIndex >= 0) {
            return item.getType(item.types[imageIndex]);
        }
    }).catch(e => {
        return null;
    });
}
