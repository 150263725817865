import { Button, Upload } from 'antd';
import React from 'react';
import styles from "./index.module.less";
import {getFileType} from "../utils/util";
import {FAIL_REASON} from "./index";

const MAX_UPLOAD_DOC_SIZE_KB = 50 * 1024;
// const MAX_UPLOAD_DOC_SIZE_KB = 50;
export default function UploadFile(props) {

    const { setFailureReasonFn, setDocFn, btnText = '选择文档', loading } = props;

    const uploadProps = {
        name: 'file',
        maxCount: 1,
        showUploadList: false,
        accept: '.pdf,.PDF',
        customRequest({ file, onSuccess, onError }) {
            const size_B = file.size;
            const fileType = getFileType(file.name);
            if (fileType !== 'PDF') {
                setFailureReasonFn(FAIL_REASON.TYPE_ERROR);
            } else if(size_B > MAX_UPLOAD_DOC_SIZE_KB * 1024) {
                setFailureReasonFn(FAIL_REASON.SIZE_ERROR);
            } else {
                setDocFn(file);
            }
        }
    };

    return (
        <>
            <Upload {...uploadProps}>
                <Button
                    className={styles.btn}
                    style={{ width: '120px'}}
                    loading={loading}
                    disabled={loading}
                >
                    {btnText}
                </Button>
            </Upload>
        </>
    )
}