import styles from "../index.module.less";
import {Button} from "antd";
import {
    closeESignCNDialogAPI,
    closeESignCNDialogAPIV2,
    refreshWebForPhantom,
    updateUserInfoForPhantom
} from "../../../../request/phantom";
import {successMessage} from "../../../components/Message";
import {afterPluginVersion2023_3} from "../../utils/util";

export function HasJoinView(props) {

    const { orgId, orgName } = props;
    const onOk = () => {
        updateUserInfoForPhantom({ enterpriseId: orgId }).then(() => {
            successMessage(`已切换到企业组织：${orgName}`)
            setTimeout(() => {
                refreshWebForPhantom({ type: 2 }).then(() => {
                    if (afterPluginVersion2023_3()) {
                        const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
                        const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
                        const closeDialogIds = [ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG];
                        closeESignCNDialogAPIV2(closeDialogIds);
                    } else {
                        closeESignCNDialogAPI()
                    }
                })
            }, 500)
        })
    }
    const onCancel = () => {
        if (afterPluginVersion2023_3()) {
            const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
            const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
            const closeDialogIds = [ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG];
            closeESignCNDialogAPIV2(closeDialogIds);
        } else {
            closeESignCNDialogAPI()
        }
    }
    return <>
        <div className={styles.orgIcon}></div>
        <div className={styles.content1}>
            <div className={styles.msg}>
                <div>您已经加入该企业，是否切到该企业组织下登录？</div>
            </div>
            <div className={styles.btnGroup}>
                <Button className={styles.primaryBtn} type="primary" htmlType="submit" onClick={onOk}>
                    切换
                </Button>
                <Button htmlType="button" onClick={onCancel}>
                    取消
                </Button>
            </div>
        </div>
    </>
}