import {
    eSignCNAuditTrailContent3Title,
    eSignCNAuditTrailWrapper,
    genContent3ItemStyleFn
} from "../style";

export function Content3CompChild (props) {
    const msgKeyMap = [
        {
            key: 'subject',
            label: '证书名称：'
        },
        {
            key: 'serialNumber',
            label: '证书序列号：'
        },
        {
            key: 'issuer',
            label: '证书颁发者：'
        },
        {
            key: 'format',
            label: '证书格式：'
        }
    ]
    const singleCertfile = props.data;
    const mode = singleCertfile.mode;

    msgKeyMap.forEach(item => {
        item.value = singleCertfile[item.key]
    })
    if (mode === 'out') {
        msgKeyMap.push({
            key: 'validDate',
            label: '证书有效期：',
            value: `${singleCertfile.validPeriodFrom} 至 ${singleCertfile.validPeriodTo}`
        })
    }
    const title = mode === 'out' ? `【${singleCertfile.signerName}】证书信息` :  `证书信息`

    return <section style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={eSignCNAuditTrailContent3Title}>{title}</div>
        <div style={{ ...eSignCNAuditTrailWrapper, padding: 0 }}>
            {
                msgKeyMap.map((item, index) => {
                    return <div key={index} style={genContent3ItemStyleFn(index)}>
                        <div style={{ width: '23%' }}>{item.label}</div>
                        <div style={{ flex: 1 }}>{item.value}</div>
                    </div>
                })
            }
        </div>
    </section>
}