import {AuditTrailMain} from "./components/Main";
import {AUDIT_TYPE} from "./services";

export function Logo(props) {
    const { style } = props;
    return (
        <svg style={style} width="75" height="30" viewBox="0 0 75 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8609 15.599C14.851 14.011 16.3746 12.6352 17.9111 11.5616H10.8007C11.4822 6.27696 14.6002 5.81408 18.6762 5.86551C19.5055 5.89122 20.3349 5.91694 21.2157 5.91694L22.045 0.0794174H21.9678C20.8621 0.0537015 19.782 0.00226966 18.6698 0.00226966C12.0994 -0.0491622 5.88896 0.709458 4.1017 8.9257C2.89305 14.4611 1.05435 23.6609 0 29.7556H8.45411L10.4214 17.6563C10.4214 17.5791 10.4471 17.502 10.4728 17.4505H12.858C13.038 17.0455 13.2373 16.6533 13.4559 16.274C13.5202 16.1519 13.5909 16.0362 13.6616 15.9204C13.7259 15.8112 13.7902 15.7083 13.8545 15.6054" fill="#FF5F00"/>
            <path d="M24.4045 11.568C19.3256 11.568 14.6646 15.6954 13.9896 20.7807C13.3145 25.8725 16.889 29.9999 21.9679 29.9999C27.0468 29.9999 31.7079 25.8725 32.3829 20.7807C33.058 15.689 29.4834 11.568 24.4045 11.568ZM27.3683 20.7807C27.0597 23.0952 24.9446 24.9724 22.6366 24.9724C20.3286 24.9724 18.7085 23.0952 19.0106 20.7807C19.3192 18.4663 21.4343 16.5955 23.7424 16.5955C26.0504 16.5955 27.6705 18.4727 27.3683 20.7807Z" fill="#FF5F00"/>
            <path d="M61.1466 9.77438L61.6223 5.22266H57.9642H55.1162L54.3318 9.77438H57.9642H61.1466Z" fill="#FF5F00"/>
            <path d="M51.3294 29.9999H59.1277L60.465 17.2705H62.6765L62.1236 22.478C61.6158 27.3061 64.901 30.1477 69.337 29.9484L72.5386 29.8456L73.1172 24.5417H71.1242C69.8127 24.5417 68.5783 24.1109 68.8034 22.1501L69.3113 17.2448H73.8051L74.4866 11.658H69.7163L70.1727 7.30554H63.763L63.3066 11.658H54.081L51.2844 29.897" fill="#FF5F00"/>
            <path d="M41.7244 27.152C41.4415 27.2291 41.1779 27.2999 40.9208 27.377L42.3994 30H49.5871L46.7069 24.9019C45.4982 25.6605 43.9295 26.4062 41.808 27.1327L41.718 27.1584L41.7244 27.152Z" fill="#FF5F00"/>
            <path d="M40.413 13.7281L39.2107 11.5936H32.0231L35.2569 17.3219C36.6391 15.8882 38.4007 14.6538 40.413 13.7216" fill="#FF5F00"/>
            <path d="M32.2735 29.2156C32.2735 29.2156 32.5306 24.4774 34.5944 21.0572C36.3238 17.4505 40.8883 14.4353 46.0701 13.5674C46.0701 13.5674 50.4225 12.5581 51.7726 11.7737C51.7726 11.7737 53.2384 10.8222 52.5762 13.0338C52.5762 13.0338 51.6955 15.1747 48.9888 16.5633C48.3524 16.8848 47.8766 16.9555 47.9731 17.5662C48.1531 18.1899 49.1881 17.7141 49.3296 17.6434C49.5739 17.4377 51.9783 16.7819 50.3775 19.0771C48.7703 21.4558 47.7931 23.6095 41.5441 25.7503C37.3781 26.8883 35.7259 27.5312 33.3086 29.717C32.0999 30.5721 32.2671 29.2156 32.2671 29.2156" fill="#FF5F00"/>
        </svg>
    )
}

export function Icon1() {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.977 7.7897C19.7262 6.98746 20.8537 6.65844 21.9168 6.93183L30.0622 9.02661L38.2556 10.9245C39.325 11.1722 40.1737 11.9841 40.4685 13.0415L42.727 21.143L45.1801 29.1876C45.5003 30.2376 45.2215 31.3786 44.4532 32.1625L38.5664 38.1692L32.826 44.316C32.0768 45.1183 30.9493 45.4473 29.8862 45.1739L21.7409 43.0791L13.5474 41.1812C12.4781 40.9335 11.6294 40.1216 11.3346 39.0642L9.07604 30.9628L6.62291 22.9181C6.30275 21.8681 6.58156 20.7272 7.34987 19.9432L13.2367 13.9365L18.977 7.7897Z" fill="#B968BB"/>
            <path d="M20.0363 33.8059C16.7029 33.8059 15.0363 31.4654 15.0363 26.7844C15.0363 24.356 15.4855 22.507 16.3839 21.2375C17.2889 19.9615 18.5975 19.3235 20.3097 19.3235C23.5649 19.3235 25.1925 21.703 25.1925 26.4621C25.1925 28.8319 24.7465 30.6483 23.8546 31.9113C22.9692 33.1744 21.6964 33.8059 20.0363 33.8059ZM20.173 21.677C18.8383 21.677 18.171 23.3534 18.171 26.7063C18.171 29.8638 18.8253 31.4426 20.1339 31.4426C21.41 31.4426 22.048 29.815 22.048 26.5598C22.048 23.3046 21.423 21.677 20.173 21.677ZM33.9913 19.2453V33.5617H30.9152V22.7219C30.7459 22.8716 30.5441 23.0149 30.3097 23.1516C30.0818 23.2818 29.8377 23.4022 29.5773 23.5129C29.3169 23.6171 29.0467 23.7082 28.7667 23.7863C28.4868 23.858 28.2101 23.91 27.9366 23.9426V21.3449C28.7374 21.1106 29.4926 20.8111 30.2023 20.4465C30.9119 20.0819 31.5532 19.6815 32.1261 19.2453H33.9913Z" fill="white"/>
        </svg>
    )
}

export function Icon2() {
    return (
        <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.977 7.11627C19.7262 6.31403 20.8537 5.98501 21.9168 6.2584L30.0622 8.35318L38.2556 10.2511C39.325 10.4988 40.1737 11.3107 40.4685 12.368L42.727 20.4695L45.1801 28.5142C45.5003 29.5642 45.2215 30.7051 44.4532 31.4891L38.5664 37.4958L32.826 43.6426C32.0768 44.4448 30.9493 44.7739 29.8862 44.5005L21.7409 42.4057L13.5474 40.5078C12.4781 40.2601 11.6294 39.4482 11.3346 38.3908L9.07604 30.2893L6.62291 22.2446C6.30275 21.1947 6.58156 20.0537 7.34987 19.2698L13.2367 13.2631L18.977 7.11627Z" fill="#B968BB"/>
            <path d="M20.0363 33.1324C16.7029 33.1324 15.0363 30.792 15.0363 26.111C15.0363 23.6826 15.4855 21.8336 16.3839 20.5641C17.2889 19.288 18.5975 18.65 20.3097 18.65C23.5649 18.65 25.1925 21.0296 25.1925 25.7887C25.1925 28.1585 24.7465 29.9749 23.8546 31.2379C22.9692 32.5009 21.6964 33.1324 20.0363 33.1324ZM20.173 21.0035C18.8383 21.0035 18.171 22.68 18.171 26.0328C18.171 29.1904 18.8253 30.7692 20.1339 30.7692C21.41 30.7692 22.048 29.1416 22.048 25.8864C22.048 22.6311 21.423 21.0035 20.173 21.0035ZM30.0558 30.3297H35.9933V32.8883H26.6769V31.8336C26.6769 31.1175 26.7973 30.4762 27.0382 29.9098C27.2791 29.3369 27.5818 28.8258 27.9464 28.3766C28.311 27.9209 28.7081 27.5205 29.1378 27.1754C29.574 26.8239 29.9874 26.5081 30.3781 26.2281C30.7882 25.9352 31.1463 25.6552 31.4523 25.3883C31.7648 25.1214 32.0252 24.8577 32.2335 24.5973C32.4484 24.3304 32.6079 24.0634 32.712 23.7965C32.8162 23.5231 32.8683 23.2334 32.8683 22.9274C32.8683 22.3284 32.699 21.8759 32.3605 21.5699C32.0219 21.264 31.5044 21.111 30.8077 21.111C29.6033 21.111 28.451 21.5895 27.3507 22.5465V19.8317C28.5682 19.0439 29.9419 18.65 31.4718 18.65C32.1814 18.65 32.8162 18.7444 33.3761 18.9332C33.9425 19.1155 34.421 19.3792 34.8116 19.7242C35.2023 20.0693 35.4985 20.4892 35.7003 20.984C35.9087 21.4723 36.0128 22.0192 36.0128 22.6246C36.0128 23.2692 35.9119 23.8421 35.7101 24.3434C35.5148 24.8447 35.2511 25.3004 34.9191 25.7106C34.5935 26.1207 34.2159 26.4983 33.7863 26.8434C33.3566 27.1819 32.9106 27.5107 32.4484 27.8297C32.1359 28.0511 31.8331 28.2724 31.5402 28.4938C31.2537 28.7086 30.9998 28.9235 30.7784 29.1383C30.5571 29.3466 30.3813 29.5517 30.2511 29.7535C30.1209 29.9554 30.0558 30.1474 30.0558 30.3297Z" fill="white"/>
        </svg>
    )
}

export function Icon3() {
    return (
        <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.977 7.44281C19.7262 6.64057 20.8537 6.31154 21.9168 6.58494L30.0622 8.67972L38.2556 10.5776C39.325 10.8253 40.1737 11.6372 40.4685 12.6946L42.727 20.7961L45.1801 28.8408C45.5003 29.8907 45.2215 31.0317 44.4532 31.8156L38.5664 37.8223L32.826 43.9691C32.0768 44.7714 30.9493 45.1004 29.8862 44.827L21.7409 42.7322L13.5474 40.8343C12.4781 40.5866 11.6294 39.7747 11.3346 38.7174L9.07604 30.6159L6.62291 22.5712C6.30275 21.5212 6.58156 20.3803 7.34987 19.5963L13.2367 13.5896L18.977 7.44281Z" fill="#B968BB"/>
            <path d="M20.0363 33.459C16.7029 33.459 15.0363 31.1185 15.0363 26.4375C15.0363 24.0091 15.4855 22.1602 16.3839 20.8906C17.2889 19.6146 18.5975 18.9766 20.3097 18.9766C23.5649 18.9766 25.1925 21.3561 25.1925 26.1152C25.1925 28.485 24.7465 30.3014 23.8546 31.5645C22.9692 32.8275 21.6964 33.459 20.0363 33.459ZM20.173 21.3301C18.8383 21.3301 18.171 23.0065 18.171 26.3594C18.171 29.5169 18.8253 31.0957 20.1339 31.0957C21.41 31.0957 22.048 29.4681 22.048 26.2129C22.048 22.9577 21.423 21.3301 20.173 21.3301ZM27.0089 32.7559V30.0703C27.9464 30.7539 29.0402 31.0957 30.2902 31.0957C31.0779 31.0957 31.6899 30.9264 32.1261 30.5879C32.5688 30.2493 32.7902 29.7773 32.7902 29.1719C32.7902 28.5469 32.5167 28.0651 31.9698 27.7266C31.4295 27.388 30.684 27.2188 29.7335 27.2188H28.4347V24.8555H29.6359C31.4588 24.8555 32.3702 24.25 32.3702 23.0391C32.3702 21.8997 31.6704 21.3301 30.2706 21.3301C29.3331 21.3301 28.4217 21.6328 27.5363 22.2383V19.7188C28.5193 19.224 29.6652 18.9766 30.9738 18.9766C32.406 18.9766 33.5193 19.2988 34.3136 19.9434C35.1144 20.5879 35.5148 21.4245 35.5148 22.4531C35.5148 24.2826 34.587 25.4284 32.7316 25.8906V25.9395C33.7212 26.0632 34.5024 26.4245 35.0753 27.0234C35.6482 27.6159 35.9347 28.3451 35.9347 29.2109C35.9347 30.5195 35.4562 31.5547 34.4991 32.3164C33.5421 33.0781 32.2205 33.459 30.5343 33.459C29.089 33.459 27.9139 33.2246 27.0089 32.7559Z" fill="white"/>
        </svg>
    )
}

export function Icon4() {
    return (
        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.977 7.76941C19.7262 6.96717 20.8537 6.63814 21.9168 6.91154L30.0622 9.00631L38.2556 10.9042C39.325 11.1519 40.1737 11.9638 40.4685 13.0212L42.727 21.1227L45.1801 29.1674C45.5003 30.2173 45.2215 31.3583 44.4532 32.1422L38.5664 38.1489L32.826 44.2957C32.0768 45.098 30.9493 45.427 29.8862 45.1536L21.7409 43.0588L13.5474 41.1609C12.4781 40.9132 11.6294 40.1013 11.3346 39.044L9.07604 30.9425L6.62291 22.8978C6.30275 21.8478 6.58156 20.7069 7.34987 19.9229L13.2367 13.9162L18.977 7.76941Z" fill="#B968BB"/>
            <path d="M20.0363 33.7856C16.7029 33.7856 15.0363 31.4451 15.0363 26.7641C15.0363 24.3357 15.4855 22.4868 16.3839 21.2172C17.2889 19.9412 18.5975 19.3032 20.3097 19.3032C23.5649 19.3032 25.1925 21.6827 25.1925 26.4418C25.1925 28.8116 24.7465 30.628 23.8546 31.8911C22.9692 33.1541 21.6964 33.7856 20.0363 33.7856ZM20.173 21.6567C18.8383 21.6567 18.171 23.3331 18.171 26.686C18.171 29.8435 18.8253 31.4223 20.1339 31.4223C21.41 31.4223 22.048 29.7947 22.048 26.5395C22.048 23.2843 21.423 21.6567 20.173 21.6567ZM35.1632 19.5375V28.3657H36.8429V30.6703H35.1632V33.5414H32.3507V30.6703H26.2472V28.2582C26.7876 27.6528 27.3475 26.992 27.9269 26.2758C28.5063 25.5532 29.0662 24.811 29.6066 24.0493C30.1469 23.2875 30.6482 22.5226 31.1105 21.7543C31.5792 20.9796 31.9764 20.2407 32.3019 19.5375H35.1632ZM29.0109 28.3657H32.3507V23.4731C32.1228 23.8963 31.8754 24.3259 31.6085 24.7621C31.3416 25.1918 31.0616 25.6183 30.7687 26.0414C30.4757 26.4581 30.1795 26.865 29.88 27.2621C29.5805 27.6528 29.2908 28.0206 29.0109 28.3657Z" fill="white"/>
        </svg>
    )
}

export function Icon5() {
    return (
        <svg width="52" height="51" viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.977 7.09595C19.7262 6.29371 20.8537 5.96468 21.9168 6.23808L30.0622 8.33285L38.2556 10.2307C39.325 10.4784 40.1737 11.2904 40.4685 12.3477L42.727 20.4492L45.1801 28.4939C45.5003 29.5438 45.2215 30.6848 44.4532 31.4688L38.5664 37.4755L32.826 43.6223C32.0768 44.4245 30.9493 44.7535 29.8862 44.4801L21.7409 42.3854L13.5474 40.4875C12.4781 40.2398 11.6294 39.4278 11.3346 38.3705L9.07604 30.269L6.62291 22.2243C6.30275 21.1744 6.58156 20.0334 7.34987 19.2495L13.2367 13.2428L18.977 7.09595Z" fill="#B968BB"/>
            <path d="M20.0363 33.1122C16.7029 33.1122 15.0363 30.7717 15.0363 26.0907C15.0363 23.6623 15.4855 21.8134 16.3839 20.5438C17.2889 19.2678 18.5975 18.6298 20.3097 18.6298C23.5649 18.6298 25.1925 21.0093 25.1925 25.7684C25.1925 28.1382 24.7465 29.9546 23.8546 31.2177C22.9692 32.4807 21.6964 33.1122 20.0363 33.1122ZM20.173 20.9833C18.8383 20.9833 18.171 22.6597 18.171 26.0126C18.171 29.1701 18.8253 30.7489 20.1339 30.7489C21.41 30.7489 22.048 29.1213 22.048 25.8661C22.048 22.6109 21.423 20.9833 20.173 20.9833ZM27.2531 32.5067V29.87C28.2101 30.4559 29.229 30.7489 30.3097 30.7489C31.1235 30.7489 31.7583 30.5568 32.214 30.1727C32.6762 29.7821 32.9073 29.2548 32.9073 28.5907C32.9073 27.204 31.9275 26.5106 29.9679 26.5106C29.2452 26.5106 28.4119 26.566 27.4679 26.6766L27.9952 18.8641H35.4073V21.3837H30.3488L30.1534 24.1864C30.6547 24.1473 31.0877 24.1278 31.4523 24.1278C32.8911 24.1278 34.0174 24.5054 34.8312 25.2606C35.645 26.0158 36.0519 27.0315 36.0519 28.3075C36.0519 29.7203 35.5669 30.8759 34.5968 31.7743C33.6267 32.6662 32.3116 33.1122 30.6515 33.1122C29.3038 33.1122 28.171 32.9104 27.2531 32.5067Z" fill="white"/>
        </svg>
    )
}

export default function AuditTrailStatic(props) {
    const { auditTrail, conclusion, qrCode } = props;
    const auditTrailMsg = { conclusion };

    return <AuditTrailMain
        type={AUDIT_TYPE.PDF}
        qrCode={qrCode}
        auditTrail={auditTrail}
        auditTrailMsg={auditTrailMsg}
    />
}