import {fetchAuditTrail, fetchSigVerifyData} from "../Model";
import {formatVerifySigForPhantom} from "./verifySig";
import {checkJwtToken, login, validateSAMLResponse} from "../../../request/user";
import {getQueryVariable} from "../service";
import {
    generateQrCode,
    getPcDomain,
    inPhantom,
    removeQueryParam,
    sanitizeStringFromSessionStorage
} from "../utils/util";
import ReactDOMServer from 'react-dom/server';
import {errorMessage, hideMessage, loadingMessage} from "../../components/Message";
import {doConvert, doUpload, downloadPdf, getDownloadUrl, getStatus} from "../../../request/convert";
import {
    ERROR_AC_SAML_VALID_FAIL_100010,
    ERROR_AC_SAML_VALID_FAIL_100011,
    ERROR_AC_SAML_VALID_FAIL_100019, ERROR_AC_TOKEN_FAIL
} from "../../../request/error_container";
import {AUDIT_TRAIL_QUERY, TOKEN} from "./index";
import {openLoginWindowOfPhantom} from "../../../request/phantom";
import {getSignFlowInfo} from "../../../request/esign";

export const AUDIT_TYPE = {
    WEB: 'web',
    PDF: 'pdf'
}

function generateSignValidStatusConclusion(data) {
    let signValidStatusOfTotal;
    let signValids = 0
    let signInValids = 0
    let signUnKnows = 0
    let signErrors = 0

    const totalCertificate = [...data.certfile, ...data.illegalSignatures]
    totalCertificate.forEach(item => {
        if (item.signValidStatus === 0) {
            signInValids++;
        } else if (item.signValidStatus === 1) {
            signValids++;
        } else if (item.signValidStatus === 2) {
            signUnKnows++;
        } else if (item.signValidStatus === 3) {
            signErrors++;
        }
    })

    if (signInValids > 0) {
        signValidStatusOfTotal = 0;
    } else if (signErrors > 0) {
        signValidStatusOfTotal = 3;
    } else if (signUnKnows > 0) {
        signValidStatusOfTotal = 2;
    } else if (signValids > 0) {
        signValidStatusOfTotal = 1;
    } else {
        signValidStatusOfTotal = -1;
    }

    return signValidStatusOfTotal;
}

function generateFileModifiedStatusConclusion(data) {
    let fileModifiedStatusOfTotal;
    let fileHasModifieds = 0;
    let fileNotModifieds = 0;
    let fileHasModifiedWithAlloweds = 0;
    data.certfile.forEach(item => {
        if (item.fileModifiedStatus === 0) {
            fileHasModifieds++;
        } else if (item.fileModifiedStatus === 1) {
            fileHasModifiedWithAlloweds++;
        } else if (item.fileModifiedStatus === 2) {
            fileNotModifieds++;
        }
    })

    if (fileHasModifieds > 0) {
        fileModifiedStatusOfTotal = 0;
    } else if (fileHasModifiedWithAlloweds > 0) {
        fileModifiedStatusOfTotal = 1;
    } else if (fileNotModifieds > 0) {
        fileModifiedStatusOfTotal = 2;
    } else {
        fileModifiedStatusOfTotal = -1;
    }

    return fileModifiedStatusOfTotal;
}

function generateIssuerValidStatusConclusion(data) {
    let status;
    let issuerValids = 0
    let issuerInValids = 0
    let issuerUnKnows = 0
    let issuerValidAndNotRevokes = 0
    let issuerNotVerified = 0;
    data.certfile.forEach(item => {
        if (item.issuerValidStatus === 0) {
            issuerInValids++;
        } else if (item.issuerValidStatus === 1) {
            issuerNotVerified++;
        } else if (item.issuerValidStatus === 2) {
            issuerUnKnows++;
        } else if (item.issuerValidStatus === 3) {
            issuerValidAndNotRevokes++;
        } else if (item.issuerValidStatus === 4) {
            issuerValids++;
        }
    })

    if (issuerInValids > 0) {
        status = 0;
    } else if (issuerNotVerified > 0) {
        status = 1;
    } else if (issuerUnKnows > 0) {
        status = 2;
    } else if (issuerValidAndNotRevokes > 0) {
        status = 3;
    } else if (issuerValids > 0) {
        status = 4;
    } else {
        status = -1;
    }

    return status;
}

function getAuditTrailFromCache(docHash) {
    let str = sessionStorage.getItem(`auditTrailData_main_${docHash}`);
    str = sanitizeStringFromSessionStorage(str);
    if (!str) return null;
    return JSON.parse(str);
}

function setAuditTrailCache(docHash, data) {
   sessionStorage.setItem(`auditTrailData_main_${docHash}`, JSON.stringify(data));
}

export async function initData() {
    const docHash = getQueryVariable('curDocHash')
    const inOnline = getQueryVariable('origin') === "ONLINE_CONTAINER";
    let auditTrail = {};
    auditTrail = getAuditTrailFromCache(docHash);
    if(!auditTrail) {
        auditTrail = await fetchAuditTrail(docHash);
        setAuditTrailCache(docHash, auditTrail);
    }
    if (inOnline) {
        const conclusion = renderAuthTrailContent0(auditTrail);
        return { auditTrailData: auditTrail, conclusion };
    } else {
        for (let item of auditTrail) {
            const verifyInfo = await fetchSigVerifyData(item.fileSizeOfSignedVersion);
            const { dwVerifyState, szSigner, bCertifable } = verifyInfo;
            const {
                signValidStatus,
                fileModifiedStatus,
                issuerValidStatus
            } = formatVerifySigForPhantom({ dwVerifyState, szSigner, bCertifable });
            item.signValidStatus = signValidStatus;
            item.fileModifiedStatus = fileModifiedStatus;
            item.issuerValidStatus = issuerValidStatus;
        }
        const obj = {};
        obj.base = {
            pageNumbers: auditTrail[0].build.pageNumbers,
            hash: auditTrail[0].hash || "暂无",
            type: auditTrail[0].build.type,
            fileName: auditTrail[0].fileName
        };
        obj.auth = auditTrail.map(item => {
            let auth = {
                ...item.certfile,
                ...item.auth,
                signerName: item.signer.signerName,
                operatorName: item.signer.operatorName,
                companyId: item.signer.companyId,
                companyName: item.signer.companyName,
                fileSizeOfSignedVersion: item.fileSizeOfSignedVersion,
                fieldId: item.fieldId,
                SAMLResponse: item.signer.SAMLResponse,
                email: item.signer.email,
                phone: item.signer.phone,
                byteRangeHash: item.byteRangeHash,
                signedHash: item.signedHash,
                signValidStatus: item.signValidStatus,
                fileModifiedStatus: item.fileModifiedStatus,
                issuerValidStatus: item.issuerValidStatus
            }
            auth.mode = item.signer.SAMLResponse ? 'in' : 'out'
            if (auditTrail[0].build.type === "signFlow") {
                if (item.assignSigner) {
                    auth.assignUserName = item.assignSigner && item.assignSigner.companyName ? `${item.assignSigner.companyName}（${item.assignSigner.signerName}）` : item.assignSigner.signerName
                    auth.assignUserName = item.assignSigner.email
                    auth.assignUserPhone = item.assignSigner.phone
                }
            }
            return auth;
        })

        for(let item of obj.auth) {
            if (item.SAMLResponse) {
                try {
                    if (item.method === "SAML") {
                        await validateSAMLResponse({ SAML: item.SAMLResponse });
                    }
                    if (item.method === "JWT") {
                        await checkJwtToken(item.SAMLResponse);
                    }
                    item.SAMLAuthVerify = true
                } catch (e) {
                    console.log('e', e)
                    item.SAMLAuthVerify = false
                }
            }
        }

        obj.certfile = auditTrail.map((item, index) => {
            let certfile = {
                ...item.auth,
                ...item.certfile,
                signerName: item.signer.signerName,
                operatorName: item.signer.operatorName,
                fileSizeOfSignedVersion: item.fileSizeOfSignedVersion,
                fieldId: item.fieldId,
                byteRangeHash: item.byteRangeHash,
                signedHash: item.signedHash,
                signValidStatus: item.signValidStatus,
                fileModifiedStatus: item.fileModifiedStatus,
                issuerValidStatus: item.issuerValidStatus,
            }
            return certfile;
        })

        if (auditTrail[0].build.type === "signFlow") {
            obj.base.startSignDate = auditTrail[0].certfile.signDate;
            obj.base.endSignDate = auditTrail[auditTrail.length - 1].certfile.signDate;
        }
        const illegalSignatures = [];
        auditTrail.forEach((item, index) => {
            if (item.illegalSignature) {
                const obj = {
                    fieldId: item.id,
                    pageIndex: item.pageIndex,
                    flowSignOrder: index + 1,
                    signValidStatus: item.signValidStatus,
                    fileModifiedStatus: item.fileModifiedStatus,
                    issuerValidStatus: item.issuerValidStatus,
                }
                if (item.assignSigner) {
                    obj.assignUserName = item.assignSigner.companyName ? `${item.assignSigner.companyName}（${item.assignSigner.signerName}）` : item.assignSigner.signerName;
                    obj.assignUserEmail = item.assignSigner.email;
                    obj.assignUserPhone = item.assignSigner.phone;
                }
                illegalSignatures.push(obj)
            }
        });
        obj.illegalSignatures = illegalSignatures;
        const conclusion = obj && Object.keys(obj).length > 0 ? renderAuthTrailContent0(obj) : null;
        return { auditTrailData: obj, conclusion };
    }
}

export function genQrCodeFn(auditTrail, docStore) {
    const processId = getQueryVariable('processId')
    if (auditTrail.base.type !== 'signFlow' || !processId || docStore !== '1') {
        return Promise.resolve();
    }
    const url = `${getPcDomain()}?id=${processId}`;
    return generateQrCode(url)
}

export function renderAuthTrailContent0(auditTrail) {
    const illegalSignerNames = auditTrail.illegalSignatures.map(item => `【${item.assignUserName}】`).join(', ');
    const existIllegalSignature = auditTrail.illegalSignatures && auditTrail.illegalSignatures.length > 0;
    const signValidStatusOfTotal = generateSignValidStatusConclusion(auditTrail);
    const fileModifiedStatusOfTotal = generateFileModifiedStatusConclusion(auditTrail);
    const issuerValidStatusOfTotal = generateIssuerValidStatusConclusion(auditTrail);

    let tip1i18n;
    let tip2i18n;
    let tip3i18n;
    let tip4i18n;
    let tip5i18n;
    let tip6i18n;
    let tip7i18n;
    switch (issuerValidStatusOfTotal) {
        case 0:
            tip1i18n = "至少有一个签署方的身份信息无效"
            break;
        case 1:
            tip1i18n = "至少有一个签署方的身份未验证"
            break;
        case 2:
            tip1i18n = "至少有一个签署方的身份信息未知"
            break;
        case 3:
            tip1i18n = existIllegalSignature ? "至少有一个签署方的身份信息未知" : "所有签署方的身份真实有效，但至少有一个签名无法检查签署方身份的吊销状态"
            break;
        case 4:
            tip1i18n = existIllegalSignature ? "至少有一个签署方的身份信息未知" : "所有签署方的身份真实有效"
            break;
        default:
            tip1i18n = "签署方的身份校验出错"
            break;
    }
    const authList = auditTrail.base.type === "signFlow" ? auditTrail.auth.slice(1) : auditTrail.auth;
    const outSignAuthList = authList.filter(item => item.mode === 'out');
    const inSignAuthList = authList.filter(item => item.mode === 'in');
    if (inSignAuthList.length > 0 && outSignAuthList.length < 1) {
        // 全为对内签名
        tip2i18n = ""
    } else if (inSignAuthList.length < 1 && outSignAuthList.length > 0) {
        // 全为对外签名
        const existNoWillingnessSign = outSignAuthList.filter(item => !item.additionalInfo || !item.additionalInfo.willingnessType).length > 0
        tip2i18n = existIllegalSignature || existNoWillingnessSign ? "至少有一个签署方的签署意愿未知" : "所有签署方的签署意愿真实有效"
    } else if (inSignAuthList.length > 0 && outSignAuthList.length > 0) {
        // 对内对外签名都有
        tip2i18n = "至少有一个签署方的签署意愿未知"
    }

    if (fileModifiedStatusOfTotal === 0) {
        tip3i18n = "签署任务中有文档被篡改";
    } else if (fileModifiedStatusOfTotal === 1) {
        tip3i18n = "签署任务中所有文档均未被篡改，但存在被允许的修改";
    } else if (fileModifiedStatusOfTotal === 2) {
        tip3i18n = "签署任务中所有文档均未被篡改";
    } else {
        tip3i18n = "文档完整性校验出错";
    }

    if (signValidStatusOfTotal === 0) {
        tip4i18n = "签署任务中至少有一个签名无效";
    } else if (signValidStatusOfTotal === 1) {
        tip4i18n = "签署任务中的所有签名均有效";
    } else if (signValidStatusOfTotal === 2) {
        tip4i18n = "签署任务中至少有一个签名有效性未知";
    } else if (signValidStatusOfTotal === 3) {
        tip4i18n = "签署任务中至少有一个签名在验证签名时发生错误";
    } else {
        tip4i18n = "签名校验出错";
    }

    if (auditTrail.base.type === "signFlow") {
        if (existIllegalSignature) {
            tip5i18n = `签署方${illegalSignerNames}并未按照签署流程中的要求完成签署`
        } else {
            tip5i18n = "所有签署方均按照签署流程要求完成签署"
        }
    }

    tip6i18n = "1）此签署报告仅对使用福昕电子签章服务的签署行为和结果进行出具。"
    tip7i18n = "2）对外签署签署人的身份认证和意愿认证过程，由杭州天谷信息科技有限公司开发的\"e签宝\"电子签名产品提供服务。杭州天谷信息科技有限公司可根据用户的申请出具相关签署证明。"
    return {
        tips: [tip1i18n, tip2i18n, tip3i18n, tip4i18n, tip5i18n].filter(it => !!it),
        declare: {
            msg1: tip6i18n,
            msg2: tip7i18n,
        }
    }
}

export function startDownAuditAction(Component, data) {
    const taskName = getQueryVariable('taskName') || '签署报告';
    const { token } = data;
    const com = <Component {...data}/>
    let html = ReactDOMServer.renderToString(com);
    html = '<style> * {font-family: \'宋体\'; line-height: 1.6 }; ul{ margin-top: 0; margin-bottom: : 1em }; h5 { margin-top: 0; margin-bottom: 0;}</style>' + html;
    loadingMessage('文档上传中，请勿关闭当前窗口…')
    let url = '';
    const file = new File([html], taskName + '.html', {
        type: "text/html",
    });
    return doUpload({
        token,
        file, uploadCb: (percent) => {
            loadingMessage(`文档上传中(${percent}%)，请勿关闭当前窗口…`)
        }
    }).then(docid => {
        const docList = [{docid, password: ""}];
        return doConvert({docList, token});
    }).then(res => {
        return getStatus({
            token,
            taskId: res.taskid, cb: (percent) => {
                loadingMessage(`文档转换中(${percent}%)，请勿关闭当前窗口…`)
            }
        });
    }).then(res => {
        url = getDownloadUrl({docid: res.docid, filename: taskName + '.pdf', token});
        return downloadPdf({
            url, cb: (percent) => {
                percent !== 100 ? loadingMessage(`文档下载中(${percent}%)，请勿关闭当前窗口…`) : hideMessage();
            }
        });
    }).then(buffer => {
        return new Blob([buffer], {type: "application/pdf"});
    }).catch(e => {
        console.log(e)
        const isTokenError = handleTokenErrorException(e);
        if(!isTokenError) {
            errorMessage('文档转换失败，请重试！')
        }
    })
}

export function handleTokenErrorException(e) {
    let isTokenError = false;
    const tokenErrors = [ERROR_AC_SAML_VALID_FAIL_100010, ERROR_AC_SAML_VALID_FAIL_100011, ERROR_AC_SAML_VALID_FAIL_100019, ERROR_AC_TOKEN_FAIL]

    const handleTokenErrorForPhantom = () => {
        openLoginWindowOfPhantom();
    }
    const handleTokenErrorForOnline = () => {
        removeQueryParam(TOKEN)
        sessionStorage.setItem(AUDIT_TRAIL_QUERY, window.location.search)
        login();
    }

    if(typeof e === 'string' && tokenErrors.includes(e)) {
        isTokenError = true;
        if (inPhantom()) {
            handleTokenErrorForPhantom();
        } else {
            handleTokenErrorForOnline();
        }
    }
    return isTokenError;
}

export function getProcessInfo(processId) {
    let taskName, docStore;
    if (!processId) {
        return Promise.resolve({});
    }
    return getSignFlowInfo({ processId }).then(data => {
        let info = data['simplified_process'];
        if (!info) {
            info = data['process'];
        }
        taskName = info.recipients.length > 1 ? info['name'] : '';
        docStore = info['doc_store'];
        return { taskName, docStore };
    })
}