import {useEffect, useState} from "react";
import {AutoComplete, Input} from "antd";
import {debounce} from "../../utils/util";
import {searchEmailForSign} from "@/request/user";
import {searchFlowMan} from "@/request/esign";
import cuid from "cuid";
import {ERROR_ESIGN_NO_RECIPIENT} from "@/request/error_container";

const OUT_PERSON_NAME = 'outPersonName';
const OUT_EMAIL_OR_PHONE = 'outEmailOrPhone';
const OUT_ORG_NAME = 'outOrgName';
const IN_PERSON_NAME = 'inPersonName';
const IN_EMAIL_OR_PHONE = 'inEmailOrPhone';

function _searchEmail(str, type = OUT_PERSON_NAME) {
    if (!str) {
        return;
    }
    if ((type === IN_EMAIL_OR_PHONE || type === OUT_EMAIL_OR_PHONE) && str.length < 3 && /^\d+$/.test(str)) { // 少于3位的数字输入，不做查询
        return;
    }

    const params = {
        page: 1,
        pageSize: 50,
    };
    if (type === IN_PERSON_NAME || type === IN_EMAIL_OR_PHONE) {
        if (type === IN_PERSON_NAME) {
            params.username = str;
        } else {
            params.useremailOrMobile = str;
        }
        return searchEmailForSign(params);
    } else {
        switch (type) {
            case OUT_EMAIL_OR_PHONE:
                params.type = 'contact';
                break;
            case OUT_ORG_NAME:
                params.type = 'company';
                break;
            default:
                params.type = 'name';
        }
        params.keyword = str;
        return searchFlowMan(params);
    }
}

export function AutoCompleteUserInfo(props) {

    const { signerNameChange, signerEmailOrPhoneChange, companyNameChange, fieldType, curValue } = props; // fieldType: outPersonName: 对外用户姓名，outEmail，outOrgName: 对外企业名称 inPersonName: 对内用户名称, inEmail: 对内用户邮箱

    const [inputValue, setInputValue] = useState('');
    const [signerNameOptions, setSignerNameOptions] = useState([]);

    const setValue = (value) => {
        if (fieldType === OUT_PERSON_NAME) {
            signerNameChange && signerNameChange(value)
        } else if (fieldType === OUT_EMAIL_OR_PHONE) {
            signerEmailOrPhoneChange && signerEmailOrPhoneChange(value)
        } else if (fieldType === OUT_ORG_NAME) {
            companyNameChange && companyNameChange(value)
        } else if (fieldType === IN_PERSON_NAME) {
            signerNameChange && signerNameChange(value)
        } else if (fieldType === IN_EMAIL_OR_PHONE) {
            signerEmailOrPhoneChange && signerEmailOrPhoneChange(value)
        }
    }
    const onChange = (e) => {
        setValue(e.target.value);
        setInputValue(e.target.value);
    }
    const onSelect = (value, option) => {
        const contact = option.contact;
        const userName = option.userName;
        const companyName = option.companyName;
        let curValueType;
        if([IN_PERSON_NAME, OUT_PERSON_NAME].includes(fieldType)) {
            curValueType = 'userName';
            setInputValue(userName);
        } else if ([IN_EMAIL_OR_PHONE, OUT_EMAIL_OR_PHONE].includes(fieldType)) {
            curValueType = 'contact';
            setInputValue(contact);
        } else if (fieldType === OUT_ORG_NAME) {
            curValueType = 'companyName';
            setInputValue(companyName);
        }
        userName && signerNameChange && signerNameChange(userName);
        contact && signerEmailOrPhoneChange && signerEmailOrPhoneChange(contact);
        if ([OUT_PERSON_NAME, OUT_ORG_NAME, OUT_EMAIL_OR_PHONE].includes(fieldType)) {
            companyNameChange && curValueType !== 'companyName' && companyNameChange(companyName);
        }
    }
    const handleSearch = async (value) => {
        debounce(async () => {
            try {
                const data = await _searchEmail(value, fieldType);
                const options = renderSignerNameOption(data);
                setSignerNameOptions(options);
            } catch (e) {
                if (e === ERROR_ESIGN_NO_RECIPIENT) {
                    console.log('没有查询到联系人');
                } else {
                    console.log(e);
                }
            }
        }, 500)(value);
    }
    const renderSignerNameOption = (data) => {
        if (fieldType === OUT_ORG_NAME || fieldType === OUT_PERSON_NAME || fieldType === OUT_EMAIL_OR_PHONE) {
            const emailList = data ? data : [];
            return renderOutFieldOption(emailList);
        } else {
            const emailList = data && data.users ? data.users : [];
            return renderInFieldOption(emailList);
        }
    }
    const renderOutFieldOption = (emailList) => {
        return emailList.map((item) => {
            const existEmailAndPhone = item.email && item.phone;
            const contact = item.email ? item.email : item.phone;
            return {
                value: `${item.name}#${contact}#${item.company}#${cuid()}`,
                label: (
                    <div
                        className='out-sign-field-options-container'
                        support-outline='true'
                    >
                        {
                            existEmailAndPhone ?
                            <>
                                <div className='person-info'>
                                    <div className={fieldType === OUT_PERSON_NAME ? 'name light-height' : 'name'}>{item.name}</div>
                                    <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{item.phone}</div>
                                </div>
                                <div className={fieldType === OUT_ORG_NAME ? 'company light-height' : 'company'}>{item.company}</div>
                                <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{item.email}</div>
                            </> :
                            <>
                                <div className='person-info'>
                                    <div className={fieldType === OUT_PERSON_NAME ? 'name light-height' : 'name'}>{item.name}</div>
                                    {
                                        item.company &&
                                        <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{contact}</div>
                                    }
                                </div>
                                <div className={fieldType === OUT_ORG_NAME ? 'company light-height' : 'company'}>{item.company}</div>
                                {
                                    !item.company &&
                                    <div className={fieldType === OUT_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{contact}</div>
                                }
                            </>
                        }
                    </div>
                ),
                userName: item.name,
                companyName: item.company,
                contact: contact
            }
        });
    }
    const renderInFieldOption = (emailList) => {
        return emailList.map((item) => {
            return {
                value: `${item.userName}${item.userEmail}${cuid()}`,
                label: (
                    <div
                        className='in-sign-field-options-container'
                        support-outline='true'
                    >
                        <div className={fieldType === IN_PERSON_NAME ? 'name light-height' : 'name'}>{item.userName}</div>
                        <div className={fieldType === IN_EMAIL_OR_PHONE ? 'email light-height' : 'email'}>{item.userEmail}</div>
                    </div>
                ),
                contact: item.userEmail,
                userName: item.userName
            }
        });
    }

    let placeholder = '';
    switch (fieldType) {
        case OUT_PERSON_NAME:
            placeholder = '身份证姓名 *';
            break;
        case OUT_ORG_NAME:
            placeholder = '企业名称 *';
            break;
        case IN_PERSON_NAME:
            placeholder = '姓名 *';
            break;
        case IN_EMAIL_OR_PHONE:
            placeholder = '邮箱/手机号 *';
            break;
        case OUT_EMAIL_OR_PHONE:
            placeholder = '邮箱/手机号 *';
            break;
        default:
            break;
    }

    useEffect(() => {
        setInputValue(curValue)
    }, [curValue])

    return (
        <AutoComplete
            dropdownMatchSelectWidth={252}
            value={inputValue}
            options={signerNameOptions}
            filterOption={true}
            onSelect={onSelect}
            onSearch={handleSearch}
            autoComplete='off'
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
        >
            <Input placeholder={placeholder} onChange={onChange}/>
        </AutoComplete>
    )
}