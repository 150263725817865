import { ConfigProvider, Modal } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import React  from 'react';
import {AccountContent} from "./content";

export default function Account(props) {

    const handleCancel = () => {
        props.close();
    };

    return (
        <ConfigProvider locale={zhCN}>
            <Modal
                title={'我的签署额度'}
                visible={true}
                width={400}
                bodyStyle={{ minHeight: 140 }}
                centered
                onCancel={handleCancel}
                footer={null}
            >
                <AccountContent/>
            </Modal>
        </ConfigProvider>
    );
}