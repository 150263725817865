import styles from "./index.module.less";
import UploadFile from "./uploadFile";
import {ConfigProvider, Image} from "antd";
import zhCN from "antd/es/locale/zh_CN";
import validatorBg from "@/assets/images/phantom/validator-bg.png";

export default function ValidatorMain(props) {
    const { setDocFn, clearDocFn, loading, failReason, setFailReasonFn} = props;

    function setFailureReasonFn(reason) {
        setFailReasonFn(reason);
        clearDocFn()
    }

    return (
        <>
            <div className={styles.banner}>
                <div className={styles.content}>
                    <div className={styles.left}>
                        <div className={styles.title}>福昕文档验签服务</div>
                        <div className={styles.desc}>
                            验签服务可以展示文档的签署信息，帮助签章验证者获取和验证文档签署时的状态，从而验证文档的真实性和完整性。
                        </div>
                        <div>
                            <UploadFile
                                setFailureReasonFn={setFailureReasonFn}
                                setDocFn={setDocFn}
                                loading={loading}
                            />
                            <ul className={styles.tips}>
                                <li>仅支持PDF格式，文档大小在50M以内</li>
                                <li>验签服务仅在本地完成，不会上传服务器</li>
                            </ul>
                            {
                                !!failReason &&
                                <div className={styles.error}>{failReason}</div>
                            }
                        </div>
                    </div>
                    <div className={styles.right}></div>
                </div>
            </div>
            <div className={styles.main}>
                <div className={styles.content}>
                    <div className={styles.title}>其他验签方式：福昕高级PDF编辑器</div>
                    <div className={styles.wrap}>
                        <div className={styles.left}>
                            <div className={styles.cycle1}>1</div>
                            <div className={styles.cycle2}>2</div>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.subTitle}>获得福昕高级PDF编辑器（如已有，请忽略这一步）</div>
                            <div>
                                <div>在您的电脑上安装福昕高级PDF编辑器客户端：</div>
                                <ul className={styles.desc}>
                                    <li>Win版：<a className={styles.link} rel="noreferrer" href='https://www.foxitsoftware.cn/pdf-editor' target='_blank'>https://www.foxitsoftware.cn/pdf-editor</a></li>
                                    <li>Mac版：<a className={styles.link} rel="noreferrer" href='https://www.foxitsoftware.cn/pdf-editor-mac' target='_blank'>https://www.foxitsoftware.cn/pdf-editor-mac</a></li>
                                </ul>
                                <div>或者，直接访问福昕高级PDF编辑器网页版：</div>
                                <ul className={styles.desc}>
                                    <li>网页版：<a className={styles.link} rel="noreferrer" href='https://online.foxitsoftware.cn' target='_blank'>https://online.foxitsoftware.cn</a></li>
                                </ul>
                            </div>
                            <div className={styles.subTitle}>打开文档</div>
                        </div>
                    </div>
                    <div>使用福昕高级PDF编辑器打开文档，在自动弹出的消息面板中，点击“签名面板”按钮，查看电子签章的有效性。</div>
                    <ConfigProvider locale={zhCN}>
                        <Image
                            width={640}
                            src={validatorBg}
                        />
                    </ConfigProvider>
                </div>
            </div>
        </>
    )
}