import {useCallback, useEffect, useState} from "react";
import {Skeleton} from "antd";
import styles from '../index.module.less';
import {getStore} from "@/store";
import {getParams} from "@/utils";
import {resizeDialog} from "../../../Model";
import {CREATE_SEAL_TYPE, PHANTOM_STAMP_TYPE, PUBLIC_SEAL_TYPE} from "../../../constant";
import {closeESignCNDialogAPI, refreshGalleryForPhantom} from "../../../../../request/phantom";
import AuthProvider from "../../../PhantomAuth/AuthProvider";

export default function PhantomOutSealAdmin() {

    const [url, setUrl] = useState('');

    const query = getParams();

    function handleIframeLoadFail() {
        console.error('iframe load fail');
    }

    const handleListenMsg = useCallback((event) => {
        const data = event.data;
        if (data && data.name === 'PUBLIC_SEAL') {
            if (data.type === 'close') {
                refreshGalleryForPhantom({ type: query.wardType })
            }
            if (data.type === 'resize') {
                const { width, height } = data;
                resizeDialog({ width, height });
            }
        }
    }, [query.wardType]);

    const endProcessCallback = ({ success }) => {
        if (!success) {
            closeESignCNDialogAPI();
        }
    }

    useEffect(() => {
        window.addEventListener('message', handleListenMsg);
        return () => {
            window.removeEventListener('message', handleListenMsg);
        }
    }, [query.wardType, handleListenMsg])



    useEffect(() => {
        const pst = getParams() ? getParams()['pst'] : '';
        const params = {
            publicSealType: PUBLIC_SEAL_TYPE.OUT_SEAL_ADMIN,
            token: getStore('userInfo').token,
            enterpriseId: getStore('userInfo').enterpriseId || '',
            isOut: query.wardType ===  '0' ? 1 : 0, // 0：对内 1：对外
            client: 2, // 客户端
            from: 'phantom',
            accountId: getStore('userInfo').accountId || '',
            trueName: getStore('esignPerson').name || '',
            forceCompany: 1, // 因为是打开企业印章管理，所以必须要进行企业认证
        }
        if (pst === PHANTOM_STAMP_TYPE.OUT_PERSON_SEAL_CREATE) {
            params.createSealType = CREATE_SEAL_TYPE.OUT_PERSON_SEAL;
        } else if (pst === PHANTOM_STAMP_TYPE.OUT_ORG_ADMIN) {
            params.orgId = getStore('userInfo').orgId || '';
        }
        if (window.location.origin.includes('localhost')) {
            params.phantom_web_mock_env = 1;
        }
        const stampUrl = `${window.location.origin}/public-seal-v2?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}`;
        setUrl(stampUrl);
    }, [query.wardType])

    const iframeClassName = !!url ? `${styles.pluginContainer} ${styles.fullScreen}` : styles.pluginContainer;


    return (
        <AuthProvider
            isOut={true}
            endProcessCallback={endProcessCallback}
        >
            <div className={`${styles.pluginBackground}`}>
                <div className={`${styles.skeletonWarp}`}><Skeleton/></div>
                {
                    !!url &&
                    <iframe
                        title='phantom-stamp-iframe'
                        className={`${iframeClassName}`}
                        src={url}
                        onError={handleIframeLoadFail}>
                    </iframe>
                }
            </div>
        </AuthProvider>
    )
}