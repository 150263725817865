import {Button, Space} from "antd";
import {useEffect, useRef, useState} from "react";
import styles from "../index.module.less";
import {CheckOutlined, ClearOutlined, RedoOutlined, UndoOutlined} from "@ant-design/icons";

export default function CreateDraw(props) {
    const {setDrawImg} = props;

    const [colorType, setColorType] = useState(0);
    const [lines, setLines] = useState([]);
    const [undoLines, setUndoLines] = useState([]);

    const canvasRef = useRef();
    const ctxRef = useRef();
    const canvasContainerRef = useRef();

    useEffect(() => {
        setTimeout(() => {
            if (canvasContainerRef.current && canvasRef.current && !ctxRef.current) {
                ctxRef.current = initCanvas(canvasContainerRef.current, canvasRef.current);
                setDrawImg('');
            }
        }, 500);
    }, [setDrawImg]);


    let isDown = false;
    let startPoint = null;
    let points = [];


    const handleMouseDown = (e) => {
        isDown = true;
        startPoint = getPoint(e, canvasRef.current);
    };

    const handleMouseMove = (e) => {
        if (!isDown) {
            return;
        }
        const point = getPoint(e, canvasRef.current);
        points.push(point);
        if (points.length > 2) {
            const [controlPoint, endPoint] = points.slice(-2);
            const middlePoint = getMiddlePoint(controlPoint, endPoint);
            drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
            startPoint = middlePoint;
        }
    };

    const handleMouseUp = () => {
        if (points.length > 3) {
            setUndoLines([]);
            setLines([...lines, points]);
            const base64 = canvasRef.current.toDataURL();
            setDrawImg(base64);
        }
        isDown = false;
        startPoint = null;
        points = [];
    };

    const clear = () => {
        clearImage(ctxRef.current, canvasRef.current);
        setLines([]);
        setUndoLines([]);
        setDrawImg('');
    }

    const undo = () => {
        const temp = [...lines];
        const lastLine = temp.pop();
        if (!lastLine) {
            return;
        }

        clearImage(ctxRef.current, canvasRef.current);
        temp.forEach(line => {
            if (line.length < 3) {
                return;
            }
            startPoint = line[0];
            for (let i = 1; i < line.length; i += 2) {
                const controlPoint = line[i];
                let endPoint = line[i + 1];
                if (line[i + 3] === undefined) {
                    if (line[i + 2]) {
                        endPoint = line[i + 2];
                        i++;
                    }
                }
                const middlePoint = getMiddlePoint(controlPoint, endPoint);
                drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
                startPoint = middlePoint;
            }
        });
        setUndoLines([...undoLines, lastLine]);
        setLines(temp);
        let base64 = '';
        if (temp.length > 0) {
            base64 = canvasRef.current.toDataURL();
        }
        setDrawImg(base64);
    };

    const redo = () => {
        const temp = [...undoLines];
        const line = temp.pop();
        if (!line) {
            return;
        }
        startPoint = line[0];
        for (let i = 1; i < line.length; i += 2) {
            const controlPoint = line[i];
            let endPoint = line[i + 1];
            if (line[i + 3] === undefined) {
                if (line[i + 2]) {
                    endPoint = line[i + 2];
                    i++;
                }
            }
            const middlePoint = getMiddlePoint(controlPoint, endPoint);
            drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
            startPoint = middlePoint;
        }
        setLines([...lines, line]);
        setUndoLines(temp);
        const base64 = canvasRef.current.toDataURL();
        setDrawImg(base64);
    };

    const changeColor = (colorType) => {
        setColorType(colorType);
        ctxRef.current.strokeStyle = colorType > 0 ? 'red' : 'black';
        clearImage(ctxRef.current, canvasRef.current);
        lines.forEach(line => {
            if (line.length < 3) {
                return;
            }
            startPoint = line[0];
            for (let i = 1; i < line.length; i += 2) {
                const controlPoint = line[i];
                let endPoint = line[i + 1];
                if (line[i + 3] === undefined) {
                    if (line[i + 2]) {
                        endPoint = line[i + 2];
                        i++;
                    }
                }
                const middlePoint = getMiddlePoint(controlPoint, endPoint);
                drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
                startPoint = middlePoint;
            }
        });
        const base64 = canvasRef.current.toDataURL();
        setDrawImg(base64);
    }


    return (<>
            <div ref={canvasContainerRef} className={styles.tabContainer + ' ' + styles.tabDraw}>
                <canvas ref={canvasRef} className={styles.canvas}
                        onMouseDown={handleMouseDown}
                        onTouchStart={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onTouchMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        onTouchEnd={handleMouseUp}
                        onMouseLeave={handleMouseUp}
                ></canvas>
            </div>
            <div style={{marginTop: 20, display: 'flex', justifyContent: 'space-between', marginRight: 270}}>
                <Space size={"middle"}>
                    <Button onClick={() => changeColor(0)} shape={'circle'} icon={<CheckOutlined/>}
                            style={{
                                backgroundColor: 'black',
                                color: colorType === 0 ? 'white' : 'black',
                                minWidth: 28,
                                width: 28,
                                height: 28, borderRadius: 100
                            }}></Button>
                    <Button onClick={() => changeColor(1)} shape={'circle'} icon={<CheckOutlined/>}
                            style={{
                                backgroundColor: 'red',
                                color: colorType === 1 ? 'white' : 'red',
                                minWidth: 28,
                                width: 28,
                                height: 28, borderRadius: 100

                            }}></Button>
                </Space>
                <div>
                    <Button disabled={lines.length < 1} onClick={undo} type={'text'}
                            icon={<UndoOutlined style={{fontSize: 16}}/>} style={{height: 28, padding: '4px 8px'}}>撤销</Button>
                    <Button disabled={undoLines.length < 1} onClick={redo} type={'text'}
                            icon={<RedoOutlined style={{fontSize: 16}}/>} style={{height: 28, padding: '4px 8px'}}>重做</Button>
                    <Button disabled={lines.length < 1} onClick={clear} type={'text'}
                            icon={<ClearOutlined style={{fontSize: 16}}/>} style={{height: 28, padding: '4px 8px'}}>清除</Button>
                </div>
            </div>
        </>
    )
}

export function initCanvas(container, canvas, lineWidth = 6, color = '#000') {
    const {width, height} = container.getBoundingClientRect();
    canvas.width = width - 20;
    canvas.height = height - 20;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    clearImage(ctx, canvas);
    return ctx;
}

export function clearImage(ctx, canvas) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
}

export function getPoint(e, canvs, isLandScape = false) {
    const point = {
        x: 0,
        y: 0
    }
    const event = e.nativeEvent;
    if (event instanceof MouseEvent) {
        point.x = event.offsetX;
        point.y = event.offsetY;
    } else if (event instanceof TouchEvent) {
        const first = event.targetTouches[0];
        const {x, y, width} = canvs.getBoundingClientRect();
        if (isLandScape) {
            point.y = width - first.clientX + x;
            point.x = first.clientY - y;
        } else {
            point.x = first.clientX - x;
            point.y = first.clientY - y;
        }
    }
    return point;
}

export function getMiddlePoint(p1, p2) {
    const x = (p1.x + p2.x) / 2;
    const y = (p1.y + p2.y) / 2;
    return {x, y};
}

export function drawCurve(ctx, startP, controlP, endP) {
    ctx.beginPath();
    ctx.moveTo(startP.x, startP.y);
    ctx.quadraticCurveTo(controlP.x, controlP.y, endP.x, endP.y);
    ctx.stroke();
    ctx.closePath();
}
