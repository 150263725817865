function U(r) {
  return new Worker(
    "/webSDK/worker/assets/worker1-Cb0MzUMI.js",
    {
      type: "module",
      name: r == null ? void 0 : r.name
    }
  );
}
function N(r) {
  return r && r.__esModule && Object.prototype.hasOwnProperty.call(r, "default") ? r.default : r;
}
var S = function(e, t) {
  var s = "000000000" + e;
  return s.substr(s.length - t);
}, k = S, q = typeof window == "object" ? window : self, D = Object.keys(q).length, P = navigator.mimeTypes ? navigator.mimeTypes.length : 0, L = k((P + navigator.userAgent.length).toString(36) + D.toString(36), 4), O = function() {
  return L;
}, f, v = typeof window < "u" && (window.crypto || window.msCrypto) || typeof self < "u" && self.crypto;
if (v) {
  var V = Math.pow(2, 32) - 1;
  f = function() {
    return Math.abs(v.getRandomValues(new Uint32Array(1))[0] / V);
  };
} else
  f = Math.random;
var C = f, u = O, R = S, I = C, a = 0, p = 4, h = 36, y = Math.pow(h, p);
function g() {
  return R((I() * y << 0).toString(h), p);
}
function M() {
  return a = a < y ? a : 0, a++, a - 1;
}
function l() {
  var r = "c", e = (/* @__PURE__ */ new Date()).getTime().toString(h), t = R(M().toString(h), p), s = u(), n = g() + g();
  return r + e + t + s + n;
}
l.slug = function() {
  var e = (/* @__PURE__ */ new Date()).getTime().toString(36), t = M().toString(36).slice(-4), s = u().slice(0, 1) + u().slice(-1), n = g().slice(-2);
  return e.slice(-2) + t + s + n;
};
l.isCuid = function(e) {
  return typeof e != "string" ? !1 : !!e.startsWith("c");
};
l.isSlug = function(e) {
  if (typeof e != "string") return !1;
  var t = e.length;
  return t >= 7 && t <= 10;
};
l.fingerprint = u;
var _ = l;
const j = /* @__PURE__ */ N(_), i = {
  RESPONSE: "response",
  REQUEST: "request",
  EVENT: "event",
  LISTENER: "listener"
};
function W() {
  return new Promise(() => {
    throw new Error("MessageUtil error");
  });
}
class F {
  constructor(e) {
    this.onRequestMethodFn = W, this.from = "", this.cacheMap = {}, this.self = e, this.init = this.init.bind(this), this.onRequest = this.onRequest.bind(this), this.onResponse = this.onResponse.bind(this), this.handleMessage = this.handleMessage.bind(this), this.doSendResponse = this.doSendResponse.bind(this);
  }
  init(e, t) {
    this.from = t, this.onRequestMethodFn = e, this.self.addEventListener("message", (s) => {
      let n = s.data;
      if (n.type === i.RESPONSE) {
        this.onResponse(n);
        return;
      }
      if (n.type === i.REQUEST || n.type === i.LISTENER) {
        this.onRequest(n, s.source);
        return;
      }
    }, !1);
  }
  onRequest(e, t) {
    const { method: s, data: n, id: o, type: d } = e;
    let m = d;
    const E = (c) => {
      let w = i.RESPONSE;
      m === i.LISTENER && (w = i.EVENT);
      let b = {
        from: this.from,
        type: w,
        id: o,
        method: s,
        data: c
      };
      try {
        t.postMessage(b);
      } catch (T) {
        console.log(T);
      }
    };
    this.onRequestMethodFn(s, n, o, E).then((c) => {
      m !== i.LISTENER && E(c);
    });
  }
  // receive message from listener
  onResponse(e) {
    let t = e.id, s = e.data, n = this.cacheMap[t];
    if (n && n.resolve) {
      delete this.cacheMap[t], n.resolve(s);
      return;
    }
    throw new Error("error");
  }
  doSendResponse(e, t, s) {
    let n = {
      from: this.from,
      type: i.REQUEST,
      id: s,
      method: e,
      data: t
    };
    this.self && this.self.postMessage(n);
  }
  /*
  * @param {string} method
  * @param {object} data
  * @returns {Promise.<Object>} result
  */
  handleMessage(e, t) {
    return new Promise((s, n) => {
      let o = j();
      this.cacheMap[o] = {
        resolve: s,
        reject: n
      };
      let d = {
        from: this.from,
        type: i.REQUEST,
        id: o,
        method: e,
        data: t
      };
      this.self && this.self.postMessage(d);
    });
  }
}
const Q = () => Promise.resolve();
class $ {
  constructor(e) {
    this.messageUtil = new F(e);
  }
  init(e) {
    return this.messageUtil.init(Q, e);
  }
  initEngine() {
    return this.messageUtil.handleMessage("initEngine").then((e) => e);
  }
  uploadDoc(e) {
    return this.messageUtil.handleMessage("uploadDoc", e).then((t) => t);
  }
  validatorDoc() {
    return this.messageUtil.handleMessage("validatorDoc").then((e) => e);
  }
}
function A(r) {
  const e = new U(), t = new $(e);
  return t.init(), t;
}
export {
  A as initWorker
};
