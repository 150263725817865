import React, {useEffect, useState, useRef} from "react";
import {Space} from "antd";
import {getOrgAdminUrl, getPersonAdminUrl, getPersonCreateUrl} from "@/request/esign";
import {
    ERROR_AC_ORG_PERMISSION_FAIL, ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED,
    ERROR_EQB_ORG_TOKEN_EXPIRED,
    ERROR_EQB_SEAL_PERMISSION_FAIL
} from "@/request/error_container";
import styles from "./index.module.less";
import {CloseCircleFilled, SyncOutlined} from "@ant-design/icons";
import {CREATE_SEAL_TYPE} from "../../../constant";
import {getParams} from "../../../../../utils";

function NoPermissionView() {
    return (
        <div style={{ height: '90vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Space style={{ color: '#FF4D4F', fontWeight: 400 }}>
                <CloseCircleFilled style={{ width: '16px'}}/>
                <span style={{ fontSize: '14px' }}>提示：您暂无该管理权限，请联系企业管理员授权。</span>
            </Space>
        </div>
    )
}

export default function PureOutSealAdminComp() {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const msg = useRef(null);

    const { createSealType, orgId, accountId, token, /** enterpriseId, trueName **/ } = getParams();

    useEffect(() => {
        setLoading(true);
        let request;
        window.parent.postMessage({
            name: 'PUBLIC_SEAL',
            type: 'resize', width: 890, height: 630
        }, window.location.origin);
        if (createSealType) {
            if (createSealType === CREATE_SEAL_TYPE.OUT_PERSON_SEAL) {
                request = getPersonCreateUrl({token, accountId});
            } else {
                request = getOrgAdminUrl({token,orgId});
            }
        } else {
            request = orgId ? getOrgAdminUrl({token,orgId}) : getPersonAdminUrl({token, accountId});
        }
        request.then(url => {
            msg.current = null;
            setUrl(url);
        }).catch(key => {
            setUrl('');
            if (key === ERROR_EQB_SEAL_PERMISSION_FAIL) {
                msg.current = null;
            } else {
                const result = {};
                if (ERROR_EQB_AUTH_EXPIRED === key) {
                    if (orgId) {
                        result.txt = '提示：您的授权过期，请重新完成企业认证/授权。';
                    } else {
                        result.txt = '提示：您的授权过期，请重新完成个人实名认证。';
                    }
                }
                else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    result.txt = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    result.txt = '您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。';
                    result.link = 'https://www.foxitsoftware.cn/esign#sales';
                    result.linkTxt = '联系销售';
                }
                else if (ERROR_AC_PERMISSION_FAIL === key) {
                    result.txt = '提示：您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。';
                } else if(ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    result.txt = '提示：您暂无印章管理权限，请联系IT管理员分配授权后重试。';
                }
                msg.current = result;
            }
        }).finally(() => {
            setLoading(false);
        });

    }, [orgId, accountId, token, createSealType]);

    return (
        <>
            {loading && <div className={styles.loading}><SyncOutlined spin/>&emsp;正在获取链接…</div>}
            {!loading && url && <iframe title="eSignCn" src={url} className={styles.iframe} height='600px'></iframe>}
            {!loading && !url && msg.current && <ErrorTip {...msg.current} />}
            {!loading && !url && !msg.current && <NoPermissionView/>}
        </>
    );
}

function ErrorTip({txt, link, linkTxt}) {
    return (
        <div className={styles.error}>
            <CloseCircleFilled/> &emsp;{txt}
            {link && linkTxt && <a href={link} target="_blank" rel="noreferrer">{linkTxt}</a>}
        </div>
    );
}