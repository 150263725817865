import { Component } from "react";
import { Modal, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { rejectFlow } from "@/request/esign";
import { errorMessage, successMessage } from "@/pages/components/Message";
import OtelService from "@/utils/otel/service";
import { getOtelSpanName } from "@/pages/PublicPage/service";

const otelService = new OtelService();

const { TextArea } = Input;

class RejectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      value: undefined,
      currProcessId: "",
      recipientOrder: undefined,
    };
  }
  openRejectModal({ currProcessId, recipientOrder }) {
    this.setState({
      visible: true,
      value: undefined,
      currProcessId,
      recipientOrder,
    });
  }
  changeValue(event) {
    const value = event.target.value;
    this.setState({
      value,
    });
  }
  async confirm() {
    const { currProcessId, recipientOrder, value } = this.state;
    if (!value) {
      this.setState({
        value: "",
      });
      return;
    }
    if (value.length > 200) return;
    if (!currProcessId) {
      return;
    }
    rejectFlow({
      processId: currProcessId,
      recipientOrder,
      rejectReason: value,
    })
      .then(() => {
        otelService
          .startCommonSpan(getOtelSpanName("request-signRecord-refuseTask"))
          .then(({ end }) => {
            end && end();
          });

        this.setState({
          visible: false,
          value: undefined,
          currProcessId: "",
        });

        successMessage("流程拒签成功");
        this.props.success();
      })
      .catch(() => {
        errorMessage("流程拒签失败，请重试");
      });
  }
  render() {
    const { visible, value } = this.state;
    return (
      <Modal
        title="拒绝签署"
        open={visible}
        okText={"确认"}
        cancelText={"取消"}
        onOk={() => this.confirm()}
        onCancel={() => this.setState({ visible: false, value:undefined, currProcessId: "" })}
      >
        <div className="esigncn-refuse-modal">
          <div className="title">
            <InfoCircleOutlined className="icon" />
            <span>拒绝后，签署任务不可重新继续签署</span>
          </div>
          <div>
            <TextArea
              rows={4}
              placeholder="请填写拒签原因，将发送给各签署参与人"
              showCount={true}
              maxLength={200}
              value={value}
              onInput={(e) => this.changeValue(e)}
            />
            {value === "" && <span className={"red"}>请输入拒签原因</span>}
            {value && value.length > 200 && <span className={"red"}>字数超过限制</span>}
          </div>
        </div>
      </Modal>
    );
  }
}
export default RejectDialog;
