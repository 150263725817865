import React, {useRef, useState} from "react";
import {Button, Input, Modal, Spin} from "antd";
import {LoadingOutlined} from '@ant-design/icons';
import {errorMessage, successMessage} from "../../components/Message";
import styles from "../index.module.less";
import {getCaptchaUrl} from "../../../request/user";

export default function PcViewer(props) {
    const {name, email, mobile, token} = props.querys;
    const checkMobile = props.checkMobile;
    const checkValueFn = props.checkValue;
    const requestCodeFn = props.requestCode;
    const submitFn = props.submit;
    const [success, setSuccess] = useState(false);
    const [inputStatus, setInputStatus] = useState('');
    const [codeStatus, setCodeStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const codeRef = useRef();

    const showName = checkMobile ? '手机号码' : '邮箱地址';
    let showMobile;
    if (checkMobile) {
        if (mobile.includes('*')) {
            showMobile = mobile;
        } else {
            showMobile = mobile.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
        }
    } else {
        if (email.includes('*')) {
            showMobile = email;
        } else {
            showMobile =  email.replace(/(.?).*@(.*)/, "$1***@$2");
        }
    }
    const title = success ? `${showName}已完善` : `完善您的${showName}`;
    const summary = success ? `${showName}设置成功！您仍可在 <b>我的账户 > 个人资料</b>中进行更换。<br /><br />
            点击【关闭】，继续完成后续签署相关操作。` :
        `为验证签署身份，请完善您福昕账户中的${showName}。完善后，您仍可在 <b>我的账户 > 个人资料</b>中进行更换。<br /><br />
            ${name && showMobile ? `若您是签署人 <b>${name||''}</b>，请验证 <b>${showMobile}</b>`: ''}`;
    const changeValue = (e) => {
        const value = e.target.value;
        if (!checkValueFn(value, checkMobile ? 'mobile' : 'email')) {
            setInputStatus('warning');
        } else {
            setInputStatus('');
        }
    };

    const changeCode = (e) => {
        const value = e.target.value;
        if (!checkValueFn(value, 'code')) {
            setCodeStatus('warning');
        } else {
            setCodeStatus('');
        }
    };

    const checkInput = () => {
        const value = inputRef.current.input.value;
        if (!checkValueFn(value, checkMobile ? 'mobile' : 'email')) {
            setInputStatus('error');
            return false;
        }
        setInputStatus('');
        return true;
    };

    const sendCode = (captchaId, captcha) => {
        const value = inputRef.current.input.value;
        return requestCode({requestCodeFn, value, checkMobile, showName, token, captchaId, captcha});
    };

    const submit = () => {
        const account = inputRef.current.input.value;
        const code = codeRef.current.input.value;

        setInputStatus('');
        setCodeStatus('');
        setLoading(true);
        submitFn({account, code, checkMobile, token}).then(res => {
            setLoading(false);
            switch (res) {
                case -1:
                    setInputStatus('error');
                    break;
                case -2:
                    setCodeStatus('error');
                    break;
                case -3:
                    errorMessage(`设置${showName}失败`);
                    break;
                case 1:
                    setSuccess(true);
                    break;
                default:
            }
        });
    };

    return (
        <>
            <div className={styles.result} style={{paddingTop: 0}}>
                <div className={styles.icon}>
                    <i className={styles.img + ' ' + (checkMobile ? styles.mobile:styles.email)}></i>
                    <p className={styles.stat + ' ' + (success ? styles.statOk : '')}>
                        <i className={styles.img + ' ' + (success ? styles.ok : styles.plus)}></i></p>
                </div>

                <div className={styles.container}>
                    <div className={styles.title}>{title}</div>
                    <div className={styles.tips} dangerouslySetInnerHTML={{__html: summary}}></div>
                    {
                        !success && <>
                            <Input addonBefore={checkMobile ? <div>+86</div>: null} ref={inputRef} status={inputStatus} onChange={changeValue} placeholder={showName}
                                   type="tel" maxLength={checkMobile ? 11 : 50} allowClear/>
                            {inputStatus === 'error' && <span className={styles.error}>请输入正确格式的{showName}</span>}
                            <br /><br />
                            <Input ref={codeRef} status={codeStatus} onChange={changeCode} placeholder="验证码"
                                   type="text" maxLength={6} allowClear suffix={<SendCode checkMobile={checkMobile} sendCode={sendCode} checkInput={checkInput} />} />
                            {codeStatus === 'error' && <span className={styles.error}>请输入正确的验证码</span>}
                        </>
                    }
                </div>
            </div>
            <div className={styles.buttons}>
                {!success && <Button loading={loading} onClick={submit} type='primary'
                                     style={{width: '90px'}}>确认</Button>}
                <Button key="back" onClick={() => props.sendMsg({type:'close', fromReload: success})}
                        style={{width: '90px', marginLeft: '10px'}}>
                    {success ? '关闭' : '取消'}</Button>
            </div>
        </>
    );
}

function SendCode(props) {
    const {checkInput, sendCode, checkMobile} = props;
    const [stat, setStat] = useState(0);
    const [sec, setSec] = useState(59);
    const [showCaptcha, setShowCaptcha] = useState(null);

    const captchaIdRef = useRef(null);

    const handleClick = () => {
        if (!checkInput()) {
            return;
        }
        if (checkMobile) {
            return openCaptcha();
        }
        setStat(1);
        sendCode().then(code => {
            setStat(code > 0 ? 2 : 0)
        });
    }

    if (stat === 2) {
        setTimeout(() => {
            if (sec > 1) {
                setSec(sec - 1);
            } else {
                setStat(0);
                setSec(59);
            }
        }, 1000)
    }
    const antIcon = <LoadingOutlined style={{fontSize: 20}} spin/>;

    const closeCaptcha = (captcha) => {
        setShowCaptcha(null);
        if (captcha) {
            sendCode(captchaIdRef.current, captcha).then(code => {
                if (code > 0) {
                    setStat(2);
                } else {
                    if (code === -4) {
                        openCaptcha();
                    }
                    setStat(0);
                }
            });
        }
    }

    const openCaptcha = () => {
        captchaIdRef.current = 'esc-'+Math.random().toString(36).slice(2);
        setShowCaptcha(getCaptchaUrl(captchaIdRef.current));
    }

    return (
        <>
            {stat === 0 && <span onClick={() => handleClick()} className={styles.sendCode}>
                    获取验证码
                </span>
            }
            {stat === 1 && <Spin indicator={antIcon}/>
            }
            {stat === 2 && <span style={{color: '#666666'}}>
                    重新发送（{sec}s）
                </span>
            }
            {showCaptcha && <Captcha close={closeCaptcha} url={showCaptcha} />}
        </>

    )
}

function Captcha(props) {
    const [captcha, setCaptcha] = useState('');

    return (
        <Modal title="请输入图形验证码"
               open={true}
               footer={null}
               width={320}
               maskClosable={false}
               centered={true}
               bodyStyle={{ paddingTop: 16 }}
               maskStyle={{backgroundColor: 'rgba(255,255,255, 0.4)'}}
               closeIcon={<></>}
        >
            <div>
                <Input placeholder="请输入图中字符" type="text" maxLength={6}
                       value={captcha} onChange={(e) => setCaptcha(e.target.value)}
                       allowClear
                       suffix={<img alt={"captcha"}
                                    height={30}
                                    style={{margin: '-4px -10px -4px 0', cursor: 'pointer'}}
                                    onClick={e => e.target.src = getCaptchaUrl()}
                                    src={props.url} />}
                />
            </div>
            <div style={{display: "flex", gap: 20, marginTop: 32}}>
                <Button onClick={() => props.close(null)}
                        style={{width: '100%'}}
                >
                    取消
                </Button>
                <Button onClick={() => props.close(captcha)}
                        type="primary"
                        disabled={captcha.length < 4}
                        style={{width: '100%'}}
                >
                    继续
                </Button>
            </div>
        </Modal>
    )
}

function requestCode({requestCodeFn, value, checkMobile, showName, token, captchaId, captcha}) {
    return requestCodeFn({account:value, checkMobile, token, captcha, captchaId}).then(code => {
        switch (code) {
            case -1:
                errorMessage(`该${showName}已被其他账号占用`);
                break;
            case -2:
            case -3:
                errorMessage('验证码发送错误或超出发送数量限制，请稍后再试')
                break;
            case -4:
                errorMessage(`图形验证码错误，请重新输入`);
                break;
            case 1:
                successMessage('验证码发送成功');
                break;
            default:
                code = 0;
        }
        return code;
    });
}
