import React, {useState} from "react";
import PureAuthComp from "./PureAuthComp";

export default function AuthProvider(props) {

    const { isOut, endProcessCallback, children } = props;

    const [showChildren, setShowChildren] = useState(false);

    const endProcessCallbackFn = (params) => {
        endProcessCallback(params);
        setShowChildren(true);
    }

    return (
        <div>
            {
                showChildren &&
                children
            }
            {
                !showChildren &&
                <PureAuthComp
                    wardType={isOut ? 0 : 1}
                    endProcessCallback={endProcessCallbackFn}
                />
            }
        </div>
    )
}