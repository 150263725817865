import otelApi from './api'

// 链接追踪
export default class OtelService {
    /**
     * Starts a common span or a child span based on the current active span.
     * If there is no current active span, it starts a new active span with the given spanName.
     * If there is a current active span, it starts a new child span with the given spanName.
     *
     * @param spanName - The name of the span to start.
     * @param callback - Optional callback function to be called when the span ends successfully.
     * @param error - Optional callback function to be called when an error occurs while processing the span.
     * @returns A promise that resolves to an object containing the started span and an 'end' method to end the span.
     */
    async startCommonSpan(spanName, callback = undefined, error = undefined) {
        const currentActiveSpan = otelApi.getCurrentActiveSpan();
        if (currentActiveSpan) {
            return this.startChildSpan(spanName);
        }
        return await this.startActiveSpan(spanName, callback, error);
    }

    /**
     * Starts a new active span with the given spanName.
     *
     * @param spanName - The name of the span to start.
     * @param callback - Optional callback function to be called when the span ends successfully.
     * @param error - Optional callback function to be called when an error occurs while processing the span.
     * @returns A promise that resolves to an object containing the started span and an 'end' method to end the span.
     */
    async startActiveSpan(spanName, callback, error) {
        if (spanName) {
            const activeSpan = await otelApi.startActiveSpan(spanName, callback, error);

            return {
                span: activeSpan,
                end: () => {
                    this.endActiveSpan();
                },
            };
        } else {
            return {
                span: null,
                end: () => {},
            };
        }
    }

    /**
     * Starts a new child span with the given spanName and options.
     *
     * @param spanName - The name of the child span to start.
     * @param options - Optional options for the child span.
     * @returns An object containing the started child span and an 'end' method to end the span.
     */
    startChildSpan(spanName, options) {
        if (spanName) {
            const activeContext = otelApi.getCurrentActiveContext();
            const childSpan = otelApi.startSpan(spanName, options, activeContext);
            return {
                span: childSpan,
                end: () => {
                    otelApi.endSpan(childSpan);
                },
            };
        } else {
            return {
                span: null,
                end: () => {},
            };
        }
    }

    /**
     * Ends the current active span.
     */
    endActiveSpan() {
        otelApi.endCurrentActiveSpan();
    }

    /**
     * Cleans up the current active span without ending it.
     */
    cleanCurrentActiveSpan() {
        otelApi.cleanCurrentActiveSpan();
    }

    /**
     * Gets trace headers from the current active context.
     *
     * @returns An object containing the trace headers.
     */
    getTraceHeaders() {
        const activeContext = otelApi.getCurrentActiveContext();
        const traceHeaders = otelApi.injectTraceHeaders(activeContext);

        return traceHeaders;
    }

    /**
     * Sets the status of the given span.
     *
     * @param span - The span to set the status for.
     * @param status - The status to set for the span.
     */
    setSpanStatus(span, status) {
        span && otelApi.setSpanStatus(span, status);
    }

    /**
     * Sets an attribute with the given name and value for the given span.
     *
     * @param span - The span to set the attribute for.
     * @param name - The name of the attribute.
     * @param value - The value of the attribute.
     */
    setSpanAttribute(span, name, value) {
        span && otelApi.setSpanAttribute(span, name, value);
    }

    /**
     * Sets an exception for the given span, optionally with additional attributes.
     *
     * @param span - The span to set the exception for.
     * @param exception - The exception to set for the span.
     * @param attributes - Optional additional attributes to set for the exception.
     */
    setSpanException(span, exception, attributes) {
        span && otelApi.setSpanException(span, exception, attributes);
    }

    /**
     * Demonstrates a multi-span test with asynchronous behavior.
     * Starts and ends multiple active spans with child spans in a specific order and delays.
     */
    async multiSpanTest() {
        await otelApi.startActiveSpan('test-1');
        await otelApi.startActiveSpan('test-2');
        await otelApi.startActiveSpan('test-3');

        const ct1 = otelApi.getActiveContext('test-1');
        const ct2 = otelApi.getActiveContext('test-2');
        const ct3 = otelApi.getActiveContext('test-3');

        const child1 = otelApi.startSpan('test-1-child', {}, ct1);
        const child2 = otelApi.startSpan('test-2-child', {}, ct2);
        const child3 = otelApi.startSpan('test-3-child', {}, ct3);

        setTimeout(() => {
            otelApi.endSpan(child3);
            otelApi.endActiveSpan('test-3');
        }, 1000);
        setTimeout(() => {
            otelApi.endSpan(child2);
            otelApi.endActiveSpan('test-2');
        }, 2000);
        setTimeout(() => {
            otelApi.endSpan(child1);
            otelApi.endActiveSpan('test-1');
        }, 3000);
    }
}