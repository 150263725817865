import Cert from "./components/Cert";
import {useState} from "react";
import Agree from "./components/Agree";
import qs from "qs";
import Result from "./components/Result";
import Header from "../components/Header";
import {getESignAccount} from "../../request/esign";
import {getQueryVariable} from "../PublicPage/service";
import {inPhantom} from "../PublicPage/utils/util";

export default function CertPage() {
    const search = window.location.search;
    const index = search.indexOf('?');
    let defaultState;
    let orgName = '';
    let params;
    if (index >= 0) {
        params = search.slice(index + 1);
        params = qs.parse(params);
        defaultState = parseInt(params.state) || 0;
        orgName = params.orgName || '';
    }

    let redirectUrl = origin + '/certification?state=3&orgName=' + orgName;
    if (inPhantom()) {
        redirectUrl = origin + `/eSignCN-cert?state=3&wardType=0&orgName=${orgName}`
        const from = getQueryVariable('from')
        if (from === 'signFlowPanel') {
            redirectUrl = redirectUrl + '&from=signFlowPanel'
        }
    }
    const authParams = {
        account: getESignAccount(),
        redirectUrl,
        orgName,
    };

    const [state, setState] = useState(defaultState);
    const changeState = (state) => {
        if (state === -1) {
            window.location.href = 'https://www.foxitsoftware.cn/esign#sales';
            return;
        }
        setState(state);
    };

    let child;
    switch(state) {
        case 1:
        case 2:
            child = <Agree changeState={changeState} authParams={authParams}  />;
            break;
        case 3:
            child = <Result changeState={changeState} authParams={authParams} resultParams={params} />;
            break;
        default:
            child = <Cert changeState={changeState} orgName={orgName} />;
    }
    return (
        <div>
            {!inPhantom() && <Header/>}
            {child}
        </div>
    )
}
