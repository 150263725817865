import {Modal, Spin, Button, message} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import './index.less'
import {useEffect, useState} from "react";
import {enterPersonCreate} from "../service";
import {getStore} from "../../../store";
import {closeESignCNDialogAPI, openUrlByBrowser, showESignCNDialogAPI} from "../../../request/phantom";
import {getOrgAdminUrl} from "../../../request/esign";
import {shouldCallPhantomApi} from "../config";
import {
    ERROR_AC_ORG_PERMISSION_FAIL, ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED, ERROR_EQB_ORG_TOKEN_EXPIRED,
    ERROR_EQB_SEAL_PERMISSION_FAIL
} from "../../../request/error_container";
import {getPhantomVersion} from "../utils/util";

export default function AddOutSignStamp(props) {

    const [personLoading, setPersonLoading] = useState(false);
    const [orgLoading, setOrgLoading] = useState(false);
    useEffect(() => {
        if (window.location.pathname === '/add-out-sign-stamp') {
            document.body.setAttribute('name', 'add-out-sign-stamp')
        }

    }, [])
    const onCancel = () => {
        if (shouldCallPhantomApi()) {
            closeESignCNDialogAPI()
        } else {
            this.setState({
                visible: false
            })
        }
    }

    const personCallback = async () => {
        const hasAuthorizedPerson = getStore('esignPerson') && Object.keys(getStore('esignPerson')).length > 0;
        if (hasAuthorizedPerson) {
            // if (false) {
            await enterPersonCreate()
        } else {
            // 个人实名认证
            showESignCNDialogAPI({ title: '个人实名认证', id: 'ESIGNCN_PERSON_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?state=2&wardType=0`, width: 600, height: 600})
        }
    }

    const orgCallback = () => {
        return new Promise((resolve) => {
            const orgName = getStore('userInfo').enterpriseName;
            if (!orgName) {
                const phantomVersion = getPhantomVersion();
                if (phantomVersion && phantomVersion[0] < 13) {
                    Modal.info({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}></span>您当前登录的账号未加入企业组织，需联系销售为您所在的企业创建企业信息；<br/><br/>若您的企业已在福昕电子签章服务创建企业信息，请联系IT管理员为您加入组织，并分配对外签署服务的使用权限。</span>,
                        zIndex: 99999,
                        okText: "联系销售",
                        mask: false,
                        closable: true,
                        centered: true,
                        className: "eSignCN_funcDialog_container",
                        onOk() {
                            openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales')
                            resolve()
                        },
                        onCancel() {
                            resolve()
                        }
                    })
                } else {
                    if(!getStore('userInfo').userEmail) {
                        showESignCNDialogAPI({ title: '完善邮箱', id: 'ESIGN_CN_ADD_MOBILE', type: 'Window', webUrl: `${window.location.origin}/add-email-phantom?wardType=0&forJoinAcOrg=1&t=${Date.now()}`, width: 600, height: 600})
                        resolve('keepDialog');
                    } else {
                        showESignCNDialogAPI({
                            title: "PDF编辑器",
                            id: 'ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO',
                            type: "Window",
                            webUrl: `${window.location.origin}/config-out-sign-org-info?wardType=0&t=${new Date().getTime()}`,
                            width: 1000, height: 625
                        })
                        resolve('keepDialog');
                    }
                }
            } else {
                const hasAuthorizedOrg = getStore('esignOrg') && Object.keys(getStore('esignOrg')).length > 0;
                if (hasAuthorizedOrg) {
                    //  已经完成企业认证，查看当前用户是否是企业管理员，如果是，直接进入e签宝印章管理页，否则：查看当前用户是否具有"全部"企业印章的"使用"权限，如果是，就提示无章可加，否则就弹出企业印章申请窗口。
                    //  目前后端没有提供查询用户角色的接口，所以判断当前用户是否是企业管理员的方式是直接查询管理页URL，如果不是企业管理员，会有错误信息提示。
                    getOrgAdminUrl({orgId: getStore('userInfo').orgId}).then(url => {
                        // 去e签宝企业印章管理页面
                        const webUrl = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(url)}&wardType=0&resizeW=840&resizeH=600`
                        showESignCNDialogAPI({ id: 'ESIGNCN_ORG_STAMP_MANAGE', title: '企业印章管理窗口', type: 'Window', webUrl })
                        resolve();
                    }).catch(key => {
                        const phantomVersion = getPhantomVersion();
                        const mainVersion = phantomVersion[0];
                        if (ERROR_EQB_AUTH_EXPIRED === key) {
                            if (mainVersion === 12) {
                                Modal.confirm({
                                    title: '福昕电子签章服务',
                                    icon: "",
                                    content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的授权过期，请重新完成企业认证/授权。</span></span>,
                                    zIndex: 99999,
                                    okText: "认证/授权",
                                    cancelText: "取消",
                                    mask: false,
                                    closable: true,
                                    className: "eSignCN_funcDialog_container",
                                    onOk() {
                                        showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?orgName=${getStore('userInfo').enterpriseName}&wardType=0`})
                                    }
                                })
                            } else {
                                showESignCNDialogAPI({
                                    title: "福昕电子签章服务",
                                    type: "Dialog",
                                    id: "ESIGNCN_ORG_AUTH_CONFIRM",
                                    msg: "您的授权过期，请重新完成企业认证/授权。"
                                })
                            }
                        } else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                            if (mainVersion === 12) {
                                Modal.confirm({
                                    title: '福昕电子签章服务',
                                    icon: "",
                                    content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。</span></span>,
                                    zIndex: 99999,
                                    okText: "联系销售",
                                    cancelText: "取消",
                                    mask: false,
                                    closable: true,
                                    className: "eSignCN_funcDialog_container",
                                    onOk() {
                                        openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales')
                                    }
                                })
                            } else {
                                showESignCNDialogAPI({
                                    title: "福昕电子签章服务",
                                    type: "Dialog",
                                    id: "ESIGNCN_OUT_SIGN_APP_STORE_EXPIRE",
                                    msg: "您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。"
                                })
                            }
                        } else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                            // result.msg = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                            if (mainVersion === 12) {
                                Modal.info({
                                    title: '福昕电子签章服务',
                                    icon: "",
                                    content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您所在的企业授权过期，请联系管理员重新进行企业认证/授权。</span></span>,
                                    zIndex: 99999,
                                    okText: "确认",
                                    mask: false,
                                    closable: true,
                                    className: "eSignCN_funcDialog_container"
                                })
                            } else {
                                showESignCNDialogAPI({
                                    title: "福昕电子签章服务",
                                    type: "Dialog",
                                    id: "ESIGNCN_OUT_SIGN_AC_AUTH_EXPIRE",
                                    msg: "您所在的企业授权过期，请联系管理员重新进行企业认证/授权。"
                                })
                            }
                        } else if (ERROR_AC_PERMISSION_FAIL === key) {
                            if (mainVersion === 12) {
                                Modal.info({
                                    title: '福昕电子签章服务',
                                    icon: "",
                                    content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。</span></span>,
                                    zIndex: 99999,
                                    okText: "确认",
                                    mask: false,
                                    closable: true,
                                    className: "eSignCN_funcDialog_container"
                                })
                            } else {
                                showESignCNDialogAPI({
                                    title: "福昕电子签章服务",
                                    type: "Dialog",
                                    id: "ESIGNCN_OUT_SIGN_AC_AUTH_LACK",
                                    msg: "您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。"
                                })
                            }
                        } else if (key === ERROR_EQB_SEAL_PERMISSION_FAIL) {
                            const outOrgSeals = getStore('plugin').outOrgSeals
                            const existOrgStampOfNoUsePermission = outOrgSeals.filter(stamp => !stamp.usePermission).length > 0;
                            if (existOrgStampOfNoUsePermission) {
                                // 弹出企业印章管理申请
                                showESignCNDialogAPI({ id: 'ENTERPRISE_STAMP_APPLY_DIALOG', title: '申请企业章权限', type: 'Window', webUrl: `${window.location.origin}/stamp-apply?wardType=0`, width: 600, height: 830 })
                                resolve('ENTERPRISE_STAMP_APPLY_DIALOG');
                            } else if(!getStore('plugin').sealsError) {
                                message.info({ content: '您已获得所有企业章的使用权限', duration: 5 })
                                resolve('keepDialog');
                            } else {
                                message.error({ content: '获取管理链接失败，请确认您拥有章管理权限或重试', duration: 5 })
                                resolve('keepDialog');
                            }
                        } else {
                            console.error('err', key)
                            resolve();
                        }
                    })
                } else {
                    // 没有进行企业认证，此处进行企业认证
                    const orgName = getStore('userInfo').enterpriseName;
                    showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?orgName=${orgName}&wardType=0`})
                    resolve()
                }
            }
        })
    }

    const choosePersonSignType = async () => {
        try {
            setPersonLoading(true)
            await personCallback()
            closeESignCNDialogAPI()
        } catch (e) {
            console.error(e);
        } finally {
            setPersonLoading(false)
            // closeESignCNDialogAPI()
        }
    }
    const chooseOrgSignType = async () => {
        try {
            setOrgLoading(true)
            const msg = await orgCallback()
            if (msg !== 'keepDialog') {
                closeESignCNDialogAPI();
            }
        } catch (e) {
            console.error(e);
        } finally {
            setOrgLoading(false)
        }
    }

    const title = '';
    const buttons = [
        <Button key="cancelBtn" onClick={onCancel}>关闭</Button>
    ]
    return (
        <Modal
            zIndex="1010"
            title={title}
            open={true}
            className="sign-cn-choose-sign-type-dialog phantom-add-out-stamp"
            footer={buttons}
            width={600}
            centered
            onCancel={onCancel}>
            <div className="choose-sign-type-header">
                <div>请选择添加的签章类型</div>
            </div>
            <section className="choose-sign-type-container">
                <div className={personLoading ? "choose-sign-type-person" : "choose-sign-type-person person-icon"} onClick={choosePersonSignType}>{personLoading && <Spin className="spin-loading" />}</div>
                <div className={orgLoading ? "choose-sign-type-org" : "choose-sign-type-org org-icon"} onClick={chooseOrgSignType}>{orgLoading && <Spin className="spin-loading" />}</div>
            </section>
        </Modal>
    )
}