import {getStore} from "../../store";
import {getQueryVariable, globalFn_getPdfInfo} from "./service";
import {inPhantom} from "./utils/util";
import {AUDIT_TRAIL_PATH} from "./constant";


const isMockEnv = getQueryVariable('phantom_web_mock_env') === "1"
export const mockSwitch = window.location.origin.indexOf('localhost') > -1 && isMockEnv;

export const PHANTOM_UA_KEY = 'Foxit PhantomPDF'


export function exceptionLog({ api, error }) {
    if(inPhantom()) {
        alert(`API: ${api}返回异常`)
        throw new Error(error)
    }
}

export function shouldCallPhantomApi() {
    return getStore('plugin').inPhantom && !mockSwitch
}

export const noNeedTokenPages = [ AUDIT_TRAIL_PATH, '/internal-identity-verify' ]
export const needLoadStampPages = [
    {
        path: '/stamp-manage',
        silent: false,
    },
    {
        path: '/add-out-sign-stamp',
        silent: true,
    },
    {
        path: '/stamp-apply',
        silent: false,
    },
    {
        path: '/sign-flow', // 新版本/sign-flow-v2 不需要加载印章列表；因为相关的异常处理逻辑已经移到authPlugin里处理。
        silent: true,
    },
    {
        path: '/stamp-dropdown',
        silent: true,
    },
    // {
    //     path: '/stamp-dropdown-v2',
    //     silent: true,
    // },
    // {
    //     path: '/stamp-dropdown-v3',
    //     silent: true,
    // }
]

export const noNeedUserInfoPages = ['/internal-identity-verify']

export const globalFnMap = {
    '/sign-flow': globalFn_getPdfInfo,
    '/sign-flow-v2': globalFn_getPdfInfo,
    '/add-mobile-phantom': globalFn_getPdfInfo,
    '/add-email-phantom': globalFn_getPdfInfo,
}