import {
    eSignCNAuditTrailContent1Item,
    eSignCNAuditTrailContent1ItemLabelBox,
    eSignCNAuditTrailContent1ItemSubLabel,
    genContent1ItemLabelFn, genContent1PrimaryLabelStyleFn
} from "../style";

export function Content1Comp (props) {

    const tableMap = [
        {
            label: "文档页数",
            key: "PAGE_NUMBERS"
        },
        {
            label: "原文档摘要",
            key: "HASH"
        }
    ]
    const { auditTrail } = props;
    const renderData = {
        PAGE_NUMBERS: auditTrail.base.pageNumbers,
        HASH: auditTrail.certfile[0].byteRangeHash
    }
    tableMap.forEach(item => {
        item.value = renderData[item.key]
    })
    if (auditTrail.base.type === "signFlow") {
        tableMap.unshift({
            label: "签署结束时间",
            key: "END_SIGN_DATE",
            value: auditTrail.base.endSignDate,
        })
        tableMap.unshift({
            label: "签署开始时间",
            key: "START_SIGN_DATE",
            value: auditTrail.base.startSignDate,
        })
        if (auditTrail.taskName) {
            tableMap.unshift({
                label: '任务名称',
                key: 'TASK_NAME',
                value: auditTrail.taskName,
            })
        }
    }
    auditTrail.certfile.forEach((item, index) => {
        if (auditTrail.base.type === "signFlow" && index === 0) return;
        const obj1 = {};
        obj1.key = `SIGNERS_${index}_BYTE_RANGE_HASH`;
        obj1.label = `Rev.${index+1}`;
        obj1.subLabel = '签署前文档摘要'
        obj1.value = item.byteRangeHash;
        tableMap.push(obj1)
        const obj2 = {};
        obj2.key = `SIGNERS_${index}_SIGNED_HASH`;
        obj2.label = `Rev.${index+1}`;
        obj2.subLabel = '签署后文档摘要'
        obj2.value = item.signedHash;
        tableMap.push(obj2)
    })

    return <div>
        {
            tableMap.map((item, index) => {
                return <div key={index} style={eSignCNAuditTrailContent1Item}>
                    <div
                        style={genContent1ItemLabelFn('label', index, tableMap.length)}
                    >
                        <div style={eSignCNAuditTrailContent1ItemLabelBox}>
                            <div style={genContent1PrimaryLabelStyleFn(!!item.subLabel)}>{item.label}</div>
                            {
                                item.subLabel &&
                                <div style={eSignCNAuditTrailContent1ItemSubLabel}>{item.subLabel}</div>
                            }
                        </div>
                    </div>
                    <div style={genContent1ItemLabelFn('value', index, tableMap.length)}>{item.value}</div>
                </div>
            })
        }
    </div>
}