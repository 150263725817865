import {useEffect, useState} from "react";
import {AUDIT_TYPE, genQrCodeFn, getProcessInfo, initData, startDownAuditAction} from "./services";
import OtelService from "../../../utils/otel/service";
import {
    getOtelSpanName,
    getQueryVariable,
} from "../service";
import {handleSignAudit} from "./sign";
import {AuditTrailMain} from "./components/Main";
import {addQueryParam, inPhantom, removeQueryParam} from "../utils/util";
import {getTokenByTicket, login} from "../../../request/user";
import AuditTrailStatic from "./static";
// import {getStore} from "../../../store";
import {getUserInfoAPIFromPhantom, openLoginWindowOfPhantom} from "../../../request/phantom";

const otelService = new OtelService();


export const AUDIT_TRAIL_QUERY = 'auditTrailQuery';
export const TOKEN = 'token';

export function AuditTrail() {
    const [auditTrail, setAuditTrail] = useState({})
    const [ auditTrailMsg, setAuditTrailMsg ] = useState(null)
    const [ file, setFile ] = useState(null);
    const [qrCode, setQrCode] = useState(null);


    const downLoadCheckForOnline = () => {
        let pass = true;
        const token = getQueryVariable(TOKEN)
        if (!token) {
            sessionStorage.setItem(AUDIT_TRAIL_QUERY, window.location.search)
            login();
            pass = false
        }
        return Promise.resolve({ pass, token });
    }

    const downLoadCheckForPhantom = () => {
        let pass = true;
        return getUserInfoAPIFromPhantom().then(data => {
            if (!data.Token) {
                openLoginWindowOfPhantom();
                pass = false
            }
            addQueryParam(TOKEN, data.Token);
            return { pass, token: data.Token };
        })
    }

    const downLoadCheck = () => {
        if (inPhantom()) {
            return downLoadCheckForPhantom();
        } else {
            return downLoadCheckForOnline();
        }
    }

    const handleDownloadAuditFile = async () => {
        const { pass, token } = await downLoadCheck();
        if (!pass) return;
        startDownAuditAction(AuditTrailStatic, {auditTrail, conclusion: auditTrailMsg.conclusion, qrCode, token }).then(blob => {
            setFile(blob);
        })
    }

    const getPdfDocInfoFn = (workerFetch, pdfViewer, pdfDocRender) => {
        handleSignAudit(workerFetch, pdfDocRender)
    }

    const checkToken = () => {
        if (inPhantom()) return ;
        const token = getQueryVariable(TOKEN);
        if (!token) {
            const ticket = getQueryVariable('ticket');
            if (ticket) {
                return getTokenByTicket(ticket).then(token => {
                    addQueryParam(TOKEN, token);
                    removeQueryParam('ticket')
                })
            }
        }
    }

   useEffect(() => {
        (async() => {
            await checkToken()
            const { auditTrailData, conclusion } = await initData()
            const processId = getQueryVariable('processId');
            let tName;
            let docStore;
            if (processId) {
                const info = await getProcessInfo(processId);
                if (info.taskName) {
                    tName = info.taskName;
                    docStore = info.docStore;
                }
            }
            if (tName) {
                auditTrailData.taskName = tName;
            }
            setAuditTrail(auditTrailData)
            setAuditTrailMsg({
                conclusion
            })
            const {end} = await otelService.startCommonSpan(getOtelSpanName('request-auditTrail'));
            end && end();

            genQrCodeFn(auditTrailData, docStore).then(base64 => {
                setQrCode(base64);
            })
       })()
   }, [])

    return <AuditTrailMain
        type={AUDIT_TYPE.WEB}
        qrCode={qrCode}
        auditTrail={auditTrail}
        auditTrailMsg={auditTrailMsg}
        file={file}
        getPdfDocInfoFn={getPdfDocInfoFn}
        handleDownloadAuditFile={handleDownloadAuditFile}
    />
}