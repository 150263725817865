import styles from "../index.module.less";
import {Cropper} from "react-cropper";
import "cropperjs/dist/cropper.min.css"
import {useEffect, useRef, useState} from "react";
import {InputNumber, Space} from "antd";
import {
    LockOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    UnlockOutlined
} from "@ant-design/icons";

export default function ImageCropper(props) {
    const {base64, setCropData, setImgSize} = props;
    const cropperRef = useRef();
    const height = 360;
    const width = height / 9 * 16;
    const [w, setW] = useState(42);
    const [h, setH] = useState(42);
    const [isLock, setIsLock] = useState(true);

    useEffect(() => {
        setImgSize([w, h])
    }, []);

    const handleCrop = () => {
        const imgEle = cropperRef.current;
        const cropper = imgEle.cropper;
        const data = cropper.getCroppedCanvas().toDataURL();
        setCropData(data);
    }

    const handleRotate = (isClockwise = true) => {
        const imgEle = cropperRef.current;
        const cropper = imgEle.cropper;
        const angle = isClockwise ? 90 : -90;
        cropper.rotate(angle);
    }

    const onChange = (value, isWidth) => {
        const imgEle = cropperRef.current;
        const cropper = imgEle.cropper;
        if (value < 10) {
            value = 10;
        }
        if (isLock) {
            let width = w;
            let height = h;
            if (isWidth) {
                setW(value);
                const ratio = value / width;
                height = height * ratio;
                if (height < 10) {
                    height = 10;
                }
                if (height > 99) {
                    height = 99;
                }
                setH(height);
                setImgSize([value, height]);
                cropper.setAspectRatio(value / height);
            } else {
                setH(value);
                const ratio = value / height;
                width = width * ratio;
                if (width < 10) {
                    width = 10;
                }
                if (width > 99) {
                    width = 99;
                }
                setW(width);
                setImgSize([width, value]);
                cropper.setAspectRatio(width / value);
            }
            return;
        }
        if (isWidth) {
            cropper.setAspectRatio(value / h);
            setW(value);
            setImgSize([value, h])
        } else {
            cropper.setAspectRatio(w / value);
            setH(value);
            setImgSize([w, value])
        }
    }

    return (
        <div style={{margin: 0}}>

            <div className={styles.tabContainer + ' ' + styles.tabCropper}>

                <div>
                    <div
                        style={{color: "rgb(153, 153, 153)", marginBottom: '5px'}}>拖放选中区域或滚动鼠标可调整印章尺寸
                    </div>
                    <Cropper src={base64}
                             ref={cropperRef}
                             crop={handleCrop}
                             viewMode={0}
                             autoCropArea={1}
                             aspectRatio={1}
                             minCropBoxWidth={30}
                             minCropBoxHeight={30}
                             preview=".crop-preview"
                             style={{height, width}}
                    />
                    <Space style={{marginTop: 10}} size={"middle"}>
                        <RotateLeftOutlined onClick={() => handleRotate(false)} className={styles.icon}
                                            style={{fontSize: 20}}/>
                        <RotateRightOutlined onClick={() => handleRotate(true)} className={styles.icon}
                                             style={{fontSize: 20}}/>
                        {/*{file && <BgColorsOutlined onClick={changeImgType} className={styles.icon}*/}
                        {/*                  style={{fontSize: 20, color: isBlack ? '' : '#A236B2'}}/>}*/}
                    </Space>
                </div>

                <div className={styles.right}>
                    <p>印章预览</p>
                    <div className={styles.preview}>
                        <div className={"crop-preview"}
                             style={{width: '100%', height: '100%', overflow: "hidden"}}></div>
                    </div>
                    <div className={styles.size}>
                        <p>实体章尺寸</p>
                        <Space size={"middle"}>
                            <InputNumber max={99} min={10}
                                         prefix={
                                             <span style={{color: '#999999'}}>宽</span>}
                                         addonAfter={<span style={{color: '#999999'}}>mm</span>}
                                         style={{width: '140px'}}
                                         value={w} onChange={val => onChange(val, true)}
                            ></InputNumber>
                            {isLock ? <LockOutlined style={{fontSize: 16}} className={styles.icon}
                                                    onClick={() => setIsLock(!isLock)}/>
                                : <UnlockOutlined style={{fontSize: 16}} className={styles.icon}
                                                  onClick={() => setIsLock(!isLock)}/>}
                            <InputNumber max={99} min={10} prefix={
                                <span style={{color: '#999999'}}>高</span>}
                                         addonAfter={<span style={{color: '#999999'}}>mm</span>}
                                         style={{width: '140px'}}
                                         value={h} onChange={val => onChange(val, false)}
                            ></InputNumber>
                        </Space>

                    </div>
                </div>

            </div>
        </div>

    )
}
