import styles from "../index.module.less";
import {Cropper} from "react-cropper";
import "cropperjs/dist/cropper.min.css"
import {useRef} from "react";

export default function ImageCropper (props) {
    const {base64, setCropData} = props;
    const cropperRef = useRef();
    const height = '360px';
    const handleCrop = () => {
        const imgEle = cropperRef.current;
        const cropper = imgEle.cropper;
        const data = cropper.getCroppedCanvas().toDataURL();
        setCropData(data);
    }

    return (
        <div style={{margin: 0}}>
            <div style={{color: "rgb(153, 153, 153)", marginTop: '0px'}}>拖放选中区域或滚动鼠标可调整印章尺寸</div>
            <div className={styles.tabContainer + ' ' + styles.tabCropper} >

                <Cropper src={base64}
                         ref={cropperRef}
                         crop={handleCrop}
                         viewMode={1}
                         autoCropArea={1}
                         style={{height, width: '992px'}}
                />
            </div>
        </div>

    )
}
