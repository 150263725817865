import {getOrgInfo, getServerConfig, getTokenByTicket, getUserInfo} from "./request/user";
import qs from "qs";
import {getSignFlowInfo, getCCList} from "./request/esign";
import {getStore, setStore} from "./store";
import {ERROR_ESIGN_CANCELLED, ERROR_ESIGN_DELETED} from "./request/error_container";

export default function  init() {
    // 获取用户信息
    return _getLoginInfo().then(user => {
        // 获取pdf信息
        return getPdfInfo().then(info => {
            if (info && !info.creator_name.includes('*') && user && user.token) { // 参与者或发起者，可查看
                const {status, process_id, ward_type, recipients} = info;
                if (+status === 2) { // 抄送
                    _ccDenied(process_id, +ward_type === 1, recipients[0]).then(res => {
                        if (res) {
                            setStore('userStatus', 1);
                            return;
                        }
                        setStore('userStatus', 2);
                    });
                } else {
                    setStore('userStatus', 2);
                }
            }
            return info;
        })
    }).then(pdfInfo =>  {
        const {status, cancel_info} = pdfInfo;
        if (+status === 3 || cancel_info) { // 已撤销的任务
            const code = ERROR_ESIGN_CANCELLED;
            window.location.href = `/result?errCode=${code}&errMsg=` + encodeURIComponent(cancel_info) // 已撤销
            return -3
        }
        return 1;
    }).catch(key => {
        if (key) {
            if (ERROR_ESIGN_DELETED === key) {
                window.location.href = '/result?errCode=' + key // 已删除
                return  -2
            }
        }
        console.log('init error', key);
        return -1;
    })
}

// 获取urls
export function getUrlConfig() {
    const configUrls = JSON.parse(sessionStorage.getItem('configUrls') || '{}');
    if (configUrls.accountApiUrl) {
        setStore('configUrls', configUrls);
        return Promise.resolve(configUrls);
    }
    return getServerConfig().then((urls) => {
        setStore('configUrls', urls);
        sessionStorage.setItem('configUrls', JSON.stringify(urls));
        return urls;
    })
}

function _getToken() {
    const data = {
        token: sessionStorage.getItem('token'),
        enterpriseId: sessionStorage.getItem('enterpriseId'),
    };
    const search = window.location.search;
    const index = search.indexOf('?');
    if (index >= 0) {
        let params = search.slice(index + 1);
        params = qs.parse(params);
        const {ticket, enterpriseId, id} = params;
        if (ticket) {
            return getTokenByTicket(ticket).then(token => {
                sessionStorage.setItem('enterpriseId', enterpriseId || '');
                sessionStorage.setItem('token', token);
                if (id) {
                    sessionStorage.setItem('processId', id);
                }

                data.token = token;
                data.enterpriseId = enterpriseId;
                window.history.pushState({}, '', '/');
                return data;
            })
        } else {
            return Promise.resolve(data);
        }
    } else {
        return Promise.resolve(data);
    }
}

function _getLoginInfo () {
    return _getToken().then(({token, enterpriseId}) => {
        if (!token) {
            return false;
        }
        return getUserInfo(token).then(info => {
            info.token = token;
            if (enterpriseId && enterpriseId !== 'undefined') {
                info.enterpriseId = enterpriseId;
            }
            // 只支持中国区，并去掉国家号
            if (info.userMobile && info.userMobile.indexOf('+86') === 0 && info.userMobile.length === 14) {
                info.userMobile = info.userMobile.substr(3, 11);
            }
            if (!info.userMobile || info.userMobile.length !== 11) {
                info.userMobile = '';
            }

            setStore('userStatus', 1);
            setStore('userInfo', info);
            return info;
        }).catch(e => {
            return false;
        })
    });
}

export function getPdfInfo() {
    let processId = sessionStorage.getItem('processId');
    let isCC = sessionStorage.getItem('cc') || '0';
    const search = window.location.search;
    const index = search.indexOf('?');
    if (index >= 0) {
        let params = search.slice(index + 1);
        params = qs.parse(params);
        const {id, supplierId, cc} = params;
        if (id) {
            processId = id;
            isCC = cc || '0';
            sessionStorage.setItem('cc', isCC);
            sessionStorage.setItem('processId', processId);
            sessionStorage.setItem('supplierId', supplierId||'1');
            window.history.pushState({}, '', '/');
        }
    }

    if (!processId || processId === 'undefined') {
        return Promise.reject('流程id为空');
    }
    return getSignFlowInfo({processId, cc: isCC}).then(res => {
        const {simplified_process, process} = res;
        let info;
        if (process) {
            info = process;
        } else if (simplified_process) {
            info = simplified_process;
        } else {
            info = {};
        }
        setStore('serverPdfInfo', info);
        return info;
    })
}

function _ccDenied(processId,isInner, creator) {
    const isCC = sessionStorage.getItem('cc') === '1';
    if (!isCC) {
        return Promise.resolve();
    }
    const user = getStore('userInfo');
    if (!user) {
        return Promise.resolve();
    }
    const {enterpriseId, userEmail, userMobile, token} = user;
    return getCCList({processId}).then(ccList => {
        if (!Array.isArray(ccList) || ccList.length < 1) {
            return;
        }
        const item = ccList.find(item => item.email === userEmail || item.phone === userMobile);
        if (!item) {
            return;
        }
        if (isInner) {
            const companys = creator.company.split('-');
            if (!Array.isArray(companys) || !companys[0]) {
                return;
            }
            if (enterpriseId !== companys[0]) {
                setStore('ccDenied', true);
                return true;
            }
        } else {
            if (item.company) {
                if (!enterpriseId) {
                    setStore('ccDenied', true);
                    return true;
                }
                return getOrgInfo(item.company, token).then(orgData => {
                    const {enterpriseList, enterpriseCount} = orgData || {};
                    if (!Array.isArray(enterpriseList) || enterpriseCount < 1) {
                        setStore('ccDenied', true);
                        return true;
                    }
                });
            }
        }

    })
}
/*
function _getInnerCert () {
    const info = getStore('userInfo');
    if (!info.enterpriseId) {
        return ;
    }
    return getSubscription({tenantId: info.enterpriseId, wardType: 1}).then(sub => {
        const {cert} = sub;
        if (cert) {
            setStore('userInfo', {...info, innerCert: cert});
            setStore('subscription', sub);
        }
    }).catch(e => {

    })
}

function _getAuth(){
    const info = getStore('userInfo');
    return binding().then(res => {
        const {person, orgList} = res;
        const enterpriseId = info.enterpriseId;
        let org = {};
        if (!person || person.length < 1) {
            return;
        }
        if (enterpriseId && Array.isArray(orgList) && orgList.length > 0) {
            orgList.reverse().some(item => {
                if (item.tenantId === enterpriseId) {
                    org = item;
                    return true;
                }
                return false;
            })
        }

        setStore('userInfo', {...info, trueName: person.name, accountId: person.accountId, orgName: org.name, orgId: org.orgId});
        setStore('userStatus', org.name ? 3 : 2);
        setStore('esignPerson', person);
        setStore('esignOrg', org);
    });

}


async function _validUser() {
    // 判断查看或编辑pdf权限
    // userStatus: 0, // 0未登录，1已登录，2已实名 3已企业实名 4查看
    // userRefuse: 0, // -1 姓名不对，-2邮箱不对，-3手机号不对，-4企业名不对，-5手机号不存在，-6未实名，-7未企业实名， -8文档流程已结束，当前用户非参与者
    // -9 已撤销  // -10 邮箱不存在 // -11 企业未订阅 // -12 个人未被授权
    let userStatus = getStore('userStatus');
    const pdfInfo = getStore('serverPdfInfo');
    const userInfo = getStore('userInfo');

    if (userStatus < 1) { // 未登录
        return 0;
    }

    const {cur_order, creator_name, ward_type, recipients, status, cancel_info} = pdfInfo;
    const {userMobile, userEmail, trueName, enterpriseId} = userInfo;
    const item = recipients[cur_order]; // 签署完成cur_order为null
    if (creator_name.includes('*')) { // 邮箱或手机号不对
        let userRefuse;
        if (!item) {
            userRefuse = -8; // 文档流程已结束，当前用户非参与者
        } else if (item.email) {
            if (userEmail) {
                userRefuse = -2;//登录邮箱不对
            } else {
                userRefuse = -10;//邮箱为空
            }
        } else if (!userMobile) {
            userRefuse = -5; // 手机号不存在
        } else {
            userRefuse = -3; // 手机号不对
        }
        return userRefuse;
    }

    if (+status === 3 || cancel_info) { // 已撤销的任务
        const code = ERROR_ESIGN_CANCELLED;
        window.location.href = `/result?errCode=${code}&errMsg=` + encodeURIComponent(cancel_info) // 已撤销
        return -9
    }

    if (parseInt(ward_type) === 0) { // 对外
        if (userStatus < 2) { // 未实名
            return -6;
        }

        const isName = recipients.map(item => item.name).some(item => item === trueName);
        if (!isName) {
            return -1;
        }

    } else {
        const first = recipients[0];
        let companyId =  first.company.split('-')[0]
        // if (Array.isArray(companyId) && companyId.length === 2 && companyId[0] !== 'undefined') {
        //     companyId = companyId[0]
        // } else {
        //     companyId = undefined
        // }
        if (!companyId || companyId !== enterpriseId) {
            return -4; // 企业名不对应
        }
        // name = fullname; 对内不再验证姓名

        const res = await checkInerAuth(enterpriseId, true);
        if (res.state < 1) {
            if (res.state === -3) {
                return -12;
            }
            return -11;
        }

    }

    return 4;
}




 */
