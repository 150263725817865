import {Button} from "antd";
import {useEffect, useRef} from "react";
import styles from "../StampManage.module.less";

export function ExtDraw (props) {
    const {setDrawClear, value} = props;
    const clearData = () => {
        setDrawClear(new Date().getTime());
    }
    return (
        <Button disabled={value.length < 1} type={"link"}  onClick={clearData}>清除</Button>
    )
}

export default function CreateDraw(props) {
    const {drawClear, setDrawImg} = props;
    const canvasRef = useRef();
    const ctxRef = useRef();
    const canvasContainerRef = useRef();
    const lastClear = useRef(null);

    useEffect(() => {
        if (canvasContainerRef.current && canvasRef.current && !ctxRef.current) {
            ctxRef.current = initCanvas(canvasContainerRef.current, canvasRef.current);
            setDrawImg('');
        }

        if (lastClear.current !== drawClear && ctxRef.current && canvasRef.current) {
            lastClear.current = drawClear;
            clearImage(ctxRef.current, canvasRef.current);
            setDrawImg('');
        }
    });



    let isDown = false;
    let startPoint = null;
    let points = [];


    const handleMouseDown = (e) => {
        isDown = true;
        startPoint = getPoint(e, canvasRef.current);
    };

    const handleMouseMove = (e) => {
        if (!isDown) {
            return;
        }
        const point = getPoint(e, canvasRef.current);
        points.push(point);
        if (points.length > 2) {
            const [controlPoint, endPoint] = points.slice(-2);
            const middlePoint = getMiddlePoint(controlPoint, endPoint);
            drawCurve(ctxRef.current, startPoint, controlPoint, endPoint);
            startPoint = middlePoint;
        }
    };

    const handleMouseUp = () => {
        isDown = false;
        startPoint = null;
        points = [];
        const base64 = canvasRef.current.toDataURL();
        if (base64.length > 7630) {
            setDrawImg(base64);
        }

    };


    return (
        <div ref={canvasContainerRef} className={styles.tabContainer + ' ' + styles.tabDraw} >
            <canvas ref={canvasRef}
                    onMouseDown={handleMouseDown}
                    onTouchStart={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onTouchMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onTouchEnd={handleMouseUp}
                    onMouseLeave={handleMouseUp}
            ></canvas>
        </div>
    )
}

function initCanvas (container, canvas, lineWidth = 6, color = '#000') {
    let { width, height } = container.getBoundingClientRect()
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    clearImage(ctx, canvas);
    return ctx;
}

function clearImage(ctx, canvas) {
    ctx.clearRect(0, 0, canvas.width, canvas.height);
}

function getPoint (e, canvs) {
    const point = {
        x: 0,
        y: 0
    }
    const event = e.nativeEvent;
    if (event instanceof MouseEvent) {
        point.x = event.offsetX;
        point.y = event.offsetY;
    } else if (event instanceof TouchEvent) {
        const first = event.targetTouches[0];
        const {x, y} = canvs.getBoundingClientRect();
        point.x = first.clientX - x;
        point.y = first.clientY - y;
    }
    return point;
}

function getMiddlePoint (p1, p2) {
    const x = (p1.x + p2.x) / 2;
    const y = (p1.y + p2.y) / 2;
    return {x, y};
}

function drawCurve (ctx, startP, controlP, endP) {
    ctx.beginPath();
    ctx.moveTo(startP.x, startP.y);
    ctx.quadraticCurveTo(controlP.x, controlP.y, endP.x, endP.y);
    ctx.stroke();
    ctx.closePath();
}
