import React, {useEffect} from 'react';
import styles from './index.module.less';
import {Button, Divider} from "antd";
import UploadFile from "./uploadFile";
import ValidStatusIcon from "../IconComp/validStatusIcon";

const validatorProps = {
    subject: '签署方',
    signTime: '签署时间',
    serialNumber: '证书序列号',
    issue: '证书颁发者',
    validDate: '证书有效期',
    originalHash: '签署前文档摘要',
    signedHash: '签署后文档摘要',
}

function FormItem(props) {
    const { label, children } = props;
    return (
        <div className={styles.formItem}>
            <div className={styles.label}>{label}：</div>
            <div className={styles.value}>
                {
                    children
                }
            </div>
        </div>
    )
}

function Item(props) {
    const { info } = props;
    const [imgUrl, setImgUrl] = React.useState('');

    useEffect(() => {
        setImgUrl('');
        if (info.apBuffer) {
            // 创建eCanvas
            const eCanvas = document.createElement('canvas');
            eCanvas.width = info.apBuffer.width;
            eCanvas.height = info.apBuffer.height;
            let ctx = eCanvas.getContext('2d');
            let data = new Uint8Array(info.apBuffer.buffer);
            let img = ctx.createImageData(info.apBuffer.width, info.apBuffer.height);
            img.data.set(data);
            ctx.putImageData(img, 0, 0);
            setImgUrl(eCanvas.toDataURL());
        }
    }, [info]);

    const genBgStyle = (info) => {
        if (!info.apBuffer) {
            return {};
        } else {
            const { width, height } = info.apBuffer;
            if (width < 90 && height < 90 ) {
                return { backgroundSize: 'auto' }
            } else {
                return { backgroundSize: 'contain' }
            }
        }
    }

    return (
        <div className={styles.item}>
            <div className={styles.left}>
                {
                    Object.keys(validatorProps).map((key, index) => {
                        return (
                            <FormItem label={validatorProps[key]} key={index}>{info[key]}</FormItem>
                        )
                    })
                }
            </div>
            <div className={styles.right}>
                {/*<Image src={imgUrl}  preview={false}></Image>*/}
                {
                    imgUrl &&
                    <div className={styles.previewWrap}>
                        <div className={styles.preview} style={{ backgroundImage: `url(${imgUrl})`, ...genBgStyle(info) }}></div>
                    </div>
                }
                <div className={styles.flexRow}>
                    <ValidStatusIcon type={ info.verifyInfo.signValid.msg.color === '#79C046' ? 'success' : ''} style={{ color: info.verifyInfo.signValid.msg.color }}></ValidStatusIcon>
                    <div>{info.verifyInfo.signValid.msg.value}</div>
                </div>
                <div className={styles.flexRow}>
                    <ValidStatusIcon type={ info.verifyInfo.fileModifiedValid.msg.color === '#79C046' ? 'success' : ''} style={{ color: info.verifyInfo.fileModifiedValid.msg.color }}></ValidStatusIcon>
                    <div>{info.verifyInfo.fileModifiedValid.msg.value}</div>
                </div>
                <div className={styles.flexRow}>
                    <ValidStatusIcon type={ info.verifyInfo.issuerValid.msg.color === '#79C046' ? 'success' : ''} style={{ color: info.verifyInfo.issuerValid.msg.color }}></ValidStatusIcon>
                    <div>{info.verifyInfo.issuerValid.msg.value}</div>
                </div>
            </div>
        </div>
    )
}


export default function ValidatorResult(props) {

    const { validInfo, clearDocFn, setDocFn, loading, setFailReasonFn, failReason } = props;

    function setFailureReasonFn(reason) {
        setFailReasonFn(reason);
    }

    function setDocFunc(file) {
        clearDocFn('result-view');
        setDocFn(file);
    }

    function goBack() {
        clearDocFn();
        setFailReasonFn('');
    }

    if (!validInfo) {
        return <></>
    }

    return (
        <div className={styles.result}>
            <div className={styles.main}>
                <div className={styles.title1}>验签结果</div>
                <div>
                    <div className={styles.title}>文档签署信息</div>
                    <div className={styles.wrap}>
                        <div className={styles.left}>
                            <FormItem label={'文档页数'}>{validInfo.docInfo.pageNums}页</FormItem>
                            <FormItem label={'原文档摘要'}>{validInfo.docInfo.originalHash}</FormItem>
                            <FormItem label={'当前文档摘要'}>{validInfo.docInfo.currentHash}</FormItem>
                        </div>
                        <div className={styles.right}>
                            <div className={styles.flexRow}>
                                <ValidStatusIcon type={ validInfo.docInfo.signValid.msg.color === '#79C046' ? 'success' : ''}  style={{ color: validInfo.docInfo.signValid.msg.color }}></ValidStatusIcon>
                                <div>{validInfo.docInfo.signValid.msg.value}</div>
                            </div>
                            <div className={styles.flexRow}>
                                <ValidStatusIcon type={ validInfo.docInfo.fileModifiedValid.msg.color === '#79C046' ? 'success' : ''} style={{ color: validInfo.docInfo.fileModifiedValid.msg.color }}></ValidStatusIcon>
                                <div>{validInfo.docInfo.fileModifiedValid.msg.value}</div>
                            </div>
                            <div className={styles.flexRow}>
                                <ValidStatusIcon type={ validInfo.docInfo.issueValid.msg.color === '#79C046' ? 'success' : ''} style={{ color: validInfo.docInfo.issueValid.msg.color }}></ValidStatusIcon>
                                <div>{validInfo.docInfo.issueValid.msg.value}</div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <br/>
                    <div className={styles.title}>签署方信息</div>
                    <br/>
                    <div>
                        {
                            validInfo.sigs.map((item, index) => {
                                return (
                                    <div key={index}>
                                        {
                                            index > 0 &&
                                            <Divider />
                                        }
                                        <Item info={item}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            {
                !!failReason &&
                <div className={styles.error} style={{ width: '920px', padding: '12px 0'}}>{failReason}</div>
            }

            <div>
                <Button className={styles.subBtn} style={{ width: '90px'}} onClick={goBack}>返回</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <UploadFile
                    setFailureReasonFn={setFailureReasonFn}
                    setDocFn={setDocFunc}
                    loading={loading}
                    btnText={'继续验签'}
                />
            </div>
        </div>
    )
}