import React, {useState, useEffect} from "react";
import PcViewer from "./pcViewer";
import MobileViewer from "./mobileViewer";
import PhantomViewer from "./phantomViewer";

export default function SetOrgName(props) {
    const {client} = props.querys;
    const [childProps, setChildProps] = useState({});

    useEffect(() => {
        setChildProps( {
            ...props
        });
    }, [props])

    return (<>
                {client === 0 && <PcViewer {...childProps} />}
                {client === 1 && <MobileViewer {...childProps} />}
                {client === 2 && <PhantomViewer {...childProps} />}
    </>)
}