import {useEffect, useState} from "react";
import {getCacheForPhantom} from "../../../request/phantom";


export const AUTH_CHECK_STATUS = {
    INIT: 0,
    SUCCESS: 1,
    FAIL: 2,
}
export function useAuthCheckCache(props) {
    const { authCheckUrl } = props
    const [authCheckStatus, setAuthCheckStatus] = useState(AUTH_CHECK_STATUS.INIT);

    useEffect(() => {
        if (!authCheckUrl) return;
        // 获取URL的queryString参数
        let authCheck = AUTH_CHECK_STATUS.FAIL;
        getCacheForPhantom({ key: authCheckUrl }).then(authCacheDataStr => {
            if (authCacheDataStr) {
                const authCacheData = JSON.parse(authCacheDataStr);
                if (authCacheData) {
                    if (authCacheData.success) {
                        // 成功
                        // do something
                        authCheck = AUTH_CHECK_STATUS.SUCCESS;
                    }
                }
            }
            setAuthCheckStatus(authCheck);
        })
    }, [authCheckUrl]);

    return [authCheckStatus];
}