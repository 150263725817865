import {observer} from "mobx-react";
import {useCallback, useEffect, useState} from "react";
import SignFlowPanel from "./SignFlowPanel";
import styles from './PhantomSignFlowPanel.module.less'
// import {formatPdfInfo} from "../../../Main";
// import eventManager, {
//     SetPdfInfoEvent,
//     SetSealsEvent,
//     ShowStampManageEvent
// } from "../../../../../utils/EventManager";
import {getAssignInfoForPhantom} from "../Model";
import {jumpToTheFieldForPhantom, showESignCNDialogAPI} from "../../../request/phantom";
import {shouldCallPhantomApi} from "../config";
import {getStore, setStore} from "../../../store";
import {Button} from "antd";
import {_initOutSign, formatPdfInfo, initInSign} from "./service";
import {FlowSignGuideView} from "./FlowSignGuideView";

function PhantomSignFlowPanel(SignFlowPanel) {
    const Fn = observer(function Comp() {
        const originPdfInfo = getStore('plugin').pdfInfo
        const onFieldClick = (annot) => {
            if (shouldCallPhantomApi()) {
                jumpToTheFieldForPhantom({ objNumber: annot.id, pageIndex: annot.pageIndex })
            }
        }
        const [pdfInfo, setPdfInfo] = useState({});

        const [showGuideView, setShowGuideView] = useState(false)
        // const [pageIndex, setPageIndex] = useState(-1);
        // const [isMyFinished, setIsMyFinished] = useState(false)
        // const [showStampModal, setShowStampModal] = useState(false);
        // const [seals, setSeals] = useState([]);

        const onSetPdfInfo = (pdfInfo) => {
            if(!pdfInfo || Object.keys(pdfInfo).length < 1) {
                setShowGuideView(true)
                return;
            }
            setShowGuideView(false)
            if (getStore('plugin').wardType === 0) {
                pdfInfo.tips = _initOutSign(pdfInfo);
            } else {
                pdfInfo.tips = initInSign(pdfInfo)
            }
            setPdfInfo(pdfInfo);
            // formatPdfInfo(info, 'out').then(res => {
            //     console.log('res', res)
            //     res.initiator = info.initiator;
            //     const tips = _initOutSign(res);
            //     res.tips = tips;
            //     setPdfInfo(res);
            //     // const pdfInfo = formatPdfInfo(res);
            //     // setStore('plugin', { ...getStore('plugin'), pdfInfo })
            //     console.info("🍭  ~ file:Main method:onSetPdfInfo line:44 -----", info);
            // })
        }

        const onPhantomUpdateInfo = (assignInfo) => {
            return formatPdfInfo(assignInfo).then(pdfInfo => {
                if (pdfInfo) {
                    pdfInfo.initiator = assignInfo.initiator;
                    setStore('plugin', { ...getStore('plugin'), pdfInfo })
                } else {
                    setStore('plugin', { ...getStore('plugin'), pdfInfo: null })
                }
            })
        }

        const checkFieldBeforeSign = (data) => {
            const {configInfo, receipts } = data;
            if (!configInfo || configInfo.docStore === 1) {
                return; // 在线文档，不允许在online内签署
            }
            const userEmail = getStore('userInfo').userEmail;
            const userMobile = getStore('userInfo').userMobile;

            // let myItem;
            const res = receipts.some(item => {
                if ((userEmail && item.email && userEmail.toLowerCase() === item.email.toLowerCase()) ||
                    (userMobile && userMobile === item.phoneNumber)
                ) {
                    let finishCnt = 0;
                    let finishSignCnt = 0;
                    item.annots.forEach(annot => {
                        if (annot.fieldStatus === 2) {
                            finishCnt++;
                        } else if (annot.fieldStatus === 1) {
                            finishSignCnt++;
                        }
                    });
                    if (finishSignCnt > 0 && item.annots.length === finishCnt + finishSignCnt) {
                        // myItem = item;
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false
                }
            });
            return !!res;
        }

        const hasFillPermission = useCallback((annot) => {
            let hasPermission = false;
            const annotId = annot.objNumber;
            const annotPageIndex = annot.pageIndex;
            if (pdfInfo && pdfInfo.myItem && pdfInfo.myItem.annots) {
                pdfInfo.myItem.annots.forEach(annot => {
                    if (annot.id === annotId && annot.pageIndex === annotPageIndex) {
                        hasPermission = true;
                    }
                })
            }
            return hasPermission;
        }, [pdfInfo])

        window.eSignCN_renderSignFlowPanel = onPhantomUpdateInfo;
        window.eSignCN_hasFillPermission = hasFillPermission;

        useEffect(() => {
            // todo wait doc resolved successful
            // eventManager.on(SetPdfInfoEvent, onSetPdfInfo);
            // const assignInfo = getAssignInfoForPhantom()
            onSetPdfInfo(originPdfInfo)
            // eventManager.on(ShowStampManageEvent, setShowStampModal);
            // eventManager.on(SetSealsEvent, setSeals);
            return () => {
                // eventManager.off(SetPdfInfoEvent, onSetPdfInfo);
                // eventManager.off(ShowStampManageEvent, setShowStampModal);
                // eventManager.off(SetSealsEvent, setSeals);
            }
        }, [originPdfInfo])

        const submit = async () => {
            const assignInfo = await getAssignInfoForPhantom()
            const checkRet = checkFieldBeforeSign(assignInfo)
            if (!checkRet) {
                // todo call phantom show msg
                showESignCNDialogAPI({ title: "福昕电子签章服务", type: "Dialog", id: "ESIGNCN_SIGN_FLOW_NOT_FILLED_ALL_FIELD", msg: "待填写的控件域未全部完成" })
                return;
            }
            showESignCNDialogAPI({ title: "福昕电子签章服务", type: "Dialog", id: "ESIGNCN_SIGN_FLOW_CONFIRM", msg: "确定提交签署吗？" })
        }

        const showSubmitBtn = pdfInfo && pdfInfo.receipts && pdfInfo.receipts[pdfInfo.currOrder] && pdfInfo.receipts[pdfInfo.currOrder].isMine

        const sealsError = getStore('plugin').sealsError;

        return <div className={styles.container}>
            {
                !showGuideView &&
                !sealsError &&
                <>
                    <SignFlowPanel pdfInfo={pdfInfo} handleFieldClick={onFieldClick}/>
                    {
                        showSubmitBtn &&
                        <Button type="primary" className={styles.submitBtn} onClick={submit}>提交签署</Button>
                    }
                </>
            }
            {
                (showGuideView || sealsError) &&
                <FlowSignGuideView/>
            }
        </div>
    })
    return Fn;
}

export default PhantomSignFlowPanel(SignFlowPanel)