import {observer} from "mobx-react";
import {useCallback, useEffect, useState} from "react";
import SignFlowPanel from "./SignFlowPanel";
import styles from './PhantomSignFlowPanel.module.less'
import {jumpToTheFieldForPhantom} from "../../../request/phantom";
import {shouldCallPhantomApi} from "../config";
import {getStore, setStore} from "../../../store";
import { formatPdfInfo } from "./service";
import {FlowSignGuideView} from "./FlowSignGuideView";

function PhantomSignFlowPanel(SignFlowPanel) {
    const Fn = observer(function Comp() {
        const originPdfInfo = getStore('plugin').pdfInfo
        const onFieldClick = (annot) => {
            if (shouldCallPhantomApi()) {
                jumpToTheFieldForPhantom({ objNumber: annot.id, pageIndex: annot.pageIndex })
            }
        }
        const [pdfInfo, setPdfInfo] = useState({});

        const [showGuideView, setShowGuideView] = useState(false)

        const onSetPdfInfo = (pdfInfo) => {
            if(!pdfInfo || Object.keys(pdfInfo).length < 1) {
                setShowGuideView(true)
                return;
            }
            setShowGuideView(false)
            setPdfInfo(pdfInfo);
        }

        const onPhantomUpdateInfo = (assignInfo) => {
            return formatPdfInfo(assignInfo).then(pdfInfo => {
                if (pdfInfo) {
                    pdfInfo.initiator = assignInfo.initiator;
                    setStore('plugin', { ...getStore('plugin'), pdfInfo })
                } else {
                    setStore('plugin', { ...getStore('plugin'), pdfInfo: null })
                }
            })
        }

        const hasFillPermission = useCallback((annot) => {
            let hasPermission = false;
            const annotId = annot.objNumber;
            const annotPageIndex = annot.pageIndex;
            if (pdfInfo && pdfInfo.myItem && pdfInfo.myItem.annots) {
                pdfInfo.myItem.annots.forEach(annot => {
                    if (annot.id === annotId && annot.pageIndex === annotPageIndex) {
                        hasPermission = true;
                    }
                })
            }
            return hasPermission;
        }, [pdfInfo])

        window.eSignCN_renderSignFlowPanel = onPhantomUpdateInfo;
        window.eSignCN_hasFillPermission = hasFillPermission;

        useEffect(() => {
            onSetPdfInfo(originPdfInfo)
            return () => {
            }
        }, [originPdfInfo])

        const sealsError = getStore('plugin').sealsError;

        return <div className={styles.container}>
            {
                !showGuideView &&
                !sealsError &&
                <>
                    <SignFlowPanel pdfInfo={pdfInfo} handleFieldClick={onFieldClick}/>
                </>
            }
            {
                (showGuideView || sealsError) &&
                <FlowSignGuideView/>
            }
        </div>
    })
    return Fn;
}

export default PhantomSignFlowPanel(SignFlowPanel)