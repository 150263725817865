import {getQueryVariable} from "../service";
import {mockSwitch, PHANTOM_UA_KEY} from "../config";
import QRCode from "qrcode";
import pluginRoutes from "../../../routes/pluginRoutes";
import {getStore} from "../../../store";

const removeNullProp = (obj) => {
    Object.keys(obj).forEach(key => {
        if (!obj[key]) {
            delete obj[key];
        }
    })
    return obj
}

export const isObjectEqual = (obj1, obj2, ignoreNullProp = false) => {
    let o1 = obj1 instanceof Object;
    let o2 = obj2 instanceof Object;
    if(!o1 || !o2) {    // 如果不是对象 直接判断数据是否相等
        return obj1 === obj2
    }

    if(ignoreNullProp) {
        obj1 = removeNullProp(obj1)
        obj2 = removeNullProp(obj2)
    }

    // 判断对象的可枚举属性组成的数组长度
    if(Object.keys(obj1).length !== Object.keys(obj2).length) {
        return false;
    }
    for(let attr in obj1) {
        let a1 = Object.prototype.toString.call(obj1[attr]) === '[object Object]'
        let a2 = Object.prototype.toString.call(obj2[attr]) === '[object Object]'
        let arr1 = Object.prototype.toString.call(obj1[attr]) === '[object Array]'
        if(a1 && a2) {
            // 如果是对象继续判断
            return isObjectEqual(obj1[attr], obj2[attr])
        } else if(arr1){
            // 如果是对象 判断
            if(obj1[attr].toString() !== obj2[attr].toString()){
                return false;
            }
        } else if(obj1[attr] !== obj2[attr]) {
            // 不是对象的就判断数值是否相等
            return false
        }
    }
    return true
}

let debounceTimer;
export function debounce(fun, delay) {
    return function (...args) {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(function () {
            fun(...args);
        }, delay);
    };
}
export function inOnline() {
   return getQueryVariable('origin') === 'ONLINE_CONTAINER';
}

export function inPhantom() {
    const ua = window.navigator.userAgent;
    let phantomUAIndex = ua.indexOf(PHANTOM_UA_KEY);
    const pathName = window.location.pathname;
    return phantomUAIndex  > -1 && pluginRoutes.map(it => it.path).includes(pathName);
}

export function inPublicPageEnv () {
    return inOnline() || inPhantom();
}

export const generateQrCode = (url, icon) => {
    return new Promise((resolve, reject) => {
        try {
            const canvas = document.createElement('canvas');
            canvas.width = 300;
            canvas.height = 300;
            const context = canvas.getContext('2d');
            let img = new Image();
            QRCode.toDataURL(url).then( base64 => {
                img.src = base64;
                img.onload = () => {
                    context.drawImage(img, 0, 0, 300, 300);
                    if (icon) {
                        let iconImg = new Image();
                        iconImg.src = icon;
                        iconImg.onload = () => {
                            context.drawImage(iconImg, 125, 125, 50, 50);
                            const base64 = canvas.toDataURL('image/png');
                            img = null;
                            iconImg = null;
                            resolve(base64);
                        }
                    } else {
                        resolve(base64)
                    }
                }
            })
        } catch (e) {
            console.error('generateQrCode error: ', e)
            reject(e);
        }
    })
};

export function getPhantomVersion() {
    if (!inPhantom()) return;
    if (!mockSwitch) {
        const ua = window.navigator.userAgent;
        let phantomUAIndex = ua.indexOf(PHANTOM_UA_KEY);
        const versionStr = ua.slice(phantomUAIndex + PHANTOM_UA_KEY.length + 1).split(' ')[0]
        return versionStr.split('.').map(i => parseInt(i.replace(/[^0-9]/ig, ""), 10));
    } else {
        return [13,1,30108]
    }
}

export function afterPluginVersion2023_3() {
    const versionArr = getStore('plugin').pluginVersionArr;
    if (versionArr[0] > 2023) {
        return true;
    } else if ( versionArr[0] === 2023 && versionArr[1] > 2) {
        return true;
    } else {
        return false;
    }
}

export function afterPluginVersion2023_4() {
    const versionArr = getStore('plugin').pluginVersionArr;
    if (versionArr[0] > 2023) {
        return true;
    } else if ( versionArr[0] === 2023 && versionArr[1] > 3) {
        return true;
    } else {
        return false;
    }
}

export function afterPluginVersion2024_1() {
    const versionArr = getStore('plugin').pluginVersionArr;
    if (!versionArr || versionArr.length < 1) return false;
    if (versionArr[0] > 2024) {
        return true;
    } else if ( versionArr[0] === 2024 && versionArr[1] > 1) {
        return true;
    } else {
        return false;
    }
}

export function afterPhantomPluginVersion(versionArr) {
    // 传入一个版本号数组，判断当前插件版本是否大于传入的版本号；如果大于（不包含等于）返回true，否则返回false
    // 如：传入的版本号数组为 [2023, 3, 0]；则判断当前插件版本是否大于2023.3.0
    if (!Array.isArray(versionArr) || versionArr.length !== 3 || versionArr.some(i => typeof i !== 'number')) {
        throw new Error('invalid versionArr')
    }
    const pluginVersionArr = getStore('plugin').pluginVersionArr;
    if (!versionArr || versionArr.length < 1) return false;
    if (pluginVersionArr[0] > versionArr[0]) {
        return true;
    } else if ( pluginVersionArr[0] === versionArr[0] && pluginVersionArr[1] > versionArr[1]) {
        return true;
    } else {
        return false;
    }
}

export function isPhantomV12() {
    const phantomVersion = getPhantomVersion();
    if (phantomVersion && phantomVersion[0] && phantomVersion[0] < 13) {
        return true;
    } else {
        return false;
    }
}

export function toFormateStr (ctx, str, draw_width, lineNum, startX, startY, steps ) {
    let strWidth = ctx.measureText(str).width;     // 测量文本源尺寸信息（宽度）
    let startpoint = startY, keyStr = '', sreLN = strWidth / (draw_width - 60); // 60是为了留出左右边距
    let liner = Math.ceil(sreLN);     // 计算文本源一共能生成多少行
    let strlen = parseInt(str.length / sreLN); 	// 等比缩放测量一行文本显示多少个字符
    // 若文本不足一行，则直接绘制，反之大于传入的最多行数（lineNum）以省略号（...）代替

    if (strWidth  < (draw_width - 20)) {

        ctx.fillText(str, startX, startpoint);

    } else {
        for (let i = 1; i < liner + 1; i++) {
            let startPoint = strlen * (i-1);
            if (i < lineNum || lineNum === -1) {
                keyStr = str.substr(startPoint, strlen);
                ctx.fillText(keyStr, startX, startpoint);
            } else {
                keyStr = str.substr(startPoint, strlen-5) + '...';
                ctx.fillText(keyStr, startX, startpoint);
                break;
            }
            startpoint = startpoint + steps;
        }

    }
    return { liner, steps }
}

export const px2pt = (px) => {
    const dpi = 96;
    return px * 72 / dpi;
}

export const sizeAdapter = (number) => Math.ceil(number * 1.3)

export function getRatio() {
    let ratio=0;
    const screen=window.screen;
    const ua=navigator.userAgent.toLowerCase();

    if(window.devicePixelRatio !== undefined)
    {
        ratio=window.devicePixelRatio;
    }
    else if(~ua.indexOf('msie'))
    {
        if(screen.deviceXDPI && screen.logicalXDPI)
        {
            ratio=screen.deviceXDPI/screen.logicalXDPI;
        }

    }
    else if(window.outerWidth !== undefined && window.innerWidth !== undefined)
    {
        ratio=window.outerWidth/window.innerWidth;
    }

    if(ratio)
    {
        ratio=Math.round(ratio*100);
    }
    return ratio / 100;
}

export function getPcDomain() {
    const origin = window.location.origin;
    if (origin.split('.').length > 3) {
        return origin;
    } else if(origin.indexOf('localhost') > -1) {
        let configs = sessionStorage.getItem('configUrls');
        if (configs) {
            configs = JSON.parse(configs);
            return configs['newEsignCNpc']
        } else {
            return origin;
        }
    } else {
        return origin;
    }
}

export function removeQueryParam(paramKey) {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    searchParams.delete(paramKey);
    const newUrl = url.origin + url.pathname + '?' + searchParams.toString();
    console.log('newUrl', newUrl)
    window.history.replaceState({}, '', newUrl);
}

export function addQueryParam(paramKey, paramValue) {
    const url = new URL(window.location.href);
    const searchParams = url.searchParams;
    searchParams.set(paramKey, paramValue);
    const newUrl = url.origin + url.pathname  + '?' +  searchParams.toString();
    window.history.replaceState({}, '', newUrl);
}

export function blobToBase64(blob, forDataUri = true) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = forDataUri ? reader.result : reader.result.replace('data:application/pdf;base64,', '');
            resolve(base64String);
        };
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
}

export function removeLastSlash(str) {
    if (str.endsWith('/')) {
        str = str.slice(0, -1); // 移除最后一个字符
    }
    return str;
}

export function truncateDomain(origin) {
    origin = removeLastSlash(origin);
    const domains = origin.split('.');
    if (domains.length > 3) {
        return 'https://' + domains.slice(-3).join('.');
    } else {
        return origin;
    }
}


export function sanitizeStringFromSessionStorage(str) {
    if (!str) {
        return '';
    }
    // 定义非法字符的正则表达式
    const illegalCharsRegex = /[<>&]/g;

    // 替换非法字符为安全的转义序列
    const sanitizedData = str.replace(illegalCharsRegex, (match) => {
        switch (match) {
            case '<':
                return '&lt;';
            case '>':
                return '&gt;';
            case '&':
                return '&amp;';
            case "/":
                return '&#x2F;';
            default:
                return match;
        }
    });

    return sanitizedData;
}

export function getFileType(docName) {
    // 获取文档名中最后一个.的索引
    const dotIndex = docName.lastIndexOf('.');
    // 确保文档名中存在.且不在开头或结尾
    if (dotIndex > 0 && dotIndex < docName.length - 1) {
        // 获取后缀名并转换为小写
        const extension = docName.slice(dotIndex + 1).toLowerCase();
        // 根据后缀名判断文档类型
        switch (extension) {
            case 'doc':
            case 'docx':
                return 'docx';
            case 'xls':
            case 'xlsx':
                return 'xlsx';
            case 'ppt':
            case 'pptx':
                return 'pptx';
            case 'pdf':
                return 'PDF';
            case 'txt':
                return 'txt';
            default:
                return 'unknow type';
        }
    }
    return 'invalid fileName';
}

export function file2Buffer(file) {
    return new Promise((resolve) => {
        // 创建 FileReader 对象
        const reader = new FileReader();

        // 注册 onload 事件处理程序
        reader.onload = function (e) {
            const buffer = e.target.result;
            resolve(buffer);
            // 在这里处理转换后的 Buffer 对象
        };

        // 以二进制格式读取文件
        reader.readAsArrayBuffer(file);
    })
}

export function getImageDataUrl(pdfImgInfo) {
    let canvas = document.createElement('canvas');
    canvas.width = pdfImgInfo.width;
    canvas.height = pdfImgInfo.height;
    let ctx = canvas.getContext('2d');
    let data = new (Uint8ClampedArray || Uint8Array)(pdfImgInfo.buffer);
    let img = ctx.createImageData(pdfImgInfo.width, pdfImgInfo.height);
    img.data.set(data);
    ctx.putImageData(img, 0, 0);
    return canvas.toDataURL();
}