import React, { Component } from 'react';
import { Button } from 'antd'
import './NetError.less'
import offLine from './svg/offLine.svg'
export default class NetError extends Component {
  constructor(props) {
    super(props);
    this.state = {
   
    };

  }
  componentDidMount() {
   
  }

  
  render() {
    return (
      <div className={`NetError `}>
          <img src={offLine} alt="" />
          <p>数据获取失败</p>
          <Button className='refresh' type='primary' onClick={this.props.handleClick}>刷新</Button>
      </div>
    );
  }
}
