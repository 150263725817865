import styles from "./Cert.module.less";
import icon from "@/assets/images/cert.png";
import {Button} from "antd";
import {useEffect, useState} from "react";
import {Logout} from "../../components/Logout";
import {getSubscription} from "../../../request/esign";
import {getStore} from "../../../store";
import {resizeDialog} from "../../PublicPage/Model";

export default function Cert(props) {
    const [info, setInfo] = useState({});

    useEffect(() => {
        resizeDialog({ width: 540, height: 500 });
    }, [])

    useEffect(() => {
        const changeInfo = (state) => {
            let data;
            switch (state) {
                case -1:
                    data = {
                        title: '企业信息尚未注册',
                        summary: '若您的企业已注册，请重新登录后选择对应的企业；或联系销售为您所在的企业创建信息。',
                        state
                    };
                    break;
                case 2:
                    data = {
                        title: '尚未企业实名认证',
                        summary: '若您的企业已注册，请重新登录后选择对应的企业；或联系销售为您所在的企业创建信息。',
                        state
                    };
                    break;
                default:
                    data = {
                        title: '尚未个人实名认证',
                        summary: '当前文档需要您使用指定身份信息完成实名认证后，才可查看与签署。若您对身份信息存疑，请联系发起方。',
                        state
                    };
                    break;
            }
            setInfo(data);
        }
        const userInfo = getStore('userInfo');
        const init = () => {
            if (props.orgName) {
                const tenantId = userInfo.enterpriseId;
                if (tenantId) {
                    getSubscription({tenantId}).then(res => {
                        if (res.tenantName !== props.orgName) {
                            changeInfo(-1);
                        } else {
                            changeInfo(2);
                        }
                    })
                } else {
                    changeInfo(-1);
                }
            } else {
                changeInfo(1);
            }

        }
        init();
    }, [props]);


    return info.title && (
        <div className={styles.main}>
            <img src={icon} alt={"icon"} width="168" />
            <div className={styles.title}>{info.title}</div>
            <div className={styles.tips}>
                {info.summary}
            </div>
            <Button onClick={()=>props.changeState(info.state)} shape={"round"} type={"primary"} style={{width: '80%'}}>
                {info.state === -1 ? '联系销售' : '实名认证'}</Button>
            {info.state === -1 &&
                <Button onClick={()=>Logout()} shape={"round"} style={{width: '80%', marginTop: '20px'}}>退出登录</Button>}
        </div>
    )
}
