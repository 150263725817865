import {memo, useEffect, useState} from "react";
import styles from './Header.module.less'
import logo from '../../assets/images/logo.png';
import {Avatar, Badge, Button, Dropdown, Space, Tooltip} from "antd";
import {LogoutOutlined, UnorderedListOutlined, UserOutlined} from "@ant-design/icons";
import {getStore} from "../../store";
import {goAccount} from "../../request/user";
import {Logout} from "./Logout";
import {getSignFlowInfo} from "../../request/esign";
import {ERROR_ESIGN_DELETED} from "../../request/error_container";
import {goOnline} from "../main/Main";
import {RefuseModal} from "./RefuseModal";
import {getMyJoinItem} from "../../utils";
import {errorMessage} from "./Message";

export default memo(Header);
function Header(props) {
    const userInfo = getStore('userInfo');
    const {pdfInfo, serverInfo, checkAuth, sealFields, stat} = props;
    const [currOrder, setCurrOrder] = useState(-1);
    const {file_name} = serverInfo || {};
    const [showBtnTips, setShowBtnTips] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showRefuseModal, setShowRefuseModal] = useState(false);

    let isUnField = false; // 是否为无域流程
    let myFields = [];
    let subBtnTxt = stat !== 1 ? '' : '开始签署';
    const nextItem = getMyJoinItem(pdfInfo);
    if ( stat === 1 && pdfInfo && pdfInfo.myItem) {
        myFields = pdfInfo.myItem.annots;
        if (nextItem) {
            myFields = myFields.concat(nextItem.annots);
        }
        if (!myFields || myFields.length < 1) {
            isUnField = true;
            subBtnTxt = '提交签署';
        }
    }
    useEffect(() => {
        if (isUnField) {
            if (Object.keys(sealFields).length > 0) {
                setDisabled(false);
            } else {
                setDisabled(true)
            }
        }
    }, [sealFields, isUnField]);

    const submit = () => {
        if (checkAuth) {
            return checkAuth();
        }

        if (isUnField) {
            const list = Object.values(sealFields);
            if (list.length < 1) {
                return;
            }
            if (nextItem) {
                const orgItem = list.find(item => item.isOrg);
                const item = list.find(item => !item.isOrg);
                if (!orgItem) {
                    errorMessage('您尚未加盖企业章，请添加企业章后再提交');
                    return ;
                } else if (!item) {
                    errorMessage('您尚未加盖个人章，请添加个人章后再提交');
                    return ;
                }
            }
            props.handleSubmit();
            return;
        }

        let myCnt = myFields.length;
        if (myCnt < 1) {
            return;
        }
        const unList = getUnFinishedOrders(myFields);
        if (!unList) {
            return;
        }
        if (unList.length === 0) {
            props.handleSubmit();
        } else {
            const curr = checkOrder(currOrder, myCnt, unList);
            props.handleFieldClick(myFields[curr]);
            setCurrOrder(curr+1);
        }
        setShowBtnTips(false);
    };

    let fileName;
    if (file_name) {
        fileName = file_name.substring(0, file_name.length - 4);
    }

    let unFillCnt = 0;
    if (myFields) {
        let myCnt = myFields.length;
        unFillCnt = myCnt;
        if (myCnt > 0) {
            const unList = getUnFinishedOrders(myFields);
            unFillCnt = unList.length;

            if (unFillCnt === 0) {
                subBtnTxt = '提交签署';
            } else if (currOrder === -1 && myCnt === unFillCnt) {
                subBtnTxt = '开始签署';
            } else {
                subBtnTxt = '下一个';
            }

            const btnTips = localStorage.getItem('btnTips');
            if (!btnTips) {
                setShowBtnTips(true);
                localStorage.setItem('btnTips', new Date().toDateString());
            }
        }
    }

    let userName;
    let menus;
    if (userInfo && userInfo.token) {
        const {trueName, fullname, orgName, userEmail} = userInfo;
        userName = trueName ? trueName : fullname;
        if (!userName) {
            userName = userEmail;
        }
        menus = [{
            label: userName,
            key: 'username',
            disabled: true,
        }];
        //const fullOrgName = orgName || tenantName;
        const fullOrgName = orgName || '';
        if (fullOrgName) {
            let showOrgName = fullOrgName;
            if (fullOrgName.length > 10) {
                showOrgName = fullOrgName.substring(0, 8) + '…' + fullOrgName.substring(fullOrgName.length-2);
            }
            menus.push({
                label: (<Tooltip title={fullOrgName}>
                        {showOrgName}
                        </Tooltip>),
                key: 'orgname',
                disabled: true,
            })
        }

        menus = [...menus,
            {
                type: 'divider',
            },
            {
                label: '我的帐户',
                key: 'profile',
                icon: <UserOutlined />,
            },
            {
                label: '签署记录',
                key: 'record',
                icon: <UnorderedListOutlined />,
            },
            {
                label: '退出登录',
                key: 'logout',
                icon: <LogoutOutlined />,
            }
        ];
    }

    const refuse = () => {
        setShowRefuseModal(true);
    };

    const closeRefuseModal = (stat) => {
        setShowRefuseModal(false);
        if (stat) {
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    };

    return (
        <div className={styles.main}>
            <a href="/"><img src={logo} height="30" alt={"logo"} /></a>
            <div className={styles.name}>{fileName}</div>
            <div className={styles.right}>
                {   pdfInfo && pdfInfo.myFinished &&
                    <Button shape='round'  type="default" onClick={props.goOnline} style={{marginRight: '10px'}}>签署记录</Button>
                }
                {
                    stat === 2 &&
                    <Button shape='round'  type="primary" onClick={handleDownload} style={{marginRight: '10px'}}>下载文档</Button>
                }

                {
                    subBtnTxt && <Space>
                        {pdfInfo && pdfInfo.myItem && <Button shape='round' type="default" style={{marginRight: '0px'}} onClick={refuse}>
                            拒绝签署</Button>}
                        <Tooltip placement="bottom" title={'点击开始签署文档'} open={showBtnTips}>
                            <Badge count={unFillCnt} offset={[-10, 5]} >
                                <Button disabled={disabled} shape='round' type="primary" style={{marginRight: '10px'}} onClick={submit}>
                                    {subBtnTxt}</Button>
                            </Badge>
                        </Tooltip>
                    </Space>

                }

                {
                    menus &&
                    <Dropdown menu={{onClick: ({key}) => handleUserMenu(myFields, {key}), items: menus}}>
                        <Avatar className={styles.avatar}>{userName[0].toUpperCase()}</Avatar>
                    </Dropdown>
                }
                {showRefuseModal && <RefuseModal close={closeRefuseModal} />}
            </div>
        </div>
    )
}
function handleUserMenu(myFields, { key }) {
    if (key === 'logout') {
        const isSigner = myFields && myFields.length > 0
        Logout(isSigner);
    } else if (key === 'profile') {
        goAccount();
    } else if (key === 'record') {
        goOnline();
    }

};

function getUnFinishedOrders(myFields) {
    const myCnt = myFields.length;
    const list = [];
    if (myCnt > 0) {
        myFields.forEach((annot, index) => {
            if (annot.fieldStatus < 1) {
                list.push(index);
            }
        })
    }
    return list;
}

function checkOrder(curr, myCnt, unList) {
    if (curr === -1 || curr >= myCnt) {
        curr = 0;
    }
    if (unList.includes(curr)) {
        return curr;
    } else {
        curr++;
        return checkOrder(curr, myCnt, unList);
    }

}

function handleDownload() {
    // const pdfDocRender = globalStore.pdfDocRender;
    // if (!pdfDocRender) return;
    //
    // const workerFetch = pdfDocRender.pdfViewer.workerFetch;
    // const buffers = [];
    // const progress = workerFetch.register(function (buffer) {
    //     buffers.push(buffer);
    // });
    // workerFetch.request('doc/download', {
    //     doc: pdfDocRender.docId,
    //     download: progress,
    // }).then(function () {
    //     const blob = new Blob(buffers);
    //     const url = window.URL.createObjectURL(blob);
    //     const a = document.createElement('a');
    //     a.href = url;
    //     a.download = 'download.pdf';
    //     a.click();
    //     window.URL.revokeObjectURL(url);
    // });

    const  serverPdfInfo = getStore('serverPdfInfo');

    getSignFlowInfo({processId: serverPdfInfo.process_id}).then(() => {
        const configUrls = getStore('configUrls');
        const url = configUrls.eSignCNUrl + "/api/file/download?docId=" + serverPdfInfo.doc_id;
        const a = document.createElement('a');
        a.href = url;
        a.download = 'download.pdf';
        a.click();
        window.URL.revokeObjectURL(url);
    }).catch(key => {
        if (ERROR_ESIGN_DELETED === key) {
            window.location.href = '/result?errCode=' + key // 已删除
        }
    })


}
