import {Tabs} from "antd";
import React, {useEffect, useState} from 'react'
import SealUsageRecord from "../SealUsageRecord";
import EsignDoc from "../EsignDoc";
import styles from './index.module.less';
import EsignBand from "./EsignBand";
import {getStore, setStore} from "../../../store";
import {ESIGN_RECORD_TABS, ORG_PERMISSION} from "../service";
import EsignOrgDoc from "../EsignOrgDoc";
import OrgSealUsageRecord from "../OrgSealUsageRecord";
import {checkUserEnterprisePermission} from "../../../request/esign";


function SignRecordGroup() {

    const [outOrgPermission, setOutOrgPermission] = useState(null);
    const [inOrgPermission, setInOrgPermission] = useState(null);
    const [showSignRecord, setShowSignRecord] = useState(false);
    const [showSealRecord, setShowSealRecord] = useState(false);

    const baseItems = [
        {
            label: '签署文档记录',
            key: ESIGN_RECORD_TABS.DOC_RECORD,
            children: <EsignDoc/>
        },
        {
            label: '签署印章记录',
            key: ESIGN_RECORD_TABS.SEAL_RECORD,
            children: <SealUsageRecord/>
        }
    ]

    const SIGN_RECORD_ITEM = {
        label: '企业签署文档记录',
        key: ESIGN_RECORD_TABS.ORG_DOC_RECORD,
        children: <EsignOrgDoc permission={{ outOrgPermission, inOrgPermission }}/>
    }
    const SEAL_RECORD_ITEM = {
        label: '企业签署印章记录',
        key: ESIGN_RECORD_TABS.ORG_SEAL_RECORD,
        children: <OrgSealUsageRecord permission={{ outOrgPermission, inOrgPermission }}/>
    }

    const checkOrgPermissionFn = () => {
        const enterpriseId = getStore('userInfo').enterpriseId;
        if (enterpriseId) {
            checkUserEnterprisePermission().then(data => {
                if (data && data['external'] && Array.isArray(data['external']) ) {
                    setOutOrgPermission(data['external']);
                }
                if (data && data['internal'] && Array.isArray(data['internal'])) {
                    setInOrgPermission(data['internal']);
                }
            })
        }
    }


    const onTabChange = (activeKey) => {
        const esignRecord = getStore('plugin').esignRecord;
        esignRecord.currentActiveTab = activeKey;
        setStore('plugin', { ...getStore('plugin'), esignRecord})
    }

    useEffect(() => {
        if ((outOrgPermission && outOrgPermission.includes(ORG_PERMISSION.SIGN_RECORD)) || (inOrgPermission && inOrgPermission.includes(ORG_PERMISSION.SIGN_RECORD))) {
            setShowSignRecord(true)
        }
        if ((outOrgPermission && outOrgPermission.includes(ORG_PERMISSION.SEAL_RECORD)) || (inOrgPermission && inOrgPermission.includes(ORG_PERMISSION.SEAL_RECORD))) {
            setShowSealRecord(true)
        }
    }, [outOrgPermission, inOrgPermission])

    useEffect(() => {
        const plugin = getStore('plugin')
        plugin.esignRecord.currentActiveTab = ESIGN_RECORD_TABS.DOC_RECORD
        setStore('plugin', plugin);

        checkOrgPermissionFn();
    }, [])

    const enterpriseId = getStore('userInfo').enterpriseId;
    let items = baseItems;
    if (enterpriseId && showSignRecord) {
        items.push(SIGN_RECORD_ITEM);
    }
    if (enterpriseId && showSealRecord) {
        items.push(SEAL_RECORD_ITEM);
    }

    return (
        <div className={styles.signRecordGroupContainer}>
            <Tabs
                defaultActiveKey={ESIGN_RECORD_TABS.DOC_RECORD}
                items={items}
                onChange={onTabChange}
            >
            </Tabs>
            <EsignBand/>
        </div>
    )
}

export default SignRecordGroup;