import styles from "./StampManage.module.less";
import {Button, Modal, Tooltip} from "antd";
import {DeleteOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import CreateSeal from "./createInnerSeals/CreateSeal";
import {useEffect, useState} from "react";
import {errorMessage, loadingMessage, successMessage} from "../../components/Message";
import {innerSealDisable} from "../../../request/esign";
import {getStore} from "../../../store";
// import {antdAdapterConfirm} from "./PublicPage/AntdAdapter";
import {mockSwitch} from "../../PublicPage/config";
import {closeESignCNDialogAPI, showESignCNDialogAPI} from "../../../request/phantom";

export default function StampInnerList (props) {
    const {visible, handleCancel, seals: oldSeals} = props;
    const [seals, setSeals] = useState([]);
    const [addVisible, setAddVisible] = useState(false);
    useEffect(() => {
        if (visible) {
            setSeals(oldSeals);
        }
    }, [visible, oldSeals])

    const handleDelte = (sealId) => {
        // if (seals.length < 2) {
        //     Modal.warning({
        //         title: '删除失败',
        //         okText: '确定',
        //         content: '请至少保留一枚印章。',
        //     });
        //     return;
        // }
        let params;
        // if (getStore('plugin').inPhantom && !mockSwitch) {
        //     params = {
        //         title: "删除印章",
        //         type: "Dialog",
        //         msg: "确认删除当前印章吗",
        //         width: 416,
        //         height: 165,
        //         okCallback: () => {
        //             innerSealDisable({sealId}).then(() => {
        //                 const newSeals = seals.filter(item => item.id !== sealId);
        //                 setSeals(newSeals);
        //             }).catch(e => {
        //                 exceptionLog('innerSealDisable error: ', e)
        //             })
        //         },
        //         cancelCallback: () => {}
        //     }
        // } else {
        //
        // }
        params = {
            title: '删除印章',
            icon: <ExclamationCircleOutlined />,
            content: '确认删除当前印章吗',
            okText: '确认',
            cancelText: '取消',
            okButtonProps: {shape: 'round'},
            cancelButtonProps: {shape: 'round'},
            onOk() {
                loadingMessage('正在删除印章…')
                innerSealDisable({sealId}).then(() => {
                    const newSeals = seals.filter(item => item.id !== sealId);
                    setSeals(newSeals);
                    successMessage('删除印章成功！');
                }).catch(() => {
                    errorMessage('删除印章失败');
                })
            },
            onCancel() {
            }
        }
        Modal.confirm(params);
    }

    const closeAdd = (data) => {
        if (getStore('plugin').inPhantom && !mockSwitch) {
            closeESignCNDialogAPI()
        } else {
            setAddVisible(false);
        }
        if (data) {
            const newSeals = [data, ...seals];
            setSeals(newSeals);
        }
    }

    const className = getStore('plugin').inPhantom ? 'phantom-stamp-manage' : ''

    const handleAddStamp = () => {
        if (getStore('plugin').inPhantom && !mockSwitch) {
            showESignCNDialogAPI({
                title: "添加印章",
                id: 'ADD_STAMP',
                type: "Window",
                webUrl: `${window.location.origin}/stamp-add?wardType=1`,
                width: 1000, height: 425
            })
        } else {
            setAddVisible(true)
        }
    }

    return (
        <>
            <Modal title='管理签章' open={visible}  onCancel={handleCancel}
                   footer={null} centered={true} maskClosable={false} width={'600px'} bodyStyle={{padding: '0', margin: 0}}  className={className}
            >
                <div className={styles.innerBox}>
                    {
                        (seals && seals.length > 0) &&
                    <div className={styles.addBtn}>
                        <Button type='link' onClick={handleAddStamp} style={{paddingRight: 0}} >+ 添加</Button></div>
                    }
                    <div>
                        {
                            Array.isArray(seals) && seals.length > 0 &&
                            <div className={styles.list}>
                                {
                                    seals.map((item) => {
                                        return (
                                            <div className={styles.item} key={item.id}>
                                                <div className={styles.img} >
                                                    <img src={item.base64} alt={'seal'} />
                                                </div>
                                                <div className={styles.cover}>
                                                    <Tooltip title="删除印章" className={styles.delBtn}>
                                                        <Button onClick={()=>handleDelte(item.id)}
                                                                shape="circle" icon={<DeleteOutlined />} />
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        }
                        {
                            (!seals || seals.length < 1) &&
                            <div className={styles.emptyContainer}>
                                <div className={styles.emptyIcon}></div>
                                <Button ghost={true} shape={"round"} onClick={handleAddStamp} type="primary">添加印章</Button>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
            {addVisible && <CreateSeal closeAdd={closeAdd} ></CreateSeal>}
        </>

    )
}
