import {useState, useEffect, useRef, useCallback} from "react";
import {Button} from "antd";
import { closeESignCNDialogAPI, fetchWillAuthData } from "../../../request/phantom";
import {getWillAuthResult} from "../../../request/esign";
import {getParams} from "../../../utils";
import iconOk from "../../../assets/images/auth-ok.png";
import iconError from "../../../assets/images/error.png";
import styles from "../../../pages/cert/components/Result.module.less";
// import styles from "../../../../../pages/cert/components/Result.module.less";
// import iconOk from "../../../../../assets/images/auth-ok.png";
// import iconError from "../../../../../assets/images/error.png";
// import {Button} from "antd";
// import {getParams} from "../../../../../utils";
// import {getWillAuthResult} from "../../../../../request/esign";
// import {closeESignCNDialogAPI, fetchWillAuthData} from "../../../../../request/phantom";

export default function WillingnessResultForPhantom() {
    const [refresh, setRefresh] = useState(0); // eslint-disable-line
    const secRef = useRef(5);
    const infoRef = useRef({});

    const closeWillAuthModal = async (willData) => {
        const { willAuthId, willAuthTime, willAuthType, willingnessBizId } = willData
        await fetchWillAuthData({ willAuthId, willAuthTime, willAuthType, willingnessBizId })
        closeESignCNDialogAPI()
    }

    const handleClick = useCallback(() => {
        const info = infoRef.current;
        if (!info.title) {
            return;
        }
        infoRef.current = {};
        closeWillAuthModal(info);
    }, []);

    const checkWillAuth = useCallback((bizId) => {
        getWillAuthResult({bizId}).then(res => {
            let title,summary;
            const { status, willAuthType, endTime, willAuthId } = res;
            if (status === 1 ) {
                title = "意愿认证成功"
                summary = "请点击按钮继续完成签署";
                infoRef.current = {title, summary, status, willAuthType, willAuthTime: endTime, willingnessBizId: bizId, willAuthId};


                const timer = setInterval(() => {
                    if (secRef.current > 1) {
                        secRef.current--;
                        setRefresh(secRef.current);
                    } else {
                        clearInterval(timer);
                        handleClick();
                    }
                }, 1000);
            } else {
                title = "意愿认证失败"
                summary = "请关闭后重新发起意愿认证";
                infoRef.current = {title, summary, status};
            }
        });
    }, [handleClick]);


    useEffect(() => {
        const params = getParams();
        const {willAuthId} = params;
        if (!willAuthId) {
            return;
        }
        const bizId = sessionStorage.getItem('bizId')
        checkWillAuth(bizId);
    }, [handleClick, checkWillAuth]);





    const info = infoRef.current;
    return (
        <div className={styles.main} >
            {info.title && <>
                <img src={info.status === 1 ? iconOk : iconError} alt={"icon"} width="355" />
                <div className={styles.title}>{info.title}</div>
                <div className={styles.tips}>
                    {info.summary}
                </div>
                {info.status === 1 ?
                    <Button onClick={()=> handleClick()} shape={"round"} type={"primary"} style={{width: '60%'}}>完成（{secRef.current}s）</Button>
                    :
                    <Button onClick={()=> handleClick()} shape={"round"} style={{width: '60%'}}>关闭</Button>
                }
            </>
            }
        </div>
    )
}
