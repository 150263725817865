import React, { Component } from 'react';
import { DownOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {Table, Dropdown, Menu, Popover, Modal, Input, message, Empty, Button} from 'antd';

import { observer } from 'mobx-react';

import docIcon_online from '../svg/docIcon.svg';
import docIcon_offline from '../svg/docIcon_2.svg';
import phantom_icon from '@/assets/images/phantom_icon.svg';
// import downIcon from '../svg/down.svg';
import tip from '../svg/tip.svg';
import SignerList from '../signerList/signerList';
import NetError from '../NetError';
import './EsignTable.less';
import dayjs from 'dayjs';
import dayjsDuration from 'dayjs/plugin/duration';
import {getStore} from "../../../../store";
import {cancelFlow, deleteFlow, getSignProcessOrgList, noticeSign} from "../../../../request/esign";
// import {copyToClipboard} from "../../../../../../utils";
import {errorMessage, loadingMessage, successMessage} from "../../../components/Message";
import {getSignLink} from "../../../../request/user";
import {mockSwitch, PHANTOM_UA_KEY} from "../../config";
import {
  downloadFileByUrlForPhantom,
  openUrlByBrowser
} from "../../../../request/phantom";
import zh_CN from 'antd/es/locale/zh_CN';
import { ConfigProvider } from 'antd';
import {chooseFileForFlowSign, openFileByPlugin, showShareOnlineDocDialog} from "../../Model";
import {afterPhantomPluginVersion, generateQrCode, getPhantomVersion, inPhantom, isPhantomV12} from "../../utils/util";
import {copyToClipboard} from "../../../../utils";
import {generateShareDocBeautyQrCode} from "../utils";
import OtelService from "../../../../utils/otel/service";
import {FLOW_SIGN_ORDERED_TYPE, getFlowSignOrderedType, getOtelSpanName} from "../../service";
import TableRowWrapper from "../../SealUsageRecord/components/TableRowWrapper";

import RejectDialog from './components/RejectDialog';
import DeadlineDialog from './components/DeadlineDialog';
import CCDialog from "./components/CCDialog";
import Invalid from "./components/Invalid";
import {ERROR_ESIGN_RECORD_PERMISSION_FAIL} from "../../../../request/error_container";

const otelService = new OtelService();
const { TextArea } = Input;
dayjs.extend(dayjsDuration);

const rejectRef = React.createRef();
const deadlineRef = React.createRef();
const ccDialogRef = React.createRef();

// @inject('appStore', 'userStore', 'accountStore', 'historyStore')
@observer
class EsignTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userBgColorList: ['#ba91ff', '#68AFFF', '#EF80DB', '#FFA800', '#75D060', '#48D3D5', '#E99570', '#758395', '#9DB4E2'],
      link: '',
      hasRequestList: false,
      taskList: [],
      serverTime: 0,
      isError: false,
      queryData: {
        sort: 'createTime desc',
        perPage: 10,
        currentPage: 1,
      },
      loading: false,
      currProcessId: '',
      openDeleteModal: false,
      openCancelModal: false,
      cancelComment: undefined,
      handleShareLinkLoading: false,
      hasInit: false,
      openInvalidModal: false,
    };
    this.timer = null;
  }
  async componentDidMount() {
    this.props.setListFunc(this.searchWordList);
    let config = getStore('configUrls');
    let newEsignCNpc = config.newEsignCNpc;
    this.setState({
      link:newEsignCNpc
    })
  }

    componentDidUpdate(prevProps, prevState, snapshot) {
      const { role, status, searchWord,  expandedSearchParams } = this.props;
      const { hasInit } = this.state;
      if (!hasInit) {
        this.initList({ role, status, searchWord, expandedSearchParams });
        this.setState({ hasInit: true })
      }
    }

  refresh = () => {
    const { role, status, searchWord, expandedSearchParams } = this.props;
    this.initList({ role, status, searchWord, expandedSearchParams });
  };

  componentWillUnmount() {
    this.timer = null;
  }
  pageChange = (page, pageSize) => {
    const { role, status, searchWord, expandedSearchParams } = this.props;

    const sendData = { ...this.state.queryData };
    sendData.currentPage = page;
    sendData.perPage = pageSize;
    this.setState({ queryData: { ...sendData } }, () => {
      this.initList({ role, status, searchWord, expandedSearchParams });
    });
  };
  onShowSizeChange = (current, pageSize) => {
    const { role, status, searchWord, expandedSearchParams } = this.props;

    const sendData = { ...this.state.queryData };
    sendData.currentPage = 1;
    sendData.perPage = pageSize;
    this.setState({ queryData: { ...sendData } }, () => {
      this.initList({ role, status, searchWord, expandedSearchParams });
    });
  };
  pagination = {
    total: 0,
    pageCount: 0,
    showSizeChanger: true,
    onShowSizeChange: this.onShowSizeChange,
    onChange: this.pageChange,
    pageSizeOptions: ['10', '30', '50', '100'],
  };
  accountIsEqual = (receiptItem) => {
    const { userEmail, userMobile } = getStore('userInfo')
    const emailIsEqual = (receiptItem) => {
      return userEmail && receiptItem.email && receiptItem.email.toLowerCase() === userEmail.toLowerCase();
    }
    const phoneIsEqual = (receiptItem) => {
      return userMobile && receiptItem.phone && receiptItem.phone.slice(-11) === userMobile.slice(-11);
    }
    return emailIsEqual(receiptItem) || phoneIsEqual(receiptItem);
  }
  handleMenuClick = (e, record) => {
    const processId = record.process_id;
    const { key } = e;
    if (key === 'copyLink') {
      //复制链接
      this.copyLink(record);
    }
    if (key === 'shareLink') {
      this.shareLink(record);
    }
    if (key === 'urge') {
      //催办
      // let currentSigner = record.signRecipientList[record.curOrder];
      let currentSignerList = this.getCurrentSignerList(record);
      this.doUrge(currentSignerList);
    }
    if (key === 'sign') {
      //签署
      otelService.startCommonSpan(getOtelSpanName('request-signRecord-goToSign')).then(({end}) => {
        end && end();
      });
      this.gotoSign(processId);
    }
    if (key === 'download') {
      this.download(record.doc_id);
    }
    if (key === 'delete') {
      this.setState({
        openDeleteModal: true,
        currProcessId: record.process_id
      });
    }
    if (key === 'cancel') {
      this.setState({
        openCancelModal: true,
        cancelComment: undefined,
        currProcessId: record.process_id
      });
    }
    if (key === 'reject') {
      let curSignRecipient = record.signRecipientList.filter((item, index)=> {
        if(index === 0) return false;
        return this.accountIsEqual(item)
      })
      if (!curSignRecipient.length) return
      rejectRef.current.openRejectModal({currProcessId: record.process_id, recipientOrder: curSignRecipient[0].order})
    } else if (key === 'deadline') {
      deadlineRef.current.openDeadlineModal({currProcessId: record.process_id, value: record.expire_time})
    } else if (key === 'carbonCopy') {
      ccDialogRef.current && ccDialogRef.current.open({currProcessId: record.process_id, isOut: record.ward_type === '0' })
    } else if (key === 'invalid') {
        this.setState({
            openInvalidModal: record,
        });
    }

  };
  download = (docId) => {
    otelService.startCommonSpan(getOtelSpanName('request-signRecord-downloadFile')).then(({end}) => {
      end && end();
    });
    const eSignCNUrl = getStore('configUrls').eSignCNUrl;
    if (!eSignCNUrl) {
      return;
    }
    try {
      const fileUrl = eSignCNUrl + "/api/file/download?docId=" + docId;
      if(getStore('plugin').inPhantom) {
        downloadFileByUrlForPhantom({ fileUrl: encodeURIComponent(fileUrl), fileName: 'download.pdf'})
      } else {
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = 'download.pdf';
        a.click();
        window.URL.revokeObjectURL(fileUrl);
      }
    } catch (e) {
      console.error('esignTable.js download fn error: ', e)
    }

  };
  doUrge = (currentSignerList) => {
    const { userEmail, userMobile } = getStore('userInfo')
    const names = currentSignerList.filter((item) => {
      if (item.email) {
        const email = item.email.toLowerCase();
        const curUserEmail = userEmail && userEmail.toLowerCase();
        if (!curUserEmail) return true;
        return email !== curUserEmail;
      }
      if (item.phone) {
        const phone = item.phone.slice(-11);
        const curUserPhone = userMobile && userMobile.slice(-11);
        if (!curUserPhone) return true;
        return phone !== curUserPhone;
      }
      return true;
    }).map((item) => `${item.name}｜${item.email || item.phone}`);
    const process_id = currentSignerList[0].process_id
    Modal.confirm({
      title: '催办',
      content: <span>是否确定向<br/>{names.map(name => <span>{name}<br/></span>)}发送催办提醒？</span>,
      zIndex: 99999,
      onOk: () => {
        noticeSign(process_id).finally(() => {
          message.success('催办已发送');
          otelService.startCommonSpan(getOtelSpanName('request-signRecord-urgeTask')).then(({end}) => {
            end && end();
          });
        })
      }
    })
  };
  isCurrentSigner = (record) => {
    if (record.cur_order === null || (record.cur_order.length === 1 && record.cur_order[0] === 0)) {
      return false;
    }
    const { userEmail, userMobile } = getStore('userInfo')
    const currentSignerList = this.getCurrentSignerList(record);
    return record.status !== '3' &&
        (
            (userMobile && currentSignerList.find(item => item.phone && item.phone.slice(-11) === userMobile.slice(-11))) ||
            (userEmail && currentSignerList.find(item => item.email && item.email.toLowerCase() === userEmail.toLowerCase() ))
        )

  };

  currentItemExistOtherSigner = (record) => {
    if (record.cur_order === null || (record.cur_order.length === 1 && record.cur_order[0] === 0)) {
      return false;
    }
    const { userEmail, userMobile } = getStore('userInfo')
    const currentSignerList = this.getCurrentSignerList(record);
    return record.status !== '3' &&
        (
            (currentSignerList.find(item => item.phone && item.phone.slice(-11) !== (userMobile || "").slice(-11))) ||
            (currentSignerList.find(item => item.email && item.email.toLowerCase() !== (userEmail || "").toLowerCase() ))
        )
  }

  getCurrentSignerList = (record) => {
    if (record.cur_order === null || (record.cur_order.length === 1 && record.cur_order[0] === 0)) {
      return [];
    }
    const list = record.signRecipientList;
    // const currentSigner = list[record.curOrder];
    const currentSignerList = [];
    record.cur_order.forEach(order => {
      list.forEach(item => {
        if (parseInt(item.order) === order) {
          currentSignerList.push(item);
        }
      })
    })
    return currentSignerList;
  };
  isCreator = (record) => {
    const { userEmail, userMobile } = getStore('userInfo')
    const { creator_email, creator_phone } = record;
    if (
        (userMobile && creator_phone && creator_phone === userMobile) ||
        (userEmail && creator_email && userEmail.toLowerCase() === creator_email.toLowerCase())
    ) {
      return true;
    } else {
      return false;
    }
  };
  gotoSign = (processId) => {
    const { link } = this.state;
    const enterpriseId = getStore('userInfo').enterpriseId;
    let url = `${link}?id=${processId}`;
    let newWindow;
    if (!inPhantom()) {
       newWindow = window.open('')
    }
    getSignLink(url, enterpriseId ? `${url}&enterpriseId=${enterpriseId}` : url).then(signLink => {
      if (inPhantom() && !mockSwitch) {
          openUrlByBrowser(signLink)
      } else {
        newWindow && (newWindow.location.href = signLink);
      }
    })
  };
  copyLink = (record) => {
    otelService.startCommonSpan(getOtelSpanName('request-signRecord-copyLink')).then(({end}) => {
      end && end();
    });
    const { process_id: processId } = record;
    const { link } = this.state;
    const enterpriseId = getStore('userInfo').enterpriseId;
    let url = enterpriseId?`${link}?id=${processId}&enterpriseId=${enterpriseId}`:`${link}?id=${processId}`;
    copyToClipboard(
        url,
        () => {
          successMessage('复制成功');
        },
        () => {},
    );
  }
  shareLink = (record) => {
    try {
      otelService.startCommonSpan(getOtelSpanName('request-signRecord-copyLink')).then(({end}) => {
        end && end();
      });
      if (this.state.handleShareLinkLoading) return;
      this.setState({ handleShareLinkLoading: true });
      const { process_id: processId, name: taskName, creator_name, signRecipientList } = record;
      const receiptsNames = signRecipientList.slice(1).map(i => i.name).join('；');
      const { link } = this.state;
      const enterpriseId = getStore('userInfo').enterpriseId;
      let url = enterpriseId?`${link}?id=${processId}&enterpriseId=${enterpriseId}`:`${link}?id=${processId}`;
      if (this.props.role === '5') {
        url = `${url}&cc=1`;
      }
      generateQrCode(url, phantom_icon).then(dataUri => {
        return generateShareDocBeautyQrCode(dataUri, { taskName, creator_name, receiptsNames });
      })
      .then(dataUri => {
        const key = 'base64,'
        const i = dataUri.indexOf(key);
        const base64 = dataUri.slice(i + key.length);
        const params = {
          title: '分享',
          shareUrl: url,
          qrCodeBase64: base64,
          qrCodeDataUri: dataUri,
          slogan: '可将合同分享给已参与当前合同流程的人员，快速完成或查看合同任务',
          copyBtnText: '复制链接',
          qrCodePrimaryTitle: `由${creator_name}分享的二维码`,
          qrCodeDesc: '请使用微信扫码',
          signReceiptsDesc: `合同参与人：${receiptsNames}`,
          qrCodeSecondTitle: taskName,
          okBtnText: '保存二维码',
          cancelBtnText: '取消',
          taskName: taskName,
        };
        setTimeout(() => {
          showShareOnlineDocDialog(params);
        }, 1000)
      })
    } finally {
        this.setState({ handleShareLinkLoading: false });
    }
  };

  getBgIndex = (id) => {
    const length = this.state.userBgColorList.length;
    if (!id) return length - 1;
    const key = id[id.length - 1];
    var s = 'a b c d e f g h i j k l m n o p q r s t u v w x y z 0 1 2 3 4 5 6 7 8 9';
    var sArray = s.split(' ');
    let keyIndex = 0;
    sArray.forEach((item, index) => {
      if (key === item) keyIndex = index % length;
    });
    return keyIndex;
  };

  onCloseInvalid = (data) => {
    const {success, processId} = data || {};
    this.setState({
      openInvalidModal: null,
    });
    if (success) {
      this.props.onChange && this.props.onChange('1');
      if (processId) {
        this.gotoSign(processId);
      }
    }
  }

  getColumns = () => {

    const ua = window.navigator.userAgent;
    let phantomUAIndex = ua.indexOf(PHANTOM_UA_KEY);
    let versionArr = null;
    if (inPhantom()) {
      const versionStr = ua.slice(phantomUAIndex + PHANTOM_UA_KEY.length + 1).split(' ')[0]
      versionArr = versionStr.split('.').map(i => parseInt(i, 10))
    }

    return [
      {
        title: '任务名称',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => {
          return (
              <Popover
                  overlayClassName="namePop"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placement="bottom"
                  content={record.doc_store === '0' ? '以文档形式进行发送' : '以链接形式进行发送'}
                  trigger="hover">
              <span style={{'wordBreak':'break-all'}}>
                <img src={record.doc_store === '0' ? docIcon_offline : docIcon_online} alt="" style={{ marginRight: '5px' }} />
                {text}
              </span>
            </Popover>
          );
        },
      },
      {
        title: '签署场景',
        dataIndex: 'ward_type',
        key: 'ward_type',
        ellipsis: true,
        width: '7%',
        align: 'center',
        render: (text) => {
          const val = <span>{text === '0' ? '对外签署' : '对内签署'}</span>;
          return <TableRowWrapper val={val}>
            {val}
          </TableRowWrapper>
        }
      },
      {
        title: '签署类型',
        dataIndex: 'ward_type',
        key: 'ward_type_2',
        ellipsis: true,
        width: '7%',
        align: 'center',
        render: (text, record) => {
          let val;
          if (record.signRecipientList && record.signRecipientList[0].type === '1') {
            val = <span>{'个人签署'}</span>;
          } else {
            val = <span>{'邀请签署'}</span>;
          }
          return <TableRowWrapper val={val}>
            {val}
          </TableRowWrapper>
        },
      },
      {
        title: '签署方式',
        dataIndex: 'free_rect_item',
        key: 'free_rect_item',
        ellipsis: true,
        width: '7%',
        align: 'center',
        render: (text, record) => {
          if(record.signRecipientList.length === 1) {
            return <span>{'不指定签署区域'}</span>;
          } else if (record.signRecipientList.length > 1) {
            return <span>{record.signRecipientList[1].free_rect_item === '1' ? "不指定签署区域" : "指定签署区域"}</span>
          }
        },
      },
      {
        title: '签署顺序',
        dataIndex: 'sign_order',
        key: 'sign_order',
        ellipsis: true,
        width: '7%',
        align: 'center',
        render: (text, record) => {
          if(record.signRecipientList.length === 1 || record.signRecipientList.length === 2) return '-'
          let peopleList = record.signRecipientList.filter((item) => item.type !== '2');
          if(getFlowSignOrderedType(peopleList) === FLOW_SIGN_ORDERED_TYPE.ALL_ORDERED){
            return "顺序签署"
          } else {
            return "无序签署"
          }
        },
      },
      {
        title: '参与人',
        key: 'signRecipientList',
        dataIndex: 'signRecipientList',
        width: '100px',
        render: (signRecipientList, record) => {
          let peopleList = signRecipientList.filter((item) => item.type !== '2');
          return <SignerList propsData={{ peopleList }} record={record}> </SignerList>;
        },
      },
      {
        title: (
            <span>
            状态
            <Popover
                overlayClassName="wrapPopTitle"
                getPopupContainer={(triggerNode) => document.body}
                placement="bottom"
                content={<span>手动发送的文档会出现签署状态更新延迟情况，<br/>其签署状态仅供参考，请以实际文档状态为准。</span>}
                trigger="hover">
              <img className='tipIcon' src={tip} alt="" />
            </Popover>
          </span>
        ),
        dataIndex: 'status',
        key: 'status',
        width: '80px',
        align: 'left',
        render: (text) => {
          return (
              <span>
                <i className={`statusIcon status_${text}`}></i>
                {this.getStatus(text)}{' '}
              </span>
          );
        },
      },
      {
        title: '发起时间',
        dataIndex: 'create_time',
        key: 'create_time',
        ellipsis: true,
        width: '14%',
        align: 'center',
        render: (text) => {
          let val = <span>{dayjs(text * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>;
          return <TableRowWrapper val={val}>
            {val}
          </TableRowWrapper>
        },
      },
      {
        title: '结束时间',
        dataIndex: 'finish_time',
        key: 'finish_time',
        ellipsis: true,
        width: '14%',
        align: 'center',
        render: (text, record) => {
          if (record.doc_store === '1') {
            if (record.expire_time && record.expire_time !== '0'){
              if (!!text) {
                text = Math.min(Number(record.expire_time), Number(text))
              } else {
                text = Number(record.expire_time)
              }
            }
          }
          if (!text) return <span>-</span>;
          let val = <span>{dayjs(text * 1000).format('YYYY-MM-DD HH:mm:ss')}</span>;
          return <TableRowWrapper val={val}>
            {val}
          </TableRowWrapper>
        },
      },
      {
        title: '发起人',
        dataIndex: 'creator_name',
        key: 'creator_name',
        ellipsis: true,
        width: '80px',
        align: 'center',
        render: (text, record) => {
          const userBgColorList = ['#ba91ff','#68AFFF','#EF80DB','#FFA800','#75D060','#48D3D5','#E99570','#758395','#9DB4E2']
          const getBgIndex = (userId) => {
            const length = this.state.userBgColorList.length
            if(!userId) return length - 1
            const key = userId[userId.length -1]
            const s = 'a b c d e f g h i j k l m n o p q r s t u v w x y z 0 1 2 3 4 5 6 7 8 9';
            const sArray=s.split(' ');
            let keyIndex = 0
            sArray.forEach((item,index) => {
              if(key === item) keyIndex = index%length
            })
            return keyIndex
          }
          const bgColor = userBgColorList[getBgIndex(record.creator_email || record.creator_phone)]
          const paperVal = <span>{record.creator_name}<br/>{record.creator_email||record.creator_phone}</span>
          return <TableRowWrapper val={paperVal}>
            <div className="defaultPortrait" style={{background: bgColor}}>{text[0].toUpperCase()}</div>
          </TableRowWrapper>
        }
      },
      {
        title: '操作',
        key: 'operation',
        ellipsis: true,
        width: '120px',
        align: 'center',
        fixed: 'right',
        render: (text, record) => {
          //两种状态  签署、查看
          if (record.doc_store === '0') {
            return false;
          } //离线
          const menu = (
            <Menu
              onClick={(e) => {
                this.handleMenuClick(e, record);
              }}>
              {[2,6,7].includes(+record.status) && <Menu.Item key="download">下载文档</Menu.Item>}
              {
                inPhantom() && versionArr && versionArr[0] === 12 &&
                <Menu.Item key="copyLink">复制链接</Menu.Item>
              }
              {
                !(inPhantom() && getPhantomVersion() && getPhantomVersion()[0] === 12) &&
                <Menu.Item key="shareLink">分享</Menu.Item>
              }
              {record.status !== '2' && record.status !== '3' && this.currentItemExistOtherSigner(record) && this.isCreator(record) && <Menu.Item key="urge">催办</Menu.Item>}
              {/* {this.isCurrentSigner(record)&&<Menu.Item key="sign" >{intl.get('esignRecord.sign')}</Menu.Item>} */}
              {
                  (!inPhantom() || afterPhantomPluginVersion([2024, 2, 0])) &&
                +record.status === 2 && +record['doc_store'] === 1 && +record['ward_type']===0 && record['invalid_process_id'] === '' && this.props.role !== '5' && <Menu.Item key="invalid">作废</Menu.Item>}
              {(record.status === '2' || record.status === '3' || record.status === '4' || record.status === '5') && this.isCreator(record) && <Menu.Item key="delete">删除任务</Menu.Item>}
              {+record.status === 1 && this.isCreator(record) && <Menu.Item key="cancel">撤销</Menu.Item>}
              {
                [
                  !this.isCreator(record) && ['1'].includes(record.status) && <Menu.Item key="reject">拒签</Menu.Item>,
                  this.isCreator(record) && ['0','1','4'].includes(record.status) && record['invalid_process_id'] === '' && <Menu.Item key="deadline">修改截止日期</Menu.Item>,
                  ['0','1','2','4'].includes(record.status) && this.props.role !== '5' && <Menu.Item key="carbonCopy">修改抄送人</Menu.Item>
                ]
              }
              </Menu>
          );
          const btnTxt = this.isCurrentSigner(record) ? '签署' : '查看'
          const btnKey = this.isCurrentSigner(record) ? 'sign' : 'check';
          return (
              <Dropdown.Button
                  type="primary"
                  className={'esign_table_dropdown'}
                  onClick={() => {
                    this.handleButtonClick(btnKey, record);
                  }}
                  overlay={menu}
                  icon={<DownOutlined />}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: '0 auto' }}
              >
                {btnTxt}
              </Dropdown.Button>
          );
        },
      },
    ];
  };
  handleButtonClick = (key, record) => {
    const processId = record.process_id;
    if (key === 'sign') {
      //签署
      otelService.startCommonSpan(getOtelSpanName('request-signRecord-goToSign')).then(({end}) => {
        end && end();
      });
      this.gotoSign(processId);
    }
    if (key === 'check') {
      //查看
      otelService.startCommonSpan(getOtelSpanName('request-signRecord-goToReview')).then(({end}) => {
        end && end();
      });
      if (record.status === '2') {
        const eSignCNUrl = getStore('configUrls').eSignCNUrl;
        if (!eSignCNUrl) {
          return;
        }
        let fileUrl = encodeURIComponent(eSignCNUrl + "/api/file/download?docId=" + record.doc_id);
        let fileName= `${record.name}.pdf`;
        openFileByPlugin({ fileUrl, fileName });
      } else {
        this.gotoSign(processId);
      }
    }
  };

  searchWordList = ({ role, status, searchWord }) => {
    const sendData = { ...this.state.queryData };
    sendData.currentPage = 1;
    this.setState(
        {
          queryData: { ...sendData },
        },
        () => {
          const { expandedSearchParams } = this.props;
          this.initList({ role, status, searchWord, expandedSearchParams });
        },
    );
  };
  initList = async ({ role, status, searchWord, expandedSearchParams }) => {
    const { queryData } = this.state;
    this.setState({
      loading: true
    })

    if (+status === 2) {
      status = '2,3'
    }
    const data = await getSignProcessOrgList({
      role,
      status,
      name: searchWord,
      ...queryData,
      expandedSearchParams,
      errCallback: (err) => {
        console.log(err);
        if (err === ERROR_ESIGN_RECORD_PERMISSION_FAIL) {
          errorMessage('您没有权限查看该列表');
        }
        this.setState({
          isError: true,
        });
      },
    });
    if (!data) return;
    this.pagination.total = data.total;
    this.setState({
      isError: false,
      taskList: data.list,
      hasRequestList: true,
      serverTime: data.serverTime,
      total: data.total,
      loading: false
    });
    this.timer = setInterval(() => {
      //计算时间偏移量
      this.setState({
        serverTime: this.state.serverTime + 30,
      });
    }, 1000 * 30);
  };

  onChange = () => {};
  getStatus = (stauts) => {
    //  # 状态  签署流程状态 0 流程已创建 | 1 流程执行中 | 2 流程已完成 ｜ 3 流程已撤销
    let statusName;
    switch (stauts) {
      case '0':
        statusName = '签署中';
        break;
      case '1':
        statusName = '签署中';
        break;
      case '2':
        statusName = '已完成';
        break;
      case '3':
        statusName = '已撤销';
        break;
      case '4':
        statusName = '已过期';
        break;
      case '5':
        statusName = '已拒签';
        break;
      case '6':
        statusName = '作废中';
        break;
      case '7':
        statusName = '已作废';
        break;
      default:
        statusName = '未匹配';
        break;
    }
    return statusName;
  };

  async handleDeleteOk() {
    const processId = this.state.currProcessId
    this.setState({
      openDeleteModal: false,
      currProcessId: ''
    })

    if (!processId) {
      return
    }
    await deleteFlow({processId}).catch(() => {
      errorMessage("流程删除失败，请重试")
    })
    otelService.startCommonSpan(getOtelSpanName('request-signRecord-deleteTask')).then(({end}) => {
      end && end();
    });
    successMessage("流程删除成功")
    const { role, status, searchWord, expandedSearchParams } = this.props;
    this.initList({ role, status, searchWord, expandedSearchParams });
  }

  async handleCancelOk() {
    const processId = this.state.currProcessId
    const comment = this.state.cancelComment
    if (!comment) {
      this.setState({
        cancelComment: '',
      })
      return
    }
    this.setState({
      openCancelModal: false,
      cancelComment: undefined,
      currProcessId: ''
    })

    if (!processId) {
      return
    }
    // store.appStore.setGlobalMessage({ type: 'loading', content: '流程撤销中……' });
    loadingMessage("流程撤销中……")
    await cancelFlow({processId,comment}).catch(() => {
      errorMessage("流程撤销失败，请重试")
    })
    otelService.startCommonSpan(getOtelSpanName('request-signRecord-cancelTask')).then(({end}) => {
      end && end();
    });
    successMessage("流程撤销成功")
    const { role, status, searchWord, expandedSearchParams } = this.props;
    this.initList({ role, status, searchWord, expandedSearchParams });
  }

  changeComment(event) {
    const value = event.target.value
    this.setState({
      cancelComment: value
    })
  }
  handleChooseFileForFlowSign() {
    chooseFileForFlowSign()
  }


  render() {
    const { role, tableHeight } = this.props;
    const { taskList, queryData, total, isError, loading, openDeleteModal, openCancelModal,cancelComment } = this.state;
    let { currentPage, perPage } = queryData;
    // const { searchWord } = this.props;
    // let noEsignDoc = hasRequestList && !searchWord && taskList.length === 0; //用户是否拥有签署文档；
    const tableEmptyRender = () => {
      if (isPhantomV12()) {
        return <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<>暂无文档</>}
        ></Empty>
      }
      return <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<>暂无文档{role === '1' && <><br />请选择文件发起签署<br /><br /><Button onClick={this.handleChooseFileForFlowSign}>发起签署</Button></>}</>}
      ></Empty>
    }
    return (
        <ConfigProvider locale={zh_CN} autoInsertSpaceInButton={false}>
        <div className="EsignTable">
          {!isError && (
              <ConfigProvider renderEmpty={tableEmptyRender}>
                <Table
                    loading={loading}
                    columns={this.getColumns()}
                    scroll={{ y: tableHeight, x: 1040 }}
                    dataSource={taskList}
                    rowKey={record => record.process_id}
                    pagination={{
                      ...this.pagination,
                      current: currentPage,
                      defaultPageSize: perPage,
                      showQuickJumper: true,
                      total: total,
                      showTotal: (total) => `总共 ${total} 条`,
                    }}
                    sticky={true}
                />
              </ConfigProvider>
          )}
          {isError && <NetError handleClick={this.refresh}></NetError>}
        </div>
        <Modal title="PDF编辑器" open={openDeleteModal} okText={'确认'} cancelText={'取消'}
               onOk={() => this.handleDeleteOk()} onCancel={() => this.setState({openDeleteModal: false, currProcessId: ''})}>
          <div className={'esigncn-delete-modal'}>
            <InfoCircleOutlined className={'icon'}/>
            <div className={'title'}>
              <p>删除后，该文档将在福昕服务中永久删除且无法恢复。 是否确认删除？</p>
              <p className={'red'}>注意：也将一同删除所有参与人的该条签署记录。请在删除文档之前，确认文档是否已妥善保存。</p>
            </div>
          </div>
        </Modal>
        <Modal title="撤销签署任务" open={openCancelModal} okText={'确认'} cancelText={'取消'}
               onOk={() => this.handleCancelOk()} onCancel={() => this.setState({openCancelModal: false, currProcessId: ''})}>
          <div className='esigncn-cancel-modal'>
            <div className='title'>
              <InfoCircleOutlined className='icon'/>
              <span>撤销后，签署任务不可重新继续签署</span>
            </div>
            <div>
              <TextArea rows={4} placeholder="请填写撤销原因，将发送给各签署参与人" showCount={true} maxLength={200}
                        value={cancelComment} onInput={e => this.changeComment(e)}
              />
              {cancelComment === '' && <span className={'red'}>请输入撤销原因</span>}
            </div>
          </div>
        </Modal>
        <RejectDialog ref={rejectRef} success={()=>{
          const { role, status, searchWord, expandedSearchParams } = this.props;
          this.initList({ role, status, searchWord, expandedSearchParams });
        }}></RejectDialog>
        <DeadlineDialog ref={deadlineRef} success={()=>{
          const { role, status, searchWord, expandedSearchParams } = this.props;
          this.initList({ role, status, searchWord, expandedSearchParams });
        }}></DeadlineDialog>
          <CCDialog ref={ccDialogRef}/>
          {this.state.openInvalidModal && <Invalid user={this.state.invalidUserInfo} record={this.state.openInvalidModal} close={data => this.onCloseInvalid(data)} />}
        </ConfigProvider>

  );
  }
}
export default EsignTable;
