import {exceptionLog} from "../pages/PublicPage/config";
import {FROM_TYPE, MessageUtil} from "../pages/PublicPage/tools/MessageUtil";

const callback = () => {
    return Promise.resolve();
}

function getParentUrl() {
    let url = null;
    if (window.parent !== window) {
        try {
            url = window.parent.location.href.substring(0, window.parent.location.href.length - 1); // 去除最后一个 / 字符
            if (!url) {
                url = window.document.referrer.substring(0, window.document.referrer.length - 1); // 去除最后一个 / 字符
            }
        }
        catch (e) {
            url = window.document.referrer.substring(0, window.document.referrer.length - 1); // 去除最后一个 / 字符
        }
    } else if (window.opener) {
        url = window.document.referrer.substring(0, window.document.referrer.length - 1); // 去除最后一个 / 字符
    }
    return url;
}

class OnlineMsgTool {
    constructor() {
        this.targetOrigin = getParentUrl();
        this.windowMessageUtil = new MessageUtil(window.opener);
    }
    init() {
        this.windowMessageUtil.init(callback, FROM_TYPE.WIN_PHANTOM_ESIGNCN_PLUGIN, this.targetOrigin)
    }
    getUserInfoAPIFromOnline() {
        try {
            return this.windowMessageUtil.handleMessage('ESignCNFetchUserInfo')
        } catch (e) {
            exceptionLog({ api: 'ESignCNFetchUserInfo' })
        }
    }
    showESignCNDialog(params) {
        try {
            return this.windowMessageUtil.handleMessage('ESignCNShowDialog', params)
        } catch (e) {
            exceptionLog({ api: 'ESignCNShowDialog' })
        }
    }
    fetchAuditTrailInfoFromOnline({ docHash }) {
        try {
            return this.windowMessageUtil.handleMessage('ESignCNFetchAuditTrailInfo', { docHash })
        } catch (e) {
            exceptionLog({ api: 'ESignCNFetchAuditTrailInfo' })
        }
    }
    openFileUrlFromOnline(params) {
        const { fileUrl, fileName } = params;
        try {
            return this.windowMessageUtil.handleMessage('ESignCNOpenFileUrl', { fileUrl, fileName })
        } catch (e) {
            exceptionLog({ api: 'ESignCNOpenFileUrl' })
        }
    }
    showShareOnlineDocDialogFromOnline(params) {
        try {
            return this.windowMessageUtil.handleMessage('ESignCNShowShareOnlineDocDialog', params)
        } catch (e) {
            exceptionLog({ api: 'ESignCNShowShareOnlineDocDialog' })
        }
    }
    loginFromOnline() {
        try {
            return this.windowMessageUtil.handleMessage('ESignCNLoginFromOnline')
        } catch (e) {
            exceptionLog({ api: 'loginFromOnline' })
        }
    }
    // 调用online方法完成作废流程发起
    invalid2Online(data) {
        try {
            return this.windowMessageUtil.handleMessage('eSignCN-Invalid2Online', data)
        } catch (e) {
            exceptionLog({ api: 'eSignCN-Invalid2Online' })
        }
    }
}

const msgTool = new OnlineMsgTool();
msgTool.init();
export default msgTool;