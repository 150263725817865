import React, {useEffect, useState, useRef} from "react";
import {AutoComplete, Button, Form} from "antd";
import styles from "../index.module.less";
import {EXIST_MULTIPLE_ORG, HAS_JOIN, NOT_EXIST, NOT_HAS_JOIN} from "../const";
import {getOrgInfo, getRelationById} from "../../../../request/user";
import {getStore} from "../../../../store";
import {closeESignCNDialogAPI, closeESignCNDialogAPIV2} from "../../../../request/phantom";
import {afterPluginVersion2023_3} from "../../utils/util";
import {searchOrgName} from "../../../../request/esign";
import {debounce} from "../../../../utils";

export function InitStatusView(props) {

    const formRef = useRef(null);

    const { defaultOrgName, updateOrgName, updateStatus, updateOrgId } = props;
    const [searchLoading, setSearchLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputStatus, setInputStatus] = useState('');
    const [orgName, setOrgName] = useState('');



    useEffect(() => {
        if (defaultOrgName) {
            formRef.current.setFieldsValue({
                orgName: defaultOrgName
            })
        }
    }, [defaultOrgName])

    const onFinish = () => {
        formRef.current.validateFields()
            .then((values) => {
                return Promise.all([searchOrgInfo(values.orgName), Promise.resolve(values.orgName)]);
            })
            .then(([orgData, orgName]) => {
                // 判断orgInfo情况，决定接下来的用户操作流程
                return resolveOrgInfoSearchResult(orgData, orgName);
            })
            .then(({ status, orgName, orgId }) => {
                updateStatus(status)
                updateOrgName(orgName)
                updateOrgId(orgId)
            })
            .catch((errorInfo) => {
            });
    }
    const onCancel = () => {
        if (afterPluginVersion2023_3()) {
            const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
            const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
            const closeDialogIds = [ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG];
            closeESignCNDialogAPIV2(closeDialogIds);
        } else {
            closeESignCNDialogAPI()
        }
    }

    const searchOrgInfo = (orgName) => {
        // 获取企业组织信息
        const token = getStore('userInfo').token;
        return getOrgInfo(orgName, token);
    }

    const checkUserOrgRelation = async (orgId, orgName) => {
        const token = getStore('userInfo').token;
        const licenseData = await getRelationById(orgId, token);
        if (licenseData && Array.isArray(licenseData.licenses)) {
            // 已经加入该组织
            // info.enterpriseId = orgId; // 相当于将个人登录切换为企业登录状态
            return {
                status: HAS_JOIN,
                orgName,
                orgId
            }
        } else {
            // 未加入该组织
            return {
                status: NOT_HAS_JOIN,
                orgName,
                orgId
            }
            // if (!optList.includes(-5) && !info.userEmail) {
            //     optList.push(-5);
            // }
            // info.joinOrgId = orgId;
            // optList.push(-13);
            // return optList;
        }
    }

    const resolveOrgInfoSearchResult = (orgData, orgName) => {
        // 判断orgInfo情况，决定接下来的用户操作流程
        if (orgData && Array.isArray(orgData.enterpriseList) && orgData.enterpriseCount > 0) {
            if (orgData.enterpriseCount === 1) {
                const org = orgData.enterpriseList[0];
                const orgId = org.id;
                return checkUserOrgRelation(orgId, orgName);
            } else {
                return {
                    status: EXIST_MULTIPLE_ORG,
                    orgName: orgName
                }
            }
        } else { // 创建组织
           return {
               status: NOT_EXIST,
               orgName: orgName
           }
        }
    }

    const onSearch = (str) => {
        if (!str || str.length < 2) {
            return;
        }
        debounce(() => {
            setSearchLoading(true);
            getOptions(str).then(list => {
                setOptions(list);
            }).catch(() => {
                setOptions([]);
            }).finally(() => {
                setSearchLoading(false);
            });
        }, 500)();
    };

    const changeValue = (value = '') => {
        value = value.trim();
        if (!value) {
            setOrgName('');
            setInputStatus('warning');
        } else {
            setOrgName(value);
            setInputStatus('');
        }
    };
    console.log('styles.nameOptions', styles.nameOptions)
    return (
        <>
            <div className={styles.orgIcon}></div>
            <div className={styles.content0}>
                <div></div>{/* grid布局占位符 */}
                <div>
                    <div className={styles.title}>填写企业组织信息</div>
                    <Form
                        className={styles.form}
                        autoComplete="off"
                        ref={formRef}
                    >
                        <Form.Item
                            label="企业名称："
                            name="orgName"
                            rules={[{ required: true, message: '企业名称不能为空' }]}
                        >
                            {/*<Input placeholder='请输入企业组织名称' disabled={!!defaultOrgName}/>*/}
                            <AutoComplete
                                options={options}
                                onSelect={changeValue}
                                onSearch={onSearch}
                                onChange={changeValue}
                                popupClassName={`${styles.nameOptions} org-name-autocomplete`}
                                value={orgName}
                                maxLength={50}
                                status={inputStatus}
                                placeholder={`请输入企业全称`}
                                allowClear={!searchLoading}
                                loading={searchLoading}
                                disabled={!!defaultOrgName}
                            >
                            </AutoComplete>
                        </Form.Item>
                    </Form>
                </div>
                <div className={styles.btnGroup}>
                    <Button className={styles.primaryBtn} type="primary" htmlType="submit" onClick={onFinish}>
                        确认
                    </Button>
                    <Button htmlType="button" onClick={onCancel}>
                        取消
                    </Button>
                </div>
            </div>
        </>
    )
}

async function getOptions(keyword) {
    const params = {
        token: getStore('userInfo').token,
        page: 1,
        pageSize: 20,
        keyword
    };
    const list = await searchOrgName(params);
    if (!list || list.length < 1) {
        return [];
    }
    return list.map(item => {
        const name = item.name;
        return {
            value: name,
            label: (<div title={name} className={styles.item}>
                <div className={styles.elli}>{name}</div>
            </div>),
        };
    });
}