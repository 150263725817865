import styles from './index.module.less';
import {useEffect, useRef, useState} from "react";
import ValidatorMain from "./main";
import ValidatorResult from "./validResult";
import {initWorker} from  '../../../../public/webSDK/worker/worker';
import {file2Buffer} from "../utils/util";
import { SDK_CODE } from '@/pages/PublicPage/constant';
import {hideMessage, loadingMessage} from "../../components/Message";


export const FAIL_REASON = {
    DEFAULT: '文档验签失败，请重试',
    TYPE_ERROR: "仅支持PDF格式的合同文档",
    SIZE_ERROR: "请选择大小在50M以内的文档",
    NO_SIG: "该文档未找到已签名的签名域",
    ENCRYPTED: "对不起，无法支持加密文档的验签，请使用福昕高级PDF编辑器进行验签",
}

export default function CommonSignValidator() {

    const [showValidResult, setShowValidResult] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validInfo, setValidInfo] = useState();
    const [workerInitSuccess, setWorkerInitSuccess] = useState(false);
    const [failReason, setFailReason] = useState('');

    const docRef = useRef();
    const workerRef = useRef();

    function uploadDoc(workerFetch, buffer) {
        return workerFetch.uploadDoc({buffer}).then(res => {
            if (!res || res.code !== SDK_CODE.SUCCESS) {
                return Promise.reject(res.code || '')
            } else {
                return Promise.resolve();
            }
        })
    }

    function validatorDoc(workerFetch) {
        return workerFetch.validatorDoc().then(res => {
            if(!res || res.code !== SDK_CODE.SUCCESS || !res.data) {
                if (res.code === SDK_CODE.NO_SIG) {
                    return Promise.reject(FAIL_REASON.NO_SIG)
                }
                return Promise.reject()
            } else {
                const data = res.data;
                if (!data.docInfo || !data.sigs || data.sigs.length < 1) {
                    setFailReason(FAIL_REASON.NO_SIG);
                    return Promise.reject(FAIL_REASON.NO_SIG);
                } else {
                    setValidInfo(res.data);
                    setShowValidResult(true);
                }
            }
        })
    }


    function setDocFn(file) {
        setFailReason('');
        docRef.current = file;
        if (!workerRef.current || !workerInitSuccess) return;
        setLoading(true);
        return file2Buffer(file).then((buffer) => {
            return uploadDoc(workerRef.current, buffer);
        })
        .then(() => {
            return validatorDoc(workerRef.current);
        })
        .catch(err => {
            console.log('err', err)
            if (!err) {
                setFailReason(FAIL_REASON.DEFAULT);
                return;
            }
            if (err === SDK_CODE.UPLOAD_DOC_FAIL_ENCRYPTED) {
               setFailReason(FAIL_REASON.ENCRYPTED);
            } else if (err === SDK_CODE.NO_SIG) {
               setFailReason(FAIL_REASON.NO_SIG);
            } else if(err === SDK_CODE.UPLOAD_DOC_FAIL_UN_KNOW) {
               setFailReason(FAIL_REASON.DEFAULT);
            } else {
               setFailReason(err);
            }
        })
        .finally(() => {
            setLoading(false);
        })
    }
    function clearDocFn(from = 'main-view') {
        docRef.current = null;
        if (from !== 'result-view') {
            setShowValidResult(false);
        }
    }

    function initWorkerFn() {
       return initWorker();
    }

    useEffect(() => {
        document.title = '福昕文档验签服务'
        document.body.setAttribute('data-env', 'common-sign-validator');

        loadingMessage('正在初始化验签引擎');
        const workerFetch = initWorkerFn();
        workerRef.current = workerFetch;
        workerFetch.initEngine().then(res => {
            if(res && res.code === SDK_CODE.SUCCESS) {
                setWorkerInitSuccess(true);
            } else {
                setFailReason('系统错误，请刷新页面重试。')
            }
        }).finally(() => {
            hideMessage();
        })
    }, [])

    return (
        <div className={styles.container}>
            {
                !showValidResult &&
                // false &&
                <ValidatorMain
                    setDocFn={setDocFn}
                    clearDocFn={clearDocFn}
                    loading={loading}
                    failReason={failReason}
                    setFailReasonFn={setFailReason}
                />
            }
            {
                showValidResult &&
                // true &&
                <ValidatorResult
                    validInfo={validInfo}
                    clearDocFn={clearDocFn}
                    setDocFn={setDocFn}
                    loading={loading}
                    failReason={failReason}
                    setFailReasonFn={setFailReason}
                />
            }
        </div>
    )
}