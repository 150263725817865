export const FROM_AUDIT_TRAIL = 'AUDIT_TRAIL'

export function getParentUrl() {
    let url = null;
    if (window.parent !== window) {
        try {
            url = window.parent.location.href.substring(0, window.parent.location.href.length - 1); // 去除最后一个 / 字符
            if (!url) {
                url = window.document.referrer.substring(0, window.document.referrer.length - 1); // 去除最后一个 / 字符
            }
        }
        catch (e) {
            url = window.document.referrer.substring(0, window.document.referrer.length - 1); // 去除最后一个 / 字符
        }
    } else if (window.opener) {
        url = window.document.referrer.substring(0, window.document.referrer.length - 1); // 去除最后一个 / 字符
    }
    return url;
}