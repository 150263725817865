import {getStore} from "../../../store";
import {getSignFlowInfo} from "../../../request/esign";
import dayjs from "dayjs";

export async function  formatPdfInfo(assignInfo) {
    if (!assignInfo || Object.keys(assignInfo).length < 1) {
        return null
    }
    const signType = getStore('plugin').wardType === 0 ? 'out' : 'in'
    let currentUserName = "";
    let currOrder = 0;
    const data = assignInfo;
    if (!data || !data.receipts || data.receipts.length < 1) {
        return;
    }

    console.log('esign flow list: ', data);

    let {configInfo, receipts} = data;
    if (!configInfo || configInfo.docStore === 1) {
        receipts = []; // 在线文档，不允许在online内签署
    }

    // 获取签署信息
    const processId = configInfo.processId;
    const servInfo = await getSignFlowInfo({ processId })
    let servRecipients = [];
    if (servInfo.process && servInfo.process.recipients) {
        servRecipients = servInfo.process.recipients.slice(1);
    }
    let myItem = {};
    // 标注完成状态
    receipts.forEach((item,index) => {
        let finishCnt = 0;
        item.annots.forEach(annot => {
            annot.userName = item.userName;
            annot.company = item.company;
            annot.color = item.color;
            if (annot.fieldStatus === 2) { // 0未完成，2已完成 1签章域已放置印章
                finishCnt++;
            }
        });
        item.isFinished = item.annots.length === finishCnt;
        item.finishCnt = finishCnt;
        if (!item.isFinished && !currentUserName) {
            currentUserName = item.userName;
        }

        if (servRecipients[index] && servRecipients[index].status === '4') {
            let updated = parseInt(servRecipients[index].update_time) * 1000;
            item.updated = dayjs(updated).format('YYYY/MM/DD HH:mm:ss');
        }
        if (item.finishCnt === item.annots.length) {
            currOrder++
        }
    });


    // 找出未完成的且轮到自己的项
    const signUserInfo = getStore('userInfo')
    let prevItem = null;
    receipts.forEach(item => {
        if (item.isFinished) {
            return false;
        }

        // 检查是否是未完成的签署环节中的第一个环节；
        const checkPrevItem = () => !prevItem;
        const checkEmail = () => signUserInfo.userEmail && item.email && signUserInfo.userEmail.toLowerCase() === item.email.toLowerCase();
        const checkMobile = () => signUserInfo.userMobile && signUserInfo.userMobile === item.phoneNumber;
        const checkName = () => {
            if (signType  === 'out') {
                return getStore('esignPerson').name === item.userName;
            } else {
                return true;
            }
        };
        const checkEnterpriseId = () => {
            if (signType  === 'in') {
                return signUserInfo.enterpriseId === item.companyId;
            } else {
                return true;
            }
        };
        const checkIllegalFillBehavior = () => !(prevItem && prevItem.illegalFillBehavior);

        if (
            checkPrevItem() &&
            (checkEmail() || checkMobile()) &&
            checkName() &&
            checkEnterpriseId() &&
            checkIllegalFillBehavior()
        ) { // 均为true 表示 该签署环节为需要用户签署的环节
            item.annots.forEach(annot => {
                item.finishCnt = 0;
                if (annot.fieldStatus > 0) { // 多了状态1
                    item.finishCnt++;
                }
                item.isMine = true;
            });
            myItem = item;
            return true;
        }
        prevItem = item;
    });
    return { ...assignInfo, receipts, currOrder, myItem, cur_order: currOrder, recipients: receipts};
}