import {useState} from "react";
import {Button, Input, Popover} from "antd";
import styles from "../index.module.less";
import './font/font.less'

const FontList = [
    'SimHei,STHeiti,Noto Serif Hebrew',
    'CESI_KT_GB2312,KaiTi,STKaiti',
    'FangSong,STFangSong,CESI_FS_GB13000',
    'SimSun,STSong,CESI_SS_GB13000',
    'Zeyada',
    'Grand Hotel',
    'Times New Roman',
    'Over The Rainbow',
    'Nothing You Could Do',
    'Rancho'
];

export function ExtInput (props){
    const [open, setOpen] = useState(false);

    const changeFont = (item) => {
        props.setFont(item)
        setOpen(false)
    }

    const handleOpenChange = (newOpen) => {
        if (props.value) {
            setOpen(newOpen);
        }
    };

    const tabInput3ClassName = styles.tabInput3
    const content = (
        <div className={tabInput3ClassName}>
            {
                FontList.map(item => {
                    return <p onClick={() => changeFont(item)} key={item} style={{fontFamily: item}}>{props.value}</p>
                })
            }
        </div>
    );
    return (
        <>
            <Popover placement="bottomRight" content={content} trigger="click" open={open} onOpenChange={handleOpenChange}>
                <Button hidden={props.value.length < 1} type={"link"} >选择字体</Button>
            </Popover>
        </>
    )
}

export default function CreateInput(props) {
    const [value, setValue] = useState('');
    const font = props.font || FontList[0];
    const [fontSize, setFontSize] = useState(100);

    const inputChange = (e) => {
        setValue(e.target.value);
        props.setValue(e.target.value).then(fontSize => {
            setFontSize(fontSize ? fontSize : 100);
        });
    }

    return (
        <div className={styles.tabContainer} >
            <div className={styles.tabInput2}>
                <label>
                    <span style={{color: '#999'}}>请输入签名：</span>
                    <Input size={"small"} type='text' maxLength={20} allowClear={true} style={{width: '230px'}}
                        onChange={inputChange}
                    />
                </label>
            </div>
            <div className={styles.tabInput} style={{fontFamily: font, fontSize: fontSize + 'px'}}>
                {value}
            </div>
        </div>
    );
}
