import axios from "axios";
import qs from "qs";
import {encryptAES, setOtelHeader} from "../utils";
import {getStore} from "../store";
import {ERROR_AC_NO_IN_ORG, ERROR_AC_NO_ORG, ERROR_AC_REJOIN_ORG} from "./error_container";

const testBaseUrl = 'https://pheecws-devcn.connectedpdf.com';
// const stgBaseUrl = 'https://pheecws-stgus.connectedpdf.com';
// const baseUrl = 'https://pheecws.foxit.com';
const stgBaseUrl = 'https://pheecws-stgcn.connectedpdf.com';
const baseUrl = 'https://pheecws.foxitsoftware.cn';
const an = 'Foxit PhantomPDF Online';
// const eSignCnAn = 'Foxit eSign China';
const encryptKey = 'p1iZkba85IwaQ65t';
const host = window.location.origin;

export function getServerConfig() {
    let url = _getBaseUrl() + `/cpdfapi/v1/server/server-config?v=${new Date().valueOf()}`;
    return axios.get(url);
}

export function login() {
    const params = {
        from: 'esignCN',
        isEnterp: 1,
        al: 'zh-CN',
        av: '3.0.0.1',
        cv: '2.0.0.0',
        an,
        service: host,
        hf: 1,
    };
    window.location.href = `${_getAccountUrl()}/site/plugin-sign-in?${qs.stringify(params)}`;
}

export function getTokenByTicket(ticket) {
    const url = _getAccountUrl() + "/api/users/accessTokenBySt?casService=" + host + "&casTicket=" + ticket + "&an=" + an;
    return axios.get(url).then(data => {
        return data.access_token;
    });
}

export function getUserInfo(token, enterpriseId) {
    return setOtelHeader('auth', () => {
        if (!enterpriseId) {
            enterpriseId = '';
        }
        const url = `${_getAccountUrl()}/api/users?access-token=${token}&enterpriseId=${enterpriseId}&userNotifyType=1`
        return axios.get(url).then(data => {
            return data[0];
        })
    });
}

export function goAccount() {
    const accountApiUrl = _getAccountUrl();
    const userInfo = getStore('userInfo') || {};
    const email = userInfo.userEmail;
    const token = userInfo.token;
    const params = {
        from:'esignCN',
        al: 'zh-CN',
        an: 'Esign China',
    }
    const ticketUrl = `${accountApiUrl}/?${qs.stringify(params)}`;
    _getTicket({email, ticketUrl, token, accountApiUrl}).then(ticket => {
        window.open(`${ticketUrl}&ticket=${ticket}`) ;
    });
}

export function getTicket(ticketUrl) {
    const accountApiUrl = _getAccountUrl();
    const userInfo = getStore('userInfo') || {};
    const email = userInfo.userEmail;
    const token = userInfo.token;
    return _getTicket({email, ticketUrl, token, accountApiUrl})
}

export function logout(token, relogin = false) {
    _deleteToken(token).then(() => {

    }).finally(() => {
        const redirect = host + (relogin ? '?go-login=1' : '');
        const  url = `${_getUrl()}/cas/logout?service=${redirect}&time=${Date.now()}`
        window.location.replace(url);
    })
}

export function logout4PublicPage(token, relogin = false) {
    _deleteToken(token).then(() => {
    }).finally(() => {
        const redirect = `${host}${window.location.pathname}` + (relogin ? '?go-login=1' : '');
        const  url = `${_getUrl()}/cas/logout?service=${redirect}&time=${Date.now()}`
        window.location.replace(url);
    })
}

// 废弃的方法，原用于设置手机号
export function updateUserInfo({ phone }) {
    if (phone.length !== 11) {
        return Promise.reject('手机号错误');
    }

    phone = encryptAES(phone, encryptKey, '');

    if (phone.length < 1) {
        return Promise.reject('手机号加密错误');
    }

    const accountApiUrl = _getAccountUrl();
    const userInfo = getStore('userInfo') || {};
    const token = userInfo.token;

    const data = {
        'phone_number': phone,
        // 'first_name':,
        // 'last_name':,
        // 'bUsedPPO':,
    }
    const fd = qs.stringify(data);

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }

    const url = `${accountApiUrl}/api/users?access-token=${token}`
    return axios.put(url, fd, config).then(res => {
        const result = res.data;
        if (result && result.ret === 0) {
            return result;
        }
        return Promise.reject(result);
    })
}

// 查询手机号或邮箱是否已被使用
export function accountExist(account, isMobile = true) {
    const accountApiUrl = _getAccountUrl();
    const userInfo = getStore('userInfo') || {};
    const token = userInfo.token || '';

    account = encryptAES(account, encryptKey);
    if (!account) {
        return Promise.reject('数据加密错误');
    }
    const data = {
        userAccount: account,
        userAccountType: isMobile ? 2 : 1,
        'access-token': token
    }
    const fd = qs.stringify(data);
    const url = `${accountApiUrl}/api/users/account-exists?${fd}`
    return axios.get(url).then(data => {
        return data.isExist;
    })
}

export function getCaptchaUrl(id) {
    const accountApiUrl = _getAccountUrl();
    return `${accountApiUrl}/api/site/captcha?captchaId=${id}&captchaLength=4&timeout=${new Date().valueOf()}`;
}

// 发送手机验证码 发送邮箱验证码
export function sendVerificationCode(account, isMobile = true, token = undefined, captchaId=undefined, captcha=undefined) {
    const accountApiUrl = _getAccountUrl();
    const userInfo = getStore('userInfo') || {};
    if (!token) {
        token = userInfo.token || '';
    }

    if (!token) {
        return Promise.reject('请登录后再操作');
    }

    account = encryptAES(account, encryptKey);
    if (!account) {
        return Promise.reject('数据加密错误');
    }
    const data = {
        encrypt: account,
        module: isMobile ? 4 : 5,
        captchaId,
        captcha
    }
    // const config = {
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded'
    //     }
    // }
    let url;
    if (isMobile) {
        // url = `${accountApiUrl}/api/users/sms?access-token=${token}&al=zh-CN&an=${eSignCnAn}`
        url = `${accountApiUrl}/api/users/sms?access-token=${token}&al=zh-CN`
    } else {
        url = `${accountApiUrl}/api/users/send-mail?access-token=${token}&al=zh-CN`;
    }
    return axios.post(url, data).then(() => {
        return true;
    })
}

// 设置手机号 设置邮箱
export function setAccount(account, code, isMobile = true, token) {
    const accountApiUrl = _getAccountUrl();
    const userInfo = getStore('userInfo') || {};
    if (!token) {
        token = userInfo.token || '';
    }

    if (!token) {
        return Promise.reject('请登录后再操作');
    }

    // account = encryptAES(account, encryptKey);
    // if (!account ) {
    //     return Promise.reject('数据加密错误');
    // }
    let url, data;
    if (isMobile) {
        data = {
            mobile: account,
            verificationCode: code,
        }
        url = `${accountApiUrl}/api/users/mobile?access-token=${token}`
    } else {
        data = {
            email: account,
            verificationCode: code,
            module: 5,
        }
        url = `${accountApiUrl}/api/users/email?access-token=${token}`;
    }
    return axios.put(url, data).then(() => {
        return true;
    })
}

// 对内签署 saml
export function getSAMLResponse() {
    return setOtelHeader('saml', () => {
        const casUrl = _getUrl();
        const userInfo = getStore('userInfo') || {};
        const token = userInfo.token;
        const url = `${casUrl}/cas/saml/assertion?access_token=${token}`;
        return axios.get(url).then(data => {
            return data.samlAssertion;
        })
    });
}

// 对内签署 jwtToken
export function getJwtToken() {
    return setOtelHeader('jwt', () => {
        const plugName = 'esignCN';
        const userInfo = getStore('userInfo') || {};
        const token = userInfo.token;
        const enterpriseId = userInfo.enterpriseId;
        const accountUrl = _getAccountUrl();
        const url = `${accountUrl}/api/users/jwt-by-token?accessToken=${token}&enterpriseId=${enterpriseId}&pluginName=${plugName}`;
        return axios.get(url).then(data => {
            return data.jwt;
        });
    });


}

export function checkJwtToken(jwt, bIgnoreTime = true) {
    return axios.post(`${_getAccountUrl()}/api/users/check-jwt?JWT=${jwt}&bIgnoreTime=${bIgnoreTime}`).then(res => {
        return res.data;
    })
}

// 废弃 获取企业订阅/用户授权状态
// export function checkAuth(isOuter = false){
//     const product = !isOuter ? 'internal' : 'external';
//     const userInfo = getStore('userInfo') || {};
//     const token = userInfo.token;
//     const enterpriseId = userInfo.enterpriseId;
//     const url = `${_getBaseUrl()}/cpdfapi/v1/esign-cn/auth?access-token=${token}&enterpriseId=${enterpriseId}&product=${product}`;
//     return axios.get(url).then(() => {
//         return 1;
//     }).catch(error => {
//         if (Number.isInteger(error)) {
//             switch (error) {
//                 case 2100058: // 企业未订阅或过期
//                     return -1;
//                 case 110016:
//                     return -2; // 用户无授权
//                 default:
//                     return -3;
//             }
//         }
//         return Promise.reject(error);
//     });
// }

// 根据企业名称获取企业信息
export function getOrgInfo(name, token) {
    const url = `${_getBaseUrl()}/cpdfapi/v1/enterprise/info-by-name?access-token=${token}&enterpriseName=${name}`;
    return axios.get(url).then(res => res).catch(error => {
        if (error === ERROR_AC_NO_ORG) {
            return Promise.resolve(null);
        }
        return Promise.reject(error);
    });
}

// 根据企业id查询用户是否在企业中
export function getRelationById(orgId, token) {
    const url = `${_getBaseUrl()}/cpdfapi/v1/enterprise/user-relation?access-token=${token}&enterpriseId=${orgId}`;
    return axios.get(url).then(res => res).catch(error => {
        if (error === ERROR_AC_NO_IN_ORG) {
            return Promise.resolve(null);
        }
        return Promise.reject(error);
    });
}

// 用户加入企业
export function joinOrg(isOut, enterpriseId, comment, token, processId) {
    const url = `${_getBaseUrl()}/cpdfapi/v1/enterprise/join-application?access-token=${token}`;
    const data = {
        enterpriseId,
        source: isOut ? 'signCnExternal' : 'signCnInternal',
        lang: 'zh-CN',
        callbackUrl: host + (processId ? ('?id=' + processId) : ''),
        applicatComments: comment,
    }
    return axios.post(url, data).then(() => {
        return true;
    }).catch(error => {
        if (error === ERROR_AC_REJOIN_ORG) {
            return Promise.resolve(true);
        }
        return Promise.reject(error);
    });
}

export function getSignLink(ticketUrl, url) {
    const userInfo = getStore('userInfo') || {};
    const email = userInfo.userEmail;
    const token = userInfo.token;
    const accountApiUrl = _getAccountUrl();
    return _getTicket({ email, ticketUrl, token, accountApiUrl }).then(ticket => {
        return `${url}&ticket=${ticket}`
    })
}

export function validateSAMLResponse({ SAML }) {
    return axios.post(`${_getUrl()}/cas/saml/checkAssertion?bIgnoreTime=true`,SAML, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}

export function validateSAMLResponseAllInfo(data){
    return axios.post(`${_getUrl()}/cas/saml/checkAssertionAllInfo`, data, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

function _getBaseUrl() {
    let url;
    if (host.includes('localhost') || host.includes('azk8s') || host.includes('devcn')) {//本地环境 //测试环境
        url = testBaseUrl;
    } else if (host.includes("stg") ) {
        url = stgBaseUrl;
    } else {
        url = baseUrl;
    }
    return url;
}

function _getUrl() {
    const configUrls = getStore('configUrls') || {};
    return configUrls.casApiUrl;
}

function _getAccountUrl() {
    const configUrls = getStore('configUrls') || {};
    let url = configUrls.accountApiUrl;
    if (!url) {
        url = configUrls.cwsApiUrl.replace('pheecws', 'account');
    }
    return url;
}

function _deleteToken(token){
    const data = {
        'access_token': token,
    }
    const url = `${_getAccountUrl()}/api/users/access_token`;
    return axios.delete(url, {data});
}

function _getTicket({ email, ticketUrl, token, accountApiUrl }) {
    const inPhantom = getStore('plugin').inPhantom;
    const params = {
        'access-token': token,
        userEmail: email,
        service: ticketUrl,
        an: inPhantom ? 'Foxit PhantomPDF' : 'Foxit PhantomPDF Online'
    };
    const url = `${accountApiUrl}/api/users/get-cas-ticket`
    return axios.get(url, {params}).then(data => {
         return data['ticket'].st;
    });
}

export function searchEmailForSign(params) {
    const userInfo = getStore('userInfo') || {};
    const token = userInfo.token;
    const enterpriseId = userInfo.enterpriseId;
    const _params = {
        ...params,
        enterpriseId,
        "access-token": token
    };
    return axios.get(`${_getBaseUrl()}/cpdfapi/v1/enterprise/user`,{
        params: _params,
    });
}
