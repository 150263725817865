import {useLocation, useNavigate, useParams} from "react-router-dom";
import qs from "qs";
import CryptoJS from "crypto-js";
import {getJwtToken, getSAMLResponse, logout, logout4PublicPage} from "../request/user";
import {checkAuth, checkEnterpriseUser, getSubscription} from "../request/esign";
import {setStore} from "../store";
import msgTool from "../request/online";
import OtelService from "./otel/service";

// 获取我的连续签署项
export function getMyJoinItem(pdfInfo) {
    if (!pdfInfo || pdfInfo.currOrder < 0 || !Array.isArray(pdfInfo.receipts) || pdfInfo.receipts.length < 2) {
        return null;
    }

    if (pdfInfo.currOrder >= pdfInfo.receipts.length) {
        return null;
    }

    const isNoOrder = pdfInfo.isNoOrder
    const item = pdfInfo.receipts[pdfInfo.currOrder];
    if (item.companyId) { // 对内
        return null;
    }

    if (pdfInfo.receipts.length <= pdfInfo.currOrder + 1) {
        return null;
    }

    if (!isNoOrder) {
        const nextItem = pdfInfo.receipts[pdfInfo.currOrder + 1];
        const isNextMine = (item.email && nextItem.email && nextItem.email === item.email)
            || (item.phoneNumber && nextItem.phoneNumber && nextItem.phoneNumber === item.phoneNumber);
        if (isNextMine) {
            return nextItem;
        }
    } else {
        const nextItem = pdfInfo.receipts.find(it => it.uniqueKey !== item.uniqueKey && ((item.email && it.email && it.email === item.email)
            || (item.phoneNumber && it.phoneNumber && it.phoneNumber === item.phoneNumber)));
        if (nextItem) {
            return nextItem;
        }
    }
    return null;
}

const otelService = new OtelService();
export function setOtelHeader(name, fn) {
    const preName = 'esigncn-request-';
    if (name) {
        name = preName + name;
    }
    let endOtel;
    return otelService.startCommonSpan(name).then(({end}) => {
        endOtel = end;
        return fn && fn();
    }).finally(() => {
        endOtel && endOtel();
    });
}

// 高级组件 react类组件，扩展路由参数
export function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (
            <Component
                {...props}
                router={{location, navigate, params}}
            />
        );
    }

    return ComponentWithRouterProp;
}

// 供应商切换
export function getSupplierId(isOuter = true) {
    const querys = getParams() || {}
    let supplierId = querys.supplierId;
    if (!supplierId) {
        supplierId = sessionStorage.getItem('supplierId');
    }
    const outer =  parseInt(supplierId) > 0 ? parseInt(supplierId) : 1; // 1易签宝，2沃通 3敏行对外 4敏行对内
    const inner = outer > 1 ? 4 : 2;
    if (isOuter) return outer;
    return inner;
}

// 获取url query参数对象
export function getParams() {
    const search = window.location.search;
    const index = search.indexOf('?');
    let params;
    if (index >= 0) {
        params = search.slice(index + 1);
        params = qs.parse(params);
    }
    return params;
}

export function base64ToArrayBuffer(base64) {
    base64 = base64.replace(/^data:.*?base64,/, '');
    let bstr = window.atob(base64),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr;
}

const USER_AGENT = navigator.userAgent.toLowerCase();
const CONTAINS_OPERA = /opera|opr/.test(USER_AGENT);
const CONTAINS_MSIE = /msie/.test(USER_AGENT);
const CONTAINS_TRIDENT = /trident/.test(USER_AGENT);
export const isIE = (CONTAINS_TRIDENT || CONTAINS_MSIE) && !CONTAINS_OPERA && 'msSaveBlob' in navigator;

export const getBase64 = file => {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader.result);
        }
    })
};

export function getCropBase64(base64) {
    return new Promise(resolve => {
        const imgDomObj = new Image();
        imgDomObj.src=base64;
        imgDomObj.onload = function(){
            let canvas = document.createElement('canvas');
            let context = canvas.getContext('2d');
            canvas.width = imgDomObj.width;
            canvas.height = imgDomObj.height;
            context.drawImage(imgDomObj,0,0);//绘制
            const imgData = context.getImageData(0, 0, canvas.width, canvas.height).data;
            let lOffset = canvas.width, rOffset = 0,tOffset = canvas.height, bOffset = 0;
            for (let i = 0; i < canvas.width; i++) {
                for (let j = 0; j < canvas.height; j++) {
                    const pos = (i + canvas.width * j) * 4
                    if (imgData[pos] === 255 || imgData[pos + 1] === 255 || imgData[pos + 2] === 255 || imgData[pos + 3] === 255) {
                        bOffset = Math.max(j, bOffset); // 找到有色彩的最下端
                        rOffset = Math.max(i, rOffset); // 找到有色彩的最右端
                        tOffset = Math.min(j, tOffset); // 找到有色彩的最上端
                        lOffset = Math.min(i, lOffset); // 找到有色彩的最左端
                    }
                }
            }
            lOffset -= 10;
            rOffset += 10;
            tOffset -= 10;
            bOffset += 10;
            canvas = document.createElement("canvas");
            canvas.width = rOffset - lOffset;
            canvas.height = bOffset - tOffset;
            context = canvas.getContext("2d");
            context.drawImage(imgDomObj, lOffset, tOffset, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
            resolve(canvas.toDataURL('image/png'))
        }
    });
}

export const getBlackBase64 = file => {
    return getBase64(file).then(base64 => {
        return new Promise(resolve => {
            const [r0, g0, b0, a0] = [255, 255, 255, 255];
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const imgDomObj = new Image();

            imgDomObj.src = base64;
            imgDomObj.onload = () => {
                const w = imgDomObj.width;
                const h = imgDomObj.height;
                canvas.width = w;
                canvas.height = h;
                context.drawImage(imgDomObj, 0, 0);
                const imgData = context.getImageData(0, 0, w, h);
                for (let i = 0; i < imgData.data.length; i += 4) {
                    const r = imgData.data[i];
                    const g = imgData.data[i + 1];
                    const b = imgData.data[i + 2];
                    const a = imgData.data[i + 3];
                    const t = Math.sqrt((r - r0) ** 2 + (g - g0) ** 2 + (b - b0) ** 2 + (a - a0) ** 2);
                    if (t <= 60) {
                        imgData.data[i] = 0;
                        imgData.data[i + 1] = 0;
                        imgData.data[i + 2] = 0;
                        imgData.data[i + 3] = 0;
                    } else {
                        const average = Math.floor((r + g + b) / 3);
                        imgData.data[i] = average;
                        imgData.data[i + 1] = average;
                        imgData.data[i + 2] = average;
                    }
                }
                context.putImageData(imgData, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            }
        })
    })
};

export function encryptAES(data, key, iv = '', ivMd5 = true){
    try {
        // let keyMD5 = CryptoJS.MD5(key)
        if(!iv && ivMd5 === true) {
            let keyMd5 = CryptoJS.MD5(key).toString();
            iv = keyMd5.slice(0, 16);
        }
        let parseKey = CryptoJS.enc.Utf8.parse(key)
        let parseIv = CryptoJS.enc.Utf8.parse(iv)
        let encrypted = CryptoJS.AES.encrypt(data, parseKey, {
            iv: parseIv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
        return encrypted.toString()
    } catch (err) {
        console.error(err);
        return '';
    }
}

export function decryptAES(encryptedStr, key, iv) {
    try {
        // let keyMD5 = CryptoJS.MD5(key)
        let parseKey = CryptoJS.enc.Utf8.parse(key)
        let parseIv = CryptoJS.enc.Utf8.parse(iv)
        let decryptedUtf8 = CryptoJS.AES.decrypt(encryptedStr, parseKey, {
            iv: parseIv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        })
        let decryptedData = decryptedUtf8.toString(CryptoJS.enc.Utf8)
        return decryptedData
    } catch (err) {
        console.error("🍭  ~ file:index method:decryptAES line:162 -----", err);
        return '';
    }
}

export function copyToClipboard(text, success, fail) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    document.body.appendChild(textarea);
    const selection = document.getSelection();
    const range = document.createRange();
    range.selectNodeContents(textarea);
    selection.removeAllRanges();
    selection.addRange(range);
    textarea.select();
    // selection.getRangeAt(0).cloneContents();
    if (document.execCommand('copy')) {
        success && success();
    } else {
        fail && fail();
    }
    document.body.removeChild(textarea)
}

export function getScrollBarSize () {

    const inner = document.createElement('div');
    inner.style.width = '100%';
    inner.style.height = '200px';

    const outer = document.createElement('div');
    const outerStyle = outer.style;

    outerStyle.position = 'absolute';
    outerStyle.top = 0;
    outerStyle.left = 0;
    outerStyle.pointerEvents = 'none';
    outerStyle.visibility = 'hidden';
    outerStyle.width = '200px';
    outerStyle.height = '150px';
    outerStyle.overflow = 'hidden';

    outer.appendChild(inner);

    document.body.appendChild(outer);

    const widthContained = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let widthScroll = inner.offsetWidth;

    if (widthContained === widthScroll) {
        widthScroll = outer.clientWidth;
    }

    document.body.removeChild(outer);

    return widthContained - widthScroll;
}

export const checkInerAuth = async (enterpriseId, onlyCheck = false) => {
    const saleText = '联系销售';
    const link = 'https://www.foxitsoftware.cn/esign#sales';

    // 判断是否为企业帐户
    if (!enterpriseId) {
        return alert(-1, '个人帐户不支持此功能，请切换企业帐户登录，若您尚未开通企业帐户，请联系销售为您创建企业信息。', saleText, link);
    }

    // 判断企业是否订阅或用户是否有授权
    let res = await checkAuth();
    if(!res || res <= 0) {
        if (res === -1) {
            return alert(-2, '对内签署服务未订阅或订阅过期，请联系销售为您所在的企业订阅该服务。', saleText, link);
        }
        if (res === -2) {
            return alert(-3, '您尚未被分配对内签署授权，请联系当前企业IT管理员为您分配授权。');
        }
        return alert(-4, '查询企业订阅/授权信息失败');
    }

    if (onlyCheck) {
        return result(1);
    }

    // 判断是否有saml
    let samlResponse, jwtToken;
    res = await getSAMLResponse();
    if (!res) {
        // 获取jwtToken
        res = await getJwtToken();
        if (res) {
            jwtToken = res;
        } else {
            return result(-5, '获取JWT失败');
        }
    } else {
        samlResponse = res;
        // 判断归属
        const enterpriseUserRelation = await checkEnterpriseUser();
        if(enterpriseUserRelation !== true){
            return alert(-6, '当前登录帐户未归属当前企业，请更换帐户重新登录');
        }
    }

    // 判断订阅信息
    res = await getSubscription({ wardType: 1, tenantId: enterpriseId });
    if (!res || !res.serverTime) {
        return alert(-7, '获取企业订阅信息失败');
    }
    const userSubscription = res;
    if (!userSubscription.serverTime || !userSubscription.expreTime || userSubscription.serverTime >= userSubscription.expreTime) {
        return alert(-8, '对内签署服务已过期，请联系销售为您所在的企业续订该服务。', saleText, link);
    }

    return result(1, samlResponse, userSubscription, jwtToken);

    function result(state = 0, samlResponse = '', userSubscription = null, jwtToken = '', alert = null) {
        return {
            state,
            samlResponse,
            jwtToken,
            userSubscription,
            alert
        };
    }

    function alert(state, content = '数据查询错误，请稍后重试', okText = '确认', okLink = undefined) {
        const alert = {
            content,
            okText,
            okLink,
        }
        return result(state, undefined, undefined, undefined, alert);
    }
};

export function doLogout(token, relogin = false) {
    logout(token, relogin);
    setStore('userInfo', null);
    sessionStorage.removeItem('enterpriseId');
    sessionStorage.removeItem('token');
}

export function doLogout4PublicPage(token, relogin = false) {
    logout4PublicPage(token, relogin)
    setStore('userInfo', null);
    sessionStorage.removeItem('enterpriseId');
    sessionStorage.removeItem('token');
    msgTool.loginFromOnline()
}

export function getRect4Pdf(dom, pageIndex, pdfDocRender, pageRender) {
    const res = getRect(dom, pageIndex, pdfDocRender);
    const {left, top, right, bottom} = res || {};
    if (!pageRender) {
        const pages = pdfDocRender.getVisiblePDFPageRenders();
        if (pages && pages.length > 0) {
            pageRender = pages[0];
        }
    }
    if (!pageRender ) {
        return null;
    }
    const device2pdf = pageRender.transformDeviceToPDF();
    if (!device2pdf) {
        return null;
    }
    const rect = device2pdf.transformRect(left, top, right, bottom);
    return {rect, pageRender};
}

export function getRect(dom) {
    const rect = dom.getBoundingClientRect();
    const padRect = dom.parentNode.getBoundingClientRect();
    const left = rect.left - padRect.left;
    const top = rect.top - padRect.top;
    const right = left + rect.width;
    const bottom = top + rect.height;
    return {left, top, right, bottom};
}

// 写一个debounce函数
let debounceTimer = null;
export function debounce(fn, delay) {
    clearTimeout(debounceTimer);
    return function() {
        debounceTimer = setTimeout(fn, delay);
    };
}

