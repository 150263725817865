import styles from "../index.module.less";
import {Button} from "antd";
import {closeESignCNDialogAPI, closeESignCNDialogAPIV2, showESignCNDialogAPI} from "../../../../request/phantom";
import {afterPluginVersion2023_3} from "../../utils/util";

export function NotExistView(props) {

    const { orgName } = props;
    const onOk = () => {
        showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/cert-plugin?orgName=${orgName}&wardType=0&state=2&forJoinOrg=1`});
    }
    const onCancel = () => {
        if (afterPluginVersion2023_3()) {
            const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
            const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
            const closeDialogIds = [ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG];
            closeESignCNDialogAPIV2(closeDialogIds);
        } else {
            closeESignCNDialogAPI()
        }
    }
    return <>
        <div className={styles.orgIcon}></div>
        <div className={styles.content1}>
            <div className={styles.msg}>
                <div>
                    您填写的<b>"{orgName}"</b>企业组织不存在,是否认证创建此企业组织?
                </div>
            </div>
            <div className={styles.btnGroup}>
                <Button className={styles.primaryBtn} type="primary" htmlType="submit" onClick={onOk}>
                    企业认证
                </Button>
                <Button htmlType="button" onClick={onCancel}>
                    取消
                </Button>
            </div>
        </div>
    </>
}