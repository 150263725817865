class MyDatePicker {
    constructor() {
        this.month = null;
        this.year = null;
        this.day = null;

        this.dom = null;
        this.dateDom = null;
        this.yearInput = null;
        this.monthSelect = null;

        this.callback = null;
        this.date = null;
        this.format = null;

        this.inputFocus = false;
        this.domFocus = false;
    }


    /*
    summary:
      设置对象属性，并创建dom
    params:
      format：返回的日期字符串格式 格式窜：yyyy-mm-dd HH:MM:SS
      callback: 关闭时回调函数，返回format后的日期字符串
      defaultValue：默认日期，Date对象
    return:
      日期选择器dom
     */
    init({callback, defaultValue, format}) {
        if (!this.dom) {
            this._create();
        }

        this.callback = callback;
        this.format = format;

        if (!defaultValue) {
            this.date = new Date();
        } else {
            this.date = defaultValue;
        }
        if (!(this.date instanceof Date)) {
            this.date = new Date();
        }

        return this.dom;
    }


    show() {
        if (!this.dom) {
            return;
        }

        if (this.dom.style.display === 'block') {
            this.dom.style.display = 'none';
            this.dom.blur();
            return;
        }

        if (!this.date) {
            this.date = new Date();
        }

        this.year = this.date.getFullYear();
        this.month = this.date.getMonth();
        this.day = this.date.getDay();

        this.yearInput.value = this.year;
        this.monthSelect.innerText = (this.month + 1) + '月';
        this._createDateNode();
        this.dom.style.display = 'block';
        this.dateParentDom.style.display = 'block';
        this.monthDom.style.display = 'none';
        this.dom.focus();
    }

    hide(type) {
        if (!this.dom) {
            return;
        }
        if (!type) {
            this.dom.style.display = 'none';
            return;
        } else if (type === 1) {
            this.date = null;
            this.year = null;
            this.month = null;
            this.day = null;
        } else if (type === 2) {
            const date = new Date();
            this.date = date;
            this.year = date.getFullYear();
            this.month = date.getMonth();
            this.day = date.getDate();
        } else if (type === 3) {
            this.date = new Date(this.year + '/' + (this.month+1) + '/' + this.day);
        }

        if (this.callback) {
            this.callback(this._formatDate());
        }

        this.dom.style.display = 'none';
    }

    _formatDate() {
        let format = this.format;
        if (!format) {
            format = 'yyyy-mm-dd';
        }
        const year = this.year;
        const month = this.month;
        const day = this.day;

        if (!year || (!month && month !== 0) || !day) {
            return '';
        }

        const obj = {
            "m+":  month + 1,  // 月份
            "d+":  day,  // 日
            // "H+":  date.getHours(),  // 小时
            // "M+":  date.getMinutes(),  // 分
            // "S+":  date.getSeconds(),  // 秒
            // "q+": Math.floor(( date.getMonth() + 3) / 3),  // 季度
            // "s":  date.getMilliseconds()  // 毫秒
        };
        if (/(y+)/.test(format)) {
            format = format.replace(RegExp.$1, (year + "").substr(4 - RegExp.$1.length));
        }
        for ( let k  in obj) {
            if ( new RegExp("(" + k + ")").test(format)) {
                format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (obj[k]) : (("00" + obj[k]).substr(("" + obj[k]).length)));
            }
        }

        return format;
    }

    _create() {
        const dom = document.createElement('div');
        dom.classList.add('myDatePicker');
        dom.appendChild(this._createHeader());

        this.dateParentDom = document.createElement('div');
        this.dateParentDom.appendChild(this._createWeek());
        this.dateDom = document.createElement('div');
        this.dateDom.classList.add('pickerList');
        this.dateParentDom.appendChild(this.dateDom);
        this.dateParentDom.appendChild(this._createFooter());
        dom.appendChild(this.dateParentDom);

        this.monthDom = this._createMonth();
        dom.appendChild(this.monthDom);

        this.dom = dom;
        dom.tabIndex = -1;
        dom.onfocus = () => {
            this.domFocus = true;
        }
        let blurTimer = null;
        dom.onblur = () => {
            this.domFocus = false;
            if (blurTimer) {
                clearTimeout(blurTimer);
                blurTimer = null;
            }
            blurTimer = setTimeout(() => {
                if (!this.inputFocus) {
                    this.hide();
                }
            }, 200);
        }
        return this.dom;
    }

    _createDateNode() {
        this.dateDom.innerHTML = '';


        if (!this.year || (!this.month && this.month !== 0)) {
            return;
        }

        const month = parseInt(this.month)+1;
        const year = parseInt(this.year);
        if (this.year < 1900 || this.year > 3000 ){
            return;
        }

        const lastDays = new Date(year, month-1, 0).getDate();// 上月天数
        const days = new Date(year, month, 0).getDate(); // 本月有多少天
        let week = new Date(month + '/' + 1 + '/' + year).getDay(); // 本月第一天周几

        let list = [];
        if (week === 0) {
            week = 7;
        }
        if(week > 1) {
            list = list.concat(Array.from(new Array(week-1)).map((_, index) => {return {curr: -1, day: lastDays + index - week + 2}}));
        }
        list = list.concat(Array.from(new Array(days)).map((_, index) => {return {curr: 0, day: index + 1}}));
        list = list.concat(Array.from(new Array(42-days-week+1)).map((_, index) => {return {curr: 1, day: index + 1}}));

        let row;
        list.forEach((item, index) => {
            const {day, curr} = item;
            if (index % 7 === 0) {
                row = document.createElement('div');
                row.classList.add('day');
            }
            const child = document.createElement('div');
            if (curr !== 0) {
                child.classList.add('gray');
            } else {
                if (year === this.date.getFullYear() && month === this.date.getMonth()+1 && day === this.date.getDate()) {
                    child.classList.add('selected');
                }
            }
            child.innerText = day;
            child.onclick = () => {
                this.month = month;
                if (curr === 0) {
                    this.month -= 1;
                }
                if (curr === -1) {
                    this.month -= 2;
                }

                if (this.month < 0) {
                    this.month = 11;
                    this.year -= 1;
                }

                if (this.month > 11) {
                    this.month = 0;
                    this.year += 1;
                }

                this.day = day;
                this.hide(3);
            };
            row.appendChild(child);
            if (index % 7 === 0) {
                this.dateDom.appendChild(row);
            }
        })
    }

    _createHeader() {
        const dom = document.createElement('div');
        dom.classList.add('pickerHeader');

        const first = document.createElement('div');
        const input = document.createElement('input');
        input.type = 'number';
        input.autofocus = false;
        first.appendChild(input);

        const second = document.createElement('div');
        second.classList.add('select');

        let timer = null;
        input.oninput = (event) => {
            let value = event.target.value;
            value = parseInt(value);
            if (!value) {
                value = '';
            } else if (!/^\d{1,4}$/.test(value)) {
                value = this.year;
            }
            this.year = value;
            event.target.value = this.year;

            if (timer) {
                clearTimeout(timer);
                timer = null;
            }
            timer = setTimeout(() => this._createDateNode(), 200);
        };

        input.onfocus = () => {
            this.inputFocus = true;
        };
        let blurTimer = null;
        input.onblur = () => {
            this.inputFocus = false;
            if (blurTimer) {
                clearTimeout(blurTimer);
                blurTimer = null;
            }
            blurTimer = setTimeout(() => {
                if (!this.domFocus) {
                    this.hide();
                }
            }, 200);
        };

        second.onclick = () => {
            if (this.monthDom.style.display === 'grid') {
                this.dateParentDom.style.display = 'block';
                this.monthDom.style.display = 'none';
                return;
            }
            this.dateParentDom.style.display = 'none';
            this.monthDom.style.display = 'grid';
        };

        this.yearInput = input;
        this.monthSelect = second;
        dom.appendChild(first);
        dom.appendChild(second);
        return dom;
    }

    _createMonth() {
        const dom = document.createElement('div');
        dom.classList.add('pickerMonth');
        Array.from(new Array(12)).forEach((_, index) => {
            const child = document.createElement('div');
            child.innerHTML =`<div>${index+1}月</div>`;
            child.onclick = () => {
                this.month = index;
                this.monthSelect.innerText = (this.month + 1) + '月';
                this._createDateNode();
                this.dateParentDom.style.display = 'block';
                this.monthDom.style.display = 'none';
            };
            dom.appendChild(child);
        })
        return dom;
    }

    _createWeek() {
        const dom = document.createElement('div');
        dom.classList.add('pickerWeek');

        const list = ['一', '二', '三', '四', '五', '六', '日'];
        list.forEach((item, index) => {
            const child = document.createElement('div');
            child.innerText = item;
            if (index > 4) {
                child.classList.add('weekend');
            }
            dom.appendChild(child);
        });
        return dom;
    }

    _createFooter() {
        const dom = document.createElement('div');
        dom.classList.add('pickerFooter');

        const clearDom = document.createElement('div');
        clearDom.innerText = '清除';

        const todayDom = document.createElement('div');
        todayDom.innerText = '今天';

        clearDom.onclick = () => this.hide(1);
        todayDom.onclick = () => this.hide(2);

        dom.appendChild(clearDom);
        dom.appendChild(todayDom);
        return dom;
    }
}

export default MyDatePicker;
