import {observer} from "mobx-react";
import Cert from "../../cert";

function PhantomESignAgree(Cert) {
    const Fn = observer(function Comp() {
        return <Cert/>
    })
    return Fn;
}

export default PhantomESignAgree(Cert)