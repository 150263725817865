import {Modal} from "antd";
import {getWillAuthUrl} from "../../../request/esign";
import {useCallback, useEffect, useRef, useState} from "react";
import {SyncOutlined} from "@ant-design/icons";
import styles from './StampManage.module.less';
import {getStore} from "../../../store";
import {inPhantom} from "../../PublicPage/utils/util";

export default function WillingnessAuth(props) {

    const [url, setUrl] = useState(null);
    const {visible, closeModal} = props;
    const reqId = useRef();

    const handleCancel = useCallback((willData) => {
        closeModal(willData);
        setUrl(null);
    }, [closeModal]);

    useEffect(() => {
        if (!visible) {
           return;
        }
        const {accountId} = getStore('userInfo');
        let redirectUrl = window.location.origin + '/willingness';
        if (inPhantom()) {
            redirectUrl = window.location.origin + `/willingness-result-for-phantom?wardType=0&t=${Date.now()}`;
        }
        getWillAuthUrl({accountId, redirectUrl}).then(data => {
            const {url, bizId} = data;
            reqId.current = bizId;
            if(inPhantom()) {
                sessionStorage.setItem('bizId', bizId);
                window.location.href = url;
            } else {
                setUrl(url);
            }
        })

        const receiveMessage = (msg) => {
            if (!msg.data) {
                return;
            }
            const {type, key, willData} = msg.data;
            if (type !== 'willAuth') {
                return;
            }


            if (key === 'getBizId') {
                const iframe = document.getElementById('resultFrame');
                iframe.contentWindow.postMessage({type: 'willAuth', key: 'sendBizId', bizId: reqId.current}, '*');
            } else if (key === 'result') {
                handleCancel(willData);
            }
        }

        window.addEventListener('message', receiveMessage);
        return () => {
            window.removeEventListener('message', receiveMessage);
        }
    }, [visible, handleCancel])

    const className = inPhantom() ? 'phantom-willing-auth-dialog' : '';
    const height = inPhantom() ? '500px' : '600px';

    return (
        <Modal title='意愿认证' open={visible}  onCancel={handleCancel}
        footer={null} centered={true} maskClosable={false} width={'600px'} bodyStyle={{padding: '0', margin: 0}} className={className}
        >
            {!url && <div className={styles.loading}><SyncOutlined spin />&emsp;正在获取意愿认证链接…</div>}
            {url && <iframe id="resultFrame" title="eSignCn" src={url} className={styles.iframe} height={height}></iframe>}
        </Modal>
    )
}
