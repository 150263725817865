import styles from "../index.module.less";
import {Button} from "antd";
import {closeESignCNDialogAPI, closeESignCNDialogAPIV2} from "../../../../request/phantom";
import {afterPluginVersion2023_3} from "../../utils/util";

export function ExistMultipleOrgView() {

    const onOk = () => {
        window.open('https://www.foxitsoftware.cn/esign#sales');
    }
    const onCancel = () => {
        if (afterPluginVersion2023_3()) {
            const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
            const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
            const closeDialogIds = [ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG];
            closeESignCNDialogAPIV2(closeDialogIds);
        } else {
            closeESignCNDialogAPI()
        }
    }
    return <>
        <div className={styles.orgIcon}></div>
        <div className={styles.content1}>
            <div className={styles.msg}>
                <div>该组织存在多个，请联系销售确认组织信息。</div>
            </div>
            <div className={styles.btnGroup}>
                <Button className={styles.primaryBtn} type="primary" htmlType="submit" onClick={onOk}>
                    联系销售
                </Button>
                <Button htmlType="button" onClick={onCancel}>
                    取消
                </Button>
            </div>
        </div>
    </>
}