import {getStore} from "../store";
import {openLoginWindowOfPhantom} from "./phantom";
import {mockSwitch} from "../pages/PublicPage/config";
const tokenExpire = (res) => res.data.code === 600002 && res.data.msg === 'Error: Invalid Token'


export function httpResponseHandle (res) {
    if (tokenExpire(res)) {
        if (getStore('plugin').inPhantom && !mockSwitch) {
            // const successCallback = () => {
            //     window.location.reload()
            // }
            // const errorCallback = () => {
            //     exceptionLog('login fail')
            // }
            openLoginWindowOfPhantom()
        }
    }
}

export const exceptionLog = (desc, error) => {
    console.error(desc, error)
}

export function loadScript(url, params = {} /* callback */) {
    return new Promise((resolve, reject) => {
        let head = document.getElementsByTagName('head')[0];
        let script = document.createElement('script');
        script.type = 'text/javascript';
        if (Object.keys(params).length) {
            for (const key in params) {
                if (Object.hasOwnProperty.call(params, key)) {
                    script[key] = params[key];
                }
            }
        }

        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState === 'loaded' || script.readyState === 'complete') {
                    script.onreadystatechange = null;
                    resolve();
                }
            };
        } else {
            // script.οnlοad=function(){
            //   resolve();
            // }
            script.onload = resolve;
            script.onerror = reject;
        }
        script.src = url;
        head.appendChild(script);
    });
}

const USER_AGENT = navigator.userAgent.toLowerCase();
export const isIOS = /ipad|iphone|ipod/.test(USER_AGENT);
export const isWeiBo = /__weibo__/.test(USER_AGENT);
