import {exceptionLog, shouldCallPhantomApi} from "../pages/PublicPage/config";
import {getSigVerifyInfo} from "../pages/PublicPage/MockData";
import {getPhantomVersion} from "../pages/PublicPage/utils/util";

const isFoxitWebview = window.isFoxitWebview;

class ApiHandler {
    constructor() {
        this.apiMap = {}
    }

    set(apiName, resolve) {
        this.apiMap[apiName] = {
            resolve
        };
    }
    get(apiName) {
        return this.apiMap[apiName];
    }
    clean(apiName) {
        delete this.apiMap[apiName];
    }
}

const apiHandler = new ApiHandler();

const commonApiReceiver = (apiName, data) => {
    const result = apiHandler.get(apiName);
    if (result && result.resolve) {
        result.resolve(data ? JSON.stringify(data) : "");
        apiHandler.clean(apiName);
    } else {
        console.error('not exist this call function: ', apiHandler.apiMap, apiName)
    }
}
window.commonApiReceiver = commonApiReceiver;

const apiCallFn = (base, apiName, params, callback = 'commonApiReceiver') => {
    if (isFoxitWebview) {
        return new Promise((resolve) => {
            apiHandler.set(apiName, resolve);
            try {
                window.chrome.webview.hostObjects.sync.FxWebView.DispatchFun2(base, apiName, params, callback);
            } catch (e) {
                console.error(`apiCallFn ${apiName} error: `, e, params)
            }
        })
        // console.log('apiCallFn: ', `${apiName}(${params})`)
        // return window.chrome.webview.postMessage(`${apiName}(${params})`)
    }  else {
        const result = window.external.DispatchFun(base, apiName, params)
        return Promise.resolve(result);
    }
}

export async function getUserInfoAPIFromPhantom() {
    try {
       // const data = window.external.DispatchFun('ESignCNBase', 'ESignCNFetchUserInfo', "")
        const data = await apiCallFn('ESignCNBase', 'ESignCNFetchUserInfo', "")
        return JSON.parse(data)
    } catch (e) {
        exceptionLog({ api: 'ESignCNFetchUserInfo', error: e })
    }
}

export function openLoginWindowOfPhantom() {
    try {
        // return window.external.DispatchFun('ESignCNBase', 'openLoginWindow', '')
        return apiCallFn('ESignCNBase', 'openLoginWindow', '')
    } catch (e) {
        exceptionLog({ api: 'openLoginWindow', error: e })
    }
}

// 让客户端用默认浏览器打开URL
export function openUrlByBrowser(url) {
    try {
        // return window.external.DispatchFun('ESignCNBase', 'openUrlByBrowser', url)
        return apiCallFn('ESignCNBase', 'openUrlByBrowser', url)
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'openUrlByBrowser', error: e })
    }
}

export function showESignCNDialogAPI({ title, type, id, icon, msg, webUrl, width = 600, height = 430, extData, okCallback, cancelCallback, btnType = "" }) {
    if (shouldCallPhantomApi()) {
        try {
            const params = { title, type, id, icon, msg, webUrl, width, height };
            if (extData) {
                params.extData = extData;
            }
            if (okCallback) {
                params.okCallback = okCallback;
            }
            if (cancelCallback) {
                params.cancelCallback = cancelCallback;
            }
            if (btnType) {
                params.btnType = btnType;
            }
            // return window.external.DispatchFun('ESignCNBase', 'ESignCNShowDialog', JSON.stringify({ title, type, id, msg, webUrl, width, height, extData }))
            return apiCallFn('ESignCNBase', 'ESignCNShowDialog', JSON.stringify(params))
        } catch (e) {
            // exceptionLog, error: e(e)
            exceptionLog({ api: 'ESignCNShowDialog', error: e })
        }
    } else {
        window.open(webUrl)
    }
}

export function closeESignCNDialogAPI(closeParent = 0) {
    if (shouldCallPhantomApi()) {
        try {
            const phantomVersion = getPhantomVersion();
            if (phantomVersion[0] > 12) {
                return apiCallFn('ESignCNBase', 'ESignCNCloseDialog', closeParent)
            } else {
                return apiCallFn('ESignCNBase', 'ESignCNCloseDialog', '')
            }
        } catch (e) {
            // exceptionLog, error: e(e)
            exceptionLog({ api: 'ESignCNCloseDialog', error: e })
        }
    } else {
        window.close()
    }
}

export function closeESignCNDialogAPIV2(dialogIds) {
    if (shouldCallPhantomApi()) {
        try {
            return apiCallFn('ESignCNBase', 'ESignCNCloseDialogV2', JSON.stringify({ dialogIds }))
        } catch (e) {
            // exceptionLog, error: e(e)
            exceptionLog({ api: 'ESignCNCloseDialogV2', error: e })
        }
    } else {
        window.close()
    }
}

export function fetchWillAuthData({ willAuthId, willAuthTime, willAuthType, willingnessBizId }) {
    if (shouldCallPhantomApi()) {
        try {
            // return window.external.DispatchFun('ESignCNWillAuth', 'ESignCNFetchWillAuthData', JSON.stringify({ willAuthId, willAuthTime, willAuthType, willingnessBizId }))
            return apiCallFn('ESignCNWillAuth', 'ESignCNFetchWillAuthData', JSON.stringify({ willAuthId, willAuthTime, willAuthType, willingnessBizId }))
        } catch (e) {
            // exceptionLog, error: e(e)
            exceptionLog({ api: 'ESignCNFetchWillAuthData', error: e })
        }
    } else {
        console.log({ willAuthId, willAuthTime, willAuthType, willingnessBizId })
    }
}

export async function fetchAuditTrailInfo() {
    try {
        // const data = window.external.DispatchFun('ESignCNAuditTrail', 'ESignCNFetchAuditTrailInfo', '')
        const data = await apiCallFn('ESignCNAuditTrail', 'ESignCNFetchAuditTrailInfo', '')
        return JSON.parse(data)
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNFetchAuditTrailInfo', error: e })
    }
}

export function fetchSigVerifyInfo(fileSizeOfSignedVersion) {
    try {
        // const verifyInfo = window.external.DispatchFun('ESignCNAuditTrail', 'ESignCNFetchSigVerifyInfo', JSON.stringify({ fileSizeOfSignedVersion }))
        const verifyInfo = apiCallFn('ESignCNAuditTrail', 'ESignCNFetchSigVerifyInfo', JSON.stringify({ fileSizeOfSignedVersion }))
        return JSON.parse(verifyInfo)
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNFetchSigVerifyInfo', error: e })
    }
}

export async function fetchSigVerifyInfoForPhantom(fileSizeOfSignedVersion) {
    try {
        if (shouldCallPhantomApi()) {
            // const verifyInfo = window.external.DispatchFun('ESignCNAuditTrail', 'ESignCNFetchSigVerifyInfoForPhantom', JSON.stringify({ fileSizeOfSignedVersion }))
            const verifyInfo = await apiCallFn('ESignCNAuditTrail', 'ESignCNFetchSigVerifyInfoForPhantom', JSON.stringify({ fileSizeOfSignedVersion }))
            return JSON.parse(verifyInfo)
        } else {
            return getSigVerifyInfo();
        }
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNFetchSigVerifyInfoForPhantom', error: e })
    }
}

export function openNewPhantomTab({url, title}) {
    try {
        // window.external.DispatchFun('ESignCNBase', 'openNewTab', JSON.stringify({url, title}))
        apiCallFn('ESignCNBase', 'openNewTab', JSON.stringify({url, title}))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'openNewTab', error: e })
    }
}

export function callPhantomLogin() {
    try {
        // window.external.DispatchFun('ESignCNBase', 'openLoginWindow', "");
        apiCallFn('ESignCNBase', 'openLoginWindow', "")
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'openLoginWindow', error: e })
    }
}

export function openFileByUrlForPhantom({ fileUrl, fileName}) {
    try {
        // window.external.DispatchFun('ESignCNBase', 'openFileByUrl', JSON.stringify({ fileUrl, fileName}));
        apiCallFn('ESignCNBase', 'openFileByUrl', JSON.stringify({ fileUrl, fileName}))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'openFileByUrl', error: e })
    }
}

export function downloadFileByUrlForPhantom({ fileUrl, fileName}) {
    try {
        // window.external.DispatchFun('ESignCNBase', 'downloadFileByUrl', JSON.stringify({ fileUrl, fileName}));
        apiCallFn('ESignCNBase', 'downloadFileByUrl', JSON.stringify({ fileUrl, fileName}))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'downloadFileByUrl', error: e })
    }
}

export function downloadFileByBase64ForPhantom({ base64, fileName}) {
    try {
        // window.external.DispatchFun('ESignCNBase', 'downloadFileByUrl', JSON.stringify({ fileUrl, fileName}));
        apiCallFn('ESignCNBase', 'downloadFileByBase64', JSON.stringify({ base64, fileName}))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'downloadFileByBase64', error: e })
    }
}

export async function getAssignInfoForPhantomAPI() {
    try {
        // const data = window.external.DispatchFun('ESignCNSignFlow', 'ESignCNGetAssignInfo', '');
        const data = await apiCallFn('ESignCNSignFlow', 'ESignCNGetAssignInfo', '')
        if (!data || data.length < 2) {
            return null
        } else {
            return JSON.parse(data)
        }
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNGetAssignInfo', error: e })
    }
}

export function jumpToTheFieldForPhantom({ objNumber, pageIndex }) {
    try {
        // window.external.DispatchFun('ESignCNBase', 'ESignCNJumpToTheField', JSON.stringify({ objNumber, pageIndex }));
        apiCallFn('ESignCNBase', 'ESignCNJumpToTheField', JSON.stringify({ objNumber, pageIndex }))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNJumpToTheField', error: e })
    }
}

export function setSigAPForPhantom({ base64AP, sealId, sealName }) {
    try {
        // window.external.DispatchFun('ESignCNSignFlow', 'ESignCNSetSigAP', JSON.stringify({ base64AP }));
        const params = {}
        if (base64AP) {
            params.base64AP = base64AP
        }
        if (sealId) {
            params.sealId = sealId
        }
        if (sealName) {
            params.sealName = sealName
        }
       return apiCallFn('ESignCNSignFlow', 'ESignCNSetSigAP', JSON.stringify(params))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNSetSigAP', error: e })
    }
}

export function callStateHandleForPhantom({type}) {
    try {
        // window.external.DispatchFun('ESignCNBase', 'ESignCNSetStateHandle', JSON.stringify({type}));
        apiCallFn('ESignCNBase', 'ESignCNSetStateHandle', JSON.stringify({type}))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNSetStateHandle', error: e })
    }
}

export function modifyFieldsResizableForPhantom({ annots, isOrg }) {
    try {
        // window.external.DispatchFun('ESignCNBase', 'ESignCNModifyFieldsResizable', JSON.stringify({ annots, isOrg}));
        apiCallFn('ESignCNBase', 'ESignCNModifyFieldsResizable', JSON.stringify({ annots, isOrg}))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNModifyFieldsResizable', error: e })
    }
}

export async function setInitFlowParamsForPhantom(params) {
    try {
        // const ret = window.external.DispatchFun('ESignCNSignFlow', 'ESignCNSetInitFlowData', JSON.stringify(params));
        const ret = await apiCallFn('ESignCNSignFlow', 'ESignCNSetInitFlowData', JSON.stringify(params))
        if(ret === "INIT_SIGN_SUCCESS") {
            return Promise.resolve();
        } else {
            return Promise.reject({ msg: "INIT_SIGN_FAIL" })
        }
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNSetInitFlowData', error: e })
    }
}

export function deleteFieldsForPhantom(fieldList) {
    //
    // fieldList: [object]
    // object: {
    //     objNumber: '',
    //     pageIndex: ''
    // }
    //
    //
    //
    try {
        // window.external.DispatchFun('ESignCNBase', 'ESignCNDeleteFields', JSON.stringify(fieldList));
        return apiCallFn('ESignCNBase', 'ESignCNDeleteFields', JSON.stringify(fieldList))
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNDeleteFields', error: e })
    }
}



export async function getDocInfoForPhantom() {
    try {
        if (shouldCallPhantomApi()) {
            // const info = window.external.DispatchFun('ESignCNBase', 'ESignCNGetDocInfo', '');
            const info = await apiCallFn('ESignCNBase', 'ESignCNGetDocInfo', '')
            return info ? JSON.parse(info) : {}
        } else {
            return {fileSize: 1204 * 40, uuid: 123};
        }
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNGetDocInfo', error: e })
    }
}

export function setCurReceiptItemConfigInfoForPhantom(data) {
    let color, isOrg;
    if (data) {
        color = data.color;
        isOrg = data.isOrg
    }
    try {
        // window.external.DispatchFun('ESignCNSignFlow', 'ESignCNSetCurReceiptItemConfigInfo', data ? JSON.stringify({
        //     isOrg,
        //     color
        // }) : "");
        return apiCallFn('ESignCNSignFlow', 'ESignCNSetCurReceiptItemConfigInfo', data ? JSON.stringify({
            isOrg,
            color
        }) : "")
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'ESignCNSetCurReceiptItemConfigInfo', error: e })
    }
}

export function showShareOnlineDocDialogForPhantom(params) {
    try {
        apiCallFn('ESignCNSignRecord', 'ESignCNShowQrCodeDialog', JSON.stringify(params));
    } catch (e) {
        // exceptionLog, error: e(e)
        exceptionLog({ api: 'showShareOnlineDocDialogForPhantom', error: e })
    }
}

export function invalidSignFlowForPhantom(data) {
    try {
        return apiCallFn('ESignCNSignRecord', 'ESignCNInvalidSignFlow', JSON.stringify(data));
    } catch (e) {
        exceptionLog({ api: 'ESignCNInvalidSignFlow' })
    }
}

export function updateUserInfoForPhantom(params) {
    try {
        return apiCallFn('ESignCNBase', 'ESignCNUpdateUserInfo', JSON.stringify(params));
    } catch (e) {
        // exceptionLog(e)
        exceptionLog({ api: 'ESignCNUpdateUserInfo' })
    }
}

export function refreshWebForPhantom(params) {
    try {
        return apiCallFn('ESignCNBase', 'ESignCNRefreshWeb', JSON.stringify(params));
    } catch (e) {
        // exceptionLog(e)
        exceptionLog({ api: 'ESignCNRefreshWeb' })
    }
}

export function notifyAuthResultForPhantom(params) {
    try {
        return apiCallFn('ESignCNAuth', 'ESignCNUserAuthResult', JSON.stringify(params));
    } catch (e) {
        // exceptionLog(e)
        exceptionLog({ api: 'ESignCNUserAuthResult' })
    }
}

export function logoutForPhantom() {
    try {
        return apiCallFn('ESignCNBase', 'ESignCNLogout', '');
    } catch (e) {
        // exceptionLog(e)
        exceptionLog({ api: 'ESignCNLogout' })
    }
}

export function resizeDialogForPhantom(params) {
    try {
        return apiCallFn('ESignCNBase', 'ESignCNResizeDialog', JSON.stringify(params));
    } catch (e) {
        // exceptionLog(e)
        exceptionLog({ api: 'ESignCNResizeDialog' })
    }
}

export function chooseFileForSignFLowForPhantom() {
    try {
        return apiCallFn('ESignCNSignRecord', 'ESignCNChooseFileForFlowSign', '');
    } catch (e) {
        exceptionLog({ api: 'ESignCNChooseFileForFlowSign' })
    }
}

export function openPagingSealSigSettingDialogForPhantom(params) {
    try {
        return apiCallFn('ESignCNSignFlow', 'ESignCNSetPagingSealSignatureDialog', JSON.stringify(params));
    } catch (e) {
        exceptionLog({ api: 'ESignCNSetPagingSealSignatureDialog' })
    }
}

export function openSignDateDialogForPhantom() {
    try {
        return apiCallFn('ESignCNSignFlow', 'ESignCNOpenSignDateSettingDialog', '');
    } catch (e) {
        exceptionLog({ api: 'ESignCNOpenSignDateSettingDialog' })
    }
}

export function notPromptAuthPromotionAgain() {
    try {
        return apiCallFn('ESignCNAuthPromotion', 'ESignCNNotPromptAuthPromotionAgain', '');
    } catch (e) {
        exceptionLog({ api: 'ESignCNNotPromptAuthPromotionAgain' })
    }
}

export function hideDialogPositionForPhantom() {
    try {
        return apiCallFn('ESignCNBase', 'ESignCNHideDialog', JSON.stringify(''));
    } catch (e) {
        exceptionLog({ api: 'ESignCNHideDialog' })
    }
}


export function setDeadlineCCInfoForPhantom(data) {
    try {
        return apiCallFn('ESignCNSignFlow', 'ESignCNSetDeadlineCCInfo', JSON.stringify(data));
    } catch (e) {
        exceptionLog({ api: 'ESignCNSetDeadlineCCInfo' })
    }
}

export function createTemporarySealForPhantom({ type, base64, width, height, scale, unit }) {
    console.log('createTemporarySealForPhantom params, ', type, base64, width, height, unit)
    try {
        const params = { type, base64 };
        if (width && height && unit) {
            params.width = width + '';
            params.height = height + '';
            params.unit = unit;
            params.scale = 1;
        } else if (scale) {
            params.scale = scale
        }
        console.error('params', params)
        return apiCallFn('ESignCNStamp', 'ESignCNCreateTemporarySeal', JSON.stringify(params));
    } catch (e) {
        exceptionLog({ api: 'ESignCNCreateTemporarySeal' })
    }
}

export function getTemporarySealListForPhantom({ type }) {
    try {
        return apiCallFn('ESignCNStamp', 'ESignCNGetTemporarySealList', JSON.stringify({ type }));
    } catch (e) {
        exceptionLog({ api: 'ESignCNGetTemporarySealList' })
    }
}

export function refreshGalleryForPhantom({ type }) {
    try {
        return apiCallFn('ESignCNStamp', 'ESignCNRefreshGallery', JSON.stringify({ type }));
    } catch (e) {
        exceptionLog({ api: 'ESignCNRefreshGallery' })
    }
}

export function setCacheForPhantom({ type, key, data }) {
    // type 1: 临时缓存，2: 持久缓存
    try {
        return apiCallFn('ESignCNBase', 'ESignCNSetCache', JSON.stringify({ type, key: encodeURIComponent(key) , data: JSON.stringify(data) }));
    } catch (e) {
        return Promise.resolve('') // 为了兼容不支持该API的客户端插件版本
    }
}

export function getCacheForPhantom({ key }) {
    try {
        return apiCallFn('ESignCNBase', 'ESignCNGetCache', JSON.stringify({key: encodeURIComponent(key) }));
    } catch (e) {
        return Promise.resolve() // 为了兼容不支持该API的客户端插件版本
    }
}

export function removeCacheForPhantom({ key }) {
    try {
        return apiCallFn('ESignCNBase', 'ESignCNRemoveCache', JSON.stringify({key: encodeURIComponent(key) }));
    } catch (e) {
        return Promise.resolve('') // 为了兼容不支持该API的客户端插件版本
    }
}