const publicPageStore = {
    inPhantom: false,
    inOnline: false,
    versionArr: [],
    userInfo: {},
    pdfInfo: {},
    initFlowInfo: {
        hasInitStore: false,
        curReceiptsOrder: -1,
        showFormType: "configForm",
        configInfo: {
            taskName: '无标题',
            freeRectItem: false,
            saveType: 'online',
        },
        receipts: [],
        orderedFlow: false,
        userHasExpandBaseTitleArea: false,
        baseTitleIconExpand: true,
        flowTitleIconExpand: true,
    },
    sealsError: false,
    wardType: null,
    inSeals: [],
    outOrgSeals: [],
    outPersonSeals: [],
    subscriptionData: {},
    esignRecord: {
        currentActiveTab: ''
    }
}

export default publicPageStore;