import {getParams} from "../../../../utils";
import {PHANTOM_STAMP_TYPE} from "../../constant";
import PhantomOutSealAdmin from "./outSealAdmin";
import CreateInSealsV2 from "./createInSeals";

const PHANTOM_OUT_ADMIN = [PHANTOM_STAMP_TYPE.OUT_ORG_ADMIN, PHANTOM_STAMP_TYPE.OUT_PERSON_ADMIN, PHANTOM_STAMP_TYPE.OUT_PERSON_SEAL_CREATE]
export default function PhantomStampV2() {

    const params = getParams();

    // pst: 1: 对外企业印章管理 2: 对外个人印章管理 3：对外个人创建永久印章  default: 创建对内印章 或 对外临时章
    const phantomStampType = params ? params['pst'] : ''
    if (PHANTOM_OUT_ADMIN.includes(phantomStampType)) {
        return <PhantomOutSealAdmin />
    }

    return (
        <CreateInSealsV2/>
    )
}