import {base64ToArrayBuffer} from "../../../utils";
import {certSignFileDigest} from "../../../request/esign";
import {TOKEN} from "./index";
import {getQueryVariable} from "../service";
import {errorMessage} from "../../components/Message";
import {getProcessInfo, handleTokenErrorException} from "./services";
import {blobToBase64, inPhantom} from "../utils/util";
import {downloadFileByBase64ForPhantom} from "../../../request/phantom";

const downLoadInPhantom = (fileName, blob) => {
    blobToBase64(blob, false).then(base64 => {
        downloadFileByBase64ForPhantom({ base64, fileName });
    })
}

const downLoadInOnline = (downloadName, blob) => {
    const blobUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = blobUrl;
    a.download = downloadName;
    a.click();
    window.URL.revokeObjectURL(blobUrl);
}

export function handleSignAudit(workerFetch, pdfDocRender) {
    let pdfFile;
    let pdfSignedDataOffset;
    let pdfBuffers = [];
    const signInfo = constructParams();
    return workerFetch.request('doc/cert/startSign', {
        doc: pdfDocRender.docId,
        signInfo,
    })
    .then(res => {
        const {file, hash, signedDataOffset} = res;
        pdfFile = file;
        pdfSignedDataOffset = signedDataOffset;
        const token = getQueryVariable(TOKEN) || '';
        const data = { hash, token };
        // 调用api签署接口，获取签名hash
        return certSignFileDigest(data);
    })
    .then(res => {
        const {signature} = res;
        // 调用pdf接口，保存签名hash
        return workerFetch.request('doc/fillSignedData', {
            doc: pdfDocRender.docId,
            signedDataOffset: pdfSignedDataOffset,
            file: pdfFile,
            signData: base64ToArrayBuffer(signature),
            progress: workerFetch.register(function (buffer) {
                pdfBuffers.push(buffer)
            })
        })
    })
    .then(() => {
        const processId = getQueryVariable('processId');
        return getProcessInfo(processId).then(info => {
            return info.taskName ? `${info.taskName}-签署报告.pdf` : '签署报告.pdf';
        })
    })
    .then((downloadName) => {
        const blob = new Blob(pdfBuffers, {type: "application/pdf"});
        if(inPhantom()) {
            downLoadInPhantom(downloadName, blob);
        } else {
            downLoadInOnline(downloadName, blob);
        }
    })
    .catch(e => {
        console.error(e)
        const isTokenError = handleTokenErrorException(e);
        if(!isTokenError) {
            errorMessage('文档下载失败，请重试！')
        }
    })
}

function constructParams () {
    const signInfo = {
        inkSign: [],
        pageIndex: 0,
        rect: [0, 0, 0, 0],
        signer: '',
    }
    return signInfo;
}
