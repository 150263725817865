import React, { Component } from 'react';
import { Tooltip } from 'antd';
import './index.less';
import {
    Modal,
    Button, Spin
} from 'antd';
import {getSealsInPlugin} from "../service";
import {getStore} from "../../../store";
import { observer } from 'mobx-react';
import {getOrgAdminUrl, getPersonAdminUrl} from "../../../request/esign";
import {closeESignCNDialogAPI, openUrlByBrowser, showESignCNDialogAPI} from "../../../request/phantom";
import {shouldCallPhantomApi} from "../config";
// import {loadingMessage} from "../../../../components/Message";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {
    ERROR_AC_ORG_PERMISSION_FAIL, ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED,
    ERROR_EQB_ORG_TOKEN_EXPIRED, ERROR_EQB_SEAL_PERMISSION_FAIL
} from "../../../request/error_container";
import {getPhantomVersion} from "../utils/util";

// const TOKEN_TIMEOUT_CODE = 610113;

@observer
export default class OutStampManageDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: true,
            promiseStatus: null,
            stampList: [],
            loading: false,
            orgList: null,
            loadingPersonAdminUrl: false,
            loadingOrgAdminUrl: false,
            fetchStampListError: false,
            outSignUserExpired: false
        };
        this.onCancel = this.onCancel.bind(this);
        this.showDialog = this.showDialog.bind(this);
        this.fetchStampList = this.fetchStampList.bind(this);
        this.enterPersonAdmin = this.enterPersonAdmin.bind(this);
        this.enterOrgAdmin = this.enterOrgAdmin.bind(this);

    }
    showDialog(noCheckSubscription) {
        this.fetchStampList(noCheckSubscription)
    }
    onCancel() {
        if (shouldCallPhantomApi()) {
            closeESignCNDialogAPI()
        } else {
            this.setState({
                visible: false
            })
        }
    }

    // listenOutStampUpdateEvent() {
    //     const { oustSignStampListOfCanUse } = store.esignCnStore;
    //     this.setState({
    //         stampList: oustSignStampListOfCanUse
    //     })
    // }

    async fetchStampList() {
        try {
            this.setState({
                loading: true,
                fetchStampListError: false,
                outSignUserExpired: false,
                visible: true
            })
            await getSealsInPlugin({ company: getStore('userInfo').enterpriseName, companyId: getStore('userInfo').enterpriseId})
        } catch (e) {
            console.log('getSealsInPlugin error', e)
            // if (e.stat) {
            //     const OUT_SIGN_SUBSCRIPTION_ERROR = e.msg && e.msg === 'OUT_SIGN_SUBSCRIPTION_ERROR' // 对外签署授权类错误，该错误不在此处处理
            //     if (!OUT_SIGN_SUBSCRIPTION_ERROR) {
            //         if (e.stat === TOKEN_TIMEOUT_CODE) {
            //             this.setState({
            //                 outSignUserExpired: true,
            //                 signatureArr: []
            //             })
            //         } else {
            //             this.setState({
            //                 fetchOutGalleryDataError: true,
            //                 signatureArr: []
            //             })
            //         }
            //     }
            // } else {
            //     console.error(e);
            // }
        } finally {
            this.setState({
                loading: false
            })
        }
    }

    async enterPersonAdmin() {

        try {
            if (this.state.loadingPersonAdminUrl) {
                return;
            }
            this.setState({
                loadingPersonAdminUrl: true
            })
            const url = await getPersonAdminUrl({ accountId: getStore('userInfo').accountId });
            const webUrl = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(url)}&wardType=0&resizeW=840&resizeH=600`
            showESignCNDialogAPI({ title: '个人印章管理', id: "ESIGNCN_PERSON_STAMP_MANAGE", type: 'Window', webUrl })
            // if (shouldCallPhantomApi()) {
            //     closeESignCNDialogAPI();
            // } else {
            //     this.setState({
            //         loadingPersonAdminUrl: false
            //     })
            // }
        } catch (key) {
            if (ERROR_EQB_AUTH_EXPIRED === key) {
                Modal.confirm({
                    title: '福昕电子签章服务',
                    icon: "",
                    content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的授权过期，请重新完成个人认证。</span></span>,
                    zIndex: 99999,
                    okText: "认证",
                    cancelText: "取消",
                    mask: false,
                    closable: true,
                    className: "eSignCN_funcDialog_container",
                    onOk() {
                        showESignCNDialogAPI({ title: '个人实名认证', id: 'ESIGNCN_PERSON_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?state=2&wardType=0`, width: 600, height: 600})
                    }
                })
            }
        }
        finally {
            this.setState({
                loadingPersonAdminUrl: false
            })
        }
    }
    async enterOrgAdmin() {
        try {
            if (this.state.loadingOrgAdminUrl) {
                return;
            }
            this.setState({
                loadingOrgAdminUrl: true
            })
            const url = await getOrgAdminUrl({ orgId: getStore('userInfo').orgId });
            const webUrl = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(url)}&wardType=0&resizeH=680&resizeW=960`
            showESignCNDialogAPI({ title: '企业印章管理', id: "ESIGNCN_ORG_STAMP_MANAGE", type: 'Window', webUrl })
            // if (shouldCallPhantomApi()) {
            //     closeESignCNDialogAPI();
            // }
        } catch (key) {
            const phantomVersion = getPhantomVersion();
            const mainVersion = phantomVersion[0];
            if (mainVersion === 12) {
                if (ERROR_EQB_AUTH_EXPIRED === key) {
                    Modal.confirm({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的授权过期，请重新完成企业认证/授权。</span></span>,
                        zIndex: 99999,
                        okText: "认证/授权",
                        cancelText: "取消",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container",
                        onOk() {
                            showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?orgName=${getStore('userInfo').enterpriseName}&wardType=0`})
                        }
                    })
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    Modal.confirm({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。</span></span>,
                        zIndex: 99999,
                        okText: "联系销售",
                        cancelText: "取消",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container",
                        onOk() {
                            openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales')
                        }
                    })
                } else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    // result.msg = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                    Modal.info({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您所在的企业授权过期，请联系管理员重新进行企业认证/授权。</span></span>,
                        zIndex: 99999,
                        okText: "确认",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container"
                    })
                } else if (ERROR_AC_PERMISSION_FAIL === key) {
                    Modal.info({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。</span></span>,
                        zIndex: 99999,
                        okText: "确认",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container"
                    })
                } else if (ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    Modal.info({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您目前没有企业签章管理权限，如需要管理请联系企业签章管理员。</span></span>,
                        zIndex: 99999,
                        okText: "确认",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container"
                    })
                }
            } else {
                if (ERROR_EQB_AUTH_EXPIRED === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_ORG_AUTH_CONFIRM",
                        msg: "您的授权过期，请重新完成企业认证/授权。"
                    })
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_APP_STORE_EXPIRE",
                        msg: "您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。"
                    })
                }
                else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    // result.msg = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_EXPIRE",
                        msg: "您所在的企业授权过期，请联系管理员重新进行企业认证/授权。"
                    })
                }
                else if (ERROR_AC_PERMISSION_FAIL === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_LACK",
                        msg: "您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。"
                    })
                } else if (ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_LACK",
                        msg: "您目前没有企业签章管理权限，如需要管理请联系企业签章管理员。"
                    })
                    // Modal.info({
                    //     title: '福昕电子签章服务',
                    //     icon: "",
                    //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您目前没有企业签章管理权限，如需要管理请联系企业签章管理员。</span></span>,
                    //     zIndex: 99999,
                    //     okText: "确认",
                    //     mask: false,
                    //     closable: true,
                    //     className: "eSignCN_funcDialog_container"
                    // })
                }
            }
            // if (e.data && e.data.code === 600062) {
            //     let eSignCode = e.data.msg.split('|')[1];
            //     const orgStampList = Array.isArray(getStore('outOrgSeals')) && getStore('outOrgSeals').length > 0 ? getStore('outOrgSeals').filter(item => item.usePermission) : []
            //     if (!orgStampList || orgStampList.length < 1) {
            //         eSignCode = "0"
            //     }
            //     let msg;
            //     switch (eSignCode) {
            //         case "0":
            //             msg = '您需要向您的企业章管理员申请用章权限。'
            //             break;
            //         case "31401007":
            //             msg = '您目前没有企业签章管理权限，如需管理请联系企业签章管理员。';
            //             break;
            //         case "1000000":
            //             msg = "您目前没有企业签章管理权限，如需管理请联系企业签章管理员。"
            //             break;
            //         case "10000001":
            //             msg = "系统异常，请重试或联系e签宝服务人员。"
            //             break;
            //         case "10000007":
            //             msg =  "参数错误"
            //             break;
            //         default:
            //             msg = "无法获取企业印章列表"
            //     }
            //     Modal.info({
            //         title: "PDF编辑器",
            //         icon: "",
            //         content: <span style={{display: 'flex', alignItems: 'center'}}><InfoCircleOutlined style={{fontSize: '22px', color: '#1890ff'}}/><span style={{flex: 1, marginLeft: '8px'}}>{msg}</span></span>,
            //         zIndex: 99999,
            //         okText: "确认",
            //         centered: true,
            //         cancelText: "取消"
            //     })
            // }
        }
        finally {
            this.setState({
                loadingOrgAdminUrl: false
            })
        }

    }

    componentDidMount() {
        if (window.location.pathname === '/stamp-manage' && getStore('plugin').wardType === 0) {
            document.body.setAttribute('name', 'out-stamp-manage-container')
        }
        window.eSignCN_refreshSeals = () => {
            this.fetchStampList()
        }
        // store.appStore.addAppEventListener(APP_CONSTANTS.EVENT_UPDATE_OUT_STAMPS, this.listenOutStampUpdateEvent)
    }

    componentWillUnmount() {
        // store.appStore.removeAppEventListener(APP_CONSTANTS.EVENT_UPDATE_OUT_STAMPS);
        // this.setState({
        //     promiseStatus: null,
        //     stampList: [],
        //     loading: false
        // })
    }
    render() {
        // const me = this;
        const outPersonSeals = getStore('plugin').outPersonSeals;
        const outOrgSeals = Array.isArray(getStore('plugin').outOrgSeals) && getStore('plugin').outOrgSeals.length > 0 ? getStore('plugin').outOrgSeals.filter(item => item.usePermission) : []
        const stampList = [...outPersonSeals, ...outOrgSeals]
        const {
            visible,
            loading,
            loadingPersonAdminUrl,
            loadingOrgAdminUrl,
            fetchStampListError
        } = this.state;
        const buttons = [
            <Button key="cancel" onClick={this.onCancel}>关闭</Button>
        ]
        const existPersonStamp = stampList.filter(item => !item.isOrg).length > 0;
        const existOrgStamp = outOrgSeals && outOrgSeals.length > 0;

        const stampListEle = stampList.map(item => {
            return (
                <div key={item.sealId} className={item.isOrg ? "sign-cn-stamp-manage__stamp-container org-stamp" : "sign-cn-stamp-manage__stamp-container"} style={{backgroundImage: `url(${item.base64})`}}>
                    {
                        item.alias
                        &&
                        (<Tooltip title={item.alias} placement='bottom' getPopupContainer={triggerNode => triggerNode.parentNode}>
                            <div className='sign-cn-stamp-manage__stamp-type stamp-alias' tooltip={item.alias}>{item.alias}</div>
                        </Tooltip>)
                    }
                </div>
            )
        })

        const className = getStore('plugin').inPhantom ? 'sign-cn-stamp-manage-dialog out-stamp-manage--dialog' : 'sign-cn-stamp-manage-dialog'


        return (
            visible &&
            <Modal
                zIndex="99999"
                title='对外签章管理'
                open={visible}
                className={className}
                width={600}
                centered
                footer={buttons}
                onCancel={this.onCancel}>
                <section style={{height: '343px'}}>
                    <div className="stamp-manage__header">
                        <div className="stamp-manage__header-left">
                            {/*<div className="stamp-manage__refresh-btn btn-color" onClick={this.fetchStampList}>刷新列表</div>*/}
                            <span>如管理后签章未自动更新，请手动<span className="stamp-manage__refresh-btn btn-color" onClick={this.fetchStampList}>刷新列表</span></span>
                        </div>
                        {
                            existPersonStamp &&
                            <div className='stamp-manage__header-right'>
                                <div className={loadingPersonAdminUrl ? 'admin-btn btn-color' : 'admin-btn btn-color btn-hover'} onClick={this.enterPersonAdmin}>
                                    {loadingPersonAdminUrl ? <i className='icon setting-icon'></i> : '管理个人章'}
                                </div>
                                {
                                    existOrgStamp &&
                                    <div className='org-admin-btn-wrap'>
                                        <span className='stamp-manage__line'>|</span>
                                        <div className={loadingOrgAdminUrl ? 'admin-btn btn-color' : 'admin-btn btn-color btn-hover'} onClick={this.enterOrgAdmin}>
                                            {loadingOrgAdminUrl ? <i className='icon setting-icon'></i> : '管理企业章'}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                    <section className="stamp-manage-container">
                        { loading && <Spin className="spin-loading" />}
                        { !loading && !fetchStampListError && stampList && stampList.length > 0 && stampListEle}
                        { !loading && !fetchStampListError && (!stampList || stampList.length < 1) && <div className='stamp-list-msg'>该账号暂无印章</div>}
                        { !loading && fetchStampListError && <div className='stamp-list-msg'>获取签章失败<div className='admin-btn btn-color btn-hover' onClick={this.fetchStampList}>请重新获取</div></div> }
                    </section>
                </section>
            </Modal>
        )
    }
}

