import {AUDIT_TYPE} from "./services";


export const flex1 = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

export const flex2 = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
}

export const eSignCNAuditTrailContainer = {
    width: '100vw',
    minHeight: '100vh',
    backgroundColor: '#FFF',
    textAlign: 'center',
    // display: 'flex',
    // flexDirection: 'column'
}

const eSignCNAuditTrailContainerHeader = {
    width: '980px',
    padding: '10px 0',
    margin: '0 auto',
    marginBottom: '20px',
    boxSizing: 'border-box',
    textAlign: 'center'
}

export const genAuditTrailContainerHeader = (type) => {
    let style = eSignCNAuditTrailContainerHeader;
    if (type === AUDIT_TYPE.WEB) {
        style = { ...style, padding: '10px 0'}
    }
    return style;
}

export const eSignCNAuditTrailContainerHeaderSvg = {
    float: 'left',
    marginTop: '8px'
}

export const eSignCNAuditTrailContainerHeaderTitle = {
    fontSize: '30px',
    fontWeight: '700'
}


let eSignCNAuditTrailMain = {
    flex: '1',
    // display: 'flex',
    // flexDirection: 'column',
    width: '980px',
    backgroundColor: '#fff',
    padding: '20px 30px',
    margin: '0 auto',
}

export const getMainStylesFn = (type) => {
    if (type === AUDIT_TYPE.PDF) {
        return { ...eSignCNAuditTrailMain, padding: '20px 30px 0 30px' }
    }
    return eSignCNAuditTrailMain;
}

export const eSignCNAuditTrailSection = {
    marginBottom: '30px',
}

export const eSignCNAuditTrailSectionFirstDiv = {
    fontSize: '21px',
    fontWeight: '700',
    color: '#2F2F2F',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
}

export const eSignCNAuditTrailWrapper = {
    color: '#262626',
    backgroundColor: '#F5F5F5',
    padding: '15px 15px',
}

export const marginBottom10 = {
    marginBottom: '10px'
}

export const padding10$0 = {
    padding: '3px 0'
}

export const fontWeight700 = {
    fontWeight: 700
}

export const eSignCNAuditTrailContent1Item = {
    height: '52px',
    display: 'flex',
    justifyContent: 'flex-start',
    // paddingBottom: '1px',
}

export const eSignCNAuditTrailContent1ItemLabel = {
    backgroundColor: '#CD89CE',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 700,
    width: '15%',
    position: 'relative',
    textAlign: 'center'
}

export const genContent1ItemLabelFn = (type, index, tableMapLength) => {
    if (type === 'label') {
        let styles = { ...eSignCNAuditTrailContent1ItemLabel, ...fontWeight700 };
        if (index === 0) {
            styles = { ...styles, ...borderTopLeftRadius8 };
        }
        if (index === tableMapLength - 1) {
            styles = { ...styles, ...borderBottomLeftRadius };
        }
        if (index % 2 === 0) {
            styles = { ...styles, backgroundColor: '#A661A8'};
        }
        return styles;
    } else if (type === 'value') {
        let styles = eSignCNAuditTrailContent1ItemValue;
        if (index % 2 === 0) {
            styles = { ...styles, backgroundColor: '#ededed'};
        }
        if (index === 0) {
            styles = { ...styles, ...borderTopRightRadius };
        }
        if (index === tableMapLength - 1) {
            styles = { ...styles, ...borderBottomRightRadius };
        }
        return styles;
    }
}


export const eSignCNAuditTrailContent1ItemLabelBox = {
    width: '84px',
    height: '37px',
    margin: '7px 0',
    display: 'inline-block'
}

export const genContent1PrimaryLabelStyleFn = (hasSubLabel) => {
    let styles = { textAlign: 'left' }
    if (!hasSubLabel) {
        styles = { ...styles, lineHeight: '37px' }
    }
    return styles;
}

export const eSignCNAuditTrailContent1ItemSubLabel = {
    fontWeight: 'normal',
    fontSize: '12px',
    textAlign: 'left'
}

export const borderTopLeftRadius8 = {
    borderTopLeftRadius: '8px',
}

export const borderBottomLeftRadius = {
    borderBottomLeftRadius: '8px'
}

export const borderTopRightRadius = {
    borderTopRightRadius: '8px'
}

export const borderBottomRightRadius = {
    borderBottomRightRadius: '8px'
}

export const eSignCNAuditTrailContent1ItemValue = {
    backgroundColor: '#f5f5f5',
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-around',
    padding: '15px',
    color: '#000',
    fontSize: '14px',
    flex: 1,
}

export const genContent2Box = (index) => {
    let styles = {
        padding: '10px 8px',
        borderBottom: '1px #fff solid'
    }
    return index % 2 === 0 ? { ...styles, backgroundColor: '#f5f5f5' } : { ...styles, backgroundColor: '#ededed' };
}

const eSignCNAuditTrailContent3Container = {
    gridRowGap: '20px',
    gridColumnGap: '20px',
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: '1fr 1fr',
}

export const genContent3Box = (length) => {
    return length === 1 ? { display: 'flex' } : eSignCNAuditTrailContent3Container
}

export const eSignCNAuditTrailContent3Title = {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#f0d9f0',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    color: '#2c2c2c',
    fontSize: '16px',
    padding: '10px 15px',
}

export const eSignCNAuditTrailContent3Item = {
    display: 'flex',
    justifyContent: 'flexStart',
    color: '#262626'
}

export const genContent3ItemStyleFn = (index) => {
    let styles = {
        ...eSignCNAuditTrailContent3Item,
        padding: '10px 15px',
        borderBottom: '1px #fff solid'
    }
    return index % 2 === 0 ? { ...styles, backgroundColor: '#f5f5f5' } : { ...styles, backgroundColor: '#ededed' };
}

export const eSignCNAuditTrailContent4Icon = {
    width: '16px',
    height: '16px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '16px 16px',
    backgroundPosition: 'center',
    marginRight: '10px',
    verticalAlign: 'text-bottom'
}