import React from "react";
import PcViewer from "./pcViewer";
import MobileViewer from "./mobileViewer";
import PhantomViewer from "./phantomViewer";
import {joinOrg} from "../../../request/user";

export default function JoinOrg(props) {
    const {client, token} = props.querys;
    const {joinOrgId, userEmail} = props.userInfo;
    if (!token || !joinOrgId) {
        return;
    }

    const childProps = {
        enterpriseId: joinOrgId,
        token,
        email: userEmail,
        submitFn: submit,
        ...props
    }

    return (
        <>
            {client === 0 && <PcViewer {...childProps} />}
            {client === 1 && <MobileViewer {...childProps} />}
            {client === 2 && <PhantomViewer {...childProps} />}
        </>
    );
}

function submit({isOut, enterpriseId, comment, token, processId}) {
    if (!comment || comment.length > 50) {
        return Promise.resolve(-1);
    }
    return joinOrg(isOut, enterpriseId, comment, token, processId).then(() => {
       return 1;
    }).catch(() => {
        return -2;
    });
};

