import {action, observable} from "mobx";
import publicPageStore from "../pages/PublicPage/store";

const globalStore = observable({
    userStatus: 0, // 0未登录，1已登录，2已实名 3已企业实名 4查看，5编辑
    userRefuse: 0, // -1 姓名不对，-2邮箱不对，-3手机号不对，-5手机号不存在，-4企业名不对
    configUrls: {},
    serverPdfInfo: null,
    userInfo: null,
    esignPerson: {},
    esignOrg: {},
    plugin: publicPageStore,
    useResHandle: true, // 是否使用ajax请求返回全局处理函数
    ccDenied: false,
})

export const getStore = (key) => {
    return globalStore[key];
}


export const setStore = (key, val) => {
    if (Object.keys(globalStore).includes(key)) {
        action(() => {
            globalStore[key] = val;
        })();
    }
}
