import {getStore} from "../../../store";
import {getSignFlowInfo} from "../../../request/esign";
import dayjs from "dayjs";
import {openUrlByBrowser, showESignCNDialogAPI} from "../../../request/phantom";
import {getPhantomVersion} from "../utils/util";

export function _initOutSign(pdfInfo) {
    // this.getPDFUI().customs.signESignProcessMessage('process', '正在获取印章列表…'); // 加载时不允许点击field
    // this.$loading.show();
    // this.$msg.hide();
    // this.$content.hide();
    // this.$submit.hide();
    // this.person = null;
    // this.orgList = null;
    //
    // let res = await this.getPDFUI().customs.verifyUserInformation();
    // if (res.stat > 0) {
    //     _render(-1);
    //     return;
    // }

    // const data = res.data;
    // if (!data || !data.person || !data.person.accountId) {
    //     _render(-2);
    //     return;
    // }
    if (!getStore('esignPerson') || !getStore('esignPerson').accountId) {
        return _render({ error: -2, pdfInfo });
    }

    // const temps = this.controller.list.filter(item => item.isMyself);
    // if (!temps || temps.length < 1) {
    //     _render();
    //     return;
    // }
    // const myItem = temps[0];
    const myItem = pdfInfo.myItem
    if (!myItem || Object.keys(myItem).length < 1) {
        return _render({pdfInfo})
    }


    // if (data.person.name !== myItem.userName) {
    //     const initInfo = await this.currentPDFDoc.getFlowSignInfo();
    //     _render(-3, initInfo.initiator.name);
    //     return;
    // }
    if (getStore('esignPerson').name !== myItem.userName) {
        return _render({error: -3, pdfInfo});
    }

    // this.person = data.person;
    // if (myItem.company.length < 1) {
    //     res = await this._getStampList(data.person);
    //     if (!res) {
    //         _render(-1);
    //         return;
    //     }
    //     _render();
    //     return;
    // }
    if (!myItem.company) {
        if (!getStore('plugin').outPersonSeals || getStore('plugin').outPersonSeals.length < 1) {
            return _render({ error: -1, pdfInfo });
        }
        return _render({pdfInfo});
    }


    // if (!this.signUserInfo.enterpriseName) {
    //     res  = await this.getPDFUI().customs.getESignerUserInfo(true);
    //     if (res.stat > 0) {
    //         _render(-1);
    //         return;
    //     }
    //     this.signUserInfo = res.data;
    //     if (!this.signUserInfo.enterpriseName) {
    //         _render(-4);
    //         return;
    //     }
    // }
    if(!getStore('userInfo').enterpriseName) {
        const phantomVersion = getPhantomVersion();
        if (phantomVersion && phantomVersion[0] > 12) {
            if(!getStore('userInfo').userEmail) {
                return _render({ error: -9, pdfInfo });
            }
        }
        return _render({ error: -4, pdfInfo});
    }


    // if (!data.orgList || data.orgList.length < 1) {
    //     _render(-5);
    //     return;
    // }
    if (!getStore('plugin').outOrgSeals || getStore('plugin').outOrgSeals.length < 1) {
        return _render({ error: -5, pdfInfo});
    }

    // if (myItem.company !== data.orgList[0].name) {
    //     const initInfo = await this.currentPDFDoc.getFlowSignInfo();
    //     _render(-6, initInfo.initiator.name);
    //     return;
    // }
    if(myItem.company !== getStore('esignOrg').name) {
        return _render({ error: - 6, pdfInfo });
    }

    // if (myItem.company !== this.signUserInfo.enterpriseName) {
    //     const initInfo = await this.currentPDFDoc.getFlowSignInfo();
    //     _render(-7, initInfo.initiator.name);
    //     return;
    // }
    if (myItem.company !== getStore('userInfo').enterpriseName) {
        return _render({ error: - 7, pdfInfo });
    }

    // this.orgList = data.orgList;
    // res = await this._getStampList(data.person, data.orgList);
    // if (!res) {
    //     _render(-1);
    //     return;
    // }
    return _render({ pdfInfo });
}

export function initInSign(pdfInfo) {
    return _render({ error: 0, pdfInfo })
}

export function _render({error = 0, pdfInfo}) {
    // this.getPDFUI().customs.signESignProcessMessage('finished');
    // this.controller.digest();
    // this.$msg.empty();

    // const i18n = (txt) => {
    //     return this.getPDFUI().i18n.t(txt);
    // };
    // const $link = $('<a>');
    let msg;
    let linkText, linkFn;
    const phantomVersion = getPhantomVersion();
    switch (error) {
        case -1:
            msg = "网络错误或超时";
            linkText = "点击重新获取数据"
            linkFn = () => _initOutSign(pdfInfo);
            // $link.click(() => this._initOutSign());
            // $link.text(i18n("e-sign-china:stampList.initRefresh2"));
            break;
        case -2:
            msg = "您尚未完成个人实名认证";
            linkText = "点击认证";
            linkFn = () => showESignCNDialogAPI({ title: '个人实名认证', id: 'ESIGNCN_PERSON_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?state=2&wardType=0&from=signFlowPanel&t=${Date.now()}`, width: 600, height: 600})
            // msg = i18n("e-sign-china:stampList.personAuthEmpty");
            // $link.click(() => this._openAuth());
            // $link.text(i18n("e-sign-china:stampList.companyAuth"));
            break;
        case -3:
            msg = `您的实名名称与签署流程中要求的名称不一致。请联系签章发起人${pdfInfo.initiator.name}。`
            // msg = i18n("e-sign-china:stampList.badName");
            // msg = msg.replace('${1}', initName);
            break;
        case -4:
            if(phantomVersion && phantomVersion[0] < 13) {
                msg = '您当前登录的账号未加入企业组织，需联系销售为您所在的企业创建企业信息； 若您的企业已在福昕电子签章服务创建企业信息，请联系IT管理员为您加入组织，并分配对外签署服务的使用权限。';
                linkText = '联系销售';
                linkFn = () => openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales');
                // $link.click(() => window.open('https://www.foxitsoftware.cn/esign#sales'));
                // $link.text(i18n("e-sign-china:buttons.contactSales"));
                // msg = i18n("e-sign-china:stampList.isNotTenantAccount");
                break;
            } else {
                const orgName = pdfInfo.myItem.company;
                msg = '您当前是使用个人身份登录，请';
                linkText = '完善企业信息';
                linkFn = () => showESignCNDialogAPI({
                    title: "PDF编辑器",
                    id: 'ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO',
                    type: "Window",
                    webUrl: `${window.location.origin}/config-out-sign-org-info?wardType=0&orgName=${orgName}&from=signFlowPanel&t=${new Date().getTime()}`,
                    width: 1000, height: 625
                });
                break;
            }
        case -5:
            msg = "您需要先进行企业认证/授权，";
            linkText = "点击认证/授权";
            linkFn = () => {
                const orgName = getStore('userInfo').enterpriseName;
                showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?orgName=${orgName}&wardType=0&from=signFlowPanel&t=${Date.now()}`})
            }
            // msg = i18n("e-sign-china:stampList.companyAuthEmpty");
            // $link.click(() => this._openAuth(true));
            // $link.text(i18n("e-sign-china:stampList.companyAuth"));
            break;
        case -6:
        case -7:
            msg = `您的企业名称与签署流程中要求的名称不一致。请联系签章发起人${pdfInfo.initiator.name}。`
            // msg = i18n("e-sign-china:stampList.badName2");
            // msg = msg.replace('${1}', initName);
            break;
        case -8:
            msg = "对应的签署方，存在签署流程外的签署行为，需按照流程要求重新签署。";
            // msg = i18n("e-sign-china:flow.illegalFillBehavior");
            break;
        case -9:
            msg = "请完善您福昕账户中的邮箱地址。";
            linkText = "立即完善";
            // linkFn = () => {this.bindPhoneConfirm(unFinishItem.email, unFinishItem.userName, false)}
            linkFn = () => {
                showESignCNDialogAPI({ title: '完善邮箱', id: 'ESIGN_CN_ADD_MOBILE', type: 'Window', webUrl: `${window.location.origin}/add-email-phantom?wardType=0&from=signFlowPanel&forJoinAcOrg=1&t=${Date.now()}`, width: 600, height: 600})
            }
            break;
        default:
            // const data = this.controller.list;
            const existIllegalFillBehavior = pdfInfo.receipts.some(item => item.illegalFillBehavior)
            if (existIllegalFillBehavior) {
                // this.controller.finished = true; // todo
                // msg = $(`<div><i class="illegal-fill-behavior-msg-icon"></i>${this.getPDFUI().i18n.t("e-sign-china:flow.illegalFillBehavior")}</div>`) todo 需要处理icon
                msg = "<i class=\"illegal-fill-behavior-msg-icon\"></i>对应的签署方，存在签署流程外的签署行为，需按照流程要求重新签署。";
            } else {
                // if (data.filter(item => item.isFinished).length === data.length) {
                if (pdfInfo.receipts.every(item => item.isFinished)) {
                    // msg = this.getPDFUI().i18n.t("e-sign-china:flow.finishedTips");
                    msg = "签署流程已全部完成";
                    // this.controller.finished = true; // todo
                    // 流程签署面板打开 Gallery置灰
                    // $('.gallery-mask').show();  // todo
                    // this.store.digest();
                } else {
                    // const unFinishItem = data.find(item => !item.isFinished);
                    // if (!unFinishItem) {
                    //     return;
                    // }
                    const unFinishItem = pdfInfo.receipts.find(item => !item.isFinished);
                    if (!unFinishItem) {
                        return;
                    }
                    // this.controller.finished = false; todo
                    // this.store.digest();
                    if (!getStore('userInfo').userMobile && unFinishItem.phoneNumber) { // 设置手机
                        // msg = this.getPDFUI().i18n.t("e-sign-china:flow.signTips3");
                        // $link.click(() => this.bindPhoneConfirm(unFinishItem.phoneNumber, unFinishItem.userName));
                        // $link.text(i18n("e-sign-china:flow.signTips3Btn"));
                        msg = "请完善您福昕账户中的手机号码，";
                        linkText = "立即完善";
                        // linkFn = () => {this.bindPhoneConfirm(unFinishItem.phoneNumber, unFinishItem.userName)}
                        linkFn = () => {
                            showESignCNDialogAPI({ title: '完善手机号', id: 'ESIGN_CN_ADD_MOBILE', type: 'Window', webUrl: `${window.location.origin}/add-mobile-phantom?from=signFlowPanel&t=${Date.now()}`, width: 600, height: 600})
                        }
                    } else if (!getStore('userInfo').userEmail && unFinishItem.email) {// 设置邮箱
                        // msg = this.getPDFUI().i18n.t("e-sign-china:flow.signTips4");
                        // $link.click(() => (unFinishItem.email, unFinishItem.userName, false));
                        // $link.text(i18n("e-sign-china:flow.signTips3Btn"));
                        msg = "请完善您福昕账户中的邮箱地址，";
                        linkText = "立即完善";
                        // linkFn = () => {this.bindPhoneConfirm(unFinishItem.email, unFinishItem.userName, false)}
                        linkFn = () => {
                            showESignCNDialogAPI({ title: '完善邮箱', id: 'ESIGN_CN_ADD_MOBILE', type: 'Window', webUrl: `${window.location.origin}/add-email-phantom?from=signFlowPanel&wardType=0&t=${Date.now()}`, width: 600, height: 600})
                        }
                    } else if (unFinishItem.isMine) {
                        // myItem = true;
                        // msg = this.getPDFUI().i18n.t("e-sign-china:flow.signTips2");
                        // msg = msg.replace('${1}', `<span style="font-weight:400;color:rgba(236, 142, 1, 1)">您</span>`);
                        msg = `请您完成<span style="font-weight:400;color:rgba(236, 142, 1, 1)">您</span>的签署区域`
                    } else {
                        // msg = this.getPDFUI().i18n.t("e-sign-china:flow.signTips");
                        // msg = msg.replace('${1}', `<span style="font-weight:400;color:rgba(236, 142, 1, 1)">${this.currentUserName}</span>`);
                        const currentUserName = pdfInfo.receipts[pdfInfo.currOrder].userName;
                        msg = `当前流程待<span style="font-weight:400;color:rgba(236, 142, 1, 1)">${currentUserName}</span>签署`
                    }
                }
            }
    }

    return { msg, linkText, linkFn }

    // this.$msg.html(msg);
    // if ($link.text()) {
    //     this.$msg.append($link);
    // }

    // this.$loading.hide();
    // this.$content.show();
    // this.$msg.show();
    // if (myItem) {
    //     this.$submit.show();
    // } else {
    //     this.$submit.hide();
    // }
}

export async function  formatPdfInfo(assignInfo) {
    if (!assignInfo || Object.keys(assignInfo).length < 1) {
        return null
    }
    const signType = getStore('plugin').wardType === 0 ? 'out' : 'in'
    let currentUserName = "";
    let currOrder = 0;
    // this.controller.list = [];
    // this.store.state.flowSignItems = [];
    // const pdfViewer = await this.getPDFUI().getPDFViewer();
    // pdfViewer.esignChinaFlowSignData = {};

    // const signType = await this.currentPDFDoc.getESignCNFlowSignMode();
    // this.controller.signType = signType === 'internal' ? 'in' : 'out';
    // const data = await this.currentPDFDoc.getAssignInfoForESignCN();
    const data = assignInfo;
    if (!data || !data.receipts || data.receipts.length < 1) {
        // this.store.trigger("getSignFlowItems"); todo
        // this.store.digest();
        return;
    }

    console.log('esign flow list: ', data);

    let {configInfo, receipts} = data;
    if (!configInfo || configInfo.docStore === 1) {
        receipts = []; // 在线文档，不允许在online内签署
    }

    // 获取签署信息
    const processId = configInfo.processId;
    // const servInfo = await this.getPDFUI().customs.getSignFlowInfo({ processId });
    const servInfo = await getSignFlowInfo({ processId })
    // if (servInfo.stat !== 0) {
    //     // this.getPDFUI().customs.signESignProcessMessage('error', "获取签署流程信息失败");
    //     return;
    // }
    let servRecipients = [];
    if (servInfo.process && servInfo.process.recipients) {
        servRecipients = servInfo.process.recipients.slice(1);
    }

    // this.controller.list = receipts;
    // this.store.state.flowSignItems = receipts;
    // this.store.trigger("getSignFlowItems"); todo
    // this.store.digest();

    let myItem = {};
    // 标注完成状态
    receipts.forEach((item,index) => {
        let finishCnt = 0;
        item.annots.forEach(annot => {
            annot.userName = item.userName;
            annot.company = item.company;
            annot.color = item.color;
            if (annot.fieldStatus === 2) { // 0未完成，2已完成 1签章域已放置印章
                finishCnt++;
            }
        });
        item.isFinished = item.annots.length === finishCnt;
        item.finishCnt = finishCnt;
        if (!item.isFinished && !currentUserName) {
            currentUserName = item.userName;
        }

        if (servRecipients[index] && servRecipients[index].status === '4') {
            let updated = parseInt(servRecipients[index].update_time) * 1000;
            item.updated = dayjs(updated).format('YYYY/MM/DD HH:mm:ss');
        }
        if (item.finishCnt === item.annots.length) {
            currOrder++
        }
    });


    // 找出未完成的且轮到自己的项
    // this.signUserInfo = await this.getPDFUI().customs.getESignerUserInfo();
    const signUserInfo = getStore('userInfo')
    let outSignUserInfo = getStore('esignPerson')
    // if (this.controller.signType  === 'out') {
    //     const res = await this.getPDFUI().customs.verifyUserInformation();
    //     if (res.stat !== 0) {
    //         console.error('verifyUserInformation error', res)
    //     }
    //     outSignUserInfo = res.data;
    // }

    let prevItem = null;
    receipts.forEach(item => {
        if (item.isFinished) {
            return false;
        }
        let checkCompanyState = true;
        if (signType  === 'in') {
            checkCompanyState = signUserInfo.enterpriseId === item.companyId;
        }
        const prevItemIsIllegalFillBehavior = prevItem && prevItem.illegalFillBehavior;
        // const outSignUserName = this.controller.signType === 'out' && outSignUserInfo.person && outSignUserInfo.person.name;

        let userName;
        if(signType === 'out') {
            userName = outSignUserInfo.name;
        } else {
            userName =  signUserInfo.fullname;
        }

        // 上个版本改得不对，更改isMine逻辑
        if (
            !prevItem && checkCompanyState && (
                (signUserInfo.userEmail && item.email && signUserInfo.userEmail.toLowerCase() === item.email.toLowerCase()) ||
                // (signUserInfo.userMobile && getUserMobile(signUserInfo.userMobile) === item.phoneNumber)
                (signUserInfo.userMobile && signUserInfo.userMobile === item.phoneNumber)
            ) && (signType  === 'in' || userName === item.userName)  && !prevItemIsIllegalFillBehavior
        ) {
            item.annots.forEach(annot => {
                item.finishCnt = 0;
                if (annot.fieldStatus > 0) { // 多了状态1
                    item.finishCnt++;
                }
                // item.showAnnots = true;
                item.isMine = true;
            });
            myItem = item;
            return true;
        }
        prevItem = item;
    });
    return { ...assignInfo, receipts, currOrder, myItem, cur_order: currOrder, recipients: receipts};
    // pdfViewer.esignChinaFlowSignData.all = allAnnots;
    // pdfViewer.esignChinaFlowSignData.mine = myAnnots;
    // pdfViewer.esignChinaFlowSignData.setBuffer = (id, buffer) => {
    //     this.buffers[id] = buffer;
    // };
    // pdfViewer.esignChinaFlowSignData.getUserMobile = getUserMobile;
    // pdfViewer.esignChinaFlowSignData.flowInit = () => this.renderFileInit();
}