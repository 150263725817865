import BaseComponent from "./BaseComponent";
import {FORMAT} from "./ADDITIONAL_ACTION_TYPE";
import MyDatePicker from "../datePicker";
import './index.less';
import '../datePicker/index.less';

export default class DateComponent extends BaseComponent{
    createDom () {
        const eDom = this.eDom = document.createElement('input');
        const field = this.field;

        eDom.classList.add('fx-field-dom');
        eDom.style.cursor = 'default';
        eDom.type = 'text';

        super.createDom();

        const myDatePicker = this.myDatePicker = new MyDatePicker();
        const dateDom = this.pickerDom = myDatePicker.init({
            format: this._parseDateFormat(field[FORMAT].javascript),
            callback: (value) => {
                this.valueChanged(value);
            }
        })
        this._setEdomPosition();
        this.eLabel.appendChild(dateDom);

        // if (field[FORMAT] && this.signCNOptions.isMine) {
        //     const dateFormat = this._parseDateFormat(field[FORMAT].javascript);
        //     if (dateFormat) {
        //         eDom.classList.add('date-input');
        //         this.eLabel.style.justifyContent = 'flex-end';
        //         //this.eLabel.style.backgroundColor = 'red';
        //         eDom.type = 'date';
        //         eDom.style.opacity = '0';
        //         this.addEle2Window(true);
        //     }
        //
        // }
    }

    bindEvent () {
        super.bindEvent();

        const clickFn = () => {
            if (!this.isMine) {
                return;
            }
            this.myDatePicker.show();
        };

        const stopFn = () => {
            if (!this.isMine) {
                return;
            }
            eDom.click()
        }



        const eDom = this.eDom;
        eDom.addEventListener('click', clickFn);
        this.releases.push(() => eDom.removeEventListener('click', clickFn));

        this.nameSpan.addEventListener('click', stopFn);
        this.releases.push(() => this.nameSpan.removeEventListener('click', stopFn));
    }

    _setEdomPosition () {
        const {left, top, width, height} = this.getRect();
        const pWidth = this.eLabel.parentNode.clientWidth;
        const pHeight = this.eLabel.parentNode.clientHeight;

        const cRight = pWidth - left - width;
        const cBottom = pHeight - top - height;

        const eWidth = 218;
        const eHeight = 281;

        this.pickerDom.style.width = eWidth + 'px';
        this.pickerDom.style.height = eHeight + 'px';

        if (cRight >= eWidth) {
            this.pickerDom.style.left = '2px';
        } else {
            this.pickerDom.style.right = '2px';
        }

        if (cBottom >= eHeight) {
            this.pickerDom.style.bottom = (-eHeight + 2) + 'px';
        } else {
            this.pickerDom.style.top = -(eHeight + 20) + 'px';
        }
    }

    _formatDate(value, fmt) {
        const date = new Date(value);
        if (isNaN(date.getTime())) {
            return '';
        }
        let ret;
        const opt = {
            "y+": date.getFullYear().toString(),
            "m+": (date.getMonth() + 1).toString(),
            "d+": date.getDate().toString(),
            "H+": date.getHours().toString(),
            "M+": date.getMinutes().toString(),
            "S+": date.getSeconds().toString()
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length === 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
    }

    _parseDateFormat (javascript) {
        if (javascript.indexOf('AFDate_Format') !== -1) {
            const reg = /AFDate_Format(?:Ex)?\("(.*?)"\)/;
            const match = javascript.match(reg);
            if (match && match.length === 2) {
                return match[1].trim();
            }
        }
        return '';
    }

}
