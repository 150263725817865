import BaseComponent from "./BaseComponent";
import './index.less'

export default class TextComponent extends BaseComponent{
    createDom () {
        const eDom = this.eDom = document.createElement('input');
        const field = this.field;

        eDom.classList.add('fx-field-dom');
        eDom.value = field.value;
        eDom.type = 'text';

        super.createDom();
    }

    bindEvent () {
        super.bindEvent();

        const eDom = this.eDom;
        const eLabel = this.eLabel;

        const setFontSize = () => {
            // 设置字体大小
            const len = eDom.value.replace(/[\u0391-\uFFE5]/g,"aa").length;
            const width = parseFloat(eLabel.style.width);
            const height = parseFloat(eLabel.style.height);
            let fontSize = 1.8 * width / len;

            if (fontSize > height - 12) {
                fontSize = height - 12;
            }
            fontSize = Math.floor(fontSize);

            // 兼容safari
            eDom.style.fontSize =  fontSize + 'px';
            eDom.style.height = height + 'px';
            eDom.style.lineHeight = fontSize + 'px';
            eDom.style.padding = '3px';
        }

        const focusFn = () => {
            if (!this.isMine) {
                return;
            }
            this.showDom();
            setFontSize()
        };

        const inputFn = () => {
            if (!this.isMine) {
                return;
            }
            setFontSize()
        }

        const blurFn = () => {
            if (!this.isMine) {
                return;
            }
            this.hideDom();
        };
        const changeFn = () => {
            if (!this.isMine) {
                return;
            }
            let value = eDom.value;
            this.valueChanged(value);
        };

        const stopFn = () => {
            if (!this.isMine) {
                return;
            }
            eDom.focus()
        }

        eDom.addEventListener('focus', focusFn);
        this.releases.push(() => eDom.removeEventListener('focus', focusFn));

        this.nameSpan.addEventListener('click', stopFn);
        this.releases.push(() => this.nameSpan.removeEventListener('click', stopFn));

        eDom.addEventListener('input', inputFn);
        this.releases.push(() => eDom.removeEventListener('input', inputFn));

        eDom.addEventListener('blur', blurFn);
        this.releases.push(() => eDom.removeEventListener('blur', blurFn));
        eDom.addEventListener('change', changeFn);
        this.releases.push(() => eDom.removeEventListener('change', changeFn));

    }

}
