/* eslint-disable */
/**!
 * Dragable 拖拽排序插件
 *
 * option = {
 * 	 draggable: '', // [必填] 允许拖拽的节点类名
 * 	 dragClass: '', // 正在被拖拽中的css类名
 * 	 chosenClass: '', // 被选中项的css 类名
 *   dragOverStrict: true, // 是否严格的dragover事件源, true: 事件源始终是 draggable参数指向的dom, false: 可以是当前拖拽经过的任何子节点
 *   endStrict: true, // onEnd将判断from和to是否为同一个dom,true：同一dom将不调用onEnd false: 不判断，onEnd都会调用
 *   inventedNode: null, // 自定义虚拟节点，null则使用默认，处于第一位
 * 	 onStart: '', // 拖拽开始事件
 *   onHover: '', // 拖拽悬停事件
 *   onEnd: ''// 拖拽结束事件
 * }
 *
 * 注意：可拖拽的节点尽量不要使用a标签，在IE下兼容不好
 */
function pluginFactory(plugnName) {
    'use strict';

    if (typeof window === "undefined" || !window.document) {
        return function plugnError() {
            throw new Error(plugnName + ".js requires a window with a document");
        };
    }

    // 事件绑定
    var EventListener = {
        add: function (el, eventName, executeFun, _this) {
            EventListener._eventFun = function (e) {
                executeFun && executeFun.call(_this, e);
            }
            el.addEventListener(eventName, EventListener._eventFun, false);
        },
        remove: function (el, eventName) {
            el.removeEventListener(eventName, EventListener._eventFun, false);
            EventListener._eventFun = undefined;
        }
    };

    // 获取指定父节点
    Node.prototype.parents = function (className) {
        var parentNode = this.parentNode;
        var hasClass = this.classList.contains(className);
        if (hasClass || parentNode.nodeName === 'BODY') return this;
        return parentNode.parents(className);
    }
    // 判断是否含有某个子节点
    Node.prototype.hasChildren = function (childNode) {
        var parentNode = childNode;
        while (parentNode) {
            if (this === parentNode) return true;
            parentNode = parentNode.parentNode;
        }
        return false;
    }

    // dataset封装，兼容IE
    var dataset = function (node, attrs) {
        if (!node) return ;
        var datasetVal = {};
        if (node.dataset) {
            datasetVal = node.dataset;
        } else {
            for (var i = 0;i < node.attributes.length;i++) {
                var item = node.attributes[i];
                if (item.name.indexOf('data-') === 0) {
                    var key = item.name.replace('data-', '');
                    var value = item.value;
                    datasetVal[key] = value;
                }
            }
        }

        for (var key in attrs) {
            var value = attrs[key];
            if (node.dataset) {
                node.dataset[key] = value;
            } else {
                node.setAttribute('data-' + key, value);
            }
        }
        return datasetVal;
    }
    // assign封装，兼容IE
    if (typeof Object.assign != 'function') {
        Object.prototype.assign = function (target) {
            if (target == null) {
                throw new TypeError('Cannot convert undefined or null to object');
            }
            target = Object(target);
            for (var index = 1; index < arguments.length; index++) {
                var source = arguments[index];
                if (source != null) {
                    for (var key in source) {
                        if (Object.prototype.hasOwnProperty.call(source, key)) {
                            target[key] = source[key];
                        }
                    }
                }
            }
            return target;
        }
    }

    // 插件主体
    function Plugn(el, options) {
        this.options = Object.assign({
            // 允许拖拽的节点类名
            draggable: '',
            // 正在被拖拽中的css类名
            dragClass: plugnName + '-drag',
            // 被选中项的css 类名
            chosenClass: plugnName + '-chosen',
            treeItemIconClass: 'tree-item-icon',
            // 是否严格的dragover事件源, true: 事件源始终是 draggable参数指向的dom, false: 可以是当前拖拽经过的任何子节点
            dragOverStrict: true,
            // 同一dom时，是否调用onEnd，true为不调用
            endStrict: true,
            // 自定义虚拟节点，不传则使用默认，处于第一位
            inventedNode: null,
            // 拖拽开始事件
            onStart: '',
            // 执行拖拽悬停事件前，返回false则不允许悬停
            onBeforeHover: '',
            // 拖拽悬停事件
            onHover: '',
            // 拖拽结束事件
            onEnd: '',
            allowDrag: true,
            // 不允许拖拽的节点类名
            excludeDraggable: '',
            // true：Enable drag and drop， false: disable drag and drop
            enabled: true,
            checkIsMoveToRight: () => {
                if (this.options.dragOverStrict) {
                    return false;
                }
                return this.end.classList.contains(this.options.treeItemIconClass)
            }
        }, options);

        if (!el) throw new Error("el must be a dom node: " + el);
        if (!this.options.draggable) throw new Error("draggable class cannot be a empty string: " + this.options.draggable);
        if (!this.options.enabled){return}
        this.el = el;
        this._bindEvent();
        this._appendStyle();
        // 缓存仓库
        this._cache = { overData: [], doms: [] };
    }

    Plugn.prototype = {
        // -------tool
        _addClass: function (dom, className, clearOther) {
            if (clearOther) {
                this._removeClass(className, dom);
            }
            dom.classList.add(className);
        },
        _removeClass: function (className, filterNode) {
            var _this = this;
            className = (className instanceof Array) ? className : [className];
            className.forEach(function (name) {
                var nodes = _this.el.querySelectorAll('.' + name);
                for (var index = 0;index < nodes.length;index++) {
                    var node = nodes[index];
                    if (filterNode && node === filterNode) continue;
                    node.classList.remove(name);
                }
            });
        },
        // 初始化dom节点的拖拽相关事件
        _initDragEvent: function (dom) {
            // document.ondrop=function(e){
            //     console.log('document.ondrop')
            //     e.stopPropagation();
            //     e.preventDefault();
            //
            //     //取消默认行为
            //
            //     return false;
            //
            // }
            // document.body.ondrop=function(e){
            //     e.stopPropagation();
            //     e.preventDefault();
            //
            //     //取消默认行为
            //
            //     return false;
            //
            // }
            // //拖拽处理
            // document.ondragover = function (e) {
            // }
            // document.body.ondragover = function (e) {
            // }

            if (!dom) return ;
            var _this = this;
            dom.draggable = this.options.allowDrag;
            dom.ondragstart = function (e) {
                if (!_this.options.enabled) {
                    e.preventDefault();
                    return;
                }
                _this._onDragstart.call(_this, e.target);
            };
            dom.ondragover = function (e) {
                e.preventDefault();
                var target = e.currentTarget;
				var ret =  _this.options.onBeforeHover && _this.options.onBeforeHover({
					dom: target,
					attrs: Object.assign(dataset(target), { text: target.innerText.trim() })
				});
				if (ret === false) return ;
                const info = {
                    mouseX: getClientX(e),
                    mouseY: getClientY(e)
                };

                e.preventDefault();
                _this.options.enabled && _this._onDragover.call(_this, e.target, info);
            };
            dom.ondragenter = function (e) {
                var target = e.currentTarget;
				var ret =  _this.options.onBeforeHover && _this.options.onBeforeHover({
					dom: target,
					attrs: Object.assign(dataset(target), { text: target.innerText.trim() })
				});
				if (ret === false) return ;

                e.stopPropagation();
                const info = {
                    mouseX: getClientX(e),
                    mouseY: getClientY(e)
                };
                _this.options.enabled && _this._onDragenter.call(_this, e.target, info);
            };
            dom.ondragleave = function (e) {
                const info = {
                    mouseX: getClientX(e),
                    mouseY: getClientY(e)
                };
                _this.options.enabled && _this._onDragleave.call(_this, e.target, info);
            };
            dom.ondragend = function (e) {
                e.stopPropagation();
                e.preventDefault();
                const info = {
                    mouseX: getClientX(e),
                    mouseY: getClientY(e)
                };
                _this.options.enabled && _this._onDragend.call(_this, e.target, info);
            };
            dom.ondrop = function (e) {
                // 阻止冒泡以及默认事件
                // 不让 online react层直接拖拽文件打开
                e.stopPropagation();
                e.preventDefault();
                _this.options.enabled && _this._onDrop.call(_this, {
                    target: e.target,
                    files: e.dataTransfer.files,
                    altKey: e.altKey,
                    ctrlKey: e.ctrlKey,
                    mouseX: getClientX(e),
                    mouseY: getClientY(e)
                });
            };
        },
        // 设置拖拽虚影
        _getDragImage: function () {
            var img = document.createElement('img');
            img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
            img.width = 0;
            img.height = 0;
            img.opacity = 0;
            return img;
        },
        // 设置1个虚拟节点，用于实现拖拽到第一个位置功能
        _setInventedNode: function () {
            var draggable = this.options.draggable;
            this._inventedNodeClassName = '_' + draggable;
            if (this.el.querySelector('.' + this._inventedNodeClassName)) return ;

            var node = this.options.inventedNode;

            if(!node) {
                node = document.createElement('div');
                var children = this.el.querySelectorAll('.' + draggable)
                var firstChild = children[0];
                var firstChildRect = firstChild.getBoundingClientRect()
                node.style.position = 'fixed';
                node.style.width = firstChildRect.width + 'px';
                node.style.height = parseInt(firstChildRect.height / 2) + 'px';
                node.style.top = firstChildRect.top + 'px';
                node.style.left = firstChildRect.left + 'px';
                node.style.transform = 'rotateZ(180deg)';
                node.style.borderWidth = '1px';
                node.style.overflow = 'hidden';
                node.style.backgroundColor = 'rgba(0, 0, 0, 0)';
                node.style.zIndex = '999';
                dataset(node, { id: '-1' });
                node.classList.add(draggable);
                node.classList.add(this._inventedNodeClassName);

                this._initDragEvent(node);
                this.el.insertBefore(node, this.el.childNodes[0]);
            } else {
                node.classList.add(draggable);
                node.classList.add(this._inventedNodeClassName);

                this._initDragEvent(node);
                this.el.insertBefore(node, this.el.lastChild.nextSibling);
            }

        },
        _removeInventedNode: function () {
            var inventedNode = this.el.querySelector('.' + this._inventedNodeClassName);
            if (inventedNode) {
                inventedNode.remove ? inventedNode.remove() : inventedNode.removeNode(true);
            }
        },
        _isTopest: function (event) {
            var elRect = this.el.getBoundingClientRect();
            return event.clientY - elRect.y < 12;
        },
        // 绑定初始事件
        _bindEvent: function () {
            EventListener.add(this.el, 'pointerdown', this._setDraggable, this);
            const getCurrentMouseOverDom = (event) => {
                let clientX, clientY;
                if(event instanceof TouchEvent) {
                    const touches = event.changedTouches || event.touches;
                    if(touches.length !== 1) {
                        return;
                    }
                    const touch = touches[0];
                    clientX = touch.clientX;
                    clientY = touch.clientY;
                } else if('clientX' in event) {
                    clientX = event.clientX;
                    clientY = event.clientY;
                }
                const element = document.elementFromPoint(clientX, clientY);
                return element.closest('.' + this.options.draggable);
            }
            if(isTouchDevice()) {
                let started = false;
                let startDom;
                let lastMoveOn;
                const onTouchStartEventListener = e => {
                    if(!this.options.enabled) {
                        return;
                    }
                    const dom = getCurrentMouseOverDom(e);
                    if(!dom) {
                        return;
                    }
                    startDom = dom;
                    lastMoveOn = null;
                    started = true;
                    document.addEventListener('pointerup', (e) => {
                        started = false;
                        this.end = lastMoveOn;
                        this._onDragend();
                        // this._onDrop.call(this, {
                        //     target: lastMoveOn,
                        //     files: [],
                        //     altKey: false,
                        //     ctrlKey: false
                        // });
                    }, {
                        once: true,
                        capture: true
                    });
                    const info = {
                        mouseX: getClientX(e),
                        mouseY: getClientY(e)
                    };
                    this._onDragstart.call(this, dom, info);
                    e.preventDefault();
                };
                const onTouchMoveEventListener = e => {
                    if(!started) {
                        lastMoveOn = undefined;
                        return;
                    }
                    e.preventDefault();
                    const info = {
                        mouseX: getClientX(e),
                        mouseY: getClientY(e)
                    };
                    const currentMouseOverDom = getCurrentMouseOverDom(e);
					if(!currentMouseOverDom){
						lastMoveOn = currentMouseOverDom;
						this.options.onHover && this.options.onHover({
							dom: currentMouseOverDom,
                            info
						});
						return
					}
                    if(currentMouseOverDom !== startDom) {
                        if(lastMoveOn !== currentMouseOverDom) {
                            if(currentMouseOverDom) {
                                this._onDragenter.call(this, currentMouseOverDom, info);
								setTimeout(()=>{
									this.end = currentMouseOverDom;
									this.setBlwAndBrw(currentMouseOverDom);
									this._addClass(currentMouseOverDom, this.options.chosenClass);
									var hoverAttrs = Object.assign(dataset(currentMouseOverDom), { text: currentMouseOverDom.innerText.trim() });
									// 不可在当前拖拽对象上进行悬停
									this.options.onHover && (currentMouseOverDom !== this.from) && this.options.onHover({
										dom: currentMouseOverDom,
										attrs: hoverAttrs,
                                        info
									});
								})
                            }
                            if(lastMoveOn) {
                                this._onDragleave.call(this, lastMoveOn, info);
                            }
                        }
                        currentMouseOverDom && currentMouseOverDom.ondragover(e);
                    }
                    lastMoveOn = currentMouseOverDom;
                };
                this.el.addEventListener('pointerdown', onTouchStartEventListener, {
                    capture: true,
                    passive: false
                });
                this.el.addEventListener('touchmove', onTouchMoveEventListener, {
                    capture: true,
                    passive: false
                });
                this.unbindEventOnDocument = () => {
                    this.el.removeEventListener('pointerdown', onTouchStartEventListener, {
                        capture: true,
                        passive: false
                    });
                    this.el.removeEventListener('mousemove', onTouchMoveEventListener, {
                        capture: true,
                        passive: false
                    });
                };
            }
        },
        // 添加样式
        _appendStyle: function () {
            this._style = document.createElement('style');
            // this._style.innerHTML =
            // '.' + this.options.chosenClass + ' {' +
            // 	'border-bottom: dashed 1px #000;' +
            // '}'
            // ;
            document.head.appendChild(this._style);
        },
        // 设置 draggable="true" 属性
        _setDraggable: function (e) {
            var children;

            if (this.options.draggable.trim()) {
                children = this.el.querySelectorAll('.' + this.options.draggable)
            }
            if (!children || children.length === 0) {
                children = this.el.children;
            }
            for (var i = 0;i < children.length;i++) {
                var dom = children[i];
                this._initDragEvent(dom);
            }
            this._cache.doms = children;

            if (!this.options.excludeDraggable) return ;
            var excludeDraggables = this.el.querySelectorAll('.' + this.options.excludeDraggable) || [];
            for (var i = 0;i < excludeDraggables.length;i++) {
                var dom = excludeDraggables[i];
                dom.draggable = false;
            }
        },
        _onDragstart: function (target, info) {
            var _this = this;
            setTimeout(function () {
                _this._setInventedNode();
            }, 0);
            this._addClass(target, this.options.dragClass, true);
            // 设置拖拽虚影不显示
            // e.dataTransfer.setDragImage && e.dataTransfer.setDragImage(_this._getDragImage(), 0, 0);

            this.end = null;
            this.from = target;
            var fromDraggableDom = target.parents(this.options.draggable);
            this.fromAttrs = Object.assign(dataset(fromDraggableDom), { text: fromDraggableDom.innerText.trim() });
            this.options.onStart && this.options.onStart({
                dom: this.from,
                attrs: this.fromAttrs,
                info
            });
        },
        _onDragover: function (mouseOverTarget, info) {
            const target = this._getTarget(mouseOverTarget);
            this.end = target;
            this.setBlwAndBrw(mouseOverTarget, info);
            this._addClass(target, this.options.chosenClass);
            if (this._cache.overData.indexOf(target) === -1) {
                this._cache.overData = [];
            }
            this._cache.overData.push(target);
            if (this._cache.overData.length === 20) {
                var hoverAttrs = Object.assign(dataset(target), { text: target.innerText.trim() });
                // 不可在当前拖拽对象上进行悬停
                this.options.onHover && (target !== this.from) && this.options.onHover({
                    dom: target,
                    attrs: hoverAttrs
                });
            }
        },
        _onDragenter: function (mouseOverTarget, info) {
            const target = this._getTarget(mouseOverTarget);
            this.end = target;
            this._addClass(target, this.options.chosenClass, true);
            this.setBlwAndBrw(mouseOverTarget, info);
        },
        _getTarget: function(eventTarget) {
            var target = this.options.dragOverStrict ? eventTarget.parents(this.options.draggable) : eventTarget;
            if (target.nodeName === '#text') {
                target = target.parentNode;
            }
            return target;
        },
        setBlwAndBrw: function(target, info) {
            let isMoveToRight = this.options.checkIsMoveToRight(target, info);

            if(isMoveToRight){
                this.end.style.setProperty('--blw','0')
                this.end.style.setProperty('--brw','2px')
            }else{
                this.end.style.setProperty('--blw','2px')
                this.end.style.setProperty('--brw','0')
            }
            this.isMoveToRight = isMoveToRight;
        },
        _onDragleave: function (e) {
            this._removeClass(this.options.chosenClass);
			this.end = null;
			this._cache.overData = [];
			this.options.onHover && this.options.onHover({
				dom: null
			});
        },
        _onDrop: function ({target, files, altKey, ctrlKey}) {
            const endDom = this.end;
            const endDraggableDom = endDom && endDom.parents(this.options.draggable);
            const endDomAttrs = endDraggableDom && Object.assign(dataset(endDraggableDom), { text: endDraggableDom.innerText.trim() });
            const isMoveToRight = this.isMoveToRight;
            this.options.onDrop && this.options.onDrop({
                endAttrs: endDomAttrs,
                dragFiles: files,
                isMoveToRight,
                target
            });
            if (!target.querySelector('.' + this.options.chosenClass)) {
                // 用于拖动后判断是否有同时按下键盘对应键
                this.end.altKey = altKey;
                this.end.ctrlKey = ctrlKey;
            }
        },
        _onDragend: function () {
            this._cache.overData = [];
            this._removeClass([this.options.chosenClass]);
            this._removeInventedNode();

            var endDom = this.end;

            var endDraggableDom = endDom && endDom.parents(this.options.draggable);
            var endDomAttrs = endDraggableDom && Object.assign(dataset(endDraggableDom), { text: endDraggableDom.innerText.trim() });

            if(endDraggableDom === this.from && this.options.endStrict) {
                return;
            }
            this.options.onEnd && this.options.onEnd({
                from: this.from,
                fromAttrs: this.fromAttrs,
                end: endDom,
                endAttrs: endDomAttrs,
                isMoveToRight: this.isMoveToRight
            });
        },
        // 销毁
        destory: function () {
            EventListener.remove(this.el, 'pointerdown');
            document.head.removeChild(this._style);
            this._cache.doms.forEach(function (dom) {
                dom.removeAttribute('draggable');
                dom.ondragstart = null;
                dom.ondragover = null;
                dom.ondragenter = null;
                dom.ondragleave = null;
                dom.ondragend = null;
                dom.ondrop = null;
            });
            this._cache = null;
        }
    };

    /**
     * Create plugn instance
     */
    Plugn.create = function (el, options) {
        return new Plugn(el, options);
    };

    // Export
    Plugn.version = '1.0.0';
    return Plugn;
}
const Draggable = pluginFactory('Draggable');

export default Draggable;
 function isTouchDevice() {
    let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    let mq = function(query) {
        if(!window.matchMedia) {
            return false;
        }
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window || navigator.maxTouchPoints) || window.DocumentTouch && document instanceof window.DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}

function getClientX(e) {
    if('clientX' in e) {//在ipad下，'clientX' in e返回true，但是e.clientX确返回undefined
        return e.clientX;
    } else {
        switch(e.type) {
            case 'touchstart':
            case 'touchmove':
                return e.touches[0].clientX;
            case 'touchend':
            case 'touchcancel':
                return e.changedTouches[0].clientX;
        }
    }
}

function getClientY(e) {
    if('clientY' in e) {
        return e.clientY;
    } else {
        switch(e.type) {
            case 'touchstart':
            case 'touchmove':
                return e.touches[0].clientY;
            case 'touchend':
            case 'touchcancel':
                return e.changedTouches[0].clientY;
        }
    }
}