export const WARD_TYPE = {
    all: -1,
    outSign: 0,
    inSign: 1
}

export const FREE_RECT_ITEM ={
    all:-1,
    specify:0,
    unspecify:1,
}

export const SEAL_TYPE = {
    outOrgSeal: 1,
    inPersonSeal: 2,
    outPersonSeal: 3
}

export const SIGN_TYPE = {
    all: -1,
    selfSign: 1,
    flowSign: 2,
}

export const SIGN_STATUS = {
    all: -1,
    created: 0,
    processing: 1,
    finished: 2,
    canceled: 3,
    expired: 4,
    rejected: 5,
    invaliding: 6,
    invalid: 7,
}