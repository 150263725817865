import {closeESignCNDialogAPI, closeESignCNDialogAPIV2} from "../../../request/phantom";
import {getQueryVariable, urlSecurityFilter} from "../service";
import {afterPluginVersion2023_3, removeLastSlash, truncateDomain} from "../utils/util";
import {resizeDialog} from "../Model";
import {useEffect} from "react";
import {getStore} from "../../../store";
import {E_QIAN_BAO_DOMAINS} from "../constant";

const receiveMessage = (msg) => {

    // 对于消息来源的域名做判断过滤
    const esignDomains = getStore('configUrls') || {};
    const pcDomain = esignDomains['newEsignCNpc'] || '';
    if (!E_QIAN_BAO_DOMAINS.includes(removeLastSlash(msg.origin))) {
        const truncateOrigin = truncateDomain(msg.origin);
        if (truncateOrigin !== removeLastSlash(pcDomain)) {
            return;
        }
    }

    if (!msg.data) {
        return;
    }
    const { type, targetUrl } = msg.data;
    if (type === 'close') {
        if (afterPluginVersion2023_3()) {
            const ESIGNCN_ORG_AUTH = 3;
            const ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG = 4;
            const ESIGNCN_PERSON_STAMP_MANAGE = 6;
            const ESIGNCN_PERSON_STAMP_CREATE = 7;
            const ESIGNCN_ORG_STAMP_MANAGE = 8;
            const ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO = 18;
            const closeDialogIds = [ESIGNCN_ORG_AUTH, ESIGNCN_ADD_OUT_SIGN_STAMP_DIALOG, ESIGNCN_PERSON_STAMP_MANAGE, ESIGNCN_PERSON_STAMP_CREATE, ESIGNCN_ORG_STAMP_MANAGE, ESIGNCN_OUT_AUTO_COMPLETE_ORG_INFO];
            closeESignCNDialogAPIV2(closeDialogIds);
        } else {
            closeESignCNDialogAPI()
        }
    } else if (type === 'switchToParentEnv') {
        const valid = E_QIAN_BAO_DOMAINS.some(domain => removeLastSlash(targetUrl).indexOf(domain) > -1);
        if (valid) {
            window.location.href = targetUrl;
        }
    } else {
        return;
    }
}

const adapter = (height) => height + 20;

export default function IframeDialog() {
    let targetUrl = getQueryVariable('targetUrl');
    targetUrl = urlSecurityFilter(targetUrl);
    window.addEventListener('message', receiveMessage);

    useEffect(() => {
        const width = getQueryVariable('resizeW');
        const height = getQueryVariable('resizeH');
        if (width && height) {
            const widthPt = parseInt(width);
            const heightPt = parseInt(height);
            resizeDialog({ width: widthPt, height: adapter(heightPt) });
        }
    }, [])

    return (
        <div className='eSignCN-iframe-container'>
            <iframe
                id='eSignCn' title="eSignCn"
                className="eSignCnIframe"
                src={targetUrl}/>
        </div>
    )
}