import axios from "axios";
import {getStore} from "../store";
import {errorMessage, loadingMessage} from "../pages/components/Message";
import {
    ERROR_AC_FAIL,
    ERROR_AC_ORG_PERMISSION_FAIL,
    ERROR_AC_PERMISSION_FAIL,
    ERROR_AC_TOKEN_FAIL,
    ERROR_EQB_FAIL,
    ERROR_EQB_ORG_TOKEN_EXPIRED,
    ERROR_EQB_SEAL_PERMISSION_FAIL,
    ERROR_ESIGN_QRDRAW_TIMEOUT,
    ERROR_ESIGN_QRDRAW_USED,
    ERROR_RES_CODE
} from "./error_container";
import {doLogout, getSupplierId, setOtelHeader as _setOtelHeader} from "../utils";
import {inOnline, inPhantom, inPublicPageEnv} from "../pages/PublicPage/utils/util";
import {AUTH_PLUGIN_URI} from "../App";
import {loginByPublicPageEnv} from "../pages/PublicPage/Model";
import OtelService from "../utils/otel/service";
import {getWardType, WARD_TYPE} from "../pages/PublicPage/service";

let XClientFrom;
const OutSupplierId =  getSupplierId();
const InSupplierId = getSupplierId(false);


const otelService = new OtelService();
// fixed ESIGNCNRD-960
axios.interceptors.request.use(
    config => {
        // 默认参数设置：所有接口都必须传的值（比如：userId）
        // let defaultParams = {
        //     userId: store.state.userId;
        // };
        // 自定义header信息（比如token）

    const esignUrl = _getUrl();
    const url = config.url;
    if (url.includes(esignUrl)) {
        // 0:online 1:phantom win 2:phantom mac 3:phantom android 4:pc client 5:h5 client
        // 6 online-advert 7 mobile-barcode 8 local-barcode
        let clientFrom = XClientFrom;
        if (!clientFrom) {
            if (inOnline()) {
                clientFrom = 0;
            }
            if (inPhantom()) {
                clientFrom = 1;
            }
        }


        const traceHeaders = otelService.getTraceHeaders();
        config.headers = {...config.headers, 'x-client-from': clientFrom, ...traceHeaders};
        // config.headers['x-client-from'] = clientFrom;
    }

    // 默认值与接口传来的参数进行合并（注：接口参数与默认值不可重复）
    //config.data = Object.assign(defaultParams, config.data);
    return config;
}, function (error) {
    // 对请求错误做些什么
    return _handleNetworkError(error, '网络连接请求错误');
});

axios.interceptors.response.use(
    response => {
        // httpResponseHandle(response);
        return _handleResponse(response);
    },  error => {
        return _handleNetworkError(error, '网络连接响应错误');
    }
);

export function setHeaderClient({value, client}) {
    // client = 0/1/2 0：pc web端 1：移动端 2：客户端
    if (value === undefined || value === 'undefined' || value === '') {
        switch (Number(client)) {
            case 1:
                value = 5;
                break;
            case 2:
                value = 1;
                break;
            default:
                value = 4;
        }
    }
    XClientFrom = value;
}

export function getESignAccount() {
    const userInfo = getStore('userInfo') || {};
    return userInfo.userEmail || (userInfo.userMobile || "").slice(-11);
}

// 手绘签章
export function qrDrawMock({type, token, hash, imgData, client = 'pc'}) {
    const url = "http://10.111.12.12:8080/api/qr-draw";
    const params = {
        accessToken: token || _getToken(),
        hash,
        client,
        type
    }
    if (type === 'save') {
        const data = {
            imgData
        };
        return axios.post(url, data, {params});
    } else {
        return axios.get(url, {params});
    }
}

// 创建手绘签章流程
export function qrDrawCreate({token, client = 'pc'}) {
    const url = _getUrl() + "/api/temp-seal/create-workflow";
    const params = {

    }
    const data = {
        accessToken: token || _getToken(),
        source: client
    }
    return axios.post(url, data, {params});
}

// 更新手绘签章流程
export function qrDrawUpdate({token, id}) {
    const url = _getUrl() + "/api/temp-seal/view-workflow";
    const params = {

    }
    const data = {
        accessToken: token || _getToken(),
        workflowId: id
    }
    return axios.post(url, data, {params});
}

// 保存手绘签章
export function qrDrawSave({token, id, imgData, width = 0, height = 0, type= 'png'}) {
    const url = _getUrl() + "/api/temp-seal/complete-workflow";
    const params = {

    }
    const data = {
        accessToken: token || _getToken(),
        workflowId: id,
        pictureBase64: imgData,
        pictureType: type,
        pictureWidth: width,
        pictureHeight: height
    }
    return axios.post(url, data, {params});
}

// 心跳查询手绘签章流程
export function qrDrawCheck({token, id}) {
    const url = _getUrl() + "/api/temp-seal/check-workflow";
    const params = {

    }
    const data = {
        accessToken: token || _getToken(),
        workflowId: id
    }
    return axios.post(url, data, {params});
}


// 获取用户实名绑定信息
export function binding(token, enterpriseId, enterpriseName) {
    const url = _getUrl() + "/api/account/binding";
    const params = {
        accessToken: token || _getToken(),
        supplierId: OutSupplierId,
        tenantId: enterpriseId,
        enterpriseName
    }
    return axios.get(url, {params});
}

// 拒绝签署
export function refuseSign({processId, recipientOrder, rejectReason, token}) {
    const url = _getUrl() + "/api/sign-process/reject";
    const params = {
        accessToken: token || _getToken(),
    }
    const data = {
        processId,
        recipientOrder,
        rejectReason
    }
    return axios.post(url, data, {params});

}

// 获取意愿认证URL
export function getWillAuthUrl({accountId, redirectUrl, token}) {
    return _setOtelHeader('willAuth', () => {
        const params = {
            accessToken: token || _getToken(),
            supplierId: OutSupplierId
        }
        const data = {
            bizType: "SIGN",
            isPCRedirect: true,
            accountId,
            redirectUrl
        }
        const url = _getUrl() + "/api/will-auth/create-auth";
        return axios.post(url, data, {params});
    });
}

// 查询意愿认证结果
export function getWillAuthResult({bizId, token}) {
    const params = {
        accessToken: token || _getToken(),
        supplierId: OutSupplierId,
        bizType: "SIGN",
        bizId,
    }
    const url = _getUrl() + "/api/will-auth/query-result";
    return axios.get(url, {params});
}

// 获取实名认证url
export function getAuthUrl(authParams, token) {
    return _setOtelHeader(authParams.orgName ? 'auth-org':'auth', () => {
        const url = _getUrl() + "/api/real-auth/auth-url";
        if (!token) {
            token = _getToken() || '';
        }
        const params = {
            accessToken: token,
            supplierId: OutSupplierId
        }
        const data = _getAuthParams(authParams)
        return axios.post(url, data,
            {
                params,
            }
        );
    });
}

// 查询实名认证的结果
export function getAuthResult({email, mobile, orgName, token}) {
    let url = _getUrl();
    const params = {
        accessToken: token || _getToken(),
        supplierId: OutSupplierId,
    }
    if (orgName) {
        params.name = orgName;
        url += '/api/authorizer/org-info';
    } else {
        if (email) {
            params.email = email;
        } else if (mobile) {
            params.mobile = mobile;
        }
        // const eSignAccount = getESignAccount();
        // if (eSignAccount.indexOf('@') > -1) {
        //     params.email = eSignAccount;
        // } else {
        //     params.mobile = eSignAccount;
        // }
        url += '/api/authorizer/person-info';
    }
    return axios.get(url, {params});
}

// 绑定实名
export function bindAuthAccount({
                                    authParams, wardType, email, mobile, name, flowId,
                                    accountId, tenantId, orgId, token
                                }) {
    let url = _getUrl();
    const params = {
        accessToken: token || _getToken(),
        supplierId: OutSupplierId,
    }
    authParams = _getAuthParams(authParams);
    let from = 0 // 产品类型 0：online；1：win phantom；2 mac phantom;
    if (getStore('plugin').inPhantom) {
        from = 1;
    }
    const data = {
        authParams,
        redirectUri: authParams.redirectUrl,
        flowId,
        wardType,
        from,
        mobile,
        email,
        name
    };
    if (orgId) {
        url += '/api/account/org-bind';
        if (tenantId) {
            data.tenantId = tenantId;
        } else {
            data.tenantId = "";
            data.enterpriseName = name;
        }
        data.orgId = orgId;
    } else {
        url += '/api/account/person-bind';
        data.accountId = accountId;
    }

    const result = {
        status: true,
        tenantId: null,
        errCode: 0,
    }
    return axios.post(url, data, {params}).then(() => {
        if (!tenantId && orgId) {
           return binding(token, undefined, name).then(res => {
               if (inPhantom()) {
                   return res
               }
               if (res.createOrgCode === 0 ) {
                   res.orgList.some(org => {
                       if (org.orgId === orgId) {
                           result.tenantId = org.tenantId;
                           return true;
                       }
                       return false;
                   })
               } else {
                   result.status = false;
                   result.errCode = res.createOrgCode;
               }
               return result;
           });
        }
        return result;
    });
}

// 绑定实名
export function bindAuthAccountForPhantom({
                                    authParams, wardType, email, mobile, name, flowId,
                                    accountId, tenantId, orgId, token
                                }) {
    let url = _getUrl();
    const params = {
        accessToken: token || _getToken(),
        supplierId: OutSupplierId,
    }
    authParams = _getAuthParams(authParams);
    let from = 0 // 产品类型 0：online；1：win phantom；2 mac phantom;
    if (getStore('plugin').inPhantom) {
        from = 1;
    }
    const data = {
        authParams,
        redirectUri: authParams.redirectUrl,
        flowId,
        wardType,
        from,
        mobile,
        email,
        name
    };
    if (orgId) {
        url += '/api/account/org-bind';
        if (tenantId) {
            data.tenantId = tenantId;
        } else {
            data.tenantId = "";
            data.enterpriseName = name;
        }
        data.orgId = orgId;
    } else {
        url += '/api/account/person-bind';
        data.accountId = accountId;
    }
    return axios.post(url, data, {params})
    //     .then(() => {
    //     if (!tenantId) {
    //         return binding(token, name).then(res => {
    //             if (inPhantom()) {
    //                 return res
    //             } else {
    //                 return res.createOrgCode === 0 ? true : res.createOrgCode;
    //             }
    //         });
    //     }
    //     return true;
    // });
}

// 获取当前账户所属租户信息
// 获取当前账户的过期时间和剩余份数
// wardType 1:对内；0：对外
export function getSubscription({wardType, tenantId, token}) {
    const url = _getUrl() + '/api/user/subscription';
    const params = {
        accessToken: token || _getToken(),
        tenantId,
        wardType: wardType === 1 ? 1 : 0
    }

    return axios.get(url, {params, timeout: 20000});
}

// 获取全局配置
export function getEsignConfig() {
    const url = _getUrl() + "/api/site/config";
    return axios.get(url);
}

// 获取签署流程信息
export function getSignFlowInfo({processId, cc = 0}) {
    return _setOtelHeader('flow', () => {
        const params = {
            accessToken: _getToken(),
            processId,
            resultVer: 2,
            cc
        }
        const url = _getUrl() + "/api/sign-process/info";
        return axios.get(url, {params});
    });
}

// 获取送抄人
export function getCCList({processId}) {
    return _setOtelHeader('flow-cc', () => {
        const params = {
            accessToken: _getToken(),
            processId,
        }
        const url = _getUrl() + "/api/sign-process/carbon-copy";
        return axios.get(url, {params});
    });


}

// 下载文档
export function downloadPdf2({docId}) {
    const url = _getUrl() + "/api/file/download?docId="+docId;
    return fetch(url).then(async response => {
        const reader = response.body.getReader()
        const len = +response.headers.get('Content-Length')

        // Step 3: read the data
        let receivedLength = 0;
        let chunks = [];
        while (true) {
            const { done, value } = await reader.read();
            if (done) {
                break;
            }
            chunks.push(value);
            receivedLength += value.length;
            const percent = Math.round(receivedLength / len * 100)
            console.log(percent + '%')
        }
        // Step 4: concatenate chunks into single Uint8Array
        let chunksAll = new Uint8Array(receivedLength); // (4.1)
        let position = 0;
        for (let chunk of chunks) {
            chunksAll.set(chunk, position); // (4.2)
            position += chunk.length;
        }
        // Step 5: decode into a string
        let result = new TextDecoder("utf-8").decode(chunksAll);
        // We're done!
        console.log(result)
    })
}

export function downloadPdf({docId}) {
    const params = {
        docId
    }
    const url = _getUrl() + "/api/file/download";
    return axios.get(url,
        {
            params, responseType: "arraybuffer",
            onDownloadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const progress = progressEvent.loaded / progressEvent.total * 100
                    console.info("🍭  ~ file:esign method:onDownloadProgress line:279 -----", progress)
                } else {
                    // console.info("🍭  ~ file:esign method:onDownloadProgress line:280 -----", progressEvent)
                }
            }
        }
    ).then(res => {
        const result = res.data;
        if (result) {
            return result;
        }
        return Promise.reject(res);
    });
}

// 获取个人印章列表
export function getPersonSeals({accountId, token, personSeals}) {
    return _setOtelHeader('selas', () => {
        const url = _getUrl() + "/api/seal/person-seals";
        return _resSeals({url, accountId, token, enterpriseId: '', seals: personSeals});
    });
}

// 获取企业印章列表
export function getOrgSeals({orgId, accountId, token, enterpriseId, orgSeals, valid = true}) {
    return _setOtelHeader('selas-org', () => {
        const url = _getUrl() + "/api/seal/new-org-seals";
        return _resSeals({url, accountId, orgId, token, enterpriseId, seals: orgSeals, valid});
    });
}

// 获取个人章管理url
export function getPersonAdminUrl({token, accountId}) {
    return _setOtelHeader('adminUrl', () => {
        const params = {
            accessToken: token || _getToken(),
            supplierId: OutSupplierId,
            accountId,
            hidePageButton: '[]',
            // hidePageButton: ["SET_DEFAULT","RENAME","DELETE"]
        };
        const url = _getUrl() + "/api/seal/person-admin-url";
        return axios.get(url, {params}).then(data => {
            return data.managePsnSealUrl;
        });
    });
}

// 获取企业章管理url
export function getOrgAdminUrl({token, orgId}) {
    return _setOtelHeader('adminUrl-org', () => {
        const params = {
            accessToken: token || _getToken(),
            supplierId: OutSupplierId,
            orgId,
        };
        const url = _getUrl() + "/api/seal/org-admin-url";
        return axios.get(url, {params}).then(data => {
            return data.SealManagmentUrl;
        });
    });
}

// 获取签名hash
// from: 0, // 0 online 1phantom win  2phantom mac 3 android
export function signFileDigest({    sealInfo, version,
                                   hash, operatorId, signerId, willingnessBizId, chapterType,
                                   tenantId, docId, from = 0, signId, processId, recipientOrder
                               }) {
    return _setOtelHeader('getHash', () => {
        const params = {
            accessToken: _getToken(),
            supplierId: willingnessBizId ? OutSupplierId : InSupplierId, // 1易签宝，2沃通(发起时需要)
            signId,
            version
        }
        const url = _getUrl() + "/api/signature/pdf-hash";
        const data = {
            hash,
            operatorId,
            signerId,
            willingnessBizId,
            chapterType,
            tenantId,
            docId,
            from,
            processId,
            recipientOrder,
            sealInfo
        }
        return axios.post(url, data, {params, timeout: 20000});
    });
}


// 存证
export function addEvidenceForSign({signId, operatorFlowId, signerFlowId, signLogId, oriHash, signedHash, willAuthId, version}) {
    const params = {
        accessToken: _getToken(),
        supplierId: OutSupplierId,
        signId,
        version
    }
    const url = _getUrl() + "/api/evidence/add-evidence";
    const data = {operatorFlowId, signerFlowId, signLogId, oriHash, signedHash, willAuthId};
    return axios.post(url, data, {params});
}

// 上传文档
export function uploadSignedFile({fileName, blob, processId, version, recipientOrder}) {
    const forms = new FormData();
    forms.append('processId', processId);
    forms.append('version', version);
    forms.append('recipientOrder', recipientOrder);
    forms.append('accessToken', _getToken());
    forms.append('file', blob, fileName);

    const url = _getUrl() + "/api/file/upload";
    return axios.post(url, forms, {timeout: 1000 * 60 * 60});
}

export async function uploadSignedFileSlice({fileName, blob, processId, recipientOrder, version}) {
    const chunkLen = 1024 * 100
    const size = blob.size
    const chunks = Math.ceil(size / chunkLen)
    const url = _getUrl() + "/api/file/upload";

    let isSuccess = true
    for (let i = 0; i < chunks; i++) {
        if (!isSuccess) {
            break;
        }
        const start = i * chunkLen
        let end = start + chunkLen
        if (end > size) {
            end = size
        }
        const file = blob.slice(start, end)
        const forms = new FormData();
        forms.append('processId', processId);
        forms.append('version', version);
        forms.append('recipientOrder', recipientOrder);
        forms.append('accessToken', _getToken());
        forms.append('chunks', chunks + "");
        forms.append('file', file, fileName);
        forms.append('chunk', i + "");

        try {
            const result = await axios.post(url, forms, {timeout: 5 * 60 * 60})
            if (result) {
                isSuccess = true
                const per = ((i+1) / chunks * 100).toFixed(0)
                loadingMessage(`正在上传文档… ${per}%`);
            } else {
                isSuccess = false
            }
        } catch (e) {
            isSuccess = false
        }
    }

    return isSuccess ? isSuccess : Promise.reject('上传文档失败')
}

// 对内 创建印章
export function innerSealCreate({token, enterpriseId, signerId, pictureBase64, scale=1,  width=0, height=0, pictureType = 'png'}) {
    return _setOtelHeader('addInnerSeal', () => {
        const params = {
            accessToken: token || _getToken(),
            enterpriseId: enterpriseId ||  _getEnterpriseId()
        }

        const url = _getUrl() + "/api/inner/seal/create";
        const data = {signerId, width, height, scale, pictureBase64, pictureType};
        return axios.post(url, data, {params});
    });
}

// 对内 获取印章列表
export function getInnerSealList(token, enterpriseId) {
    return _setOtelHeader('seals-inner', () => {
        const params = {
            accessToken: token || _getToken(),
            enterpriseId: enterpriseId ||  _getEnterpriseId()
        }
        const url = _getUrl() + "/api/inner/seal/list";
        return axios.get(url,{
            params,
            timeout: 1000 * 60 * 60
        }).then(data => {
            const {lists} = data;
            const seals = [];
            if (lists) {
                for (let i = 0; i < lists.length; i++) {
                    const item = lists[i];
                    const base64 = `data:image/${item['picture_type']};base64,${item['picture_base64']}`
                    delete item['picture_base64'];
                    item.base64 = base64;
                    seals.push(item);
                }
            }
            return seals;
        });
    });
}

// 对内 删除印章
export function innerSealDisable({token, enterpriseId, sealId}) {
    return _setOtelHeader('delInnerSeal', () => {
        const params = {
            accessToken: token || _getToken(),
            enterpriseId: enterpriseId ||  _getEnterpriseId()
        }
        const url = _getUrl() + "/api/inner/seal/disable";
        const data = {sealId};
        return axios.post(url, data, {params});
    });
}

// 对内 签署
export function innerSignFileDigest({hash, processId, version, from = 0, recipientOrder, signerId, signId, sealInfo = []}) {
    return _setOtelHeader('getHash-inner', () => {
        const params = {
            accessToken: _getToken(),
            enterpriseId: _getEnterpriseId(),
            signId,
            version
        }
        const url = _getUrl() + "/api/inner/signature/pdf-hash";
        const data = {
            hash,
            signerId,
            from,
            processId,
            recipientOrder,
            sealInfo
        }
        return axios.post(url, data, {params, timeout: 20000});
    });
}

// 检测当前saml用户，是否归属当前企业
export function checkEnterpriseUser() {
    const params = {
        accessToken: _getToken(),
        enterpriseId: _getEnterpriseId()
    }

    const url = _getUrl() + "/api/user/enterprise-user";
    return axios.get(url, {params}).then(data => {
        return data && data.userType === 2;
    });
}

// 查询签署记录复合接口
export function getSignProcessList({processId,role,wardType,name,status, expandedSearchParams, perPage, currentPage,errCallback}) {
    if (!currentPage) {
        currentPage = 1;
    }
    let params = {
        accessToken: _getToken(),
        needTotal:1,
        pageSize:perPage,
        page:currentPage,
    }
    if(processId) params.processId = processId;
    if(role !== undefined) params.tabType = role;
    if(wardType) params.wardType = wardType;
    if(name) params.name = name;
    if(status) params.status = status;
    if (params.status && params.status.indexOf(',')) delete params.status;
    params = {...params, ...expandedSearchParams}
    return axios.get(`${_getUrl()}/api/sign-process/new-list`, {
        params,
        timeout: 20000,
    }).catch(e => {
        errCallback(e)
    })
}

// 查询企业签署记录复合接口
export function getSignProcessOrgList({processId,role,wardType,name,status, expandedSearchParams, perPage, currentPage,errCallback}) {
    if (!currentPage) {
        currentPage = 1;
    }
    let params = {
        accessToken: _getToken(),
        needTotal:1,
        pageSize:perPage,
        page:currentPage,
        enterpriseId: _getEnterpriseId(),
    }
    if(processId) params.processId = processId;
    if(wardType) params.wardType = wardType;
    if(name) params.name = name;
    if(status) params.status = status;
    if (params.status && params.status.indexOf(',')) delete params.status;
    params = {...params, ...expandedSearchParams}
    return axios.get(`${_getUrl()}/api/sign-process/enterprise-list`, {
        params,
        timeout: 20000,
    }).catch(e => {
        errCallback(e)
    })
}

// 签署提醒接口
export function noticeSign(processId, recipientOrder) {
    const data = {
        processId
    }
    if (typeof recipientOrder === 'number') {
        data.recipientOrder = recipientOrder
    }
    const params = {
        accessToken: _getToken(),
    }
    return axios.post(`${_getUrl()}/api/notice/message`, data,{ params });
}

// 获取用户订阅信息
export function getNewSubscription( { tenantId } ) {
    const params = {
        accessToken: _getToken(),
    }
    if(tenantId){
        params.tenantId = tenantId
    }
    return axios.get(`${_getUrl()}/api/user/new-subscription`, {
        params,
        timeout: 20000
    });
}

// 查询签署记录总数计算
export function getSignProcessListTotal() {

    let params = {
        accessToken: _getToken(),
    }
    return axios.get(`${_getUrl()}/api/sign-process/new-list-total`, {
        params,
        timeout: 20000
    });
}

// 获取个人章创建url
export function getPersonCreateUrl({accountId, token}) {
    const params = {
        accessToken: token || _getToken(),
        supplierId: OutSupplierId,
        accountId,
        redirectUrl: 'iframe://close',
        sealCreateMode: 'template',
        sealTemplateStyle: 'RECTANGLE_BORDER'
    }
    return axios.get(
        `${_getUrl()}/api/seal/psn-create-url`,
        {
            params
        }).then(data => data.PsnSealCreateUrl )
}

// 申请企业印章
export function applyOutStampUsePermission({ token, orgId, accountId, sealId, sealName, applicantName}) {
    return axios.post(`${_getUrl()}/api/seal/org-internal-auth`,{
        orgId,
        accountId,
        sealId,
        sealName,
        applicantName
    }, {
        params: {
            accessToken: token || _getToken(),
            supplierId: OutSupplierId
        }
    })
}

// 催办申请企业印章
export function resendApplyOutStampUsePermission({token, orgId, accountId, sealId}) {
    return axios.post(`${_getUrl()}/api/seal/resend`,{
            orgId,
            accountId,
            sealId,
        }, {
        params: {
            accessToken: token || _getToken(),
            supplierId: OutSupplierId
        }
    })
}

// 删除签名流程
export function deleteFlow({processId}) {
    const url = `${_getUrl()}/api/sign-process/delete`
    return axios.post(url, {
            processId
        },{
        params: {
            accessToken: _getToken()
        }
    });
}

// 拒签
export function rejectFlow({processId, rejectReason, recipientOrder}) {
    const url = `${_getUrl()}/api/sign-process/reject`
    return axios.post(url, {
            processId,
            rejectReason,
            recipientOrder
        },{
        params: {
            accessToken: _getToken()
        }
    });
}
// 修改截止日期
export function changeDeadline({processId, expireTime}) {
    const url = `${_getUrl()}/api/sign-process/change-expire`
    return axios.post(url, {
            processId,
            expireTime
        },{
        params: {
            accessToken: _getToken()
        }
    });
}

// 获取抄送信息
export function getProcessCCInfo({ processId }) {
    const params = {
        accessToken: _getToken(),
        processId
    }
    const url = `${_getUrl()}/api/sign-process/carbon-copy`;
    return axios.get(url, { params });
}

// 修改抄送信息
export function updateProcessCCInfo({ processId, data }) {
    const params = {
        accessToken: _getToken(),
    }
    const url = `${_getUrl()}/api/sign-process/change-carbon-copy`;
    return axios.post(url,  { processId, carbon_copies: data }, { params });
}


// 撤销签名流程
export function cancelFlow({processId, comment}) {
    const url = `${_getUrl()}/api/sign-process/cancel`
    return axios.post(url, {
        processId,
        comment
       },{
        params: {
            accessToken: _getToken()
        },
        timeout: 20000
    });
}

// 获取企业订阅/用户授权状态
export function checkAuth(isOuter = false, enterpriseId = undefined,  token = undefined){
    const product = !isOuter ? 'internal' : 'external';
    token = token ? token : _getToken();
    enterpriseId = enterpriseId ? enterpriseId : _getEnterpriseId();
    const url = `${_getUrl()}/api/user/enterprise-auth?accessToken=${token}&enterpriseId=${enterpriseId}&product=${product}`;
    return axios.get(url).then(() => {
        return 1;
    }).catch(key => {
        switch (key) {
            case ERROR_AC_ORG_PERMISSION_FAIL: // 企业未订阅或过期
                return -1;
            case ERROR_AC_PERMISSION_FAIL:
                return -2; // 用户无授权
            default:
                return -3;
        }
    });
}

// 查询流程签中自己的历史联系人
// type: name, contcat, company
export function searchFlowMan({type, keyword, page= 1, pageSize= 50}) {
    const params = {
        accessToken: _getToken(),
        query: keyword,
        type,
        page,
        pageSize
    }
    return axios.get(`${_getUrl()}/api/user/search-recipient`, {params})
}

export function searchOrgNameForPhantom({keyword, page=1, pageSize=20}) {
    const params = {
        accessToken: _getToken(),
        query: keyword,
        page,
        pageSize
    }
    return axios.get(`${_getUrl()}/api/site/search-enterprise`, {
        params
    }).then(res => {
        if (res.code) {
            return null;
        } else {
            return Object.values(res);
        }
    });
}

// wardType
/**
 * wardType
 * enterpriseId
 * sealType
 * sealName
 * processName
 * startDate
 * endDate
 * timezone
 * pageNum,
 * pageSize
 * **/
export function searchSealUsageRecord({wardType, sealName, sealType, taskName: processName, startDate, endDate, pageNum, pageSize, enterpriseFlag = 0} = {}) {
    if (!pageNum) {
        pageNum = 1;
    }
    let params = {
        accessToken: _getToken(),
        pageSize,
        pageNum,
        enterpriseFlag
    }
    if(typeof wardType === 'number') params.wardType = wardType;
    if(processName) params.processName = processName;
    if(sealName) params.sealName = sealName;
    if(typeof sealType === 'number') params.sealType = sealType;
    if(startDate) params.startDate = startDate;
    if(endDate) params.endDate = endDate;
    params.enterpriseId = _getEnterpriseId();
    // if(status) params.status = status;
    return axios.get(`${_getUrl()}/api/sign-process/seal-history`, {
        params,
        timeout: 20000,
    })
}

export function getUsedSeal(enterpriseFlag = 0) {
    let params = {
        accessToken: _getToken(),
        wardType: 0, // 该接口返回的是sealName的数组，目前只有对外印章才有sealName
        enterpriseId: _getEnterpriseId() ?? '',
        enterpriseFlag
    }
    return axios.get(`${_getUrl()}/api/sign-process/used-seal`, {
        params,
        timeout: 20000,
    }).then(data => {
        if(!Array.isArray(data)) {
            let names = [];
            const keys = Object.keys(data);
            keys.forEach(key => {
                names.push(data[key])
            })
            return names;
        } else {
            return data;
        }
    })
}

export function getSealImage({ sealLogId }) {
    let params = {
        accessToken: _getToken(),
        sealLogId,
    }
    return axios.get(`${_getUrl()}/api/sign-process/seal-detail`, {
        params,
        timeout: 20000,
    }).then(res => {
        if (!res || Object.keys(res).length === 0) {
            return { url: 'loading' } // 不存在URL时，展示Image组件的默认图片
        } else {
            if (getWardType(res.ward_type) === WARD_TYPE.in) {
                res.url = `data:image/${res['picture_type']};base64,${res['picture_base64']}`;
                return res;
            } else if (getWardType(res.ward_type) === WARD_TYPE.out) {
                return _setImgBuffer(res.url).then(base64 => {
                    res.url = base64;
                    return res;
                })
            } else {
                return { url: 'loading' }
            }
        }
    }).catch(e => {
        console.log(e)
        return { url: 'error' }
    })
}

// 查询企业
export function searchOrgName({token, keyword, page=1, pageSize=20}) {
    const params = {
        accessToken: token || _getToken(),
        query: keyword,
        page,
        pageSize
    }
    const url = _getUrl() + '/api/site/search-enterprise';
    return axios.get(url, {
        params
    }).then(res => {
        if (!Array.isArray(res)) {
            return Object.values(res);
        }
        return [];
    });
}

// 获取企业成员列表
export function getEnterpriseUserPermissionList({ wardType = 0 }) { // 0-对外，1-对内
    const params  = {
        accessToken: _getToken(),
        enterpriseId: _getEnterpriseId(),
        wardType,
    }
    const url = _getUrl() + '/api/enterprise-permission/list';
    return axios.get(url, {
        params
    })
}


// 设置企业管理人员权限
export function setEnterpriseUserPermission({ wardType = 0, users }) {
    const params = {
        accessToken: _getToken()
    }
    const data  = {
        enterpriseId: _getEnterpriseId(),
        wardType,
        users
    }
    const url = _getUrl() + '/api/enterprise-permission/add';
    return axios.post(url, data, { params } )
    /**
     * "users": [
     *     {
     *       "userId": "string",
     *       "permissionTypes": {
     *         "signRecord": 0,
     *         "sealRecord": 0
     *       }
     *     }
     * ]
     *
     * 对于同步失败的用户则会在data.failUsers数组里面返回其userId。
     */
}


function _resSeals({url, accountId, orgId, token, enterpriseId, seals, valid = true}) {
    if (Array.isArray(seals) && seals.length > 0) {
        return Promise.resolve(formatSeals(seals, valid));
    }
    const params = {
        accountId,
        orgId,
        enterpriseId: enterpriseId === undefined ? _getEnterpriseId() : enterpriseId,
        accessToken: token || _getToken(),
        supplierId: OutSupplierId,
        pageNum: 1,
        pageSize: 20
    }
    let reqSeals = [];
    return req().then(() => {
        return formatSeals(reqSeals, valid);
    });

    function req() {
        return axios.get(url, {params}).then(async data => {
            if (data.seals) {
                reqSeals = [...reqSeals, ...data.seals];
                if (data.total > reqSeals.length) {
                    params.pageNum++;
                    return req();
                }
            }
            return reqSeals;
        });
    }
}

export async function formatSeals(list, valid = true) {
    if (!Array.isArray(list) || list.length === 0) {
        return Promise.resolve([]);
    }
    const seals = [];
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (valid) {
            if(!inPhantom() && item.usePermission !== undefined && !item.usePermission) {
                continue;
            }
        }

        item.base64 = await _setImgBuffer(item.url);
        seals.push(item);
    }
    return seals;
}

// 获取实名url的请求参数
function _getAuthParams({account, redirectUrl, orgName = ''}) {
    const data = {
        account,
        redirectUrl,
        bizType: "combination",
        responseType: "code",
        scope: orgName ? "get_user_info,op_organ_admin" : "get_user_info",
        state: "",
    }
    if (orgName) {
        data.authSubject = {
            name: orgName,
            organizationType: "",
            legalRepName: "",
            legalRepCertNo: "",
            legalRepCertType: "",
        };
    }
    return data;
}

function _setImgBuffer(src) {
    return new Promise((resolve, reject) => {
        let img = document.createElement('img');
        img.src = src;
        img.crossOrigin = '';
        img.onload = function () {
            const width = img.width;
            const height = img.height;
            const canvas = document.createElement("canvas");
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0, width, height);
            const ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
            const base64 = canvas.toDataURL("image/" + ext);
            img.onload = null;
            img = null;
            resolve(base64);
        };
        img.onerror = function (e) {
            reject(e)
        }
    });
}

function _getToken() {
    const userInfo = getStore('userInfo') || {};
    return userInfo?.token || '';
}

function _getEnterpriseId() {
    const userInfo = getStore('userInfo') || {};
    return userInfo.enterpriseId || '';
}

function _useResHandle() {
    return getStore('useResHandle') || false;
}

function _getUrl() {
    const configUrls = getStore('configUrls') || {};
    return configUrls.eSignCNUrl;
}

function _handleResponse(res) {
    if (!_useResHandle()) {
        return res;
    }
    if (res.config && res.config.responseType === "arraybuffer") {
        return res;
    }
    const result = res.data;
    if (result) {
        if (result.code === 10) { // 10为分片上传成功的code码
            return true;
        }
        if (result.ret === 1) { // html2Pdf 转换进程
            result.ret = 0;
        }
        const code = result.code === undefined ? result.ret : result.code;
        const message = result.msg === undefined ? result.message : result.msg;
        if (code === 0 ) {
            return result.data;
        } else {
            return _handleResError(Number.isInteger(code) ? code : -2, message, res);
        }
    } else {
        return _handleResError(-1, '', res);
    }
}

function _handleResError(code, message, response) {
    let title, errorKey;
    if (code < 0) {
        title = '网络数据响应错误';
    } else {
        for (let key in ERROR_RES_CODE) {
            if (ERROR_RES_CODE[key] === code || (Array.isArray(ERROR_RES_CODE[key]) && ERROR_RES_CODE[key].includes(code))) {
                if (ERROR_AC_TOKEN_FAIL === key) { // ac token error
                    title = '登录过期或状态错误，请重新登录';
                    code = undefined;
                    message = undefined;
                    if(inPublicPageEnv()) {
                        loginByPublicPageEnv()
                    } else if(window.location.href.includes(AUTH_PLUGIN_URI)) {
                        setTimeout(() =>
                                window.parent.postMessage({type: 'close', relogin: true, name: 'esignCNAuthPlugin'}, '*'),2000);
                    } else {
                        setTimeout(() => {doLogout(_getToken())}, 2000); // 延时可查看提示
                    }
                } else if(ERROR_EQB_FAIL === key) { // 易签宝 接口返回错误
                    title = "易签宝数据错误";
                    errorKey =  key;
                    // const msgArr = message.split('|');
                    // if (msgArr.length > 2) {
                    //     code = parseInt(msgArr[1]);
                    //     message = msgArr[2];
                    //     // 印章无管理权限
                    //     if (ERROR_RES_CODE[ERROR_EQB_SEAL_PERMISSION_FAIL] === code ||
                    //         (Array.isArray(ERROR_RES_CODE[ERROR_EQB_SEAL_PERMISSION_FAIL]) && ERROR_RES_CODE[ERROR_EQB_SEAL_PERMISSION_FAIL].includes(code))){
                    //         title = undefined;
                    //         errorKey =  ERROR_EQB_SEAL_PERMISSION_FAIL;
                    //     }
                    //     if (ERROR_RES_CODE[ERROR_EQB_ORG_TOKEN_EXPIRED] === code ||
                    //         (Array.isArray(ERROR_RES_CODE[ERROR_EQB_ORG_TOKEN_EXPIRED]) && ERROR_RES_CODE[ERROR_EQB_ORG_TOKEN_EXPIRED].includes(code))) {
                    //         title = undefined;
                    //         errorKey =  ERROR_EQB_ORG_TOKEN_EXPIRED;
                    //     }
                    //  }
                } else if ([ERROR_ESIGN_QRDRAW_TIMEOUT, ERROR_ESIGN_QRDRAW_USED, ERROR_EQB_ORG_TOKEN_EXPIRED, ERROR_EQB_SEAL_PERMISSION_FAIL].includes(key)) {
                    title = undefined;
                    errorKey =  key;
                } else if (key !== ERROR_AC_FAIL) {
                    errorKey =  key;
                } else if (key === ERROR_AC_FAIL ) {
                    const url = response?.config?.url || '';
                    if (url.includes('/api/user/enterprise-auth')) {
                        errorKey =  key;
                    }
                }
                break;
            }
        }

        if (!errorKey && !title) {
            if (!response.request.responseURL.includes('site/search-enterprise')) {
                title = '未知的网络数据错误';
            }
        }
    }

    if (title) {
        if (code) {
            title += `: ${code}`;
        }
        if(message) {
            title += `(${message})`;
        }
    }

    return _handleNetworkError(errorKey, title);
}

function _handleNetworkError(error, title) {
    if (!_useResHandle()) {
        return Promise.reject(error);
    }

    if (title) {
        errorMessage(title);
    }
    return Promise.reject(error);
}

// 基础证书签署
export async function certSignFileDigest({ hash, token }) {
    const params = {
        accessToken: token || _getToken(),
    };
    const formData = new FormData();
    formData.set('hash', hash);
    const url = _getUrl() + '/api/cert/signature';
    return axios.post(url, formData, { params }).catch(e => {
        console.error('e', e)
    })
}

export async function checkUserEnterprisePermission() {
    const params = {
        accessToken: _getToken(),
        enterpriseId: _getEnterpriseId(),
    };
    const url = _getUrl() + '/api/enterprise-permission/user';
    return axios.get(url, { params }).catch(e => {
        console.error('e', e)
    })
}