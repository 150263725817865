import IconProvider from "./iconProvider";

function ValidStatusIcon(props) {

    const { type, ...resetProps } = props;

    if (type === 'success') {
        return (
            <IconProvider {...resetProps}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 0C2.7 0 0 2.66667 0 6C0 9.3 2.66667 12 6 12C9.3 12 12 9.33333 12 6C12 2.7 9.3 0 6 0ZM9.66667 4.06667L4.9 8.83333C4.76667 8.96667 4.6 8.96667 4.46667 8.83333L4.26667 8.63333C4.2 8.56667 4.2 8.5 4.16667 8.43333L2.33333 6.3C2.2 6.2 2.2 6 2.33333 5.86667L2.53333 5.66667C2.66667 5.56667 2.83333 5.56667 2.96667 5.66667L4.76667 7.73333L9.03333 3.46667C9.16667 3.33333 9.33333 3.33333 9.46667 3.46667L9.66667 3.66667C9.8 3.76667 9.8 3.96667 9.66667 4.06667Z" fill="currentColor"/>
                </svg>
            </IconProvider>
        )
    } else {
        return (
            <IconProvider  {...resetProps}>
                <svg width="16" height="16" t="1721973726647" viewBox="0 0 1024 1024" verssion="1.1"
                     xmlns="http://www.w3.org/2000/svg" p-id="4241" style={{ marginLeft: '-2px'}}>
                    <path
                        d="M512 97.52381c228.912762 0 414.47619 185.563429 414.47619 414.47619s-185.563429 414.47619-414.47619 414.47619S97.52381 740.912762 97.52381 512 283.087238 97.52381 512 97.52381z m36.571429 341.333333h-73.142858v292.571428h73.142858V438.857143z m0-121.904762h-73.142858v73.142857h73.142858v-73.142857z" p-id="4242" fill="currentColor"></path>
                </svg>
            </IconProvider>
        )
    }

}

export default ValidStatusIcon;