import styles from "./Result.module.less";
import successIcon from "../../../assets/images/auth-ok.png";
import errorIcon from "../../../assets/images/error.png";
import {Button} from "antd";
import {
    bindAuthAccount,
    getAuthResult,
    getEsignConfig,
    getNewSubscription,
} from "../../../request/esign";
import {useState, useEffect} from "react";
import {getStore} from "../../../store";
import {closeESignCNDialogAPI, refreshWebForPhantom} from "../../../request/phantom";
import {getQueryVariable} from "../../PublicPage/service";
// import {useNavigate} from "react-router-dom";

export default function Result(props) {
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState({});
    //const navigate = useNavigate();


    useEffect(() => {
        const userInfo = getStore('userInfo');
        const {flowId} = props.resultParams;
        const authParams = props.authParams;
        const tenantId = userInfo.enterpriseId;
        const email = userInfo.userEmail;
        const mobile = userInfo.userMobile;
        let isTrial = 1;
        const bind = () => {
            const {orgName, redirectUrl} = authParams;
            if (orgName) {
                if (tenantId ) {
                    return getNewSubscription({tenantId}).then(res => {
                        const tenantName = res?.orgExternal?.tenantName;
                        if (tenantName !== orgName) {
                            return Promise.reject('tenantName un-equals orgName: ');
                        } else {
                            return result({orgName});
                        }
                    })
                } else {
                    return Promise.reject('tenantId is empty: ');
                }
            } else {
                return getNewSubscription({}).then(res => {
                    isTrial = res?.personExternal?.isTrial;
                }).catch(e => {
                    console.error("🍭  ~ file:Result method: line:41 -----", e);
                }).finally(() => {
                    return result({email, mobile});
                })
            }

            function result(params) {
                return getAuthResult(params).then(res => {
                    const data = {
                        authParams,
                        redirectUri: redirectUrl,
                        wardType: 0, // 1：对内；0：对外；目前该saas分支只是针对对外的，所以这里写死为0；
                    };
                    if (email) {
                        data.email = email;
                    }
                    if (mobile) {
                        data.mobile = mobile;
                    }

                    const { accountId, orgId, name, psnSmFlowId, orgSmFlowId } = res;
                    if (tenantId && orgName) {
                        if (!name || !orgSmFlowId) {
                            return Promise.reject('getAuthResult is error: ' + res);
                        }
                        data.tenantId = tenantId;
                        data.flowId = orgSmFlowId;
                        data.orgId = orgId;
                        data.orgName = name;
                    } else {
                        if (!accountId || !name || !psnSmFlowId) {
                            return Promise.reject('getAuthResult is error: ' + res);
                        }
                        data.accountId = accountId;
                        data.flowId = psnSmFlowId;

                    }
                    data.name = name;
                    return bindAuthAccount(data);
                });
            }
        }
        const init = () => {
            setLoading(true);
            let limits;
            getEsignConfig().then(res => {
                limits = res.trial;
                return bind();
            }).then(() => {
                let title,summary;
                const {orgNum, orgPeriod, personNum, personPeriod} = limits;
                const {orgName} = authParams;
                if (orgName) {
                    if (flowId) {
                        title = '企业实名认证成功';
                        summary = `恭喜您完成企业实名认证，公测和推广期间，免费赠送您${orgNum}个文档签署额度！（有效期${orgPeriod}天）`;
                    } else {
                        title = '企业授权认证成功';
                        summary = '';
                    }
                } else {
                    if (flowId) {
                        title = '个人实名认证成功';
                        summary = Number(isTrial) === 0 ? '' : `恭喜您完成个人实名认证，公测和推广期间，免费赠送您${personNum}个文档签署额度！（有效期${personPeriod}天）`;
                    } else {
                        title = '个人授权认证成功';
                        summary = '';
                    }
                }
                setInfo({title, summary, type: 'success'});
            }).catch(e => {
                console.log("🍭  ~ file:Result method: line:41 -----", e)
                let title,summary;
                title = '实名认证/授权失败';
                summary = '请重新尝试实名认证/授权或<a href="https://www.foxitsoftware.cn/esign#sales" rel="noreferrer" target=\'_blank\'>提交客服</a>处理。';
                setInfo({title, summary, type: 'fail'});
            }).finally(() => {
                setLoading(false);
            })
        }
        init();
    }, [props]);


    const handleClick = () => {
        //navigate('/main');
        if (getStore('plugin').inPhantom) {
            const from = getQueryVariable('from');
            const type = from === 'signFlowPanel' ? 2 : 3;
            refreshWebForPhantom({ type }).finally(() => {
                closeESignCNDialogAPI();
            });
        } else {
            window.location.href = '/'
        }
    }

    const bgIcon = info.type === 'success' ? successIcon : errorIcon
    return (
        <div className={styles.main}>
            {!loading && info.title && <>
                    <img src={bgIcon} alt={"icon"} width="355" />
                    <div className={styles.title} style={{ color: info.type === 'success' ? '#73AD45' : '#000'}}>{info.title}</div>
                    <div className={styles.tips} dangerouslySetInnerHTML={{__html: info.summary}}></div>
                    {
                        info.type !== 'fail' &&
                        <Button onClick={()=> handleClick()} shape={"round"} type={"primary"} style={{width: '60%'}}>开始签署</Button>
                    }
                </>
            }
            {
                loading &&
                <div className={styles.loading}>
                    <div className={styles.loadingBg}>
                        <div className={styles.loadingIcon}></div>
                    </div>
                    <div className={styles.loadingText}>正在获取认证/授权结果…</div>
                </div>
            }
        </div>
    )
}
