import React, {useEffect, useState} from 'react'
import {Button, DatePicker, Form, Input, Select, Spin} from "antd";
import dayjs from "dayjs";
import {observer} from "mobx-react";
import {SEAL_TYPE, WARD_TYPE} from "../constants";
import {getUsedSeal} from "../../../../request/esign";
import {getStore} from "../../../../store";
import { ESIGN_RECORD_TABS } from "../../service";


const { RangePicker } = DatePicker;

function SearchForm(props) {

    const subscriptionData = getStore('plugin').subscriptionData;
    const currentActiveTab = getStore('plugin').esignRecord?.currentActiveTab;
    const isOnlyInSignSubscription = subscriptionData.orgInternal?.cert && (!subscriptionData.orgExternal?.cert && !subscriptionData.personExternal?.cert)
    const { onSearchFormDataChange, loading } = props;
    const [usedSealList, setUsedSealList] = useState([]);
    const [usedSealListFetching, setUsedSealListFetching] = useState(false);
    const [formData, setFormData] = useState({ wardType: isOnlyInSignSubscription ? WARD_TYPE.inSign : WARD_TYPE.outSign, sealType: '' });

    const [form] = Form.useForm();



    const fetchUsedSealList = async () => {
        try {
            setUsedSealListFetching(true);
            const optionList = await getUsedSeal()
            setUsedSealList(optionList.map((name, index) => ({ label: name, value: index })))
        } finally {
            setUsedSealListFetching(false);
        }
    }

    const onFinish = (formData) => {
        const { dateRange } = formData;
        let startDate, endDate;
        if (Array.isArray(dateRange) && dateRange.length === 2) {
            startDate = dayjs(dateRange[0]).format('YYYY-MM-DD')
            endDate = dayjs(dateRange[1]).format('YYYY-MM-DD')
        }
        const searchData = {
            taskName: formData.taskName ?? '',
            startDate: startDate ?? "",
            endDate: endDate ?? "",
            wardType: formData.wardType,
            sealType: ''
        }
        if (Array.isArray(formData.sealName) && formData.sealName.length > 0) {
            searchData.sealName = Array.from(new Set(formData.sealName.map(i => i.label))).join(',');
        } else {
            searchData.sealName = '';
        }
        if (typeof formData.sealType === 'number') {
            if (formData.sealType === SEAL_TYPE.outOrgSeal) {
                searchData.sealType = 1;
            } else if (formData.sealType === SEAL_TYPE.outPersonSeal) {
                searchData.sealType = 0;
            }
        }
        searchData.enterpriseId = getStore('userInfo').enterpriseId;
        onSearchFormDataChange(searchData)
    }

    const onFormValuesChange = () => {
        const formData = form.getFieldsValue();
        setFormData(formData)
    }

    useEffect(() => {
        if(currentActiveTab === ESIGN_RECORD_TABS.SEAL_RECORD) {
            fetchUsedSealList()
        }
    }, [currentActiveTab])

    useEffect(() => {
        form.submit()
    }, [form, subscriptionData])

    useEffect(() => {
        if (formData.wardType === WARD_TYPE.inSign) {
            form.setFieldValue('sealType', '')
            form.setFieldValue('taskName', '')
            form.setFieldValue('sealName', [])
            form.setFieldValue('dateRange', [])
        } else {
            form.setFieldValue('taskName', '')
            form.setFieldValue('dateRange', [])
        }
    }, [form, formData.wardType])

    return (
        <Form
            layout="inline"
            onFinish={onFinish}
            style={{ marginBottom: '16px'}}
            id="SealUsageRecordSearchForm"
            className='search-form'
            form={form}
            initialValues={formData}
            onClick={onFormValuesChange}
            disabled={loading}
        >
            <Form.Item label="签署场景" name="wardType">
                <Select
                    style={{ width: 100 }}
                    placeholder="请选择签署场景"
                    options={[
                        {
                            value: WARD_TYPE.outSign,
                            label: '对外签署',
                        },
                        {
                            value: WARD_TYPE.inSign,
                            label: '对内签署',
                        }
                    ]}
                />
            </Form.Item>
            {
                formData.wardType === WARD_TYPE.outSign && <Form.Item label="印章分类" name="sealType">
                    <Select
                        style={{ width: 180 }}
                        placeholder="请选择印章分类"
                        onChange={() => {}}
                        options={[
                            {
                                value: '',
                                label: '全部',
                            },
                            {
                                value: SEAL_TYPE.outOrgSeal,
                                label: '企业章',
                            },
                            {
                                value: SEAL_TYPE.outPersonSeal,
                                label: '个人章（对外签署）',
                            }
                        ]}
                    />
                </Form.Item>
            }
            {
                formData.wardType === WARD_TYPE.outSign && <Form.Item label="印章名称" name="sealName">
                    <Select
                        placeholder="请选择印章名称"
                        mode="multiple"
                        labelInValue
                        filterOption={true}
                        notFoundContent={usedSealListFetching ? <Spin size="small" /> : "暂无数据"}
                        options={usedSealList}
                        maxTagCount='responsive'
                        style={{ width: 180 }}
                    />
                </Form.Item>
            }
            <Form.Item
                label="任务名称"
                name="taskName"
            >
                <Input
                    placeholder="请输入任务名称"
                    style={{ width: 120 }}
                />
            </Form.Item>
            <div style={{display: 'flex', flexWrap: 'nowrap'}}>
                <Form.Item
                    label="用印时间"
                    name="dateRange"
                >
                    <RangePicker/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" disabled={loading} style={{ backgroundColor: '#A236B2', color: "#FFF", borderColor: "inherit"}}>搜索</Button>
                </Form.Item>
            </div>
        </Form>
    )
}

export default observer(SearchForm)