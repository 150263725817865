// import styles from '../Main.module.less';
import {Steps, Button} from "antd";
import {CheckCircleOutlined, CheckCircleFilled} from "@ant-design/icons";
import {useState, memo} from "react";
import styles from './SignFlowPanel.module.less';
const { Step } = Steps;

export default memo(SignFlowPanel);
function SignFlowPanel(props) {
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const {pdfInfo} = props;
    const {receipts, tips, currOrder} = pdfInfo;

    if (!receipts) {
        return ;
    }


    // 展开收起域列表
    const handleFields = (index) => {
        let _index;
        if (selectedIndex < 0) {
            if (index === currOrder) {
                _index = 10000; // 设置一个不存在的值，用于收起
            } else {
                _index = index;
            }
        } else {
            if (index === selectedIndex) {
                _index = 10000;
            } else {
                _index = index;
            }
        }
        setSelectedIndex(_index);
    }

    return (
        <>
            <div className={styles.tips}>
                {
                    tips &&
                    <div>
                        <span dangerouslySetInnerHTML={{__html:tips.msg}}></span>
                        {/*{tips.linkText && <a href={`${window.location.origin}/add-mobile`} target={tips.linkTarget}>{tips.linkText}</a>}*/}
                        {tips.linkText && <Button type='link' onClick={tips.linkFn} className={styles.tipBtn}>{tips.linkText}</Button>}
                    </div>
                }
            </div>
            <div>
                <Steps direction="vertical" current={currOrder} status={'process'} size={'small'} >
                    {
                        receipts.map((item, index) => {
                            const cnt = item.annots.length;

                            let showFields;
                            if (selectedIndex < 0) {
                                showFields = currOrder === index;
                            } else {
                                showFields = selectedIndex === index;
                            }

                            let contentCss = ' ';
                            let fieldsCss = ' ';
                            if (item.isMine) {
                                contentCss += styles.contentCurrent;
                                fieldsCss += styles.fieldCurrent;
                            }

                            let finishCnt = 0;
                            const annots = [];
                            item.annots.forEach(annot => {
                                const it = JSON.parse(JSON.stringify(annot));
                                let typeStr;
                                switch (annot.type) {
                                    case "FullSignature":
                                        typeStr = '签名域';
                                        break;
                                    case "Date":
                                        typeStr = '日期域';
                                        break;
                                    case "Title":
                                        typeStr = '文本域';
                                        break;
                                    default:
                                        typeStr = '';
                                }
                                it.typeStr = typeStr;
                                if (annot.fieldStatus > 0) { // 默认值设置 0未完成，2已完成 1签章域已放置印章
                                    it.checked = true;
                                    finishCnt++;
                                } else {
                                    it.checked = false;
                                }
                                annots.push(it);
                            });
                            if (finishCnt === 0) {
                                finishCnt = item.finishCnt;
                            }

                            const title = (
                                <div className={styles.item} >
                                    <div className={styles.content+contentCss} onClick={() => handleFields(index)}>
                                        <div className={styles.head}>
                                            <div className={styles.title} >{item.userName}<span className={styles.bade}>{finishCnt}/{cnt}</span></div>
                                            <div className={styles.company}>{item.company ? item.company : '个人'}</div>
                                            {item.updated && <div className={styles.company}>已签署：{item.updated}</div>}
                                        </div>

                                    </div>
                                    <div className={styles.fields+fieldsCss} style={{display: showFields ? '':'none'}}>
                                        {

                                            annots.map(annot => {
                                                let iconCss;
                                                if (annot.checked) {
                                                    iconCss = styles.iconSelected;
                                                } else {
                                                    iconCss = styles.icon;
                                                }
                                                return (
                                                    <div key={annot.id} className={styles.field}
                                                         onClick={() => props.handleFieldClick(annot)}>
                                                        {annot.typeStr}
                                                        {annot.checked ? <CheckCircleFilled className={iconCss}  /> :
                                                            <CheckCircleOutlined className={iconCss} />
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            );
                            return <Step key={`${item.email}-${item.phoneNumber}-${item.companyId}-${item.userName}`} title={title} />;
                        })
                    }
                </Steps>
            </div>
        </>
    )
}

