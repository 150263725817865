import styles from "../StampManage.module.less";
import {Cropper} from "react-cropper";
import "cropperjs/dist/cropper.min.css"
import {useRef} from "react";
export default function ImageCropper (props) {
    const {base64, setCropData} = props;
    const cropperRef = useRef();
    const handleCrop = () => {
        const imgEle = cropperRef.current;
        const cropper = imgEle.cropper;
        const data = cropper.getCroppedCanvas().toDataURL();
        setCropData(data);
    }

    return (
        <>
            <div style={{color: '#ccc', margin: '10px 20px'}}>拖放选中区域或滚动鼠标可调整印章尺寸</div>
            <div className={styles.tabContainer + ' ' + styles.tabCropper} >

                <Cropper src={base64}
                         ref={cropperRef}
                         crop={handleCrop}
                         viewMode={1}
                         autoCropArea={1}
                         style={{height: '300px', width: '960px'}}
                />

            </div>
        </>

    )
}
