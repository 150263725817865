import styles from "../phantom.module.less";
import React, {useRef, useState} from "react";
import {Button, Input, Tooltip} from "antd";
import {errorMessage} from "../../components/Message";
const { TextArea } = Input;
export default function PhantomViewer(props) {
    const {isOut, companyName, processId} = props.querys;
    const {enterpriseId, token, email, submitFn} = props;
    const [success, setSuccess] = useState(false);
    const [inputStatus, setInputStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();


    const changeValue = (e) => {
        const value = e.target.value;
        if (!value || value.length > 50) {
            setInputStatus('warning');
        } else {
            setInputStatus('');
        }
    };

    const submit = () => {
        const comment = inputRef.current.resizableTextArea.textArea.value;
        setInputStatus('');
        setLoading(true);
        submitFn({isOut, enterpriseId, comment, token, processId}).then(code => {
            switch (code) {
                case -1:
                    setInputStatus('error');
                    break;
                case -2:
                    errorMessage(`申请加入企业组织失败`);
                    break;
                default:
                    setSuccess(true);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    let showOrgName = companyName;
    if (showOrgName.length > 18) {
        showOrgName = showOrgName.substring(0, 15) + '…' + showOrgName.substring(showOrgName.length-2);
    }

    const title = success ? '申请提交成功' : '申请加入企业组织';
    const summary = success ? '申请已提交，请耐心等待组织管理员审批邮件通知。' : '';

    return (
        <>
            <div className={styles.result}>
                <div className={styles.icon}>
                    <i className={styles.img + ' ' + styles.org}></i>
                    <p className={styles.stat + ' ' + (success ? styles.statOk : '')}>
                        <i className={styles.img + ' ' + (success ? styles.ok : styles.plus)}></i></p>
                </div>

                <div className={styles.container}>
                    <div className={styles.title}>{title}</div>
                    {success && <div className={styles.tips} dangerouslySetInnerHTML={{__html: summary}}></div>}
                    {
                        !success && <>
                            <div className={styles.tips}>
                                <p>企业名称：
                                    <Tooltip title={companyName}>
                                        {showOrgName}
                                    </Tooltip>
                                </p>
                                <p>申请人：{email}</p>
                            </div>
                            <TextArea placeholder="申请原因（必填）…" autoSize={{ minRows: 4, maxRows: 4 }}
                                      ref={inputRef} allowClear showCount status={inputStatus} maxLength={50} onChange={changeValue} />
                            {inputStatus === 'error' && <span className={styles.error}>申请原因为必填，长度不可超过50个字</span>}
                        </>
                    }
                </div>
            </div>
            <div className={styles.buttons}>
                {!success && <Button loading={loading} onClick={submit} type='primary'
                                     style={{width: '90px'}}>确认</Button>}
                <Button key="back" onClick={() => props.sendMsg({type:'close'})}
                        style={{width: '90px', marginLeft: '10px'}}>
                    {success ? '关闭' : '取消'}</Button>
            </div>
        </>
    );
}

