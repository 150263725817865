import {message} from "antd";

const key = 'esignCnPdf'
export function loadingMessage(content = 'Loading...', duration = 0) {
    message.loading({content, key, duration}).then();
    _changeShadow(true);
}

export function successMessage(content = '操作成功！', duration = 3) {
    hideMessage();
    message.success({content, key, duration, onClose: hideMessage}).then();
}

export function errorMessage(content = '操作失败！', duration = 3) {
    hideMessage();
    message.error({content, key, duration, onClose: hideMessage}).then();
}

export function hideMessage() {
    message.destroy(key);
    _changeShadow();
}

function _changeShadow(isShow = false) { // 设置遮罩
    setTimeout(() => {
        const el = document.querySelector(".ant-message");
        if (!el) return;
        el.style.pointerEvents = isShow ? "all" : "";
        el.style.backgroundColor = isShow ? "translate" : ""; // translate
        el.style.height = isShow? "100vh" : "";
    }, 100);
}
