import {AccountContent} from "./content";
import styles from './index.module.less';
import {useEffect} from "react";
import {resizeDialog} from "../Model";

export default function PhantomAccount() {

    useEffect(() => {
        resizeDialog({ width: 400, height: 492 });
    }, []);

    return (
        <div className={styles.phantomContainer}>
            <AccountContent/>
        </div>
    )
}