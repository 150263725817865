import React, { useEffect, useState } from 'react';
import { Col, Modal, Row, Space, Radio, Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import {setEnterpriseUserPermission} from "../../../request/esign";
import {ERROR_ESIGN_ORG_PERMISSION_FAIL} from "../../../request/error_container";
import {errorMessage, successMessage} from "../../components/Message";

export default function EditPermission({items, save, isOut}) {
    const [btnLoading, setBtnLoading] = useState(false);
    const [docValue, setDocValue] = useState(-1);
    const [sealValue, setSealValue] = useState(-1);

    const isOutText = isOut? '对外' : '对内';

    useEffect(() => {
        let docValue = 0;
        let sealValue = 0;

        if (items.length === 1) {
            docValue = items[0].permissions.includes(1) ? 1 : 0;
            sealValue = items[0].permissions.includes(2) ? 1 : 0;
            setDocValue(docValue);
            setSealValue(sealValue);
            return;
        }
        items.forEach(item => {
            const permissions = item.permissions;
            if (permissions.includes(1)) {
                docValue += 1;
            } else {
                docValue += -1;
            }
            if (permissions.includes(2)) {
                sealValue += 1;
            } else {
                sealValue += -1;
            }
        });
        if (docValue === items.length) {
            setDocValue(1);
        } else if (docValue === -items.length) {
            setDocValue(0);
        }
        if (sealValue === items.length) {
            setSealValue(1);
        } else if (sealValue === -items.length) {
            setSealValue(0);
        }
    }, []);

    const onSave = () => {
        setBtnLoading(true);
        const users = items.map(item => {
            return {userId: item.userId, permissionTypes: {signRecord: docValue === 1 ? 1 : 0, sealRecord: sealValue === 1 ? 1 : 0}};
        });
        setEnterpriseUserPermission({wardType: isOut ? 0 : 1, users}).then((res) => {
            save(true);
            successMessage("编辑授权成功！")
        })
        .catch(e => {
            if (e === ERROR_ESIGN_ORG_PERMISSION_FAIL) {
                errorMessage('当前账户无操作权限，请联系管理员。');
            } else {
                errorMessage("编辑授权失败，请重试！")
            }
        })
        .finally(() => {
            setBtnLoading(false);
        });

    };

    const onCancel = () => {
        save();
    };

    const onChangeDoc = (e) => {
        setDocValue(e.target.value);
    };
    const onChangeSeal = (e) => {
        setSealValue(e.target.value);
    };


    return (
        <Modal
            title="编辑授权"
            open={true}
            width={400}
            centered
            confirmLoading={btnLoading}
            bodyStyle={{height: 200}}
            onOk={onSave}
            okButtonProps={{disabled: docValue === -1 || sealValue === -1}}
            onCancel={onCancel}
        >
            <Row >
                <Col flex="auto">
                    <Space>
                        <span>{isOutText}印章记录使用授权</span>
                        <Tooltip title={`允许用户查看所有${isOutText}印章的使用记录`}><QuestionCircleFilled style={{color: 'rgba(0, 0, 0, 0.25)'}}/></Tooltip>
                    </Space>
                </Col>
                <Col flex="none">
                    {/*<Switch onChange={cked => onChange(cked, 0)} />*/}
                    <Radio.Group onChange={onChangeSeal} value={sealValue} style={{marginRight: -18}}>
                        <Radio value={1}>授权</Radio>
                        <Radio value={0}>取消</Radio>
                    </Radio.Group>
                </Col>
            </Row>
            <Row style={{marginTop: 20}} >
                <Col flex="auto">
                    <Space>
                        <span>{isOutText}文档签署记录授权</span>
                        <Tooltip title={`允许用户查看所有${isOutText}文档的签署记录`}><QuestionCircleFilled style={{color: 'rgba(0, 0, 0, 0.25)'}} /></Tooltip>
                    </Space>
                </Col>
                <Col flex="none">
                    <Radio.Group onChange={onChangeDoc} value={docValue} style={{marginRight: -18}}>
                        <Radio value={1}>授权</Radio>
                        <Radio value={0}>取消</Radio>
                    </Radio.Group>
                </Col>
            </Row>
        </Modal>
    );
}