import {useEffect, useState, useRef} from "react";
import StampManage from "../main/components/StampManage";
import './index.less'
import {getSealsInPlugin} from "./service";
import {getStore} from "../../store";
import { observer } from 'mobx-react';
import OutStampManageDialog from "./outStampManage";
// import {useRef} from "@types/react";


function PhantomStampManage (StampManage) {
    const Fn = observer(function Comp() {
        // const { pdfInfo, serverInfo, seals, accountId, orgId } = getESignCNStampManageData();
        const outStampManageRef = useRef(null);
        const [visible, setVisible] = useState(true);
        const closeStampModal = () => {
            setVisible(false)
        }
        const pdfInfo = { myItem: { company: '' } };
        const serverInfo = { ward_type: getStore('plugin').wardType }
        const accountId = getStore('userInfo').accountId
        const orgId = getStore('userInfo').orgId
        let seals;
        if (getStore('plugin').wardType === 1) {
            seals = getStore('plugin').inSeals
        } else {
          // todo
        }
        useEffect(() => {
            if (window.location.pathname === '/stamp-manage' && getStore('plugin').wardType === 1) {
                document.body.setAttribute('name', 'stamp-manage-container')
            }
            window.eSignCN_refreshSeals = () => {
                if (getStore('plugin').wardType === 0) {
                    outStampManageRef.current && outStampManageRef.current.fetchStampList()
                } else {
                    getSealsInPlugin({ company: getStore('userInfo').enterpriseName, companyId: getStore('userInfo').enterpriseId })
                }
            }
        }, [])
        if (getStore('plugin').wardType === 0) {
            return <OutStampManageDialog ref={outStampManageRef}/>
        } else if (getStore('plugin').wardType === 1) {
            return <StampManage
                pdfInfo={pdfInfo}
                serverInfo={serverInfo}
                seals={seals}
                visible={visible}
                accountId={accountId}
                orgId={orgId}
                close={closeStampModal}>
            </StampManage>
        }
    })
    return Fn;
}

export default PhantomStampManage(StampManage)