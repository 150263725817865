import React, {useEffect, useState} from "react";
import PcViewer from "./pcViewer";
import MobileViewer from "./mobileViewer";
import PhantomViewer from "./phantomViewer";
import {bindAuthAccount, binding, getAuthResult, getEsignConfig, getSubscription} from "../../../request/esign";
import {AUTH_PLUGIN_URI} from "../index";
import styles from "../index.module.less";
import {Skeleton} from "antd";
import {getSupplierId} from "../../../utils";

const seller = `<a href="https://www.foxitsoftware.cn/esign#sales" rel="noreferrer" target='_blank'>提交客服</a>`;

export default function CertAuth(props) {
    const {client} = props.querys;
    const [childProps, setChildProps] = useState({});

    useEffect( () => {
        const {companyName, client} = props.querys;
        const info = props.userInfo;
        const stat = props.stat;
        if (!info.token) {
            return;
        }
        const token = info.token;
        let email = info.userEmail;
        let mobile = info.userMobile;
        let cname = '';
        let account = email ? email : mobile;
        let enterpriseId = '';
        if (stat === 2 || stat === 3) {
            if (!companyName || companyName === 'setting') {
                cname = info.enterpriseName;

            } else {
                cname = companyName;
            }
            if (!cname) {
                return;
            }
            if (!info.person) {
                binding(token, '').then(res => {
                    const {person} = res;
                    if (!person || !person.authParams) {
                        return;
                    }
                    info.person = person;
                    getOrgProps();
                });

            } else  {
                getOrgProps();
            }
        } else {
            getProps();
        }

        function getOrgProps() {
            account = info.person.authParams.account;
            email = info.person.email;
            mobile = info.person.mobile;
            enterpriseId = stat === 2 && info.enterpriseId;
            getProps();
        }


        function getProps() {
            const supplierId =  getSupplierId();
            const redirectUrl = origin + AUTH_PLUGIN_URI + `?t=10&supplierId=${supplierId}&c=${client}&st=${stat}&cn=${cname}&ei=${enterpriseId}`;
            const authParams = {
                account,
                redirectUrl,
                orgName: cname,
            };

            setChildProps( {
                enterpriseId,
                token,
                email,
                mobile,
                authParams,
                seller,
                initFn: init,
                ...props
            });
        }


    }, [props]);

    return (<>
            {!childProps.authParams && <Skeleton active loading={true}  className={styles.skeleton}></Skeleton>}
            {childProps.authParams && (
            <>
                {client === 0 && <PcViewer {...childProps} />}
                {client === 1 && <MobileViewer {...childProps} />}
                {client === 2 && <PhantomViewer {...childProps} />}
            </>
            )}
    </>)
}

function init({token, flowId, authParams, enterpriseId, email, mobile, stat}) {
    return bind({token, authParams, enterpriseId, email, mobile}).then(res => {
        if (res.status !== true) {
            return Promise.reject(res.errCode);
        }
        try { // 创建企业成功后的query参数处理
            if (res.tenantId) {
                const tempParams = JSON.parse(sessionStorage.getItem("authPluginParams"));
                if (tempParams && tempParams.t) {
                    if (!tempParams.ei) {
                        tempParams.ei = res.tenantId;
                        tempParams.a = 1; // isCreated query参数尽可能短
                        sessionStorage.setItem("authPluginParams", JSON.stringify(tempParams));
                    }
                }
            }
        } catch (e) {

        }

        return getEsignConfig();
    }).then(res => {
        const limits = res.trial;
        let title,summary;
        const {orgNum, orgPeriod, personNum, personPeriod} = limits;
        const {orgName} = authParams;
        if (orgName) {
            if (flowId) {
                title = '企业实名认证成功';
                summary = `恭喜您完成企业实名认证，公测和推广期间，免费赠送您<b><font color='green'>${orgNum}</font></b>个文档签署额度！（有效期<b><font color='green'>${orgPeriod}</font></b>天）`;
            } else {
                title = '企业授权认证成功';
                summary = '';
            }
            return {title, summary};
        }

        if (flowId) {
            let isTrial = 1;
            const type = +JSON.parse(sessionStorage.getItem("authPluginParams") || '{}').type;
            return getSubscription({token}).then(res => {
                isTrial = res.isTrial;
                title = '个人实名认证成功';
                summary = isTrial === 0 ? '' : `恭喜您完成个人实名认证，公测和推广期间，
                    免费赠送您<b><font color='green'>${personNum}</font></b>个文档签署额度！（有效期<b><font color='green'>${personPeriod}</font></b>天）
                    <div style="display: ${stat > 3 || type !== 1 ? 'none':'block'}; margin-top: 10px; ">若您需要使用企业印章加盖文档，请点击【企业实名认证】,或点击【关闭】进行后续操作。</div>
                `;
                return {title, summary};
            })
        } else {
            title = '个人授权认证成功';
            summary = `<div style="">若您需要使用企业印章加盖文档，请点击【企业实名认证】,或点击【关闭】进行后续操作。</div>`;
            return {title, summary};
        }
    }).catch(e => {
        // binding接口通过企业名查询企业是否创建成功的返回值
// 0：表示创建成功，此时orgList字段有值
// 610190：表示创建中，还未完成
// 610191：表情有多个同名的企业，此时需要用户联系销售或者客服进行线下处理
// 610192：表示在App Store那边创建失败了（由于用户已经在App Store里面有了ac的订阅或者其他原因），此时需要用户联系销售或者客服进行线下处理
        let title,summary;
        if (e === 610190) {
            title = '企业正在创建中';
            summary = '点击【关闭】，可稍后再次查询验证。';
        } else if (e === 610191) {
            title = '企业创建失败，存在同名企业';
            summary = `点击【关闭】后，可联系销售人员或${seller}处理。`;
        } else if (e === 610192) {
            title = '企业创建失败';
            summary = `点击【关闭】后，可联系销售人员或${seller}处理。`;
        } else {
            return Promise.reject(e);
        }
        return {title, summary, isError: true};
    });
}

function bind({token,authParams, enterpriseId, email, mobile}) {
    const {orgName} = authParams;
    let params;
    if (orgName) {
        params = {orgName, token};
    } else {
        params = {email, mobile, token};
    }
    return getAuthResult(params).then(res => {
        const {accountId, orgId, name, psnSmFlowId, orgSmFlowId} = res;
        if (!name) {
            return Promise.reject('getAuthResult is error: ' + res);
        }
        const data = {
            token,
            name,
            authParams,
            wardType: 0, // 1：对内；0：对外；目前该saas分支只是针对对外的，所以这里写死为0；
        };
        if (email) {
            data.email = email;
        } else if (mobile) {
            data.mobile = mobile;
        }
        if (orgName) {
            if (orgName !== name) {
                return Promise.reject('orgName error: '+ name + orgName);
            }
            if (enterpriseId) {
                data.tenantId = enterpriseId;
            }
            data.flowId = orgSmFlowId;
            data.orgId = orgId;
        } else {
            data.accountId = accountId;
            data.flowId = psnSmFlowId;
        }
        return bindAuthAccount(data);
    });
}
