import axios from "axios";
import {getStore} from "../store";
import cuid from "cuid";
import qs from "qs";
import {sleep} from "../pages/PublicPage/service";

const WebToolAPIs = {
    upload: "platform/api/doc",
    convert: "platform/api/doc/convert",
    taskStatus: "platform/api/task",
    download: "platform/api/download",
    downloadZip: "platform/api/downloadzip"
};
export function doUpload ({file, token, uploadCb, cancelCb}) {
    token = token || _getToken();
    const url = _getUrl() + `/${WebToolAPIs.upload}?access_token=${token}`;
    const fd = new FormData();
    fd.append('file', file);
    const source = getCancelSource();
    const config = {
        onUploadProgress: function(progressEvent) {
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
            uploadCb && uploadCb(percent);
        },
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        cancelToken: source.token
    }
    cancelCb && cancelCb(source);
    return axios.post(url, fd, config).then(data => {
        if (data && data.docid) {
            return data.docid;
        }
        return Promise.reject(data);
    });
}

export function doConvert({docList, token}) {
    const type = 21;
    const config = {
        confText: JSON.stringify(getCfg()),
        fileIndexList: "",
        overlayText: "",
        pageOrganizer: "",
        redactText: ""
    }

    token = token || _getToken();
    let data;
    if(docList && docList.length !== 0){
        data = Object.assign({ documents: docList }, config);
    }
    const url =  _getUrl() + `/${WebToolAPIs.convert}?access_token=${token}&type=${type}`;
    return axios.post(url, data).then(data => {
        if (data) {
            return data;
        }
        return Promise.reject(data);
    });
}

export function getStatus({taskId, token, cb}) {
    token = token || _getToken();
    let data = qs.stringify({
        taskid: taskId,
        random: cuid(),
        access_token: token
    });
    let url = _getUrl() + `/${WebToolAPIs.taskStatus}?${data}`;
    return axios.get(url).then(data => {
        if (data && data.percentage) {
            cb && cb(data.percentage);
            if (data.percentage === 100) {
                return data;
            } else {
                return sleep(2000).then(() => getStatus({taskId, token, cb}));
            }
        }
        return Promise.reject(data);
    });
}

export function getDownloadUrl({docid, filename = 'stream', token}) {
    token = token || _getToken();
    let url
    let data = {
        docid: docid,
        filename,
        random: cuid(),
        access_token: token
    };
    data = qs.stringify(data);
    url = _getUrl() + `/${WebToolAPIs.download}?${data}`;
    return url;
}

export function downloadPdf({url, cb}) {
    return axios.get(url,
        {
            responseType: "arraybuffer",
            onDownloadProgress: (progressEvent) => {
                if (progressEvent.lengthComputable) {
                    const progress = Math.round(progressEvent.loaded / progressEvent.total * 100);
                    cb && cb(progress);
                }
            }
        }
    ).then(res => {
        const result = res.data;
        if (result) {
            return result;
        }
        return Promise.reject(res);
    });
}

function _getToken() {
    const userInfo = getStore('userInfo') || {};
    return userInfo?.token || '';
}

function _getUrl() {
    const configUrls = getStore('configUrls') || {};
    return configUrls.platformApiUrl;
}

function getCancelSource() {
    const source = axios.CancelToken.source();
    return source;
}

function getCfg() {
    return {
            "width": 595,//宽
            "height": 842,//高
            "top": 18,//位置
            "right": 18,//位置
            "bottom": 18,//位置
            "left": 18,//位置
            "rotate": 0,//0:no rotate;1:90 degrees;2:180 degress;3:270 degress
            "timeout": 120,
            "isConvertLink": true, //是否不转换link
            "pageMode": "1",//是否单页模式
            "toGenerateBookmarks":false,//Create bookmarks
            "isGenerateTag":true,
            "isOptTagTree":false
        };
}