import React, { Component } from 'react';
import validEsignImg from './assets/validEsign.svg'
import openFile from './assets/openFile.svg'
import { Button, Input, Checkbox, Message, Form, Upload } from 'antd';
import {checkJwtToken, validateSAMLResponseAllInfo} from "../../../request/user";
import './index.less'
import {initData} from "../auditTrail/services";
import {
    ERROR_AC_SAML_VALID_FAIL_100010,
    ERROR_AC_SAML_VALID_FAIL_100011,
    ERROR_AC_SAML_VALID_FAIL_100019,
    ERROR_AC_SAML_VALID_FAIL_110011,
    ERROR_AC_SAML_VALID_FAIL_140004,
    ERROR_AC_SAML_VALID_FAIL_140005,
    ERROR_AC_SAML_VALID_FAIL_140006,
    ERROR_AC_SAML_VALID_FAIL_140007,
    ERROR_AC_SAML_VALID_FAIL_140008,
    ERROR_AC_SAML_VALID_FAIL_140009,
    ERROR_AC_SAML_VALID_FAIL_140010,
} from "../../../request/error_container";
import {inPhantom} from "../utils/util";
import {getQueryVariable} from "../service";
import {FROM_AUDIT_TRAIL, getParentUrl} from "./utils";
const { TextArea } = Input;
let formRef = React.createRef()

const { Dragger } = Upload;

class EsignTools extends Component {

    constructor(props) {
        super(props)
        this.state = {
            signaturesInfo: null,
            activeIndex : 0,
            samlAttrs:{},
            authType: 'SAML',
            SAMLResponse:'',
            showValidResult:false,
            auth:null,
            showJwtValidImg: false,
            ignoreTimeForJwt: true,
            showVerifyToolBgImg: true
        }
    }

    componentDidMount() {
        window.addEventListener('message', this.handleMessage.bind(this), false);
        this.initEnv()
    }

    handleMessage(res) {
        if (res.data.type === 'esign_tool_wraper') {
            const data = res.data.data;
            this.getSignatures(data);
        }
    }

    async initEnv() {
        const fromAuditTrail = getQueryVariable('parent_web') === "AUDIT_TRAIL";
        if (inPhantom() || fromAuditTrail) {
            let { auditTrailData: signaturesInfo } = await initData();
            this.getSignatures(signaturesInfo);
        }
    }

    getSignatures = async (signaturesInfo)=>{
        if(!signaturesInfo) {
            Message.error("请打开正确的对内签署文档")
            return
        }
        const auth_inner = signaturesInfo.auth.filter(item=>item.method === 'SAML' || item.method === 'JWT');
        if(!auth_inner || auth_inner.length === 0){
            Message.error("请打开正确的对内签署文档")
            return
        }
        signaturesInfo.auth = this.filterSignatures(auth_inner)
        this.setState({
            signaturesInfo
        })
        this.resolveSigInfo(signaturesInfo)
    }
    filterSignatures = (auth)=>{
        let newAuth =  auth.reduce((pre,cur)=>{
            let flag = false;
            pre.forEach(item=>{
                if(item.name === cur.name && item.companyId === cur.companyId && item.email === cur.email){
                    if(item.SAMLResponse === cur.SAMLResponse){
                        flag = true
                    }
                }
            })
            if(flag){
                return pre
            }else {
                pre.push(cur)
                return pre
            }
        },[])
        return newAuth
    }

    resolveSigInfo = (signaturesInfo, index = 0) => {
        try {
            if (signaturesInfo && Object.keys(signaturesInfo).length > 0) {
                this.setState({
                    showVerifyToolBgImg: false
                })
                const authType = signaturesInfo.auth[index].method;
                let SAMLResponse = signaturesInfo.auth[index].SAMLResponse;
                if (authType === 'SAML') {
                    let samlAttrs = this.getSAMLattrs(SAMLResponse)
                    this.setState({
                        activeIndex: index,
                        samlAttrs,
                        SAMLResponse:SAMLResponse,
                        showValidResult:false,
                        auth:signaturesInfo.auth[index],
                        authType: "SAML",
                        showJwtValidImg: false
                    }, () => {
                        this.setInput()
                    })
                } else if(authType === 'JWT') {
                    this.setState({
                        activeIndex:index,
                        SAMLResponse:SAMLResponse,
                        showValidResult:false,
                        auth:signaturesInfo.auth[index],
                        showJwtValidImg: true,
                        authType: "JWT"
                    })
                } else {
                    this.setState({
                        showValidResult:false,
                        showJwtValidImg: false,
                        showVerifyToolBgImg: true
                    })
                }
            }
        } catch (e) {
            console.error(e);
            this.setState({
                showValidResult:false,
                showJwtValidImg: false,
                showVerifyToolBgImg: true
            })
        }
    }
    validEsign = () => {
        if (this.state.authType === "SAML") {
            formRef && formRef.current.validateFields(['IdP_EntityId', 'SP_EntityId', 'Target_URL', 'X_509'], (err, values) => {}).catch(e => {
                console.log(e)
            });
            if (formRef && formRef.current.validateFields) {
                formRef.current.validateFields(['IdP_EntityId', 'SP_EntityId', 'Target_URL', 'X_509', 'ignoreTime']).then(values => {
                    const { SAMLResponse } = this.state;
                    validateSAMLResponseAllInfo({
                        bIgnoreTime: values.ignoreTime,
                        IDPID:values.IdP_EntityId,
                        SPID:values.SP_EntityId,
                        SPUrl:values.Target_URL,
                        assertion:SAMLResponse,
                        IDPX509Certificate:values.X_509
                    }).then(()=>{
                        this.setState({showValidResult: true})
                        Message.success("验证成功")
                    }).catch(key => {
                        let errorMsg = this.getErrorMsg(key)
                        Message.error(errorMsg)
                        this.setState({showValidResult: false})
                    })
                }).catch(e => {
                    console.log(e)
                })
            }
        } else {
            checkJwtToken(this.state.SAMLResponse, this.state.ignoreTimeForJwt).then(() => {
                this.setState({showValidResult: true})
                Message.success("验证成功")
            }).catch(key => {
                let errorMsg = this.getErrorMsg(key)
                Message.error(errorMsg)
                this.setState({showValidResult: false})
            })
        }
    }
    getErrorMsg=(key)=>{
        let errorMsg = '';
        switch(key){
            case ERROR_AC_SAML_VALID_FAIL_100010:
                errorMsg="JWT验证失败，Token无效或过期"
                break;
            case ERROR_AC_SAML_VALID_FAIL_100011:
                errorMsg="Token错误"
                break;
            case ERROR_AC_SAML_VALID_FAIL_100019:
                errorMsg="Token为Null"
                break;
            case ERROR_AC_SAML_VALID_FAIL_110011:
                errorMsg="assertion 或 IDPX509Certificate 为空"
                break;
            case ERROR_AC_SAML_VALID_FAIL_140004:
                errorMsg="assertion 格式错误或无效"
                break;
            case ERROR_AC_SAML_VALID_FAIL_140005:
                errorMsg="时间校验无效"
                break;
            case ERROR_AC_SAML_VALID_FAIL_140006:
                errorMsg="签名无效"
                break;
            case ERROR_AC_SAML_VALID_FAIL_140007:
                errorMsg="身份提供商实体ID错误"
                break;
            case ERROR_AC_SAML_VALID_FAIL_140008:
                errorMsg="SP实体ID错误"
                break;
            case ERROR_AC_SAML_VALID_FAIL_140009:
                errorMsg="SP断言用户服务URL错误"
                break;
            case ERROR_AC_SAML_VALID_FAIL_140010:
                errorMsg="X.509公钥证书错误"
                break;
            default:
                errorMsg="未知错误"
        }
        return errorMsg
    }
    hasErrors=(fieldsError)=> {
        return Object.keys(fieldsError).some(field => fieldsError[field]);
      }
    setInput=()=>{
        const { samlAttrs } = this.state;
        const {IdP_EntityId,SP_EntityId,Target_URL,X_509} = samlAttrs;
        formRef && formRef.current.setFieldsValue({
            IdP_EntityId,
            SP_EntityId,
            Target_URL,
            X_509
            });
    }
    getSAMLattrs=(samlBase64)=>{
        const saml = window.atob(samlBase64)
        let samlDom = loadXML(saml)
        let email = samlDom.querySelector('Attribute[Name="email"]').querySelector('AttributeValue').innerHTML;
        let IdP_EntityId = samlDom.querySelector('Issuer').innerHTML;
        let SP_EntityId = samlDom.querySelector('AudienceRestriction').querySelector('Audience').innerHTML;
        let Target_URL = samlDom.querySelector('SubjectConfirmationData').getAttribute('Recipient')
        let X_509 = samlDom.querySelector('X509Certificate').innerHTML
        let obj = {
            email:email,
            IdP_EntityId:IdP_EntityId,
            SP_EntityId:SP_EntityId,
            Target_URL:Target_URL,
            X_509:X_509,
        }
        return obj
    }
    onIgnoreTimeFn = (e) => {
        const checked = e.target.checked;
        this.setState({
            ignoreTimeForJwt: checked
        })
    }

    onOpenFile = (file) => {
        // 向父窗口发送消息
        const parentUrl = getParentUrl()
        window.parent.postMessage({ type: 'esign_tool', data: file }, parentUrl)
    }

    render() {
        const { activeIndex,samlAttrs,auth,showValidResult, signaturesInfo, showJwtValidImg, showVerifyToolBgImg, ignoreTimeForJwt  } = this.state;
        let fileName = signaturesInfo && signaturesInfo.fileName;
        const isInnerDoc = signaturesInfo && signaturesInfo.auth.filter(item => item.mode === 'in').length > 0
        const chooseProps = {
            accept: '.pdf',
            showUploadList: false,
            multiple:false,
            customRequest: ({file}) => {
                console.log('file', file)
                if (file) {
                    // this.esignToolsRef.current.resetFields();
                    this.onOpenFile(file);
                }
            }
        }

        const fromAuditTrail = getQueryVariable('parent_web') === FROM_AUDIT_TRAIL


        return <div className='wrapEsignTools'>
            <div className="header">
                <img src={validEsignImg} alt="" />
                <span className='title'>签名身份信息验证系统</span>
            </div>
            <div className="container">
                <div className="containerHeader">
                    {
                        !fromAuditTrail &&
                        <Upload {...chooseProps}>
                            <Button className="openFile" type="primary"><img src={openFile} alt="" />上传文档</Button>
                        </Upload>
                    }
                    {fileName && !showVerifyToolBgImg &&
                        <div className='fileName'>
                            <p>当前文档：<span>{getFileName(fileName)}</span></p>
                        </div>
                    }
                </div>
               <div hidden={!showValidResult } className="validResult">
                   <p className='successText'><i className="successIcon"></i>验证成功</p>
                   <p className='validInfo'>
                       企业名称：<span>{auth?.companyName}</span>
                       用户名：<span>{auth?.signerName}</span>
                       邮箱：<span>{auth?.email}</span>
                    </p>
               </div>
                <div className="docBoard">
                    {
                        showVerifyToolBgImg && !fromAuditTrail &&
                        <div className='container'>
                            <Dragger
                                accept=".pdf"
                                multiple={false}
                                showUploadList={false}
                                {...chooseProps}>
                                <div className="content-wrapper">
                                    <div className="beforeUploadBgImg"></div>
                                    <div className="primaryMsg">将文档拖放到这里上传</div>
                                    <div className="secondMsg">（暂无签名，可上传文档，识别文档签名者）</div>
                                </div>
                            </Dragger>
                        </div>
                    }
                    { !showVerifyToolBgImg &&
                        <>
                            <div className="board_left" id="pdf-viewer" >
                                <p className="title bold"> 签名者：</p>
                                {!isInnerDoc &&
                                    <div className="empty">
                                        <div className="NoData">
                                            <p>暂无签名者</p>
                                            <p>可上传文档，识别文档签名者</p>
                                        </div>
                                    </div>
                                }
                                <div hidden={!isInnerDoc} className="wrapList">
                                    <ul>
                                        {signaturesInfo&&signaturesInfo.auth&&signaturesInfo.auth.map((item,index)=>{
                                            return (
                                                <li key={index} className={(index === activeIndex)?'active':''} onClick={()=>{this.resolveSigInfo(signaturesInfo, index)}}>
                                                    <p>
                                                        <span className='name'>{item.signerName}</span>
                                                        {item.email && <span>({item.email})</span>}
                                                    </p>
                                                    <p>{item.companyName}</p>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                            <div className="board_right">
                                {
                                    !showJwtValidImg &&
                                    <>
                                        <p className="title bold">验证条目：</p>
                                        <Form ref={formRef} layout='vertical'>
                                            <Form.Item
                                                label="身份提供商实体ID"
                                                name="IdP_EntityId"
                                                value={samlAttrs.IdP_EntityId}
                                                rules={
                                                    [
                                                        {
                                                            required: true,
                                                            message: "SP实体ID",
                                                        }]
                                                }
                                            >
                                                <Input/>
                                            </Form.Item>
                                            <Form.Item
                                                label="SP实体ID"
                                                name="SP_EntityId"
                                                value={samlAttrs.SP_EntityId}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "SP实体ID",
                                                    }
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            {/* <Form.Item
                                label="身份提供商SSO URL"
                            >
                                {getFieldDecorator('SSO_urL', {
                                    rules: [
                                        {
                                            required: true,
                                            message: 'Please input your SSO_urL!',
                                        }],
                                })(<Input value={samlAttrs.SP_EntityId} />)}
                            </Form.Item> */}
                                            <Form.Item
                                                label="SP断言用户服务URL"
                                                name="Target_URL"
                                                value={samlAttrs.Target_URL}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "SP断言用户服务URL",
                                                    }]}
                                            >
                                                <Input  />
                                            </Form.Item>
                                            <Form.Item
                                                label="X.509公钥证书"
                                                name="X_509"
                                                value={samlAttrs.X_509}
                                                rules= {
                                                    [
                                                        {
                                                            required: true,
                                                            message: "X.509公钥证书",
                                                        }
                                                    ]
                                                }
                                            >
                                                <TextArea   autoSize={{
                                                    minRows: 4, maxRows: 4
                                                }} />
                                            </Form.Item>
                                            <Form.Item
                                                name="ignoreTime"
                                                value={samlAttrs.ignoreTime}
                                                initialValue={true}
                                                valuePropName='checked'>
                                                <Checkbox>忽略时间问题</Checkbox>
                                            </Form.Item>
                                            <Form.Item wrapperCol={{ span: 24 }}>
                                                { /** disabled={this.hasErrors(formRef.current.getFieldsError()) || !isInnerDoc} **/ }
                                                <Button type="primary" shape="round" className='validSaml' onClick={this.validEsign}>验证</Button>
                                            </Form.Item>
                                        </Form>
                                    </>
                                }
                                {
                                    showJwtValidImg &&
                                    <div>
                                        <div className="eSignCNJwtValidImg"></div>
                                        <div className="eSignCNIgnoreTime"><Checkbox onChange={this.onIgnoreTimeFn} checked={ignoreTimeForJwt}>忽略时间问题</Checkbox></div>
                                        <Button disabled={!isInnerDoc} type="primary" shape="round" className='validSaml' onClick={this.validEsign}>验证</Button>
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    }
}
export default EsignTools;


function loadXML (xmlString) {
    let xmlDoc = null;
    //判断浏览器的类型
    //支持IE浏览器 
    if (!window.DOMParser && window.ActiveXObject) {   //window.DOMParser 判断是否是非ie浏览器
        let xmlDomVersions = ['MSXML.2.DOMDocument.6.0', 'MSXML.2.DOMDocument.3.0', 'Microsoft.XMLDOM'];
        for (let i = 0; i < xmlDomVersions.length; i++) {
            try {
                xmlDoc = new window.ActiveXObject(xmlDomVersions[i]);
                xmlDoc.async = false;
                xmlDoc.loadXML(xmlString); //loadXML方法载入xml字符串
                break;
            } catch (e) {
            }
        }
    }
    //支持Mozilla浏览器
    else if (window.DOMParser && document.implementation && document.implementation.createDocument) {
        try {
            /* DOMParser 对象解析 XML 文本并返回一个 XML Document 对象。
             * 要使用 DOMParser，使用不带参数的构造函数来实例化它，然后调用其 parseFromString() 方法
             * parseFromString(text, contentType) 参数text:要解析的 XML 标记 参数contentType文本的内容类型
             * 可能是 "text/xml" 、"application/xml" 或 "application/xhtml+xml" 中的一个。注意，不支持 "text/html"。
             */
            let domParser = new window.DOMParser();
            xmlDoc = domParser.parseFromString(xmlString, 'text/xml');
        } catch (e) {
           console.log(e)
        }
    }
    else {
        return null;
    }

    return xmlDoc;
}
function getFileName(name){
    var json = name.split(".")
    return json[0];
    }