/**
 * 公共插件，用于online和pc的自签，发起，参与的身份验证
 * 插件通过iframe的方式嵌入到页面中，通过query params向插件发送消息，通过postMessage返回结果
 * 此插件不处理具体的签章逻辑，只根据传入的需验证类型参数对身份进行分级前置验证以及与此对应的后续操作流程
 * 验证成功时，会通过消息返回success；验证失败时，会在界面上展示错误和可继续完善信息

 * 请求路径 pcDomain + /auth

 * 传入必填参数
 * 登录令牌 tk, token
 * 签署类型 t, type = 1/2/3/10 1:自签/发起 2:参与 3 仅需要个人实名 4 需要同时完成个人和企业实名 10：易签宝回调
 *  1：自签/发起： 检测个人实名，如果带有组织id，则会检测组织授权、企业实名。如果企业验证失败仍返回成功，但只有个人印章，否则返回个人和企业印章
 *  2：参与： 根据传入的参数进行强制个人和企业实名验证
 *  3: 仅个人实名，不验证企业实名
 *  4: 先验证个人实名，个人成功后再验证企业实名，如果不带cn，则会要求输入企业名称。只有都成功才返回成功
 *  fc: 个人实名成功后，加此参数，表示强制企业实名，如果不带cn，则会要求输入企业名称，主用于type=1场景
 *  cn: 企业名称，用于验证或创建企业，可用于type=1或4场景
 *  10：易签宝回调
 * 调用方 c, client = 0/1/2 0：pc端 1：移动端 2：客户端

 * 传入可选参数
 * 自定义header中x-client-from字段，用于区分不同的调用方 cf, clientFrom 如不传则通过client值判断
 * 在线签流程Id pi, processId 用于加入组织时，管理员审核通过后，邮件内的链接跳转
 * 企业Id ei, enterpriseId
 * 对外或对内 o, isOut = 0/1 0:对内 1:对外
 * 企业或个人 og, isOrg = 0/1 0:个人 1:企业
 * 是否展示界面 s, isShow = 0/1 0:不展示 1:展示（暂未使用）
 * 需判断的邮箱 e, email
 * 需判断的手机号 m, mobile
 * 需判断的姓名 n, name
 * 需判断的公司名 cn, companyName
 * 需判断的企业id ci, companyId
 * 调用方自传字段，会原样返回 from
 * 实名认证成功后的重定向Link rdl redirectLink
 * 在请求通过后是否使用缓存 uc, useCache = 0/1 0:不使用 1:使用
 * 非流程签时，强制需要企业实名判断（默认只要个人实名成功即返回成功）fc forceCompanyAuth = 0/1 0:不强制 1:强制

 * 自动生成参数 调用方不可自传！
 * 易签宝回传自定义状态 st stat 默认0，1个人实名，2企业实名，3企业创建（无企业id）4 个人实名（广告位）5 个人实名信息存在（广告位）
 * 创建企业成功状态 a isCreated
 * 用于实名成功后重载页面时的参数 可选 loadParams （需要url encode）

 * 返回的消息结构体
 * name: esignCNAuthPlugin 用于标识消息来源
 * type: close|resize       返回值类型，close表示结束，resize表示调整iframe大小
 * relogin: boolean         type为close时有效，提示调用方重新登录
 * setName: boolean         type为close时有效，提示调用方打开用户中心设置用户名
 * success: boolean         type为close时有效，true表示验证通过，false表示验证失败
 * person: object           type为close，且sucess为true时有效，实名个人信息
 * company: object          type为close，且sucess为true时有效，实名企业信息
 * personSeals: array       type为close，且sucess为true时有效，个人印章列表
 * orgSeals: array          type为close，且sucess为true时有效，企业印章列表
 * enterpriseId: string     type为close，且sucess为true时有效，企业id
 * enterpriseRole number    type为close，且sucess为true时有效，企业角色
 * fullName: string         type为close，且sucess为true时有效，个人全名
 *
 * width: string            type为resize时有效，提示调用方调整iframe宽度
 * height: string           type为resize时有效，提示调用方调整iframe高度

 */

import {getParams} from "../../utils";
import {getOrgInfo, getRelationById, getUserInfo} from "../../request/user";
import {binding, checkAuth, getOrgSeals, getPersonSeals, getSubscription, setHeaderClient} from "../../request/esign";
import React, {useEffect, useState} from "react";
import {ERROR_EQB_AUTH_EXPIRED, ERROR_EQB_ORG_TOKEN_EXPIRED} from "../../request/error_container";
import MainComponent from "./main";

export const AUTH_PLUGIN_URI = '/auth';

export default function AuthPlugin() {
    const [codes, setCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [querys, setQuerys] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const [tips, setTips] = useState('');
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        _sendMsg({type: 'resize', width: '640px', height: '320px'});
        const queryParams = _formatQuery();
        if (!queryParams) {
            return;
        }
        const {isOut, token, enterpriseId, name, email, mobile, companyName, companyId, type, useCache, client, clientFrom, forceCompanyAuth} = queryParams;
        setHeaderClient({client, value: clientFrom});
        // 使用缓存
        if (useCache) {
            try {
                const data = JSON.parse(localStorage.getItem(window.location.search));
                if (data && data.success) {
                    _sendMsg(data);
                    return;
                }
            } catch (e) {

            }
        }
        setTips('获取帐户信息…');
        _getUserInfo(token, enterpriseId).then(info => {
            if (!info) {
                return;
            }
            setQuerys(queryParams);
            setUserInfo(info);
            if ([1, 2, 3, 4].includes(type)) {
                requestFn(info);
            }
        });

        function requestFn(info) {
            const request = type !== 2 ? _checkSeals : _checkFlow;
            request({info, forceCompanyAuth, isOut, name, email, mobile, companyName, companyId, setTips, type}).then(optList => {
                resultFn(optList, info);
            }).catch(() => {
                setIsError(true);
            }).finally(() => {
                setIsLoading(false);
            });
        }

        function resultFn(optList, info) {
            if (optList.length === 0) {
                sendSuccess(info, queryParams);
            } else {
                setCodes(optList);
            }
        }
    },[]);

    const childProps = {
        codes,
        isLoading,
        isError,
        tips,
        querys,
        userInfo,
        sendMsg: _sendMsg
    }
    return <MainComponent {...childProps}></MainComponent>
}

export function sendSuccess(info, queryParams) {
    _sendMsg({
        type: 'close',
        success: true,
        enterpriseId: info.enterpriseId,
        enterpriseRole: info.enterpriseRole,
        person: info.person,
        company: info.company,
        personSeals: info.personSeals,
        orgSeals: info.orgSeals,
        fullName: info.fullname,
    }, queryParams);
}
function _formatQuery() {
    let query = {};
    const queryParams = getParams() || {};
    query.type = queryParams.t || queryParams.type;
    query.token = queryParams.tk || queryParams.token;
    query.client = queryParams.c || queryParams.client;
    query.processId = queryParams.pi || queryParams.processId;
    query.enterpriseId = queryParams.ei || queryParams.enterpriseId;
    query.name = queryParams.n || queryParams.name;
    query.email = queryParams.e || queryParams.email;
    query.mobile = queryParams.m || queryParams.mobile;
    query.companyId = queryParams.ci || queryParams.companyId;
    query.companyName = queryParams.cn || queryParams.companyName;
    query.isOut = queryParams.o || queryParams.isOut;
    query.isOrg = queryParams.og || queryParams.isOrg;
    query.from = queryParams.from;
    query.useCache = queryParams.uc || queryParams.useCache;
    query.isShow = queryParams.s || queryParams.isShow;
    query.isCreated = queryParams.a || queryParams.isCreated;
    query.stat = queryParams.st || queryParams.stat;
    query.redirectLink = queryParams.rdl || queryParams.redirectLink;
    query.clientFrom = queryParams.cf || queryParams.clientFrom;
    query.forceCompanyAuth = queryParams.fc || queryParams.forceCompany;

    const type = Number(query.type) || 0;
    if (!query.token && type === 10) {
        const tempParams = JSON.parse(sessionStorage.getItem("authPluginParams") || '{}');
        query = {...tempParams, ...query};
        query.code = queryParams.code;
        query.flowId = queryParams.flowId;
        query.oid = queryParams.oid;
        query.appId = queryParams.appId;
        query.token = tempParams.token;
    }
    if (!query.token || type < 1) {
        return null;
    }
    if (type !== 10) {
        sessionStorage.setItem("authPluginParams", JSON.stringify(query));
    }

    query.type = type;
    query.client = Number(query.client) || 0;
    query.stat = Number(query.stat) || 0;
    query.isOut = Number(query.isOut) > 0;
    query.isOrg = Number(query.isOrg) > 0;
    query.isShow = Number(query.isShow) > 0;
    query.isCreated = Number(query.isCreated) > 0; // 是否为创建成功后的reload
    query.useCache = Number(query.useCache) > 0;
    query.forceCompanyAuth = Number(query.forceCompanyAuth) > 0;

    return query;
}

// 流程签
// -1 组织未订阅；-2 组织未授权；-3 个人未实名或过期；-4 实名不符；-5 邮箱为空；-6 邮箱不符；-7 手机号为空；-8 手机号不符；
//          -9 企业未实名或过期; -10 无组织信息；-11 企业名不符; -12 企业id不符;
//          -13 申请加入组织；-14 创建组织,-15 通知企业重新授权; -16 ac存在多个同名企业； -17 对内签时当前用户无用户名
// -18 进输入企业名称页
async function _checkFlow({info, name, email, mobile, companyName, companyId, setTips}) {
    const optList = [];
    const isOut = !companyId;

    if (email) {
        if (!info.userEmail) {
            optList.push(-5);
        } else if (info.userEmail !== email) {
            return [-6];
        }
    }
    if (mobile) {
        if (!info.userMobile) {
            optList.push(-7);
        } else if (info.userMobile !== mobile) {
            return [-8];
        }
    }

    // 对内只可以切换组织，不能创建
    if (!isOut) {
        if (!info.fullname) {
            return [-17];
        }
        if (!companyId) {//对内默认需要传companyId
            if (optList.length === 0) {
                return [-12];
            } else {
                return optList;
            }
        }
        const result = await _checkOrg({isOut, info, companyId, setTips, optList});
        if (result.errCode !== 0) {
            return [result.errCode];
        }
        // 对内不再验证姓名
        return optList;
    }

    if (!name) {//对外默认需要传name
        if (optList.length === 0) {
            return [-4];
        } else {
            return optList;
        }

    }

    setTips('检查实名信息…');
    const {person, orgList} = await _getUserRealInfo(info.token) || {};
    let isBinding = true;
    if (!person || !person.accountId) {
        isBinding = false;
        optList.push(-3);
    } else if (person.name !== name) {
        return [-4];
    }

    let company;
    if (companyName) {
        const result = await _checkOrg({isOut, info, companyName, setTips, optList});
        if (!result.success) {
            if (result.errCode !== 0) {
                return [result.errCode];
            } else {
                return optList;
            }
        }
        if (orgList && Array.isArray(orgList) && orgList.length > 0) {
            orgList.reverse().some(item => {
                if (item.tenantId === info.enterpriseId) {
                    company = item;
                    return true;
                }
                return false;
            })
        }
        if (!company) {
            info.person = person; // 用于企业实名
            isBinding = false;
            optList.push(-9);
        }
    }

    return await _checkSealToken(
        {isBinding, info, setTips, optList, company, person});
}

// 自签或发起
async function _checkSeals({type, forceCompanyAuth, info, companyName, isOut, setTips}) {
    const optList = [];

    if (info.enterpriseId) {
        setTips('检查组织授权信息…');
        const authRes = await _checkAcPermission(isOut, info.enterpriseId, info.token);
        if(authRes < 1) {
            return [authRes];
        }
    }

    // 对内
    if (!isOut) {
        if (!info.enterpriseId) {
            return [-10];
        }
        if (!info.fullname) {
            return [-17];
        }
        return [];
    }

    if (!info.userMobile && !info.userEmail) {
        optList.push(-7);
    }

    setTips('检查实名信息…');
    const {person, orgList} = await _getUserRealInfo(info.token) || {};
    let isBinding = true;
    if (!person || !person.accountId) {
        isBinding = false;
        optList.push(-3);
    }

    if (type === 4 && optList.length > 0) { // 个人认证失败 直接返回
        return optList;
    }

    if (type === 3) { // 仅检查个人是否实名
        if (optList.length === 0) {
            return [3];
        }
        return optList;
    }

    // 当个人登录且带有企业名时，对外切换，加入或创建组织
    if (companyName && !info.enterpriseId ) {
        if (companyName === 'setting') {
            return [-18];
        }
        const result = await _checkOrg({isOut, info, companyName, setTips, optList});
        if (!result.success) {
            if (result.errCode !== 0) {
                return [result.errCode];
            } else {
                return optList;
            }
        }
    }

    let company;
    if (info.enterpriseId || type === 4) {
        if (orgList && Array.isArray(orgList) && orgList.length > 0) {
            orgList.reverse().some(item => {
                if (item.tenantId === info.enterpriseId) {
                    info.enterpriseName = item.name;
                    company = item;
                    info.enterpriseName = item.name;
                    return true;
                }
                return false;
            })
        }

        if (type === 4) {
            forceCompanyAuth = true;
        }
        if (!company && forceCompanyAuth) {
            isBinding = false;

            if (info.enterpriseId) {
                const {tenantName} = await getSubscription({token: info.token, tenantId: info.enterpriseId});
                info.enterpriseName = tenantName;
                info.person = person; // 用于企业实名
                optList.push(-9);
            } else {
                return [-18];
            }

        }
    }

    return await _checkSealToken(
        {isBinding, info, setTips, optList, company, person});
}

// 对外可以切换、创建或加入组织，对内只能切换或加入
async function _checkOrg({info, companyId, companyName, isOut, setTips, optList}) {
    const result = {
        success: true,
        errCode: 0,
    }

    if (isOut) {
        setTips('获取所在组织信息…');
        const orgData = await getOrgInfo(companyName, info.token);
        let index = 0;
        if (orgData && Array.isArray(orgData.enterpriseList) && orgData.enterpriseCount > 0) {
            if (orgData.enterpriseList.length > 1) {
                index = orgData.enterpriseList.findIndex(item => item.id === info.enterpriseId);
                if (index < 0) {
                    result.success = false;
                    result.errCode = -16;
                    return result;
                }
            }
            const org = orgData.enterpriseList[index];
            companyId = org.id;
        } else { // 创建组织
            if (!optList.includes(-5) && !info.userEmail) {// 加入组织需要有邮箱
                optList.push(-5);
            }
            optList.push(-14);
            result.success = false;
            return result;
        }
    }

    if (!info.enterpriseId || companyId !== info.enterpriseId) {
        setTips('获取组织授权信息…');
        const licenseData = await getRelationById(companyId, info.token);
        if (licenseData && Array.isArray(licenseData.licenses)) {
            const tempInfo = await _getUserInfo(info.token, companyId); // 将当前登录切换为指定企业登录状态
            info.enterpriseId = tempInfo.enterpriseId;
            info.enterpriseRole = tempInfo.enterpriseRole;
        } else { // 申请加入
            if (!optList.includes(-5) && !info.userEmail) {
                optList.push(-5);
            }
            info.joinOrgId = companyId;
            optList.push(-13);
            result.success = false;
            return result;
        }
    }
    setTips('检查组织授权信息…');
    const authRes = await _checkAcPermission(isOut, info.enterpriseId, info.token);
    if(authRes < 1) {
        result.success = false;
        result.errCode = authRes;
    }
    return result;
}

async function _checkSealToken({isBinding, info, person={}, company={}, setTips, optList}) {
    if (!isBinding) {
        return optList;
    }
    setTips('检查签章授权信息…');
    const res = await _getSealList(info.token, person.accountId, info.enterpriseId, company.orgId);
    if (res.status !== 0) {
        switch (res.status) {
            case -1:
                optList.push(-3);
                break;
            case -2:
                optList.push(-9);
                break;
            default:
                return [-15];
        }
    } else {
        info.personSeals = res.personSeals;
        info.orgSeals = res.orgSeals;
    }

    info.person = person;
    info.company = company;
    return optList;
}


// 获取实名个人和企业信息
function _getUserRealInfo(token, enterpriseId = undefined) {
    return binding(token, enterpriseId).then(res => {
        // const {person, orgList} = res;
        return res;
    })
}

// 获取实名后的印章列表，用于验证易签宝token,code等信息是否过期，以决定是否需要重新实名或授权
function _getSealList(token, accountId, enterpriseId = undefined, orgId = undefined) {
    const result = {
        personSeals: [],
        orgSeals: [],
        status: 0,
    }
    return getPersonSeals({accountId, token}).then(seals => {
        if (Array.isArray(seals) && seals.length > 0) {
            result.personSeals = seals;
        }
        if (!orgId || !enterpriseId) {
            return result;
        }
        return getOrgSeals({orgId, accountId, token, enterpriseId})
    }).then(seals => {
        if (Array.isArray(seals) && seals.length > 0) {
            result.orgSeals = seals;
        }
        return result;
    }).catch(key => {
        if (ERROR_EQB_AUTH_EXPIRED === key) {
            if (result.personSeals.length > 0) {
                result.status = -2;
            } else {
                result.status = -1;
            }
        } else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
            result.status = -3;
        }
        return result;
    })
}

// 获取用户信息
function _getUserInfo(token, enterpriseId = undefined) {
    return getUserInfo(token, enterpriseId).then(info => {
        info.token = token;
        if (enterpriseId) {
            info.enterpriseId = enterpriseId;
        }
        info.userMobile = _formatCnMobile(info.userMobile);
        return info;
    });
}

// 发送消息给父页面
function _sendMsg (data = {}, queryParams = {}) {
    if (!data) {
        return;
    }
    if (data.reload) {
        const params = getParams() || {};
        const {tk:token, t:type, e:email = '', m:mobile = '', loadParams} = params;
        if (!token && Number(type) === 10) {
            const tempParams = JSON.parse(sessionStorage.getItem("authPluginParams") || '{}');
            // 将tempParams对象转换成url参数
            let querys = AUTH_PLUGIN_URI;
            for (let key in tempParams) {
                let value = tempParams[key];
                if (value === true) {
                    value = 1;
                } else if (value === false) {
                    value = 0;
                } else if((key === 'cn' || key === 'companyName') && data.setName) {
                    value = 'setting';
                } else if(key === 'fc' || key === 'forceCompany') {
                    value = data.fc||0;
                }
                querys += `&${key}=${value}`;
            }
            window.location.replace(origin + querys.replace('&', '?'));
            return;
        } else if(email.includes('*') || mobile.includes('*')) {
            data.success = true;
        } else {
            if (loadParams && Number(type) === 10) {
                window.location.reload(origin + AUTH_PLUGIN_URI + decodeURI(loadParams));
            } else {
                window.location.reload();
            }
            return;
        }
    }

    if (!data.type) {
        return;
    }

    if (!['close', 'resize'].includes(data.type)) {
        return;
    }

    if (data.fromReload) {
        data.reload = true;
    }

    data.name = 'esignCNAuthPlugin';

    queryParams && (data.from = queryParams.from);

    // 启用缓存
    if (data.type === 'close' && data.success) {
        if (queryParams && queryParams.useCache) {
            localStorage.setItem(window.location.search, JSON.stringify(data));
        }
    }

    window.parent.postMessage(data, '*');
}

// 检查ac的订阅或授权权限
async function _checkAcPermission(isOut, enterpriseId, token) {
    const authRes = await checkAuth(isOut, enterpriseId, token);
    if(!authRes || authRes <= 0) {
        if (authRes === -2) {
            return -2;
        } else {
            return -1;
        }
    }
    return 1;
}

// 只支持中国区，并去掉国家号
function _formatCnMobile (userMobile) {

    if (userMobile && userMobile.indexOf('+86') === 0 && userMobile.length === 14) {
        userMobile = userMobile.substr(3, 11);
    }
    if (!userMobile || userMobile.length !== 11) {
        userMobile = '';
    }
    return userMobile;
}