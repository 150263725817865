import {Modal} from "antd";
import {useCallback, useEffect, useState} from "react";
import {doLogout} from "../../utils";
import {getStore, setStore} from "../../store";
import {goAccount} from "../../request/user";

export default function IframeModal({url, isSealPlugin, closeFn, width = 640, height = 320}) {
    const [w, setW] = useState(width);
    const [h, setH] = useState(height);
    const [open, setOpen] = useState(true);

    const authMsgNam = 'esignCNAuthPlugin';
    const willMsgName = 'esignCNWillAuthPlugin';
    const sealMsgNam = 'esignCNSealPlugin';

    const handleOnCancel = useCallback((data = {}) => {
        setOpen(false);
        data.sealPlugin = isSealPlugin;
        closeFn(data);
    }, [closeFn, isSealPlugin]);

    const getMsg = useCallback((e) => {
        if (!e.data || ![authMsgNam, willMsgName, sealMsgNam].includes(e.data.name)) {
            return;
        }
        const data = e.data;
        switch (data.type) {
            case 'close':
                handleOnCancel(data);
                if (isSealPlugin) {
                    break;
                }
                if (data.relogin) {
                    doLogout(getStore('userInfo')?.token, true);
                }else if (data.setName) {
                    goAccount();
                }else if (data.success && data.fullName) {
                    const userInfo = getStore('userInfo') || {};
                    if (userInfo.fullname !== data.fullName) {
                        userInfo.fullname = data.fullName;
                        setStore('userInfo', {...userInfo});
                    }
                }
                break;
            case 'resize':
                if (data.width) {
                    setW(data.width);
                }
                if (data.height) {
                    setH(data.height);
                }
                break;
            default:
                break;
        }
    }, [handleOnCancel, isSealPlugin]);



    useEffect(() => {
        window.addEventListener('message', getMsg );
        return () => window.removeEventListener('message', getMsg);
    }, [getMsg]);
    return (
        <Modal title='福昕电子签章服务' open={open}  onCancel={() => handleOnCancel()}
               footer={null} centered={true} maskClosable={false} width={w} bodyStyle={{padding: '0', margin: 0}} >
            <iframe id="resultFrame" title="eSignCn" allow="clipboard-read; clipboard-write" src={url} height={h} style={{width: '100%', border: 0}}></iframe>
        </Modal>
    );
}
