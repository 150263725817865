import {Form, Select, Button} from "antd";
import {useCallback, useEffect, useRef, useState, forwardRef, useImperativeHandle} from "react";
import {observer} from "mobx-react";
import './index.less'
import {getStore, setStore} from "@/store";
import {
    callStateHandleForPhantom,
    deleteFieldsForPhantom,
    jumpToTheFieldForPhantom, modifyFieldsResizableForPhantom, setCurReceiptItemConfigInfoForPhantom,
    showESignCNDialogAPI
} from "@/request/phantom";
import {shouldCallPhantomApi} from "../../config";
import {
    cleanReceiptItemFormDataCacheFn,
    getReceiptItemFormDataCacheFn,
    setReceiptItemFormDataCacheFn
} from "./service";
import {afterPluginVersion2023_4, isObjectEqual} from "../../utils/util";
import {AutoCompleteUserInfo} from "./AutoCompleteUserInfo";
import {openPagingSealSigSettingDialogForPhantom} from "../../../../request/phantom";
import {UserInfoSelector} from "./UserInfoSelector";

const fieldTypeMap = {
    FullSignature: "签名域",
    PagingSealSignature: "骑缝章",
    Date: "日期域",
    Title: "文本域",
}
const getFieldType = ({fieldType, isPagingSealSignature}) => {
    if (isPagingSealSignature) {
        return "骑缝章"
    }
    return fieldTypeMap[fieldType];
}

const colorList = ["#A0C802", "#E51CE9", "#34B6FF", "#FFA234",
    "#001AFF", "#FF0000", "#40A710", "#A66816",
    "#1F78D3", "#C6B30C", "#CE5700", "#2EC3A0",
    "#6480E8", "#FF71CF", "#FF5D00", "#7D51AA",
    "#547B46", "#008BA9", "#0300A9", "#B53B3B"];

const SignerInfoForm = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => {
        return {
            eSignCN_annotUpdateNotifier: (params) => {
                handleUpdateAnnotsAction(params)
            }
        }
    })

    const { initSignerType, onFinishForm } = props
    const wardType = getStore('plugin').wardType;
    const initFlowInfo = getStore('plugin').initFlowInfo;
    const curOrder = initFlowInfo.curReceiptsOrder;


    const guideMsg1 = getStore('plugin').wardType === 0 ? '为保证签署流程顺利进行，请填写参与人真实信息，邮箱和手机号至少填写一项。' : '参与人可通过输入姓名或邮件搜索，参与人邮箱和手机号至少填写一项。'
    const guideMsg2 = '请选择控件后，在左侧PDF文档中添加'

    const signerTypeOption = [
        {
            value: 'personSigner',
            label: '个人签章参与人'
        },
        {
            value: 'orgSigner',
            label: '企业签章参与人'
        }
    ]

    const [form] = Form.useForm();
    let initFormData = curOrder === -1 ? { signerType: initSignerType } : initFlowInfo.receipts[curOrder]

    const [formData, setFormData] = useState({ ...initFormData, hasInitStore: false })

    const [annotValidErrMsg, setAnnotValidErrMsg] = useState('')

    const formDataRef = useRef(formData);

    const checkFieldFn = useCallback((annots) => {
        let valid = true
        setAnnotValidErrMsg('')
        if (annots) {
            if(!annots.some(item => item.fieldType === 'FullSignature' && !item.isPagingSealSignature)) {
                setAnnotValidErrMsg('至少需要创建一个签名域')
                valid = false;
            }
        } else if (!formData || !formData.annots || !formData.annots.some(item => item.fieldType === 'FullSignature' && !item.isPagingSealSignature)) {
            setAnnotValidErrMsg('至少需要创建一个签名域')
            valid = false;
        }
        return valid;
    }, [formData])

    // 获取颜色
    const getColor = useCallback(() => {
        let currColor = null;
        colorList.some(color => {
            const res = initFlowInfo.receipts.every(item => item.color !== color);
            if (res) {
                currColor = color;
                return true;
            } else {
                return false;
            }
        });
        return currColor;
    }, [initFlowInfo.receipts])

    const checkSignerName = (rules, value) => {
        if (!value || value.trim().length < 1) {
            return Promise.reject("请填写参与人姓名")
        } else if (value.trim().length > 20) {
            return Promise.reject("最多可输入20个字符")
        } else {
            return Promise.resolve()
        }
    }

    const checkEmailOrPhone = (rules, value) => {
        if (!value || !value.trim()) {
            return Promise.reject("为保证签署流程顺利进行，请填写参与人真实信息，邮箱和手机号至少填写一项。")
        }
        const hasExistValue = (val) => {
            const tempFormData = form.getFieldsValue();
            if (!tempFormData.companyName) {
                tempFormData.companyName = ""
            };
            return initFlowInfo.receipts.some((it, index) => index !== curOrder && (it.signerEmailOrPhone === val && ((wardType === 0 && it.companyName === tempFormData.companyName) || wardType === 1)))
        }
        const isEmail = /^[a-zA-Z\d._-]+@[a-zA-Z\d_-]+(\.[a-zA-Z\d_-]+)+$/.test(value);
        const isPhone = /^1[3-9]\d{9}$/.test(value);
        if (isEmail) {
            if (hasExistValue(value)) {
                return Promise.reject("邮箱已存在")
            }
        } else {
            if (!isPhone) {
                return Promise.reject("邮箱或手机号格式错误")
            }
            if (hasExistValue(value)) {
                return Promise.reject("手机号已存在")
            }
        }
        return Promise.resolve()
    }

    const cleanReceiptItemAllFieldsFn = useCallback(() => {
        deleteFieldsForPhantom(formData.annots.map(annot => ({ objNumber: annot.objNumber, pageIndex: annot.pageIndex})))
    }, [formData])


    // eslint-disable-next-line
    const handleUpdateAnnotsAction = (params) => {
        const setStateFn = (prevState) => {
            const {annots, type} = params;
            let formDataAnnot = []
            if (type === "ADD") {
                const originAnnot = prevState.annots ? prevState.annots : []
                formDataAnnot = [...originAnnot, ...annots]
            } else if (type === "REMOVE") {
                const originAnnot = prevState.annots ? prevState.annots : []
                if (originAnnot.length > 0) {
                    formDataAnnot = originAnnot.filter(it => {
                        let ret = true;
                        annots.forEach(annot => {
                            if (annot.objNumber === it.objNumber && annot.pageIndex === it.pageIndex) {
                                ret = false;
                            }
                        })
                        return ret;
                    })
                }
            } else if (type === "UPDATE") {
                const originAnnot = prevState.annots ? prevState.annots : []
                if (originAnnot.length > 0) {
                    formDataAnnot = originAnnot.map(it => {
                        annots.forEach(annot => {
                            if (annot.objNumber === it.objNumber && annot.pageIndex === it.pageIndex) {
                                it = annot;
                            }
                        })
                        return it;
                    })
                }
            }
            checkFieldFn(formDataAnnot)
            return {...prevState, annots: formDataAnnot, color: annots[0].color}
        }
        setFormData(setStateFn)
    }

    const cancelEditReceiptItem = useCallback(async () => {
        const data = curOrder === -1 ? {signerType: initSignerType} : initFlowInfo.receipts[curOrder];
        if (curOrder === -1) {
            if (formData.annots && formData.annots.length > 0) {
                const delAnnots = formData.annots.map(it => ({objNumber: it.objNumber, pageIndex: it.pageIndex}));
                await deleteFieldsForPhantom(delAnnots)
            }
        }
        setFormData(data);
        onFinishForm()
        cleanReceiptItemFormDataCacheFn(initFlowInfo.uuid)
    }, [initFlowInfo, curOrder, initSignerType, onFinishForm, formData])

    useEffect(() => {
        // window.eSignCN_annotUpdateNotifier = handleUpdateAnnotsAction; // 订阅客户端的annot更新消息
        window.eSignCN_cancelEditReceiptItem = cancelEditReceiptItem;
        window.eSignCN_cleanReceiptItemAllFields = cleanReceiptItemAllFieldsFn;
    }, [cancelEditReceiptItem, cleanReceiptItemAllFieldsFn])

    useEffect(() => {
        const cacheId = initFlowInfo.uuid;
        if (cacheId && formData.hasInitStore) {
            setReceiptItemFormDataCacheFn(cacheId, formData)
        }
    }, [formData, initFlowInfo.uuid])

    useEffect(() => {
        const cacheId = initFlowInfo.uuid;
        if (cacheId && !formData.hasInitStore) {
            const cacheReceiptItemFormData = getReceiptItemFormDataCacheFn(cacheId)
            if (cacheReceiptItemFormData) {
                setFormData(cacheReceiptItemFormData)
                form.setFieldsValue({
                    signerType: cacheReceiptItemFormData.signerType,
                    companyName: cacheReceiptItemFormData.companyName,
                    signerName: cacheReceiptItemFormData.signerName,
                    signerEmailOrPhone: cacheReceiptItemFormData.signerEmailOrPhone
                })
            } else {
                setFormData({...formData, hasInitStore: true })
            }
        }
    }, [form, formData, initFlowInfo])

    useEffect(() => {
        formDataRef.current = formData;
    }, [formData])

    const handleChange = async (value) => {
        form.setFieldValue('signerType', value);
        // value === 'personSigner' && form.setFieldValue('companyName', '');
        onFormValuesChange();
        setStore('plugin', { ...getStore('plugin'), initFlowInfo: { ...initFlowInfo, configInfo: { ...initFlowInfo.configInfo, signerType: value}}})
        await setCurReceiptItemConfigInfoForPhantom({isOrg: value === "orgSigner", color: formData.color || getColor()}); // 更新当前签署环节是否为企业签
        if (formData.annots) {
            const annots = formData.annots.filter(it => it.fieldType === "FullSignature").map(it => ({
                objNumber: it.objNumber,
                pageIndex: it.pageIndex,
                fieldType: it.fieldType
            }))
            modifyFieldsResizableForPhantom({annots, isOrg: value === "orgSigner"})
        }
    };

    const onFormValuesChange = () => {
        const tempFormData = form.getFieldsValue();
        if (tempFormData.signerEmailOrPhone) {
            const isEmail = /^[a-zA-Z\d._-]+@[a-zA-Z\d_-]+(\.[a-zA-Z\d_-]+)+$/.test(tempFormData.signerEmailOrPhone);
            if (isEmail) {
                setFormData({ ...formData, ...tempFormData, isEmail: true })
            } else {
                setFormData({ ...formData, ...tempFormData, isEmail: false })
            }
        } else {
            setFormData({ ...formData, ...tempFormData })
        }
    }

    const handleSubmit = () => {
        form.validateFields().then(() => {
            const fieldValidRet = checkFieldFn();
            if (!fieldValidRet) return;
            if (formData.signerType === 'personSigner') {
                formData.companyName = '';
            }
            if (getStore('plugin').wardType === 0) { // 对外
                initFlowInfo.receipts[curOrder === -1 ? initFlowInfo.receipts.length : curOrder] = { ...formData };
            } else { // 对内
                initFlowInfo.receipts[curOrder === -1 ? initFlowInfo.receipts.length : curOrder] = { ...formData, companyId: getStore('userInfo').enterpriseId, companyName: getStore('userInfo').enterpriseName };
            }
            setStore('plugin', { ...getStore('plugin'), initFlowInfo: { ...initFlowInfo } })
            cleanReceiptItemFormDataCacheFn(initFlowInfo.uuid)
            onFinishForm()
        }).catch(e => {
            console.error('e', e)
        })
    }

    const handleCancel = () => {
        if (!shouldCallPhantomApi()) {
            cancelEditReceiptItem()
        }
        const hasChangeFormData = !isObjectEqual(formData, { ...initFormData, hasInitStore: true }, true)
        if (hasChangeFormData || curOrder !== -1) { // 取消编辑时 始终提示确认弹框
            showESignCNDialogAPI({
                title: "福昕电子签章服务",
                type: "Dialog",
                id: "CANCEL_EDIT_RECEIPT_ITEM_FORM_CONFIRM",
                msg: curOrder === -1 ? "确定取消添加签章参与人吗？" : "确定取消编辑签章参与人吗？"
            })
        } else {
            cancelEditReceiptItem()
        }
    }

    const addMySelf = () => {
        // 获取用户信息
        const companyName = getStore('userInfo').enterpriseName;
        const name = getStore('plugin').wardType === 0 ? getStore('esignPerson').name : getStore('userInfo').fullname;
        const mobile = getStore('userInfo').userMobile;
        const email = getStore('userInfo').userEmail;

        // 填充表单
        form.setFieldValue('signerName', name);
        form.setFieldValue('signerEmailOrPhone', email || mobile)

        if (form.getFieldValue('signerType') === 'orgSigner') {
            form.setFieldValue('companyName', companyName)
        } else {
            form.setFieldValue('companyName', '')
        }
        // 验证表单
        form.validateFields()
            .catch(e => {
                console.error('e', e)
            })
            .finally(() => {
                onFormValuesChange() // setFieldsValue 不会触发onValuesChange，所以此处手动调用下onFormValuesChange。https://github.com/ant-design/ant-design/issues/22168
        })
    }

    const callStateHandle = (type) => {
        if (shouldCallPhantomApi()) {
            callStateHandleForPhantom({
                type
            })
        }
        if (!shouldCallPhantomApi()) {
            const mockDatta = {
                type: "ADD",
                annots: [
                    {
                        objNumber: 123,
                        pageIndex: 1,
                        fieldType: 'Date',
                        // color: getColor()
                    },
                    {
                        objNumber: 533,
                        pageIndex: 2,
                        fieldType: 'Title',
                        // color: getColor()
                    },
                    {
                        objNumber: 513,
                        pageIndex: 1,
                        fieldType: 'FullSignature',
                        // color: getColor()
                    }
                ]
            }
            handleUpdateAnnotsAction(mockDatta)
        }
    }

    const deleteField = (objNumber, pageIndex) => {
        deleteFieldsForPhantom([{ objNumber, pageIndex }])
    }

    const handleClickSelectedFieldItem = (objNumber, pageIndex) => {
        jumpToTheFieldForPhantom({ objNumber, pageIndex })
    }

    const cleanAllField = () => {
        showESignCNDialogAPI({
            title: "福昕电子签章服务",
            type: "Dialog",
            id: "CLEAN_RECEIPT_ITEM_FIELDS_CONFIRM",
            msg: "是否确认删除该参与人的全部签章区域？"
        })
        // deleteFieldsForPhantom(formData.annots.map(annot => ({ objNumber: annot.objNumber, pageIndex: annot.pageIndex})))
    }

    const signerNameChange = (val) => {
        form.setFieldsValue({
            signerName: val
        })
        onFormValuesChange()
        form.validateFields(['signerName']).catch(e => {
            console.log(e)
        })
    }
    const signerEmailOrPhoneChange = (val) => {
        form.setFieldsValue({
            signerEmailOrPhone: val
        })
        onFormValuesChange()
        form.validateFields(['signerEmailOrPhone']).catch(e => {
            console.log(e)
        })
    }
    const companyNameChange = (val) => {
        if (formData.signerType === 'orgSigner') {
            form.setFieldsValue({
                companyName: val
            })
            onFormValuesChange()
            form.validateFields(['companyName']).catch(e => {
                console.log(e)
            })
        }
    }
    const openPagingSealSigSettingDialog = () => {
        const params = {
            type: "CREATE"
        };
        const pagingSealSigInfo = formData.annots?.find(annot => annot.isPagingSealSignature);
        if (pagingSealSigInfo) {
            params.type = "EDIT";
            params.objNumber = pagingSealSigInfo.objNumber;
            params.pageIndex = pagingSealSigInfo.pageIndex;
        }
        openPagingSealSigSettingDialogForPhantom(params)
    }
    return (
        <div className='container'>
            {/*参与人信息*/}
            <div className='signer-info-title'>
                <span><span className='signer-info-tip'>1</span>签章参与人信息</span>
                <Button className='add-me' type="link" onClick={addMySelf}>添加自己</Button>
            </div>
            <div className='guideMsg'>{guideMsg1}</div>
            <Form
                name="signerInfoForm"
                layout="Vertical"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                form={form}
                initialValues={formData}
                onValuesChange={onFormValuesChange}
            >
                {
                    wardType === 0 &&
                    <>
                        <Form.Item
                            label=""
                            name="signerType"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Select
                                onChange={handleChange}
                                options={signerTypeOption}
                            />
                        </Form.Item>
                    </>
                }
                <Form.Item
                    label=""
                    name="signerName"
                    className='esign-auto-complete-container'
                    rules={[{ required: true, message: '' }, {
                        validator: checkSignerName
                    }]}
                >
                    <AutoCompleteUserInfo
                        fieldType={wardType === 0 ? 'outPersonName' : 'inPersonName'}
                        curValue={formData.signerName}
                        signerNameChange={signerNameChange}
                        signerEmailOrPhoneChange={signerEmailOrPhoneChange}
                        companyNameChange={companyNameChange}
                    />
                </Form.Item>
                <Form.Item
                    label=""
                    name="signerEmailOrPhone"
                    rules={[{ required: true, message: '' }, {
                        validator: checkEmailOrPhone
                    }]}
                >
                    <AutoCompleteUserInfo
                        curValue={formData.signerEmailOrPhone}
                        fieldType={wardType === 0 ? 'outEmailOrPhone' : 'inEmailOrPhone'}
                        signerNameChange={signerNameChange}
                        signerEmailOrPhoneChange={signerEmailOrPhoneChange}
                        companyNameChange={companyNameChange}
                    />
                </Form.Item>
                {
                    wardType === 0 &&
                    <>
                        {
                            formData.signerType === 'orgSigner' &&
                            <Form.Item
                                label=""
                                name="companyName"
                                className='esign-auto-complete-container'
                                rules={[{required: true, message: '请填写企业全称'}]}
                            >
                                <UserInfoSelector
                                    companyName={formData.companyName}
                                    fieldType='outOrgName'
                                    companyNameChange={companyNameChange}
                                    placeholder="企业全称 *"
                                />
                            </Form.Item>
                        }
                    </>
                }
            </Form>
            {/*指定签章区域*/}
            <div>
                <div className='signer-info-title'><div><span className='signer-info-tip'>2</span>指定签章区域</div></div>
                <div className='guideMsg'>{guideMsg2}</div>
                <Button
                    icon={<span className="state-handle-control-icon sig-field-icon"></span>}
                    block style={{ marginBottom: '10px', outline: `1px solid ${formData.color || getColor()}` }} onClick={() => {callStateHandle('FullSignature')}} support-outline='true'>签名域</Button>
                { annotValidErrMsg && <div className='annot-valid-error' style={{ marginBottom: '10px' }}>{annotValidErrMsg}</div> }

                <Button
                    icon={<span className="state-handle-control-icon text-field-icon"></span>}
                    block style={{ marginBottom: '10px', outline: `1px solid ${formData.color || getColor()}`}} onClick={() => {callStateHandle('Title')}} support-outline='true'>文本域</Button>
                <Button
                    icon={<span className="state-handle-control-icon date-field-icon"></span>}
                    block style={{ marginBottom: '10px', outline: `1px solid ${formData.color || getColor()}`}} onClick={() => {callStateHandle('Date')}} support-outline='true'>日期域</Button>
                {
                    afterPluginVersion2023_4() &&
                    <Button
                        icon={<span className="state-handle-control-icon paging-seal-sig-field-icon"></span>}
                        block style={{ outline: `1px solid ${formData.color || getColor()}` }}
                        onClick={() => {openPagingSealSigSettingDialog()}}
                        support-outline='true'>
                        { formData?.annots?.some(annot=>annot.isPagingSealSignature) ? '更改骑缝章' : '骑缝章' }
                    </Button>
                }
            </div>
            <br/>
            {/*已添加的表单列表*/}
            {
                formData.annots && formData.annots.length > 0 &&
                <div>
                    <div className="field-list-title">
                        <span>已添加的签章区域</span>
                        <Button type='link' onClick={cleanAllField}>清空</Button>
                    </div>
                    <div className='selected-field-list-container'>
                        {formData.annots.map((annot, index) => {
                            return <div key={annot.objNumber} className='selected-field-list' onClick={() => handleClickSelectedFieldItem(annot.objNumber, annot.pageIndex)}>
                                <div>
                                    <span>{index + 1}、</span>
                                    <span>{getFieldType({fieldType: annot.fieldType, isPagingSealSignature: annot.isPagingSealSignature})}</span>
                                </div>
                                <i className='icon del-icon' onClick={() => deleteField(annot.objNumber, annot.pageIndex)}></i>
                            </div>
                        })}
                    </div>
                </div>
            }
            <div className='btn-group'>
                <Button type="primary" shape='round' onClick={handleSubmit} style={{width: '42%'}} className='primary-btn'>确认</Button>
                <Button shape='round' style={{width: '42%'}} onClick={handleCancel}>取消</Button>
            </div>
        </div>
    )
})

export default observer(SignerInfoForm);