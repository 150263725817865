export function setInitFlowInfoCacheFn (cacheId, data) {
    localStorage.setItem(`${cacheId}_initFlowInfoCache`, JSON.stringify(data))
}

export function setReceiptItemFormDataCacheFn (cacheId, data) {
    localStorage.setItem(`${cacheId}_receiptItemFormDataCache`, JSON.stringify(data))
}

export function getInitFlowInfoCacheFn (cacheId) {
    const data = localStorage.getItem(`${cacheId}_initFlowInfoCache`)
    return data ? JSON.parse(data) : null;
}

export function getReceiptItemFormDataCacheFn (cacheId) {
    const data = localStorage.getItem(`${cacheId}_receiptItemFormDataCache`)
    return data ? JSON.parse(data) : null;
}

export function cleanReceiptItemFormDataCacheFn (cacheId) {
    localStorage.removeItem(`${cacheId}_receiptItemFormDataCache`)
}

export function cleanInitFlowInfoCacheFn (cacheId) {
    localStorage.removeItem(`${cacheId}_initFlowInfoCache`)
}