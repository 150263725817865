import {sleep} from "../service";

export class RequestQueue {
    constructor({ maxConcurrentRequests, pause = 0 }) {
        this.requestQueue = [];
        this.pause = pause;
        this.maxConcurrentRequests = maxConcurrentRequests;
    }
   async sendRequest(api) {
        try {
            const response = await api();
            return response;
        } catch (error) {
            console.error('请求出错', error);
            throw error;
        }
    };
    async processQueue () {
        while (this.requestQueue.length) {
            const currentRequests = [];
            for (let i = 0; i < this.maxConcurrentRequests && this.requestQueue.length; i++) {
                const request = this.requestQueue.shift();
                currentRequests.push(request());
            }
            if (this.pause) {
                await sleep(this.pause);
            }
            await Promise.all(currentRequests);
        }
    };
    addToQueue(url) {
        const request = () => this.sendRequest(url);
        this.requestQueue.push(request);
    };
    clearQueue() {
        this.requestQueue.length = 0;
    }
}
