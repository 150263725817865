import React, {Component} from 'react';
import styles from './index.module.less';
import {Button, Modal, Spin, Tooltip} from 'antd';
import {errorMessage, loadingMessage, successMessage} from "../components/Message";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {applyOutStampUsePermission, getOrgSeals, resendApplyOutStampUsePermission} from "../../request/esign";
import {sendMsg} from "./index";

export default class ApplyOrgSeals extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stampList: [],
            loading: false,
            applyLoading: false,
        };
        const {token, accountId, orgId, trueName, enterpriseId} = props;
        this.token = token;
        this.accountId = accountId;
        this.orgId = orgId;
        this.trueName = trueName;
        this.enterpriseId = enterpriseId;
    }

    async handleApplyStamp(sealInfo) {
        const data = {
            orgId: this.orgId,
            accountId: this.accountId,
            sealId: sealInfo.sealId,
            token: this.token,
        };
        const isResend = +sealInfo.authStatus === 1;
        let request;
        if (!isResend) {
            data.sealName = sealInfo.alias;
            data.applicantName = this.trueName;
            request = applyOutStampUsePermission;
        } else {
            request = resendApplyOutStampUsePermission;
        }
        Modal.confirm({
            title: '用章申请',
            icon: <ExclamationCircleOutlined/>,
            content: isResend ? '将向管理员再次发送申请通知，是否确认？':'将向管理员申请所选印章的使用权限，是否确认提交申请？',
            okText: '确认',
            cancelText: '取消',
            okButtonProps: {},
            cancelButtonProps: {},
            onOk: () => {
                const name = isResend ? '催办' : '申请';
                this.setState ({applyLoading: true});
                loadingMessage(name + '提交中…');
                request(data).then(() => {
                    successMessage(name + '提交成功');
                    !isResend && this.getOrgSeals();
                }).catch(() => {
                    errorMessage(name + '失败，请稍后重试');
                }).finally(() => {
                    this.setState({applyLoading: false});
                });
            },
            onCancel() {}
        });
    }

    componentDidMount() {
        sendMsg({type: 'resize', width: '890px', height: '606px'});
        this.getOrgSeals();
    }

    getOrgSeals() {
        this.setState({loading: true});
        getOrgSeals({orgId:this.orgId, accountId:this.accountId, token:this.token, enterpriseId:this.enterpriseId, valid: false}).then(seals => {
            if (Array.isArray(seals) && seals.length > 0) {
                this.setState({stampList: seals});
                return;
            }
            this.setState({stampList: []});
        }).catch(() => {
            // errorMessage('获取印章失败，请关闭后重试');
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    render() {
        const {
            stampList,
            loading,
            applyLoading
        } = this.state;

        return (
            <div className={styles.applyDialog}>
                {loading && <Spin className={styles["spin-loading"]}></Spin>}
                <section className={styles["stamp-apply-container"]}>

                    {!loading && stampList && stampList.length > 0 && <>
                        <div className={styles["header"]}>
                            <div className={styles["left"]}>
                                <span>请选择需要申请使用权限的签章，并提交申请。</span>
                            </div>
                        </div>
                        <div className={styles.list}>
                            {
                                stampList.map(item => {
                                    let stampTag, stampClass;
                                    const {usePermission, authStatus, sealId, alias, base64} = item;
                                    if (+authStatus === 1) {
                                        stampTag = "审批中"
                                        stampClass = styles['stamp-tag']
                                    } else if (usePermission) {
                                        stampTag = "已授权"
                                        stampClass = styles['stamp-tag-success']
                                    }
                                    return (
                                        <div key={sealId} className={styles['item']}
                                             style={{backgroundImage: `url(${base64})`}}>
                                            {stampTag && <div className={stampClass}>{stampTag}</div>}
                                            {alias &&
                                                (<Tooltip title={alias} placement='bottom'
                                                          getPopupContainer={triggerNode => triggerNode.parentNode}>
                                                    <div className={styles['stamp-alias']} tooltip={alias}>{alias}</div>
                                                </Tooltip>)}
                                            {!usePermission && <div className={styles['no-permission_wraper']}>
                                                <Button key="applyBtn" disabled={applyLoading}
                                                        onClick={this.handleApplyStamp.bind(this, item)}>
                                                    {+authStatus === 1 ? "催办" : "申请使用权限"}</Button>
                                            </div>}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>}
                    {!loading && (!stampList || stampList.length < 1) &&
                        <div className={styles['stamp-list-msg']}>未查询到可用的企业签章，请关闭后重试</div>}
                </section>
            </div>
        )
    }
}

