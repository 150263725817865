import {observer} from "mobx-react";
import {closeESignCNDialogAPI, fetchWillAuthData} from "../../../request/phantom";
import WillingnessAuth from "../../main/components/WillingnessAuth";

function PhantomWillingnessAuth(WillingnessAuth) {
    const Fn = observer(function Comp() {
        if (window.location.pathname === '/willing-auth') {
            document.body.setAttribute('name', 'will-auth-container')
        }
        const closeWillAuthModal = async (willData) => {
            const { willAuthId, willAuthTime, willAuthType, willingnessBizId } = willData
            await fetchWillAuthData({ willAuthId, willAuthTime, willAuthType, willingnessBizId })
            closeESignCNDialogAPI()
        }
        return <WillingnessAuth visible={true} closeModal={closeWillAuthModal}/>
    })
    return Fn;
}

export default PhantomWillingnessAuth(WillingnessAuth)