import {openNewPhantomTab} from "../../../../request/phantom";
import {mockSwitch} from "../../config";
import {Button} from 'antd'
import {getStore} from "../../../../store";
import {getQueryVariable} from "../../service";
import {FROM_AUDIT_TRAIL} from "../../esignTools/utils";
import {eSignCNAuditTrailWrapper, fontWeight700, genContent2Box} from "../style";

export function Content2Comp(props) {
    const { auditTrail, type = 'web' } = props;

    const handleOpenESignTool = () => {
        const parentOrigin = decodeURIComponent(getQueryVariable('domain'));
        const url = `${parentOrigin}/internal_identity_verify`;
        const title = "签名身份信息验证系统"
        if (mockSwitch) {
            window.open(url)
        } else if (getStore('plugin').inPhantom){
            const phantomUrl = `${window.location.origin}/internal-identity-verify`;
            openNewPhantomTab({url: `${phantomUrl}?parent_web=${FROM_AUDIT_TRAIL}`, title})
        } else if (getStore('plugin').inOnline) {
            const curDocHash = getQueryVariable('curDocHash')
            window.open(`${url}?origin=ONLINE_CONTAINER&curDocHash=${curDocHash}&parent_web=${FROM_AUDIT_TRAIL}&env=cn`)
        }
    }

    let auth = Object.assign([], auditTrail.auth)
    if (auditTrail.base.type === "signFlow") { // 此处不展示发起人信息
        auth.shift()
    }

    const keys = []

    let filterKey = item => `${item.email}-${item.phone}-${item.signerName}-${item.mode}-${item.companyId}`;
    auth = auth.filter(item => {

        if (!item.email && !item.phone) {
            console.error("auditTrailController.js renderAuthTrailContent2 error: ", "存在签名信息没有email", auditTrail)
            return false;
        }
        if (keys.includes(filterKey(item))) {
            return false
        } else {
            keys.push(filterKey(item));
            return true
        }
    });
    filterKey = null;

    return <section style={ {...eSignCNAuditTrailWrapper, padding: '0'} }>
        {
            auth.map((item, index) => {
                return <div key={index} style={genContent2Box(index)}>
                    { item.mode === "out" &&
                        <div style={{ display: 'flex', alignItems: 'center'}}>
                            <span style={fontWeight700}>{`【签署方${index + 1}】`}</span>
                            <span style={{flex:1}}>
      <span className="audit-trail__key_content">{item.signerName}</span>
                                由
                                <span className="audit-trail__key_content">{item.method}</span>
                                方式完成身份认证，并由
                                <span className="audit-trail__key_content">{item.issuer}</span>
                                CA颁发证书
      </span>
                        </div>
                    }
                    {
                        item.mode === 'in' &&
                        <div style={{ display: 'flex' }}>
                            <span style={fontWeight700}>{`【签署方${index + 1}】`}</span>
                            <span
                                style={{flex:1}}>签署者为
                                <span className="audit-trail__key_content">{item.signerName}</span>
                                ，其身份由其签署时所在的企业
                                <span className="audit-trail__key_content">{item.companyName}</span>
                                的身份认证系统提供，验证
                                {item.SAMLAuthVerify ? `通过` : <span className='audit-trail__waring'>未通过</span>}
                                。
                                {
                                    type === 'web' &&
                                    <>
                                        了解更多身份验证信息，请点击
                                        <Button onClick={handleOpenESignTool} type="link" style={{ paddingLeft: '0px'}}>这里</Button>
                                    </>
                                }
      </span>
                        </div>
                    }
                </div>
            })
        }
    </section>
}