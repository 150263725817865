import React, {useEffect, useState} from 'react';
import {closeESignCNDialogAPI} from "../../../request/phantom";
import styles from "./index.module.less";
import {Skeleton} from "antd";
import PureAuthComp from "./PureAuthComp";
import qs from "qs";

function getParams() {
    const search = window.location.search;
    const index = search.indexOf('?');
    let params;
    if (index >= 0) {
        params = search.slice(index + 1);
        params = qs.parse(params);
    }
    for(let key in params) {
        if (/^[0-9]+\.?[0-9]*$/.test(params[key])) {
            params[key] = parseInt(params[key])
        }
    }
    return params;
}

export default function PhantomAuth() {
    const [wardType, setWardType] = useState(null);
    const [checkSignFlow, setCheckSignFlow] = useState(null);
    const [orgAuthEntrance, setOrgAuthEntrance] = useState(null);
    const [authPromotion, setAuthPromotion] = useState(null);

    const endProcessCallback = () => {
        closeESignCNDialogAPI();
    }

    useEffect(() => {
        const params = getParams();
        if (!params) {
            throw new Error('PhantomAuth/index.jsx error: params is null')
        }
        const { wardType, checkSignFlow, orgAuthEntrance, authPromotion } = params;
        setWardType(wardType);
        setCheckSignFlow(checkSignFlow);
        setOrgAuthEntrance(orgAuthEntrance);
        setAuthPromotion(authPromotion);
    }, [])



    return (
        <div className={`${styles.pluginBackground}`}>
            <div className={`${styles.skeletonWarp}`}><Skeleton/></div>
            <PureAuthComp
                wardType={wardType}
                checkSignFlow={checkSignFlow}
                orgAuthEntrance={orgAuthEntrance}
                authPromotion={authPromotion}
                endProcessCallback={endProcessCallback}
            />
        </div>
    )
}