import React from 'react';
import styles from "./index.module.less";
import iconMobile from "@/assets/images/change_mobile.png";
import iconMobileSuccess from "@/assets/images/add-mobile-success.png";
import {Button, Input, Modal, Spin} from "antd";
import {useEffect, useRef, useState} from "react";
import {accountExist, sendVerificationCode, setAccount} from "@/request/user";
import {LoadingOutlined} from '@ant-design/icons';
import {errorMessage, successMessage} from "@/pages/components/Message";
import {closeESignCNDialogAPI} from "../../../request/phantom";
import {
    ERROR_AC_CAPTCHA_FAIL,
} from "../../../request/error_container";
import {getCaptchaUrl} from "../../../request/user";
import {getStore} from "../../../store";

function requestCodeFn ({account, checkMobile, token, captchaId, captcha}) {
    if (checkMobile) {
        account = '+86' + account;
    }
    return accountExist(account, checkMobile).then(res => {
        if (res) {
            return -1;
        }
        return sendVerificationCode(account, checkMobile, token, captchaId, captcha);
    }).then(res => {
        if (res === -1) {
            return res;
        }
        return res ? 1 : -2;
    }).catch(err => {
        if (err === ERROR_AC_CAPTCHA_FAIL) {
            return -4;
        }
        return -3;
        // if (res.message) {
        //     if (res.ret === 2100086) {
        //         errorMessage('验证码发送失败：发送数量超出每日限制')
        //     } else if(res.ret === 2100080) {
        //         errorMessage('验证码发送失败：发送数量超出限制')
        //     } else {
        //         errorMessage('验证码发送失败：' + res.message)
        //     }
        // } else {
        //     errorMessage('验证码发送失败，请稍后重试')
        // }
        // return false
    })
}

function checkValueFn(value, type) {
    let reg;
    switch (type) {
        case 'email':
            reg = /^[a-zA-Z\d._-]+@[a-zA-Z\d_-]+(\.[a-zA-Z\d_-]+)+$/; // 中括号内中的.不需要转义
            break;
        case 'mobile':
            reg = /^1[3-9]\d{9}$/;
            break;
        default:
            reg = /^\d{1,6}$/;
            break;
    }
    return reg.test(value);
}

export default function PhantomReplacePhone() {
    const isEmail = false;
    const showName = isEmail ? '邮箱地址': '手机号码';
    let icon, iconSuccess;
    if (isEmail) {
        // icon = iconEmail;
        // iconSuccess = iconEmailSuccess;
    } else {
        icon = iconMobile;
        iconSuccess = iconMobileSuccess;
    }

    const [success, setSuccess] = useState(false);
    const [inputStatus, setInputStatus] = useState();
    const [codeStatus, setCodeStatus] = useState();
    const [loading, setLoading] = useState(false);
    const inputRef = useRef();
    const codeRef = useRef();

    // const navigate = useNavigate();
    const img = success ? iconSuccess : icon;
    const title = success ? `${showName}已更换！` : `更换您的${showName}`;

    useEffect(() => {
        if (window.location.pathname === '/replace-phone') {
            document.body.setAttribute('name', 'replace-phone')
        }
    }, [])

    // if (isEmail) {
    //     if (email.includes('*')) {
    //         showPhone = email;
    //     } else {
    //         showPhone =  email.replace(/(.?).*@(.*)/, "$1***@$2");
    //     }
    // } else {
    //     if (phone.includes('*')) {
    //         showPhone = phone;
    //     } else {
    //         showPhone = phone.replace(/(\d{3})\d*(\d{4})/, '$1****$2');
    //     }
    // }
    //
    // const summary = success ? `${showName}设置成功，请开始签署` :
    //     `为验证签署身份，请完善您福昕账户中的${showName}。完善后，您仍可在 <b>我的账户 > 个人资料</b>中进行更换。<br /><br />
    //         如您是当前签署人${name}，请验证${showPhone}`;


    const cancel = () => {
        closeESignCNDialogAPI()
    }

    const submit = () => {
        let value = inputRef.current.input.value;
        const code = codeRef.current.input.value;

        let reg;
        if (isEmail) {
            // eslint-disable-next-line
            reg = /^[a-zA-Z\d\._-]+@[a-zA-Z\d_-]+(\.[a-zA-Z\d_-]+)+$/;
        } else {
            value = '+86' + value;
            reg = /^\+861[3-9]\d{9}$/;
        }

        if (!reg.test(value)) {
            setInputStatus('error');
            return;
        }
        if (!/^\d{6}$/.test(code)) {
            setCodeStatus('error');
            return;
        }
        setInputStatus(undefined);
        setLoading(true);
        setAccount(value, code, !isEmail).then(() => {
            setSuccess(true);
        }).catch(res => {
            if (res.message) {
                errorMessage(`设置${showName}失败：` + res.message)
            } else {
                errorMessage(`设置${showName}失败`)
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    const changeValue = (e) => {
        if (isEmail) return;
        const value = e.target.value;
        if (!/^\d{1,11}$/.test(value)) {
            setInputStatus('warning');
        } else {
            setInputStatus(undefined);
        }
    }

    const changeCode = (e) => {
        const value = e.target.value;
        if (!/^\d{1,6}$/.test(value)) {
            setCodeStatus('warning');
        } else {
            setCodeStatus(undefined);
        }
    }

    const checkInput = () => {
        const value = inputRef.current.input.value;
        if (!checkValueFn(value, !isEmail ? 'mobile' : 'email')) {
            setInputStatus('error');
            return false;
        }
        setInputStatus('');
        return true;
    };

    const toMain = () => {
        closeESignCNDialogAPI()
    }

    const sendCode = (captchaId, captcha) => {
        const value = inputRef.current.input.value;
        return requestCode({requestCodeFn, value, checkMobile: !isEmail, showName, token: getStore('userInfo').token, captchaId, captcha});
    }
    return (
        <>
            <div className={styles.main}>
                <img src={img} alt={"icon"} width="168" height="168"/>
                <div className={styles.left}>
                    <div className={styles.title}>{title}</div>
                    {/*<div className={styles.tips} dangerouslySetInnerHTML={{__html: summary}}></div>*/}
                    {success ? (
                        <Button onClick={toMain} type='primary' shape={"round"} style={{width: '80%'}}>签署</Button>
                    ) : (
                        <div className={styles.form}>
                            <Input ref={inputRef} status={inputStatus} onChange={changeValue} placeholder={showName}
                                   type="tel" maxLength={isEmail?50:11} allowClear/>
                            {inputStatus === 'error' && <div className={styles.error}>请输入正确格式的{showName}</div>}
                            <br/>
                            <Input ref={codeRef} status={codeStatus} onChange={changeCode} placeholder="验证码"
                                   type="text" maxLength={6} allowClear suffix={<SendCode checkMobile={!isEmail} sendCode={sendCode} checkInput={checkInput} />} />
                            {codeStatus === 'error' && <span className={styles.error}>请输入正确的验证码</span>}
                            <div className={styles.buttons}>
                                <Button loading={loading} onClick={submit} type='primary' shape={"round"}
                                        style={{width: '30%'}}>确认</Button>
                                <Button onClick={cancel} shape={"round"} style={{width: '30%'}}>取消</Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>

    )
}


function SendCode(props) {
    const {checkInput, sendCode, checkMobile} = props;
    const [stat, setStat] = useState(0);
    const [sec, setSec] = useState(59);
    const [showCaptcha, setShowCaptcha] = useState(null);

    const captchaIdRef = useRef(null);

    const genCaptchaId = () => {
        captchaIdRef.current = 'esc-'+Math.random().toString(36).slice(2);
        return captchaIdRef.current;
    };

    const handleClick = () => {
        if (!checkInput()) {
            return;
        }
        if (checkMobile) {
            return openCaptcha();
        }
        setStat(1);
        sendCode().then(code => {
            setStat(code > 0 ? 2 : 0)
        });
    }

    if (stat === 2) {
        setTimeout(() => {
            if (sec > 1) {
                setSec(sec - 1);
            } else {
                setStat(0);
                setSec(59);
            }
        }, 1000)
    }
    const antIcon = <LoadingOutlined style={{fontSize: 20}} spin/>;

    const closeCaptcha = (captcha) => {
        setShowCaptcha(null);
        if (captcha) {
            sendCode(captchaIdRef.current, captcha).then(code => {
                if (code > 0) {
                    setStat(2);
                } else {
                    if (code === -4) {
                        openCaptcha();
                    }
                    setStat(0);
                }
            });
        }
    }

    const openCaptcha = () => {
        setShowCaptcha(getCaptchaUrl(genCaptchaId()));
    }

    return (
        <>
            {stat === 0 && <span onClick={() => handleClick()} className={styles.sendCode}>
                    获取验证码
                </span>
            }
            {stat === 1 && <Spin indicator={antIcon}/>
            }
            {stat === 2 && <span style={{color: '#666666'}}>
                    重新发送（{sec}s）
                </span>
            }
            {showCaptcha && <Captcha close={closeCaptcha} url={showCaptcha} genCaptchaId={genCaptchaId}  />}
        </>

    )
}

function Captcha(props) {
    const { genCaptchaId } = props;
    const [captcha, setCaptcha] = useState('');

    const handleChangeCaptcha = (e) => {
        e.target.src = getCaptchaUrl(genCaptchaId());
    }

    return (
        <Modal title="请输入图形验证码"
               open={true}
               footer={null}
               width={320}
               maskClosable={false}
               centered={true}
               bodyStyle={{ paddingTop: 16 }}
               maskStyle={{backgroundColor: 'rgba(255,255,255, 0.4)'}}
               closeIcon={<></>}
        >
            <div>
                <Input placeholder="请输入图中字符" type="text" maxLength={6}
                       value={captcha} onChange={(e) => setCaptcha(e.target.value)}
                       allowClear
                       suffix={<img alt={"captcha"}
                                    height={30}
                                    style={{margin: '-4px -10px -4px 0', cursor: 'pointer'}}
                                    onClick={handleChangeCaptcha}
                                    src={props.url} />}
                />
            </div>
            <div style={{display: "flex", gap: 20, marginTop: 32}}>
                <Button onClick={() => props.close(null)}
                        shape="round"
                        style={{width: '100%'}}
                >
                    取消
                </Button>
                <Button onClick={() => props.close(captcha)}
                        shape="round"
                        type="primary"
                        disabled={captcha.length < 4}
                        style={{width: '100%'}}
                >
                    继续
                </Button>
            </div>
        </Modal>
    )
}

function requestCode({requestCodeFn, value, checkMobile, showName, token, captchaId, captcha}) {
    return requestCodeFn({account:value, checkMobile, token, captcha, captchaId}).then(code => {
        switch (code) {
            case -1:
                errorMessage(`该${showName}已被其他账号占用`);
                break;
            case -2:
            case -3:
                errorMessage('验证码发送错误或超出发送数量限制，请稍后再试')
                break;
            case -4:
                errorMessage(`图形验证码错误，请重新输入`);
                break;
            case 1:
                successMessage('验证码发送成功');
                break;
            default:
                code = 0;
        }
        return code;
    });
}
