class OtelApi {
    constructor() {
        this.activeSpanMap = new Map()
        this.activeContextMap = new Map()

        this.context = null
        this.propagation = null
        this.currentActiveSpan = null
        this.currentActiveContext = null
        this.globalTracer = null
        this.otelInitialized = false
    }

    async initOtel(serverUrl, name, version) {
        if(this.otelInitialized || !serverUrl) return;

        const {BatchSpanProcessor, WebTracerProvider, OTLPTraceExporter, context, propagation, Resource, SemanticResourceAttributes} = await import('./export');
        const collectorOptions = {
            url: `${serverUrl}/v1/traces`, // url is optional and can be omitted - default is http://localhost:4318/v1/traces
            headers: {}, // an optional object containing custom headers to be sent with each request
            concurrencyLimit: 10, // an optional limit on pending requests
        };
        const provider = new WebTracerProvider({
            resource: new Resource({
                [SemanticResourceAttributes.SERVICE_NAME]: 'esigncn_pc'
            }),
        });
        const exporter = new OTLPTraceExporter(collectorOptions);

        provider.addSpanProcessor(new BatchSpanProcessor(exporter, {
            // The maximum queue size. After the size is reached spans are dropped.
            maxQueueSize: 100,
            // The maximum batch size of every export. It must be smaller or equal to maxQueueSize.
            maxExportBatchSize: 10,
            // The interval between two consecutive exports
            scheduledDelayMillis: 500,
            // How long the export can run before it is cancelled
            exportTimeoutMillis: 30000,
        }));

        provider.register();
        const tracer = provider.getTracer(
            name, version
        );

        this.context = context;
        this.propagation = propagation;
        this.globalTracer = tracer;
        this.otelInitialized = true;
    }

    getCurrentActiveSpan() {
        return this.currentActiveSpan
    }

    getCurrentActiveContext() {
        return this.currentActiveContext
    }

    getActiveContext(spanName) {
        const activeContext = this.activeContextMap.get(spanName)
        return activeContext
    }

    startSpan(spanName, options = {}, context) {
        if(!this.context || !context)
            return;
        let span = this.globalTracer.startSpan(spanName, options, context);
        span.setAttribute("foxit.category", "otel_traces");
        return span;
    }

    startActiveSpan(spanName, callback, error) {
        if(!this.context || !this.globalTracer) {
            error && error();
            return
        }

        return new Promise((resolve) => {
            this.globalTracer.startActiveSpan(spanName, (span) => {
                this.generateActiveContext(spanName)
                span.setAttribute("foxit.category", "otel_traces");

                this.currentActiveSpan = span
                this.activeSpanMap.set(spanName, span)

                resolve(span);
                callback(span);
            })
        })
    }

    endCurrentActiveSpan() {
        this.currentActiveSpan && this.currentActiveSpan.end()
        this.currentActiveSpan = null
        this.currentActiveContext = null
    }

    endSpan(span) {
        span && span.end();
    }

    endActiveSpan(spanName) {
        let span = this.getActiveSpan(spanName);
        span&&span.end();
        this.activeSpanMap.delete(spanName)
        this.activeContextMap.delete(spanName)
    }

    cleanCurrentActiveSpan() {
        this.currentActiveSpan = null
        this.currentActiveContext = null
    }

    injectTraceHeaders(activeContext) {
        if(!this.context)
            return {}
        let traceHeaders = {};
        activeContext = activeContext || this.context.active();
        this.propagation.inject(activeContext, traceHeaders)
        return traceHeaders;
    }

    setSpanStatus(span,status) {
        span && span.setStatus(status);
    }

    setSpanAttribute(span, name, value) {
        span && span.setAttribute(name, value)
    }

    setSpanException(span, exception, attributes) {
        this.setSpanStatus(span, { code: 'ERROR', message: exception })
        span && span.recordException(exception, attributes)
    }

    generateActiveContext(spanName) {
        if(!this.context)
            return;

        const activeContext = this.context.active()

        this.currentActiveContext = activeContext
        this.activeContextMap.set(spanName, activeContext)

        return this.currentActiveContext
    }
}

const otelApi = new OtelApi();
export default otelApi;