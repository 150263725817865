import {eSignCNAuditTrailContent4Icon, eSignCNAuditTrailWrapper} from "../style";
export function Content4Comp(props) {

    const displayValidateStatusIcon = (bool) => {
        if (bool) {
            return (
                <svg style={eSignCNAuditTrailContent4Icon} width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="14" cy="14" r="14" fill="#A8C302"/>
                    <g clipPath="url(#clip0_380_77896)">
                        <path d="M12.8499 18.6899C12.6947 18.7141 12.5359 18.7014 12.3865 18.6527C12.2371 18.6041 12.1012 18.5209 11.9899 18.4099L8.28994 14.7099C8.10164 14.5216 7.99585 14.2662 7.99585 13.9999C7.99585 13.8681 8.02182 13.7375 8.07228 13.6157C8.12274 13.4939 8.1967 13.3832 8.28994 13.2899C8.38318 13.1967 8.49387 13.1227 8.61569 13.0723C8.73751 13.0218 8.86808 12.9958 8.99994 12.9958C9.26624 12.9958 9.52164 13.1016 9.70994 13.2899L12.8499 16.4399L18.9899 10.2899C19.1782 10.1016 19.4336 9.99585 19.6999 9.99585C19.9662 9.99585 20.2216 10.1016 20.4099 10.2899C20.5982 10.4782 20.704 10.7336 20.704 10.9999C20.704 11.2662 20.5982 11.5216 20.4099 11.7099L13.7099 18.4099C13.6165 18.5026 13.5057 18.5759 13.3838 18.6257C13.262 18.6755 13.1315 18.7007 12.9999 18.6999L12.8499 18.6899Z" fill="white"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_380_77896">
                            <rect width="12.7" height="8.7" fill="white" transform="translate(8 10)"/>
                        </clipPath>
                    </defs>
                </svg>
            )
        } else {
            return (
                <svg style={eSignCNAuditTrailContent4Icon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.00008 16.3333C13.0502 16.3333 16.3334 13.05 16.3334 8.99996C16.3334 4.94987 13.0502 1.66663 9.00008 1.66663C4.94999 1.66663 1.66675 4.94987 1.66675 8.99996C1.66675 13.05 4.94999 16.3333 9.00008 16.3333Z" stroke="#FF0000" stroke-width="2"/>
                    <path d="M11.1213 6.54529L6.87866 11.4546" stroke="#FF0000" stroke-width="2" stroke-linecap="round"/>
                    <path d="M6.87866 6.54529L11.1213 11.4546" stroke="#FF0000" stroke-width="2" stroke-linecap="round"/>
                </svg>

            )
        }
    }

    const { auditTrail } = props;
    const illegalSignerNames = auditTrail.illegalSignatures && auditTrail.illegalSignatures.length > 0 ? auditTrail.illegalSignatures.map(item => item.assignUserName).join(", ") : ''

    const genStylesFn = (index) => {
        let styles = { padding: '10px 15px', border: '1px #fff solid' }
        styles = index % 2 === 0 ? { ...styles, backgroundColor: '#f5f5f5' } : { ...styles, backgroundColor: '#ededed' };
        return styles;
    }

    return <div style={{ ...eSignCNAuditTrailWrapper, padding: 0 }}>
        {
            auditTrail.auth.map((item, index) => {
                const willAuthTypeEle = item.additionalInfo && item.additionalInfo.willingnessType ?
                    <span>通过<span > {item.additionalInfo.willingnessType}意愿认证方式 </span></span> : <span></span>
                const displayName = item.operatorName && item.operatorName !== item.signerName ? `${item.signerName}（${item.operatorName}）`: `${item.signerName}`;
                if (auditTrail.base.type === "signFlow" && index === 0) {
                    return (
                        <div
                            key={index}
                            style={genStylesFn(index)}>
                            {displayValidateStatusIcon(item.signValidStatus === 1)}
                            <span style={{flex:1}}>
                                    <span style={{paddingLeft: 0}}> {displayName} </span>
                                    于
                                    <span > {item.signDate} </span>
                                {willAuthTypeEle}发起签署流程
                                </span>
                        </div>
                    )
                } else {
                    return (
                        <div
                            key={index}
                            style={genStylesFn(index, auditTrail.auth.length)}>
                            {displayValidateStatusIcon(item.signValidStatus === 1)}
                            <span style={{flex:1}}>
                              <span  style={{paddingLeft: 0}}> {displayName} </span>
                              于
                              <span> {item.signDate} </span>{willAuthTypeEle}完成<span> {displayName} </span>部分的签署</span>
                        </div>
                    )
                }
            })
        }
        {
            auditTrail.illegalSignatures && auditTrail.illegalSignatures.length > 0 &&
            <div>
                {displayValidateStatusIcon(false)}
                <span style={{flex:1}}>
                    <span style={{ paddingLeft: 0, fontWeight: 700 }}>{illegalSignerNames}并未按照签署流程中的要求完成签署</span>
                </span>
            </div>
        }
    </div>
}