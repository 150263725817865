import {useState, useEffect} from "react";
import CreateTabs from "./CreateTabs";
import {Button, Space} from "antd";
import styles from "../index.module.less";
import ImageCropper from "./ImageCropper";
import {getCropBase64, getParams} from "@/utils";
// import {innerSealCreate} from "../../../request/esign";
import cuid from "cuid";
// import {errorMessage, successMessage} from "@/components/Message";
import {innerSealCreate} from "@/request/esign";
import {errorMessage, successMessage} from "../../../../components/Message";

export default function CreateSeal({close }) {
    const [imgData, setImgData] = useState('');
    const [imgSize, setImgSize] = useState([0, 0]);
    const [showCropper, setShowCropper] = useState(false);
    const [cropData, setCropData] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [useCrop, setUseCrop] = useState(false);

    let {token, enterpriseId, isTemp, isOut} = getParams();
    isTemp = parseInt(isTemp) === 1;
    isOut = parseInt(isOut) === 1;

    useEffect(() => {
        const height = 490;
        window.parent.postMessage({
            name: 'PUBLIC_SEAL',
            type: 'resize', width: 1032, height
        }, window.location.origin);
    }, [isTemp])



    if (!token) {
        return;
    }
    if (!enterpriseId && !isTemp) {
        return;
    }

    const handleOk = () => {
        if (showCropper) {
            if (cropData.length > 100) saveData(cropData);
        } else if(!useCrop) {
            if (imgData.length > 100) saveData(imgData);
        } else {
            getCropBase64(imgData).then(data => {
                if (data.length > 100) saveData(data)
            });
        }
    }

    const setData = (data, useCrop = true, width = 0, height=0) => {
        if (!showCropper) {
            setImgData(data)
            setUseCrop(useCrop);
            setImgSize([width, height])
        }
    }

    const saveData = (saveData) => {
        const pictureBase64 = saveData.replace(/^data:.*?base64,/, '');
        if (isTemp && isOut) {
            const unit = 'mm';
            const scale = imgSize[1];
            close(saveData, false, imgSize[0], imgSize[1], scale, unit);
            return;
        }

        setBtnLoading(true);
        const signerId = cuid();
        let scale = 1;
        let width = imgSize[0];
        let height = imgSize[1];
        if (width > 0 && height > 0) {
            scale = 1;
        } else if (width > 0) {
            scale = width;
            height = 0;
            width = 0;
        } else if (height > 0) {
            scale = height;
            height = 0;
            width = 0;
        }
        innerSealCreate({token, enterpriseId, signerId, pictureBase64, width, height, scale}).then(id => {
            if (!id) {
                errorMessage('新增印章失败！');
                return;
            }
            successMessage('新增印章成功！');
            close({id, base64: saveData});
        });
    }
    return (
        <>
            <CreateTabs visible={!showCropper} isOutTemp={isTemp && isOut} setImgData={setData} />
            {
                showCropper && <ImageCropper base64={imgData}  setCropData={setCropData} setImgSize={setImgSize} />
            }
            <div className={styles.tabFooter}>
                <Space>
                    <Button disabled={!imgData} shape={"round"} onClick={() => setShowCropper(!showCropper)}>
                        {showCropper ? '上一步' : '裁切签名'}
                    </Button>
                    <Button disabled={!imgData} type={"primary"} loading={btnLoading} onClick={handleOk}>确认</Button>
                    <Button shape={"round"} onClick={() => close(null)}>取消</Button>
                </Space>
            </div>
        </>
    )
}
