import {Content3CompChild} from "./Content3CompChild";
import {genContent3Box} from "../style";

export function Content3Comp (props) {

    const { auditTrail } = props;
    let certfile = Object.assign([], auditTrail.certfile)
    if(auditTrail.base.type === "signFlow") {  // 此处不展示发起人信息
        certfile.shift()
    }

    // certfile根据证书序列号去重
    const keys = []
    certfile = certfile.filter(item => {
        if (keys.includes(item.serialNumber)) {
            return false
        } else {
            keys.push(item.serialNumber);
            return true
        }
    }).filter(item => {
        if (!item.subject && !item.serialNumber && !item.issuer && !item.format) {
            return false
        } else {
            return true
        }
    })

    return <div style={genContent3Box(certfile.length)}>
        {
            certfile.map((item, index) => {
                return <Content3CompChild key={index} data={item}></Content3CompChild>
            })
        }
    </div>
}