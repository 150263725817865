import CreateSeal from "./createSeals/CreateSeal";

export default function PublicSeal() {

    function close(seals, reAuth = false) {
        window.parent.postMessage({
            name: 'PUBLIC_SEAL',
            type: 'close', success: !!seals, sealData: seals, reAuth
        }, window.location.origin);
    }

    return (
        <div style={{ padding: '10px' }}>
            <CreateSeal close={close}></CreateSeal>
        </div>
    )
}