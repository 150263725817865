import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {getStore} from "../../store";
import {doLogout} from "../../utils";

export const Logout = (isSigner = false) => {
    let content;
    if (isSigner === true) {
        content = '签署操作未完成，是否确认退出当前帐号？';
    } else {
        content = '是否确认退出当前帐号？';
    }
    const userInfo = getStore('userInfo');
    Modal.confirm({
        title: '退出登录',
        icon: <ExclamationCircleOutlined />,
        content,
        okText: "确认",
        cancelText:"取消",
        okButtonProps: {shape: 'round'},
        cancelButtonProps: {shape: 'round'},
        onOk() {
            const token = userInfo.token;
            doLogout(token);
        },
        onCancel() {},
    });
}
