import React, {useState, useEffect} from "react";
import qs from "qs";
import styles from "../mobile.module.less";
import icon from "../../../assets/images/authPlugin/esignbao.png";
import {Button, Skeleton} from "antd";
import {getAuthUrl, getEsignConfig} from "../../../request/esign";
import {isIOS} from "../../../request/utils";

function keepSecurityQueryString(str) {
    const index = str.indexOf('?');
    if (index < 0) {
        console.error('invalid string:', str)
        return ''; // 如果字符串中没有?开头的内容，则返回原始字符串
    } else {
        return str.slice(index); // 返回从第一个?开头的位置开始的剩余内容
    }
}

export function getParamsByUrl(search = window.location.search) {
    const index = search.indexOf('?');
    let params;
    if (index >= 0) {
        params = search.slice(index + 1);
        params = qs.parse(params);
    }
    return params;
}

export default function MobileViewer(props) {
    const [showAuthGuide, setShowAuthGuide] = useState(false);
    const {flowId, type, redirectLink, clientFrom} = props.querys;

    const handleShowAuthGuide = () => setShowAuthGuide(true)

    if (!showAuthGuide) {
        return <>
            {type === 10 &&  <Result {...props} flowId={flowId}></Result>}
            {type !== 10 &&  props.stat !== 5 && <Agree {...props} redirectLink={redirectLink} clientFrom={clientFrom} handleShowAuthGuide={handleShowAuthGuide}></Agree>}
            {type === 3 && props.stat === 5 && <ResultUI stat={props.stat} sendMsg={props.sendMsg} redirectLink={redirectLink} clientFrom={clientFrom}></ResultUI>}
        </>
    } else {
        return <AuthGuideView {...props}></AuthGuideView>
    }
}

function Agree(props) {
    const {token, authParams, sendMsg, redirectLink, clientFrom, handleShowAuthGuide, userInfo} = props;
    const [btnLoading, setBtnLoading] = useState(false);
    const [showUI, setShowUI] = useState(false);

    useEffect(() => {
        sendMsg({type: 'resize', height: '480px'});
        if (authParams.orgName) {
            _getUrl({token, authParams, sendMsg, redirectLink, clientFrom, enterpriseId: userInfo ? userInfo.enterpriseId || '' : ''});
        } else {
            setShowUI(true);
        }
    }, [token, sendMsg, authParams, redirectLink, clientFrom, userInfo]);


    const toEsignbao = () =>{
        setBtnLoading(true);
        _getUrl({token, authParams, sendMsg, redirectLink, clientFrom, enterpriseId: userInfo ? userInfo.enterpriseId || '' : ''});
    }

    const boxMaxHeight = isIOS ? '50vh' : '55vh';

    return showUI && (
        <div className={styles.agree} style={{margin: 0, padding:0}}>
            <div className={styles.head}>
                <div className={styles.title} style={{width: '50%'}}>
                    请您知悉，本电子签章服务的部分功能由第三方机构e签宝为您提供。
                </div>
                <img src={icon} alt={"icon"} height="59" />
            </div>
            <div className={styles.box} style={{ maxHeight: boxMaxHeight }} >
                为向您提供电子签章服务，您需授权使用您的福昕账号登录e签宝平台，或将您的福昕账号与e签宝账号绑定。
                <br/><br/>
                为保障您的文档内容的私密性，避免您或他人的个人隐私、商业秘密等内容泄露，福昕会将您的文档进行哈希加密，并由e签宝基于文档哈希值为您提供电子签章服务。
                福昕和e签宝平台不会获取、保存您的签署文档原文。因此，请您妥善保存签署文档。
                <br/><br/>
                在您使用本电子签章服务过程中，e签宝对您个人信息的收集、使用将遵循
                <a href="https://h5.esign.cn/usercenterFront/protocol/privacy" rel="noreferrer" target='_blank'>e签宝隐私政策</a>，福昕对e签宝收集、使用您的个人信息不承担责任。
                请您在使用本电子签章服务前认真阅读e签宝隐私政策以了解e签宝如何收集、使用您的个人信息。
            </div>
            <div className={styles.buttons}>
                <Button loading={btnLoading} onClick={toEsignbao} type={"primary"} >同意并继续</Button>
                <Button key="back" onClick={() => {
                    handleShowAuthGuide(true);
                    sendMsg({type: 'close'});
                }} >取消</Button>
            </div>
        </div>
    )
}

function _getUrl({authParams, token, sendMsg, redirectLink, clientFrom = "", enterpriseId}){
    const redirectUrlParmas = authParams.redirectUrl.split('?')[1];
    const redirectUrlParamsData = getParamsByUrl(`?${redirectUrlParmas}`)
    const st = redirectUrlParamsData.st;
    const cn = redirectUrlParamsData.cn;
    const queryString = window.location.search;
    localStorage.setItem('authSearch', queryString);
    authParams.redirectUrl = `${redirectLink}?c=1&st=${st}&cn=${cn}&cf=${clientFrom}&aph=${window.location.origin}&ei=${enterpriseId}`;
    getAuthUrl(authParams, token).then(url => {
        if (url) {
            sendMsg({type: 'close', redirect: url, authParams});
            // window.location.replace(url);
        }
    });
}

function Result(props) {
    const {token, flowId, enterpriseId, email, mobile, authParams, sendMsg, stat, seller, initFn} = props;
    const [info, setInfo] = useState(    {});
    const [success, setSuccess] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        sendMsg({type: 'resize', height: '250px'});
        setIsLoading(true);
        initFn({token, flowId, authParams, enterpriseId, email, mobile}).then(info => {
            if (!info.summary) {
                info.summary = stat === 4 ? "您的账户已完成实名认证" : `点击【关闭】，继续完成后续签署相关操作。`;
            }
            setInfo(info);
            setSuccess(!info.isError);
        }).catch(e => {
            setInfo({
                title: '实名认证/授权失败',
                summary: stat === 4 ?  "点击【重新登录】，可重新尝试实名认证" : `点击【关闭】，可重新尝试实名认证/授权或${seller}处理。`
            });
            setSuccess(false);
        }).finally(() => {
            setIsLoading(false);
        });
    }, [sendMsg, token, flowId, authParams, enterpriseId, email, mobile, initFn, seller, stat]);

    return <ResultUI stat={stat} isLoading={isLoading} success={success} info={info} sendMsg={sendMsg}></ResultUI>
}

export function ResultUI(props) {
    let {isLoading= true, stat, success = false, info, sendMsg} = props;
    sendMsg({type: 'resize', height: '250px'});
    if (stat === 5) {
        isLoading = false;
        success = true;
        info = {
            title: '您已个人实名认证',
            summary: '您的账户已完成实名认证'
        }
    }

    if(success) {
        if (stat > 3) { // 仅当推广活动页时，才在此处发送消息
            sendMsg({type: 'close', success})
        }
    }

    const closeFn = () => {
        if (stat > 3) {
            sendMsg({type:'close', success});
        } else {
            if (success) {
                const href = localStorage.getItem('authSearch');
                localStorage.removeItem('authSearch')
                if (!href) {
                    console.error('sessionStorage.getItem("authSearch") is null')
                }
                let url = href.replace("t=10", "t=2");
                url = keepSecurityQueryString(url);
                window.location.replace(url)
            } else {
                sendMsg({type: 'close', reload: false});
            }
        }
    }
    return (
        <Skeleton active loading={isLoading}  className={styles.skeleton}>
            <div className={styles.result}>
                <div className={styles.icon}>
                    <i className={styles.img + ' ' + (stat === 2 || stat === 3 ? styles.org : styles.cert ) }></i>
                    <p className={styles.stat + ' ' + (success ? styles.statOk : (info.title === '企业正在创建中' ? '' : styles.statErr))}>
                        <i className={styles.img + ' ' + (success ? styles.ok : (info.title === '企业正在创建中' ? styles.waiting : styles.error))}></i></p>
                </div>

                <div className={styles.container}>
                    <div className={styles.title} dangerouslySetInnerHTML={{__html: info.title}}></div>
                    <div className={styles.tips} dangerouslySetInnerHTML={{__html: info.summary}}></div>
                </div>
            </div>
            <div className={styles.buttons}>
                {
                    stat >3 && <Button onClick={() => sendMsg({type:'close', relogin: true})} type={"primary"}>退出登录</Button>
                }
                {
                    stat < 4 &&
                    <Button key="back" onClick={closeFn}
                            style={{ marginLeft: '10px'}}>关闭</Button>
                }
            </div>
        </Skeleton>
    )
}

function AuthGuideView(props) {

    const [loading, setLoading] = useState(false);
    const [configInfo, setConfigInfo] = useState({});
    const {sendMsg} = props;


    const title = "<span style='color:#FF7422; font-size: 24px;'>您尚未实名认证</span>"
    const summary = `<span style='color:#3c3c3c; font-size: 14px;'>个人认证成功会免费赠送您<span style='color: green'>${configInfo.trial && configInfo.trial.personNum}</span>个文档签署额度</span>`

    useEffect(() => {
        setLoading(true)
        getEsignConfig().then(config => {
            setConfigInfo(config);
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <Skeleton active loading={loading}  className={styles.skeleton}>
            <div className={styles.result}>
                <div className={styles.icon}>
                    <i className={styles.img + ' ' + styles.warning }></i>
                </div>

                {
                    !loading &&
                    <div className={styles.container}>
                        <div className={styles.title} dangerouslySetInnerHTML={{__html: title}}></div>
                        <div className={styles.tips} dangerouslySetInnerHTML={{__html: summary}}></div>
                    </div>
                }
            </div>
            <div className={styles.buttons}>
                <Button onClick={() => window.location.reload()} type={"primary"}>个人认证</Button>
                <Button onClick={() => sendMsg({type:'close', relogin: true})} >退出登录</Button>
            </div>
        </Skeleton>
    )
}