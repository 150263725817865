import BaseComponent from "./BaseComponent";
import './index.less';
import eventManager, {
    SetSealsEvent,
    SetTempStampEvent, ShowDateManageEvent,
    ShowStampManageEvent
} from "../../utils/EventManager";
import {base64ToArrayBuffer, getScrollBarSize} from "../../utils";

// 数据先回，对象未创建
const  MySeals = {personSeals: [], orgSeals:[]};
eventManager.on(SetSealsEvent, ({personSeals, orgSeals}) => {
    MySeals.personSeals = personSeals || [];
    MySeals.orgSeals = orgSeals || [];
});

export default class SignComponent extends BaseComponent{
    createDom () {
        super.createDom();
        this.isSetAp = false
        this.eLabel.tabIndex = -1;
        this.tempSeals = [];
        this.seals = this.signCNItem.company ? MySeals.orgSeals : MySeals.personSeals;

        const eLabel = this.eLabel;
        const boxDom = this.boxDom = document.createElement('div');
        boxDom.classList.add('sign-drapbox');

        const eList = this.eList = document.createElement('div');
        eList.classList.add('list');
        this.boxDom.tabIndex = -1;
        this.boxDom.appendChild(eList);
        this.eSetting = this._addSettingBtn();

        if (!this.isInner && !this.signCNItem.company) {
            this._addCreateBtn(); // is outer personal sign
        }

        this._addDateBtn();

        this.hideDom();
        eLabel.appendChild(boxDom);
    }


    showDom () {
        if (this.pagingSignType === 2) {
            return;
        }
        this.eList.innerHTML = '';
        this.eSetting.style.display = '';
        // if (!this.seals) {
        //     this.eSetting.style.display = 'none';
        // }

        if (this.seals.length > 0 || this.tempSeals.length > 0) {
            this.tempSeals.forEach(base64 => {
                const ele = this._generateStampEle({src: base64, isTemp: true});
                this.eList.appendChild(ele);
            })
            this.seals.forEach(item => {
                const {base64} = item;
                const sealInfo = {
                    "sealId": item.sealId || item.id || '', // 印章id
                    "sealName": item.alias || '', // 印章名称
                    "sealType": item.usePermission ? 1 : 0 // 印章类型：1：企业章 0：个人章
                };
                const ele = this._generateStampEle({src: base64, sealInfo});
                this.eList.appendChild(ele);
            });
        } else {
            this.eList.innerHTML = '<div class="noData">暂无签章</div>';
        }

        if (!this.isSetAp) {
            this.nameSpan.style.display = 'none'
        }

        this._setEdomPosition();
        this.boxDom.style.display = '';
        this.boxDom.focus()
    }

    hideDom () {
        this.boxDom.style.display = 'none';
        this.boxDom.blur()
        if (!this.isSetAp && this.nameSpan) {
            this.nameSpan.style.display = ''
        }
    }

    bindEvent () {
        super.bindEvent();

        const clickFn = () => {
            if (!this.isMine) {
                return;
            }
            this.showDom();
        };
        const blurFn = () => {
            if (!this.isMine) {
                return;
            }
            this.hideDom();
        };

        const setSeals = ({personSeals, orgSeals}) => {
            if (!this.isMine) {
                return;
            }
            if (this.signCNItem.company) {
                this.seals = orgSeals ? orgSeals : this.seals;
            } else {
                this.seals = personSeals ? personSeals : this.seals;
            }
        }

        const setStamp = (imgData, objNum) => {
            if (this.signCNItem.company || !this.isMine) {
                return;
            }
            if (objNum === this.widget.objNum) {
                const inkSignBuffer = base64ToArrayBuffer(imgData);
                this._setField(inkSignBuffer);
            }
            this.tempSeals.push(imgData);
        }


        const eLabel = this.eLabel;
        eLabel.addEventListener('click', clickFn);
        this.releases.push(() => eLabel.removeEventListener('click', clickFn));
        this.boxDom.addEventListener('blur', blurFn);
        this.releases.push(() => this.boxDom.removeEventListener('blur', blurFn));

        eventManager.on(SetSealsEvent, setSeals);
        this.releases.push(() => eventManager.off(SetSealsEvent, setSeals));

        eventManager.on(SetTempStampEvent, setStamp);
        this.releases.push(() => eventManager.off(SetTempStampEvent, setStamp));

    }

    _setEdomPosition () {
        const {left, top, width, height} = this.getRect();
        const pWidth = this.eLabel.parentNode.clientWidth;
        const pHeight = this.eLabel.parentNode.clientHeight;

        const cRight = pWidth - left - width;
        const cBottom = pHeight - top - height;
        const cnt = this.tempSeals.length + this.seals.length;
        let eHeight = this.isInner || this.signCNItem.company ? 279 : 311;

        // if (!this.isInner) {
        //     if (cnt < 1 ) {
        //         eHeight -= 32;
        //     } else if (!this.seals) {
        //         eHeight -= 32;
        //     }
        // }

        let eWidth = 214;
        let listHeight = 193;
        let scrollBarWidth = getScrollBarSize();
        if (cnt < 3 ) {
            eHeight -= 86;
            listHeight -= 86;
        }
        if (cnt > 4 ) {
            eWidth += scrollBarWidth;
            this.boxDom.style.paddingRight = `0`;
        } else {
            this.boxDom.style.paddingRight = `${scrollBarWidth}px`;
        }


        this.boxDom.style.width = eWidth + 'px';
        this.boxDom.style.height = eHeight + 'px';
        this.eList.style.height = listHeight + 'px';

        if (cRight >= eWidth) {
            this.boxDom.style.left = '2px';
        } else {
            this.boxDom.style.right = '2px';
        }

        if (cBottom >= eHeight) {
            this.boxDom.style.bottom = (-eHeight + 2) + 'px';
        } else {
            this.boxDom.style.top = -(eHeight + 2) + 'px';
        }
    }

    _addSettingBtn() {
        const clickFn = () => {
            eventManager.emit(ShowStampManageEvent, 1, !!this.signCNItem.company);
        };

        const divEle = document.createElement('div');
        divEle.addEventListener('click', clickFn);
        this.releases.push(() => divEle.removeEventListener('click', clickFn));
        divEle.classList.add('setting');

        const iconEle = document.createElement('i');
        const spanEle = document.createElement('span');
        spanEle.innerText = '签章管理';


        divEle.appendChild(iconEle);
        divEle.appendChild(spanEle);
        this.boxDom.appendChild(divEle);
        return divEle;
    }

    _addCreateBtn() {
        const clickFn = () => {
            eventManager.emit(ShowStampManageEvent, this.widget.objNum);
        };

        const divEle = document.createElement('div');
        divEle.addEventListener('click', clickFn);
        this.releases.push(() => divEle.removeEventListener('click', clickFn));
        divEle.classList.add('setting');
        divEle.classList.add('create');

        const iconEle = document.createElement('i');
        const spanEle = document.createElement('span');
        spanEle.innerText = '手绘签章';

        divEle.appendChild(iconEle);
        divEle.appendChild(spanEle);
        this.boxDom.appendChild(divEle);
        return divEle;
    }

    _addDateBtn() {
        const clickFn = () => {
            const data = {
                objNum: this.widget.objNum,
                eLabel: this.eLabel,
                pageIndex: this.pageRender.index,
                isOrg: !!this.signCNItem.company,
            }
            eventManager.emit(ShowDateManageEvent, data);
        };

        const divEle = document.createElement('div');
        divEle.addEventListener('click', clickFn);
        this.releases.push(() => divEle.removeEventListener('click', clickFn));
        divEle.classList.add('setting');
        divEle.classList.add('date-create');

        const iconEle = document.createElement('i');
        const spanEle = document.createElement('span');
        spanEle.innerText = '设置日期';

        divEle.appendChild(iconEle);
        divEle.appendChild(spanEle);
        this.boxDom.appendChild(divEle);
        return divEle;
    }

    _generateStampEle({src, isTemp = false, sealInfo}) {
        const clickFn = () => {
            const inkSignBuffer = base64ToArrayBuffer(imgEle.src);
            this._setField(inkSignBuffer, sealInfo);
            this.eLabel.blur();
        };

        const divEle = document.createElement('div');
        divEle.classList.add('item');

        if (isTemp) {
            const triangleEle = document.createElement('div');
            triangleEle.classList.add('triangle');
            const triangleSpan = document.createElement('div');
            triangleSpan.innerText = '临';
            triangleEle.appendChild(triangleSpan);
            divEle.appendChild(triangleEle);
        }

        const imgEle = document.createElement('img');
        imgEle.classList.add('img');
        imgEle.src = src;
        imgEle.draggable = false;
        imgEle.addEventListener('click', clickFn);
        this.releases.push(() => imgEle.removeEventListener('click', clickFn));
        divEle.appendChild(imgEle);
        return divEle;
    }

    _setField (inkSignBuffer, sealInfo) {
        const annotObjNum = this.widget.objNum;
        const pdfDocRender = this.pageRender.pdfDocRender;
        const pageIndex = this.pageRender.index;

        const pagingIndexs = [];
        this.fields.some(annot => {
            if (annot.annotObjNum === annotObjNum) {
                if (annot.isPagingSig && annot.pagingSigs && annot.pagingSigs.length > 0) {
                    annot.pagingSigs.forEach(item => {
                        pagingIndexs.push(item.pageIndex)
                    })
                }
                return true;
            }
            return false;
        });


        let isPagingSig = pagingIndexs.length > 0;
        return pdfDocRender.pdfViewer.workerFetch.request('doc/field/setSigAP', {
            doc: pdfDocRender.docId,
            annotObjNum,
            pageIndex,
            inkSignBuffer,
            isPagingSig
        }).then(res => {
            if (!res.ret) {
                throw new Error(res.msg);
            }
            this.isSetAp = true
            this.nameSpan.style.display = 'none'
            this.redrawViewerByActionResult({isSign: true, pagingIndexs, annotObjNum, sealInfo});
        });
    }
}
