import React from "react";
import {accountExist, sendVerificationCode, setAccount} from "../../../request/user";
import PcViewer from "./pcViewer";
import MobileViewer from "./mobileViewer";
import PhantomViewer from "./phantomViewer"
import {ERROR_AC_CAPTCHA_FAIL} from "../../../request/error_container";

export default function AddMobile(props) {
    const {client} = props.querys;
    const {userMobile} = props.userInfo;

    const childProps = {
        checkMobile: !userMobile,
        checkValue,
        requestCode,
        submit,
        ...props
    }

    return (
        <>
            {client === 0 && <PcViewer {...childProps} />}
            {client === 1 && <MobileViewer {...childProps} />}
            {client === 2 && <PhantomViewer {...childProps} />}
        </>
    );
}

function checkValue(value, type) {
    let reg;
    switch (type) {
        case 'email':
            reg = /^[a-zA-Z\d._-]+@[a-zA-Z\d_-]+(\.[a-zA-Z\d_-]+)+$/; // 中括号内中的.不需要转义
            break;
        case 'mobile':
            reg = /^1[3-9]\d{9}$/;
            break;
        default:
            reg = /^\d{1,6}$/;
            break;
    }
    return reg.test(value);
}

function requestCode({account, checkMobile, token, captchaId, captcha}) {
    if (checkMobile) {
        account = '+86' + account;
    }
    return accountExist(account, checkMobile).then(res => {
        if (res) {
            return -1;
        }
        return sendVerificationCode(account, checkMobile, token, captchaId, captcha);
    }).then(res => {
        if (res === -1) {
            return res;
        }
        return res ? 1 : -2;
    }).catch(err => {
        if (err === ERROR_AC_CAPTCHA_FAIL) {
            return -4;
        }
        return -3;
        // if (res.message) {
        //     if (res.ret === 2100086) {
        //         errorMessage('验证码发送失败：发送数量超出每日限制')
        //     } else if(res.ret === 2100080) {
        //         errorMessage('验证码发送失败：发送数量超出限制')
        //     } else {
        //         errorMessage('验证码发送失败：' + res.message)
        //     }
        // } else {
        //     errorMessage('验证码发送失败，请稍后重试')
        // }
        // return false
    })
}

function submit({account, code, checkMobile, token}) {
    if (!checkValue(account, checkMobile ? 'mobile' : 'email')) {
        return Promise.resolve(-1);
    }
    if (!checkValue(code,  'code')){
        return Promise.resolve(-2);
    }
    if (checkMobile) {
        account = '+86' + account;
    }
    return setAccount(account, code, checkMobile, token).then(() => {
        return 1;
    }).catch(() => {
        return -3;
    });
}
