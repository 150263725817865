import styles from "../index.module.less";
import {InboxOutlined} from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import {Button, Checkbox, Upload} from "antd";
import {errorMessage} from "../../components/Message";
import {useEffect, useRef, useState} from "react";
import {getBase64, getBlackBase64} from "../../../utils/";


export function ExtUpload (props) {
    const {setUploadClear, hasImg} = props;
    const clearData = () => {
        setUploadClear(new Date().getTime());
    }
    return (
        <Button hidden={!hasImg} type={"link"}  onClick={clearData}>替换</Button>
    )
}

export default function CreateUpload (props) {
    const [isBlack, setIsBlack] = useState(false);
    const {setUpload, uploadClear} = props
    const [imgUrl, setImgUrl] = useState();
    const [file, setFile] = useState(undefined);

    const imgRef = useRef();

    useEffect(() => {
        if (!file) {
            return
        }
        if (isBlack) {
            getBlackBase64(file).then(base64 => {
                setImgUrl(base64);
                setUpload(base64);
            });
        } else {
            getBase64(file).then(base64 => {
                setImgUrl(base64);
                setUpload(base64);
            });
        }
    }, [file, isBlack]);

    useEffect(() => {
        if (uploadClear) {
            imgRef.current.click();
        }
    }, [uploadClear]);

    const uploadProps = {
        name: 'file',
        multiple: false,
        accept: ".png, .jpg, .jpeg",

        beforeUpload: file => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                errorMessage('图片类型错误！');
                return Upload.LIST_IGNORE;
            }

            const isLt200K = file.size / 1024  < 200;

            if (!isLt200K) {
                errorMessage('图片尺寸超过限制！');
                return Upload.LIST_IGNORE;
            }
            setFile(file)
            return Upload.LIST_IGNORE;
        },


        onDrop(e) {
            // console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (<>
            <div className={styles.tabContainer + ' ' + styles.tabUpload} >
                <Dragger {...uploadProps}  >
                    {imgUrl ? (
                        <img src={imgUrl} alt="" ref={imgRef} />
                    ) : (
                        <div className={styles.upload}>
                            <p>
                                <InboxOutlined />
                            </p>
                            <p>点击或拖拽印章到当前区域完成上传</p>
                            <p style={{color: '#ccc'}}>
                                仅支持png / jpg / jpeg类型图片，大小限制为200kb
                            </p>
                        </div>
                    )}

                </Dragger>
            </div>

            <div style={{marginTop: 20, userSelect: 'none'}}>
                <Checkbox checked={isBlack} onChange={e => setIsBlack(e.target.checked)}>
                    将图片转换为黑白色</Checkbox>
            </div>
    </>

    )
}


