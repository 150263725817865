export const INIT = 'INIT';
export const NOT_EXIST = 'NOT_EXIST';
export const CREATE_ORG_SUCCESS = 'CREATE_ORG_SUCCESS';
export const EXIST_MULTIPLE_ORG = 'EXIST_MULTIPLE_ORG';
export const HAS_JOIN = 'HAS_JOIN';
export const NOT_HAS_JOIN = 'NOT_HAS_JOIN';


export const USER_ORG_RELATION = {
    INIT: {
        status: 0
    },
    NOT_EXIST: {
        status: 1,
        msg: '您填写的”福建福昕软件开发股份有限公司“企业组织不存在,是否 认证创建此企业组织？'
    },
    CREATE_ORG_SUCCESS: {
        status: 2,
    },
    EXIST_MULTIPLE_ORG: {
        status: 2,
        msg: "该组织存在多个，请联系销售确认组织信息",
        btn: "联系销售"
    },
    HAS_JOIN: {
        status: 3,
        msg: "您已加入该组织，是否切换组织？",
        btn: "切换组织"
    },
    NOT_HAS_JOIN: {
        status: 4,
        msg: (orgName) => `您填写的”${orgName}“企业组织已存在,是否申请加入此企业组织?`,
        btn: "申请加入"
    },

}
