import React, {useState} from "react";
import './esignBand.less'
import Account from "../Account";

export default function EsignBand() {

    const [show, setShow] = useState(false);

    const handleClick = () => {
        setShow(true)
    }

    return (
        // <Popover overlayClassName='wrapLog' getPopupContainer={triggerNode => triggerNode.parentNode} placement="bottomRight" content={getContent()} trigger="click">
        //     <div className="logo"></div>
        // </Popover>
        <div>
            <div className="logo" onClick={handleClick}></div>
            {
                show &&
                <Account close={() => setShow(false)}/>
            }
        </div>
    )
}