import TextComponent from "./formComponents/TextComponent";
import {FIELD_READONLY} from "./formComponents/FIELD_FLAG";
import {SIGN, TEXT} from "./formComponents/FIELD_TYPE";
import SignComponent from "./formComponents/SignComponent";
import eventManager, {SetDeadlined, SetPdfInfoEvent, ZoomToEvent} from "../utils/EventManager";
import DateComponent from "./formComponents/DateComponent";
import {getMyJoinItem} from "../utils";

let Deadlined = false;
eventManager.on(SetDeadlined, (stat) => {
    Deadlined = stat
});

export default class FormFillPlugin {
    constructor(pdfDocRender) {
        this.pdfDocRender = pdfDocRender;
        this.workerFetch = this.pdfDocRender.pdfViewer.workerFetch;
        this.fields = null;
        this.components = [];
        this.pdfInfo = null;
        this.scale = 1.0;
    }

    destroy () {

    }


    onDocOpened() {
        this.components = [];
        this.workerFetch.request('doc/field/all', {
            doc: this.pdfDocRender.docId,
        }).then(({fields}) => {
            this.fields = fields;
        })
    }

    onPageVisible(pdfPageRender) {
        const index = pdfPageRender.index;
        const ePage = pdfPageRender.ePage;
        ePage.children[0].style.backgroundColor = '#ffffff';

        eventManager.emit('pageChange', index);

        if (this.components[index]) {
            return;
        }

        let widgets;
        this.workerFetch.request('doc/page/annot/all', {
            doc: this.pdfDocRender.docId,
            page: index,
        }).then(annots => {
            widgets = annots.filter(annot => annot.type === 20);
            widgets = this._getWidgetWithFieldAnnots(widgets, this.fields);
            if (widgets.length < 1) {
                return;
            }

            if (this.pdfInfo) {
                return this.pdfInfo;
            } else {
                return this.workerFetch.request('doc/getAssignInfo', {doc: this.pdfDocRender.docId}).then(info => {
                    if (!info || !info.receipts || info.receipts.length < 1) {
                        return;
                    }
                    let isNoOrder = false;
                    const sinerOrders = [];
                    info.receipts.forEach(item => {
                        const cnt = item.annots.length;
                        let finishCnt = 0;
                        item.annots.forEach(annot => {
                            if (annot.fieldStatus === 2) { // 0未完成，2已完成 1签章域已放置印章
                                finishCnt++;
                            }
                        })
                        if (cnt > 0 && finishCnt === cnt) {
                            item.finished = true;
                        }

                        if (!isNoOrder) {
                            if (sinerOrders.includes(item.signOrder)) {
                                isNoOrder = true;
                            } else {
                                sinerOrders.push(item.signOrder);
                            }
                        }
                    });

                    if (isNoOrder) {
                        info.receipts.sort((a, b) => {
                            if (!a.finished && b.finished) {
                                return 1;
                            }
                            return -1;
                        });
                    } else {
                        info.receipts.sort((a, b) => {
                            return a.signOrder - b.signOrder;
                        });
                    }
                    info.isNoOrder = isNoOrder;
                    this.pdfInfo = info;
                    eventManager.emit(SetPdfInfoEvent, info);
                    return info;
                });
            }
        }).then(pdfInfo => {
            if (!pdfInfo) {
                return;
            }
            const {receipts} = pdfInfo;
            if (!receipts || receipts.length < 1) {
                return;
            }
            console.log('show field: ', pdfInfo);
            let signItem; // 仅显示当前待签署的项
            receipts.some(item => {
                const res = item.annots.some(annot => {
                    if (annot.fieldStatus !== 2) {
                        return true;
                    }
                    return false;
                })
                if (res) {
                    signItem = item;
                    return true;
                }
                return false;
            })

            if (!signItem) {
                return;
            }

            widgets.forEach(widget => {
                if (widget.fieldJSON.flags & FIELD_READONLY) { // field只读
                    return;
                }
                let signType;
                let pagingSigs;
                let pagingSignType = 0;
                signItem.annots.some(annot => {
                    if (annot.isPagingSig) {
                        pagingSigs = annot.pagingSigs;
                    }
                    if (annot.annotObjNum === widget.objNum) {
                        if (annot.isPagingSig) {
                            pagingSignType = 1;
                        }
                        signType = annot.type;
                        return true;
                    }
                    if (pagingSigs && pagingSigs.length > 0) {
                        return pagingSigs.some(pagingSig => {
                            if (pagingSig.annotObjNum === widget.objNum) {
                                signType = annot.type;
                                pagingSignType = 2;
                                return true;
                            }
                            return false;
                        })
                    }
                    return false;
                })

                if (signType) {
                    this._createDoms(widget, pdfPageRender, signItem, signType, pagingSignType);
                    return;
                }

                const nextItem = getMyJoinItem(pdfInfo);
                if (!nextItem) {
                    return;
                }
                nextItem.annots.some(annot => {
                    if (annot.isPagingSig) {
                        pagingSigs = annot.pagingSigs;
                    }
                    if (annot.annotObjNum === widget.objNum) {
                        if (annot.isPagingSig) {
                            pagingSignType = 1;
                        }
                        signType = annot.type;
                        return true;
                    }
                    if (pagingSigs && pagingSigs.length > 0) {
                        return pagingSigs.some(pagingSig => {
                            if (pagingSig.annotObjNum === widget.objNum) {
                                signType = annot.type;
                                pagingSignType = 2;
                                return true;
                            }
                            return false;
                        })
                    }
                    return false;
                })
                if (!signType) {
                    return;
                }

                this._createDoms(widget, pdfPageRender, nextItem, signType, pagingSignType);
            });
        });
    }

    onPageZooming(pdfPageRender, scale) {
        this.scale = scale;
    }

    onPageZoomed(pdfPageRender) {
        const index = pdfPageRender.index;
        eventManager.emit(ZoomToEvent, this.scale, index);
        if (this.components[index]) {
            this.components[index].forEach(component => {
                component.changeRect();
            });
        }
    }

    _createDoms(widget, pdfPageRender, item, type, pagingSignType) {
        if (Deadlined) {
            return;
        }
        let component;
        if (widget.fieldJSON.type === TEXT) {
            if (type === 'Date') {
                component = new DateComponent(pdfPageRender, widget, item);
            } else {
                component = new TextComponent(pdfPageRender, widget, item);
            }
        }
        if (widget.fieldJSON.type === SIGN) {
            component = new SignComponent(pdfPageRender, widget, item, pagingSignType);
        }
        if (component) {
            const index = pdfPageRender.index;
            if (!this.components[index]) {
                this.components[index] = [];
            }
            this.components[index].push(component);
        }
    }

    _getWidgetWithFieldAnnots (widgets, fields) {
        let fieldNameMap = {};
        fields.forEach(function (field) {
            fieldNameMap[field.name] = field;
        });
        return widgets.map(function (widget) {
            widget.fieldJSON = fieldNameMap[widget.field] || {};
            return widget;
        });
    }
}
