import cuid from 'cuid';
// import {getQueryVariable} from 'Utils/utils'

export const FROM_TYPE = {
  AI_PLUGIN:'ai-plugin-100',
  ONLINE:'fo-online-101',
  WIN_PHANTOM_ESIGNCN_PLUGIN: 'win-phantom-esigncn-plugin'
}

const MESSAGE_TYPE = {
  RESPONSE: 'response',
  REQUEST: 'request',
  EVENT: 'event',
  LISTENER: 'listener',
}

function noOp(/* method, data */) {
  return new Promise((/* resolve, reject */)=>{
    throw new Error('MessageUtil error')
  })
}
/**
 * @typedef Message
 * @property {string} from one of 'fo-online' 'ai-plugin'
 * @property {string} type one of 'response' 'request'
 * @property {string} id (optional)
 * @property {string} method (optional)
 * @property {object} data
 */

export class MessageUtil{
  constructor(parentWindow){
    this.onRequestMethodFn = noOp;
    this.from = '';
    this.cacheMap = {};
    this.targetOrigin = '*';
    this.parentWindow = parentWindow;

    this.init = this.init.bind(this);
    this.onRequest = this.onRequest.bind(this);
    this.onResponse = this.onResponse.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.doSendResponse = this.doSendResponse.bind(this);
  }

  init(onRequestMethodFn, from, targetOrigin){
    this.from = from;
    targetOrigin && (this.targetOrigin = targetOrigin)
    
    this.onRequestMethodFn = onRequestMethodFn;


    if((!window.parent||!window.parent.postMessage) && !this.parentWindow){
      throw new Error('window.parent error')
      //return;
    }
    let _this = this;

    window.addEventListener("message", (event)=>{
      if(`${event.origin}` !== _this.targetOrigin){
        return;
      }
      let message = event.data;
      // response
      if (message.type === MESSAGE_TYPE.RESPONSE) {
        this.onResponse(message);
        return;

      }
      // request to do something
      if (message.type === MESSAGE_TYPE.REQUEST || message.type === MESSAGE_TYPE.LISTENER) {
        this.onRequest(message, event.source);

        return;
      }

    }, false);
  }

  onRequest(message, source) {
    const {method, data, id, type} = message;
    let sourceType = type;
    const fn = (result)=>{
      let type = MESSAGE_TYPE.RESPONSE
      if(sourceType === MESSAGE_TYPE.LISTENER) type = MESSAGE_TYPE.EVENT;
      let message = {
        from: this.from,
        type: type,
        id,
        method,
        data: result
      }
    
      // post to parent
      try{
        source.postMessage(message, this.targetOrigin);
      }catch(error){
        console.log(error)
      }

    }
    this.onRequestMethodFn(method, data, id, fn).then((result)=>{
      if(sourceType === MESSAGE_TYPE.LISTENER) return;
      fn(result);
    })
  }
  // receive message from listener
  onResponse(message){
    let id = message.id;
    let data = message.data;
    let calls = this.cacheMap[id];
    if(calls && calls.resolve){
      delete this.cacheMap[id];
      calls.resolve(data)
      return;
    }
    throw new Error('error')
  }
  // send response to other window
  doSendResponse(method, data, id){
    let message = {
      from: this.from,
      type: MESSAGE_TYPE.REQUEST,
      id,
      method,
      data
    }
    if (this.parentWindow) {
      this.parentWindow.postMessage(message, this.targetOrigin)
    } else {
      window.parent.postMessage(message, this.targetOrigin)
    }
  }

  /*
  * @param {string} method
  * @param {object} data
  * @returns {Promise.<Object>} result
  */
  handleMessage(method, data) {
    return new Promise((resolve, reject)=>{
      let id = cuid();
      this.cacheMap[id] = {
        resolve, reject
      }
      let message = {
        from: this.from,
        type: MESSAGE_TYPE.REQUEST,
        id,
        method,
        data
      }
      if (this.parentWindow) {
        this.parentWindow.postMessage(message, this.targetOrigin)
      } else {
        window.parent.postMessage(message, this.targetOrigin)
      }
    })

  }
}

