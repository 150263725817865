import {getStore} from "../../../../store";
import {useCallback, useEffect, useState} from "react";
import {Skeleton} from "antd";
import styles from './index.module.less';
import {
    closeESignCNDialogAPI,
    createTemporarySealForPhantom, refreshGalleryForPhantom,
} from "../../../../request/phantom";
import {getParams} from "../../../../utils";
import {resizeDialog} from "../../Model";

export default function PhantomStamp() {

    const [url, setUrl] = useState('');

    const query = getParams();

    function handleIframeLoadFail() {
        console.error('iframe load fail');
    }

    const handleListenMsg = useCallback((event) => {
        const data = event.data;
        if (data && data.name === 'PUBLIC_SEAL') {
            if (data.type === 'close') {
                const { sealData = "" } = data;
                if (!sealData) {
                    return closeESignCNDialogAPI();
                }
                if (+query.wardType === 0) {
                    createTemporarySealForPhantom({ type: query.wardType, base64: sealData.split('base64,')[1] })
                        .then(() => {
                            return closeESignCNDialogAPI();
                        })
                } else {
                    refreshGalleryForPhantom({ type: query.wardType })
                        .then(() => {
                        return closeESignCNDialogAPI();
                    })
                }
            }
            if (data.type === 'resize') {
                const { width, height } = data;
                resizeDialog({ width, height });
            }
        }
    }, [query.wardType]);

    useEffect(() => {
        window.addEventListener('message', handleListenMsg);
        return () => {
            window.removeEventListener('message', handleListenMsg);
        }
    }, [query.wardType, handleListenMsg])



    useEffect(() => {
        const params = {
            token: getStore('userInfo').token,
            enterpriseId: getStore('userInfo').enterpriseId || '',
            isTemp: query.wardType === '0' ? 1 : 0, // 对外为临时章，对内为非临时章
            isOut: query.wardType ===  '0' ? 1 : 0, // 0：对内 1：对外
            client: 2, // 客户端
            from: 'phantom',
            accountId: getStore('userInfo').accountId || '',
            trueName: getStore('esignPerson').name || '',
            orgId: getStore('userInfo').orgId || '',
        }
        if (window.location.origin.includes('localhost')) {
            params.phantom_web_mock_env = 1;
        }
        const stampUrl = `${window.location.origin}/public-seal?${Object.keys(params).map(key => `${key}=${params[key]}`).join('&')}`;
        setUrl(stampUrl);
    }, [query.wardType])

    const iframeClassName = !!url ? `${styles.pluginContainer} ${styles.fullScreen}` : styles.pluginContainer;

    return (
        <div className={`${styles.pluginBackground}`}>
            <div className={`${styles.skeletonWarp}`}><Skeleton/></div>
            {
                !!url &&
                <iframe
                    title='phantom-stamp-iframe'
                    className={`${iframeClassName}`}
                    src={url}
                    onError={handleIframeLoadFail}>
                </iframe>
            }
        </div>
    )
}