export const ERROR_EQB_AUTH_EXPIRED = "EQB_AUTH_EXPIRED";
export const ERROR_EQB_ORG_ADMIN_FAIL = "EQB_ORG_ADMIN_FAIL";
export const ERROR_EQB_PERSON_TOKEN_EXPIRED = "EQB_PERSON_TOKEN_EXPIRED";
export const ERROR_EQB_ORG_TOKEN_EXPIRED = "EQB_ORG_TOKEN_EXPIRED";
export const ERROR_EQB_ORG_ADMIN_TOKEN_EXPIRED = "EQB_ORG_ADMIN_TOKEN_EXPIRED";
export const ERROR_EQB_FAIL = "EQB_FAIL";

export const ERROR_AC_ORG_ID_FAIL = "AC_ORG_ID_FAIL";
export const ERROR_AC_TOKEN_FAIL = "AC_TOKEN_FAIL";
export const ERROR_AC_PERMISSION_FAIL = "AC_PERMISSION_FAIL";
export const ERROR_AC_ORG_PERMISSION_FAIL = "AC_ORG_PERMISSION_FAIL";
export const ERROR_AC_SAML_VALID_FAIL_100010 = "AC_SAML_VALID_FAIL_100010";
export const ERROR_AC_SAML_VALID_FAIL_100011 = "AC_SAML_VALID_FAIL_100011";
export const ERROR_AC_SAML_VALID_FAIL_100019 = "AC_SAML_VALID_FAIL_100019";
export const ERROR_AC_SAML_VALID_FAIL_110011 = "AC_SAML_VALID_FAIL_110011";
export const ERROR_AC_SAML_VALID_FAIL_140004 = "AC_SAML_VALID_FAIL_140004";
export const ERROR_AC_SAML_VALID_FAIL_140005 = "AC_SAML_VALID_FAIL_140005";
export const ERROR_AC_SAML_VALID_FAIL_140006 = "AC_SAML_VALID_FAIL_140006";
export const ERROR_AC_SAML_VALID_FAIL_140007 = "AC_SAML_VALID_FAIL_140007";
export const ERROR_AC_SAML_VALID_FAIL_140008 = "AC_SAML_VALID_FAIL_140008";
export const ERROR_AC_SAML_VALID_FAIL_140009 = "AC_SAML_VALID_FAIL_140009";
export const ERROR_AC_SAML_VALID_FAIL_140010 = "AC_SAML_VALID_FAIL_140010";

export const ERROR_CAS_SMS_FAIL_OVERLOAD_PER_DAY = "CAS_SMS_FAIL_OVERLOAD_PER_DAY";
export const ERROR_CAS_SMS_FAIL_OVERLOAD_TOTAL = "CAS_SMS_FAIL_OVERLOAD_TOTAL";

export const ERROR_EQB_SEAL_PERMISSION_FAIL = "EQB_SEAL_PERMISSION_FAIL";

export const ERROR_AC_NO_IN_ORG = "AC_NO_IN_ORG";
export const ERROR_AC_NO_ORG = "AC_NO_ORG";
export const ERROR_AC_REJOIN_ORG = "AC_REJOIN_ORG";
export const ERROR_AC_CAPTCHA_FAIL = "AC_CAPTCHA_FAIL";
export const ERROR_AC_FAIL = "AC_FAIL";

export const ERROR_ESIGN_TOKEN_FAIL = "ESIGN_TOKEN_FAIL";
export const ERROR_ESIGN_DELETED = "ESIGN_DELETED";
export const ERROR_ESIGN_CANCELLED = "ESIGN_CANCELLED";
export const ERROR_ESIGN_NUM_LIMIT = "ESIGN_NUM_LIMIT";
export const ERROR_ESIGN_RISK_CONTROL = "ESIGN_RISK_CONTROL";
export const ERROR_ESIGN_STOCK_CONTROL = "ESIGN_STOCK_CONTROL";
export const ERROR_ESIGN_NO_RECIPIENT = "ESIGN_NO_RECIPIENT";

export const ERROR_ESIGN_QRDRAW_TIMEOUT = "ESIGN_QRDRAW_TIMEOUT";
export const ERROR_ESIGN_QRDRAW_USED = "ESIGN_QRDRAW_USED";

export const ERROR_ESIGN_RECORD_PERMISSION_FAIL = "ESIGN_RECORD_PERMISSION_FAIL";
export const ERROR_ESIGN_ORG_PERMISSION_FAIL = 'ESIGN_ORG_PERMISSION_FAIL';

export const ERROR_RES_CODE = {
    EQB_AUTH_EXPIRED: [610113,600022,600024], // 认证过期
    EQB_ORG_ADMIN_FAIL: 600021, // 获取企业管理员信息失败
    EQB_SEAL_PERMISSION_FAIL: 700004, // 用户无印章管理权限，此错误码来自易签宝
    EQB_FAIL: [700001,700002,700003], // 其它错误
    // EQB_PERSON_TOKEN_EXPIRED: 600022, // 个人token过期，可同610113相同处理
    EQB_ORG_TOKEN_EXPIRED: [600023, 1499000], // 企业非管理员token过期
    // EQB_ORG_ADMIN_TOKEN_EXPIRED: 600024, // 企业管理员token过期

    AC_ORG_ID_FAIL: 600025, // 企业id有错误（对应着ac接口返回ret为2100061）
    AC_TOKEN_FAIL: [600026, 210007, 600002], // 用户的access token有错误（对应着ac接口返回ret为210007）
    AC_PERMISSION_FAIL: 600027, // 企业未对当前用户授权（对应着ac接口返回ret为110016）
    AC_ORG_PERMISSION_FAIL: 600028, // 企业未订阅当前服务或订阅过期过期（对应着ac接口返回ret为2100058）
    AC_FAIL: 600030, // 以上4个情况外的其他错误。
    AC_NO_ORG: 31020, // 查询的组织不存在
    AC_NO_IN_ORG: 31024, // 用户不在当前组织中
    AC_REJOIN_ORG: 31028, // 重复加入组织
    AC_IGNORE: [],
    AC_CAPTCHA_FAIL: 2100081, // 短信发送时，图形验证码错误
    AC_SAML_VALID_FAIL_100010: [100010, 200010], // JWT验证失败，Token无效或过期
    AC_SAML_VALID_FAIL_100011: 100011, // Token错误
    AC_SAML_VALID_FAIL_100019: 100019, // Token为Null
    AC_SAML_VALID_FAIL_110011: 110011, // assertion 或 IDPX509Certificate 为空
    AC_SAML_VALID_FAIL_140004: 140004, // assertion 格式错误或无效
    AC_SAML_VALID_FAIL_140005: 140005, // 时间校验无效
    AC_SAML_VALID_FAIL_140006: 140006, // 签名无效
    AC_SAML_VALID_FAIL_140007: 140007, // 身份提供商实体ID错误
    AC_SAML_VALID_FAIL_140008: 140008, // SP实体ID错误
    AC_SAML_VALID_FAIL_140009: 140009, // SP断言用户服务URL错误
    AC_SAML_VALID_FAIL_140010: 140010, // X.509公钥证书错误

    CAS_SMS_FAIL_OVERLOAD_PER_DAY: 2100086, // 验证码发送失败：发送数量超出每日限制
    CAS_SMS_FAIL_OVERLOAD_TOTAL: 2100086, // 验证码发送失败：发送数量超出限制

    // ESIGN_TOKEN_FAIL: 600002, // token错误，可同600026相同处理
    ESIGN_DELETED: 610153, // 文档已删除
    ESIGN_CANCELLED: 610154, // 文档已撤销
    ESIGN_NUM_LIMIT: 610170, // 操作次数限制，如24小时仅可催办一次
    ESIGN_RISK_CONTROL: 610171, // 账户因存在安全风险被限制
    ESIGN_STOCK_CONTROL: 610172, // 签署额度扣除失败
    ESIGN_NO_RECIPIENT: 610180, // 对外发起填写参与人信息时，查询历史联系人未查询到结果

    ESIGN_QRDRAW_TIMEOUT: 610202, // 手绘签名超时
    ESIGN_QRDRAW_USED: 610203, // 手绘签名已使用
    ESIGN_QRDRAW_NULL: 610200, // 手绘签名未找到
    ESIGN_QRDRAW_CANCELED: 610201, // 手绘签名已作废

    ESIGN_ORG_PERMISSION_FAIL: 600063, // 用户无企业管理权限
    ESIGN_RECORD_PERMISSION_FAIL: 600063, // 用户无权限查看企业 签署/用印记录
};
