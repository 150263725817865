import styles from "../index.module.less";
import {Button} from "antd";

export function CreateOrgSuccessView(props) {

    const { orgName, adminEmail } = props;

    const onCancel = () => {

    }
    return <>
        <div className={styles.orgIcon}></div>
        <div className={styles.content2}>
            <div className={styles.msg2}>
                <div className={styles.title2}>创建企业组织成功</div>
                <div>企业名：{orgName}</div>
                <div>企业管理员邮箱：{adminEmail}</div>
            </div>
            <div className={styles.btnGroup}>
                <Button htmlType="button" onClick={onCancel}>
                    关闭
                </Button>
            </div>
        </div>
    </>
}