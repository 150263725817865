import React, { Component } from 'react';
// import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { Tooltip, Popover, Modal, message } from 'antd';
import dayjs from 'dayjs';
import './signerList.less';
import {getStore} from "../../../../store";
import {noticeSign} from "../../../../request/esign";
import {FLOW_SIGN_ORDERED_TYPE, getFlowSignOrderedType} from "../../service";



/*
 params
 @peopleList: Array // This is a list of members who share files
 @reviewSessionID: String // Request user data necessary parameters
 @cDocID: String // Document ID
 @fileLink: Sreing // Online document address
 @shareState: Number(0: share | 1: not) // Whether or not to share
 @type: String (yours|others) // Share whether the file is yours
 */
class SignerList extends Component {
    constructor(props) {
        super(props);
        this.userListRef = null
        this.SharedUserListRef = React.createRef()
        this.portraitListRef  = React.createRef()
        this.rightArrowRef = React.createRef()
        this.totalUserRef = React.createRef()
        this.state = {
            userBgColorList: ['#ba91ff','#68AFFF','#EF80DB','#FFA800','#75D060','#48D3D5','#E99570','#758395','#9DB4E2'],
            notPhantomOnline: false,
            isolateComponent: false,
            visible: false,
            isSuccess: false,
            successLength: 0,
            isLoading: false,
            isAddUser: false,
            userList: [],
            addOption: [],
            selectData: [],
            userListData: [],
            detailsUserList: [],
            email: '',
            userListLoading:false,
            propsData: {
                // "peopleList": [
                //     {
                //         "name": "Chao Lu",
                //         "cUserID": "61dbe3fc90d12c000174311d",
                //         "email": "chao_lu@foxitsoftware.com",
                //         "userName": "Chao Lu",
                //         "avatar": null,
                //         "prohibitComment": 0,
                //         "isOwner": 1,
                //         "bg": "#FFA800"
                //     },
                //     {
                //         "name": "Lc",
                //         "cUserID": "605d47ab1ecccb00018effd1",
                //         "email": "302532172@qq.com",
                //         "userName": "Lc",
                //         "avatar": null,
                //         "prohibitComment": 1,
                //         "bg": "#ba91ff"
                //     },
                // ]
            },
        };
    }
    componentDidMount() {

    }

    getBgIndex = (userId) => {
        const length = this.state.userBgColorList.length
        if(!userId) return length - 1
        const key = userId[userId.length -1]
        var s = 'a b c d e f g h i j k l m n o p q r s t u v w x y z 0 1 2 3 4 5 6 7 8 9';
        var sArray=s.split(' ');
        let keyIndex = 0
        sArray.forEach((item,index) => {
            if(key === item) keyIndex = index%length
        })
        return keyIndex
    }

    getUserDetailsList = () => {
        const { propsData } = this.props;
        let list = propsData.peopleList;
        list.forEach(item => {
            item.bg = this.state.userBgColorList[this.getBgIndex(item.email)]
        })
        return list.map((item,index) => {
            if(index>1){
                return <div key={index}></div>
            }
            return (
                <li key={index} >
                    {/*<Tooltip placement="bottom" title={this.getUserCommentAuthority(item)} getPopupContainer={triggerNode => triggerNode.parentNode}>*/}
                    {/* {!!item.avatar &&
            <i className="user-avatar" style={{background: `url(${item.avatar}) no-repeat 0 0 / 20px 20px`}}/>
          } */}
                    {/* {!item.avatar && (item.isGuest === 1 || !item.process_id) &&
            <i className="guest-avatar user-avatar"/>
          } */}
                    {/* {!item.avatar && (item.isGuest !== 1 && item.process_id) && */}
                    <p className="defaultPortrait" style={{background: item.bg}}>{item.name[0].toUpperCase()}</p>
                    {/* } */}
                    {/*</Tooltip>*/}
                </li>
            )
        })
    }



    getUserCommentAuthority = (item) => {
        let str = `${item.name}`;
        if(item.email){
            str += `\r\n${item.email}`
        } else if(item.phone){
            str += `\r\n${item.phone}`
        }
        if(/* item.chapter_type == '2' && */ item.company){//企业类型
            str += `\r\n${item.company}`
        }
        return str;
    }
    getContent = () => {

        // const { propsData } = this.props;
        // const { peopleList} = this.props.propsData
        return (
            <div className='sharedPopoverBox' onClick={e => { e.stopPropagation() }}>
                {/*<div className="title">*/}
                {/*  {propsData.peopleList.length} {'collaboration.sharedList.SharedUserList.popTitle'}*/}

                {/*</div>*/}
                <div className='tableTitle'>
                    <span style={{width: '28%'}}>姓名</span>
                    <span style={{width: '31%'}}>手机号/邮箱</span>
                    <span style={{width: '19%'}}>签署时间</span>
                    <span style={{width: '14%'}}>耗时</span>
                    <span style={{width: '8%'}}> </span>
                </div>
                <ul className="userList">
                    {this.getUserList()}
                </ul>
            </div>
        )
    }

    // 判断是否为本人发起的
    isCreator = (record) => {
        const { userEmail, userMobile } = getStore('userInfo')
        const { creator_email, creator_phone } = record;
        if (
            (userMobile && creator_phone && creator_phone.slice(-11) === userMobile.slice(-11)) ||
            (userEmail && creator_email && userEmail.toLowerCase() === creator_email.toLowerCase())
        ) {
            return true;
        } else {

        }
    };
    isCurrentSigner = (record) => {
        if (record.cur_order === null || (record.cur_order.length === 1 && record.cur_order[0] === 0)) {
            return false;
        }
        const { userEmail, userMobile } = getStore('userInfo');
        const currentSignerList = this.getCurrentSignerList(record);
        return record.status !== '3' &&
            (
                (userMobile && currentSignerList.find(item => item.phone && item.phone.slice(-11) === userMobile.slice(-11))) ||
                (userEmail && currentSignerList.find(item => item.email && item.email.toLowerCase() === userEmail.toLowerCase() ))
            )

    };
    getCurrentSignerList = (record) => {
        if (record.cur_order === null || (record.cur_order.length === 1 && record.cur_order[0] === 0)) {
            return [];
        }
        const list = record.signRecipientList;
        // const currentSigner = list[record.curOrder];
        const currentSignerList = [];
        record.cur_order.forEach(order => {
            currentSignerList.push(list[order])
        })
        return currentSignerList;
    };
    doUrge = (currentSigner) => {
        // store.appStore.onShowWarningMessage(
        //     (isOk) => {
        //         isOk && eSignChinaService.noticeSign(currentSigner.process_id).then(() => {
        //             eSignChinaService.signProcessMessage('success', '催办已发送');
        //         });
        //     },
        //     {
        //         message: 'esignRecord.urgeContent',
        //         messageParams: { creator_email: currentSigner.email, creator_name: currentSigner.name },
        //         title: 'esignRecord.urge',
        //     },
        //     false,
        // );
        Modal.confirm({
            title: '催办',
            content: <span>是否确定向<br/>{currentSigner.name}｜{currentSigner.email || currentSigner.phone}<br/>发送催办提醒？</span>,
            zIndex: 99999,
            onOk: () => {
                noticeSign(currentSigner.process_id).finally(() => {
                    message.success('催办已发送');
                })
            }
        })
    };

    getUserList = () => {
        const { peopleList} = this.props.propsData
        const {record} = this.props
        const isUrge = record.status !== '3' && record.doc_store !== '0' && Array.isArray(record.cur_order) && record.cur_order[0] !== 0 && this.isCreator(record)
        return peopleList.map((item, index) => {
            const { userEmail, userMobile } = getStore('userInfo')
            const isCurrentUser = (
                (userMobile && item.phone && item.phone.slice(-11) === userMobile.slice(-11)) ||
                (userEmail && item.email && item.email.toLowerCase() === userEmail.toLowerCase())
            )
            let itemShowUrage = false
            if (isUrge && item.status !== '4' && !isCurrentUser && record.cur_order.includes(parseInt(item.order)) ) {
                itemShowUrage = true
            }

            let orderJsx = null
            if(getFlowSignOrderedType(peopleList) === FLOW_SIGN_ORDERED_TYPE.ALL_ORDERED) {
                orderJsx = <span style={{display:"inline-block", marginRight:"5px", height:"20px", width:"20px", minWidth:"20px", lineHeight:"20px", textAlign:"center",color:"white", fontSize:"14px", borderRadius:"50%",background:"#982E91"}}>
                                {index+1}
                            </span>
            }
            return (
                <li key={index}>
                    {
                        !item.company ? 
                        <div style={{width: '28%',display:"flex", alignItems:"center"}}>{orderJsx}{item.name}</div> : 
                        <div style={{width: '28%', paddingRight:"14px", display:"flex", alignItems:"center"}}>
                            {orderJsx}
                            <Tooltip title={`${item.name}(${item.company})`} color="#FFF">
                                <div style={{display:"flex", flex:"1", width:"0", lineHeight:"20px", wordBreak:"keep-all"}}>
                                    <div>{item.name}</div>
                                    <div style={{overflow:"hidden", whiteSpace:"nowrap", textOverflow:"ellipsis"}}>
                                        {`(${item.company}`}
                                    </div>
                                    <div>{')'}</div>
                                </div>
                            </Tooltip>
                        </div>
                    }
                    <div style={{width: '31%'}}>
                        <Tooltip title={this.getUserCommentAuthority(item)} color="#FFF">
                            <div style={{overflow:"hidden", whiteSpace:"nowrap",  lineHeight:"20px", textOverflow:"ellipsis"}}>
                                {item.email.slice(0, 40) || item.phone}
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{width: '19%'}}>{
                        item.status === '4' ? dayjs(parseInt(item.update_time) * 1000).format('YYYY-MM-DD HH:mm:ss') :
                            record.cur_order?.includes(index+1) ? '待签署' : '---'
                    }</div>
                    <div style={{width: '14%'}}>
                        {
                            item.costTime || '---'
                        }
                    </div>
                    <div style={{width: '8%'}}>
                        {
                            itemShowUrage &&  (
                                <div
                                    style={{width:"50px"}}
                                    className='urgeBtn'
                                    onClick={() => {
                                        this.doUrge(item);
                                    }}>
                                    催办
                                </div>
                            )}
                    </div>
                    {/*<div className="headPortrait">*/}
                    {/*    <span style={{background: item.bg}}>{item.name&&item.name[0].toUpperCase()}</span>*/}
                    {/*</div>*/}
                    {/*<div className="content">*/}
                    {/*  <Tooltip title={this.getUserCommentAuthority(item)}>*/}
                    {/*    <p>{this.getUserCommentAuthority(item)}</p>*/}
                    {/*  </Tooltip>*/}
                    {/*</div>*/}
                </li>
            )
        })
    }
    visibleChange = (visible) => {
        if(!visible) {
            this.setState({
                isAddUser: false,
                addOption: [],
                selectData: []
            })
        } else {
        }
        this.setState({visible})
    }

    setUserList = (list)=>{
        this.setState({
            detailsUserList: list,
        })
    }

    render() {
        const {  visible } = this.state;
        const { peopleList } = this.props.propsData;
        let peopleLength = peopleList.length;
        return (
            <div className='signerList'>
                <div ref={this.totalUserRef} className="total-user">
                    {peopleLength > 99 ? '99+' : peopleLength}
                </div>


                <Popover
                    getPopupContainer={(triggerNode) => document.body}
                    zIndex={1050}
                    content={this.getContent()}
                    open={visible}
                    trigger="click"
                    placement="bottomRight"
                    onOpenChange={this.visibleChange}
                    overlayClassName='noArrow'
                >
                    <div style={{display: 'flex'}}>
                        <ul ref={this.portraitListRef} className={['portraitList', peopleLength >= 99 ? 'morePortraitList' : ''].join(' ')}>
                            {this.getUserDetailsList()}
                        </ul>
                        <div ref={this.rightArrowRef} className="right-arrow-btn" />
                    </div>

                </Popover>

            </div>

        );
    }
}
// const WrapSignerList = Form.create({ name: 'SignerList' })(SignerList);

export default SignerList;
