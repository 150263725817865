import {pt2px} from "../utils/px.module";
import eventManager, {PdfRefrashEvent, SetMyItemEvent} from "../../utils/EventManager";

// 数据先回，对象未创建
let MyItemUniqueKey = [];
eventManager.on(SetMyItemEvent, keys => {
    MyItemUniqueKey = keys
});


export default class BaseComponent {
    constructor(pageRender, widget, item = {}, pagingSignType = 0) {
        this.signCNItem = item;
        this.pageRender = pageRender;
        this.widget = widget;
        this.field = widget.fieldJSON;
        this.releases = [];
        this.eLabel = null;
        this.eDom = null;
        this.nameSpan = null;
        this.isSetValue = false;
        this.isInner = false;
        this.isMine = false;
        this.pagingSignType = pagingSignType;

        const {annots} = this.signCNItem;
        this.fields = annots;
        this.init();
    }

    showDom () {
        this.eDom.style.opacity = '';
        if (!this.isSetValue && this.nameSpan) {
            this.nameSpan.style.display = 'none'
        }
    }

    hideDom () {
        this.eDom.style.opacity = '0';
        if (!this.isSetValue && this.nameSpan) {
            this.nameSpan.style.display = ''
        }
    }

    init() {
        this.createDom();
        this.bindEvent();
    }

    bindEvent () {
        if (MyItemUniqueKey.includes(this.signCNItem.uniqueKey)) {
            this.isMine = true;
        }
        const setMyItem = (keys) => {
            if (keys.includes(this.signCNItem.uniqueKey)) {
                this.isMine = true;
            }
        }
        eventManager.on(SetMyItemEvent, setMyItem);
        this.releases.push(() => eventManager.off(SetMyItemEvent, setMyItem));
    }

    createDom () {
        const pageRender = this.pageRender;
        const widget = this.widget;
        const ePagePad = pageRender.ePagePad;

        const eLabel = this.eLabel = document.createElement('div');
        const borderWidth = pt2px(widget.borderInfo.width) * pageRender.scale;
        eLabel.classList.add('fx-field-label');
        this.changeRect(borderWidth);

        const div = document.createElement('div');
        div.style.width = '100%';
        div.style.height = '100%';
        eLabel.appendChild(div);

        if (this.eDom) {
            div.appendChild(this.eDom);
            this.hideDom();
        }

        const {color, userName, company, companyId} = this.signCNItem;
        this.isInner = !!companyId;
        if (color) {
            div.style.backgroundColor = color + '22'; // 透明度
            div.style.outline = color + ' 1px solid';


            if (this.pagingSignType !== 2) {
                const span = document.createElement('span');
                span.classList.add('sign-username');
                span.style.backgroundColor = color;
                if (company) {
                    span.innerText = '[企]' + userName;
                } else {
                    span.innerText = userName;
                }
                if (this.pagingSignType === 1) {
                    span.style.writingMode = 'vertical-rl';
                }
                this.nameSpan = span;
                eLabel.appendChild(span);
            }
        }

        ePagePad.style.overflow = 'hidden';
        ePagePad.appendChild(eLabel);
        this.addEle2Window();
    }

    addEle2Window() {
        if (!window.signCNDoms) {
            window.signCNDoms = [];
        }
        const objNum = this.widget.objNum;
        window.signCNDoms[objNum] = this.eDom ? this.eDom : this.eLabel;
    }

    changeRect(borderWidth) {
        const labelStyle = this.getRect();
        const eLabel = this.eLabel;
        eLabel.style.cssText = `
            position: absolute;
            left: ${labelStyle.left}px;
            top: ${labelStyle.top}px;
            width: ${labelStyle.width}px;
            height: ${labelStyle.height}px;
        `;
        if (borderWidth) {
            eLabel.style.padding = `${borderWidth}px`;
        }
    }

    getRect() {
        const pageRender = this.pageRender;
        const widget = this.widget;
        const pdf2device = pageRender.transformPDFToDevice();
        // const pdf2device2 = pageRender.transformPDFToDevice();
        // pdf2device2.a = 1;
        // pdf2device2.b = 0;
        // pdf2device2.c = 0;
        // pdf2device2.d = 1;
        // pdf2device2.e = -29.7;
        // pdf2device2.f = -29.7;

        const rect = widget.rect;
        // const [left2, top2, right2, bottom2] = pdf2device2.transformRect(rect.left, rect.top, rect.right, rect.bottom);
        const [left, top, right, bottom] = pdf2device.transformRect(rect.left, rect.top, rect.right, rect.bottom);
        return  {
            left: left,
            top: top,
            width: right - left,
            height: bottom - top,
        };
    }

    valueChanged (value, ignoreCalcOrderFields = false, doRedrawViewer = true) {
        const field = this.field;
        this.setField(field.name, {value}).then(() => {
            field.value = value;
            if (value) {
                this.isSetValue = true
                this.nameSpan.style.display = 'none'
            } else {
                this.isSetValue = false
                this.nameSpan.style.display = ''
            }
            this.redrawViewerByActionResult({});
        });
    }

    setField (fieldName, option) {
        const pdfDocRender = this.pageRender.pdfDocRender;
        return pdfDocRender.pdfViewer.workerFetch.request('doc/field/set', {
            doc: pdfDocRender.docId,
            field: fieldName,
            set: option
        });
    }

    redrawViewerByActionResult ({isSign, pagingIndexs, annotObjNum, sealInfo}) {
        const pageRender = this.pageRender;
        const pdfDocRender = pageRender.pdfDocRender;
        pdfDocRender.redraw({page: pageRender.index }).then(() => {
            // 改变annot的选中状态
            const fields = this.fields;
            const value = this.field.value;
            const objNum = this.widget.objNum;
            fields.some(annot => {
                if (annot.annotObjNum === objNum) {
                    if (isSign) {
                        annot.fieldStatus = 1;
                    } else {
                        if (value) {
                            annot.fieldStatus = 2;
                        } else {
                            annot.fieldStatus = 0;
                        }
                    }

                    return true;
                }
                return false;
            });
            eventManager.emit(PdfRefrashEvent, {index: pageRender.index,annotObjNum, sealInfo});
            // if (pagingIndexs && pagingIndexs.length > 0) {
            //     pagingIndexs.forEach(index => {
            //         if (index !== pageRender.index) {
            //             pdfDocRender.redraw({page: index })
            //         }
            //     })
            // }
        });
    }

    destroy () {
        this.releases.forEach(fn => fn());
        this.releases = [];
    }
}
