const REVOCATION_NONE = 0;
const REVOCATION_OK = 1;
const REVOCATION_REVOCATION = 2;
const REVOCATION_CHAIN_REVOCATION = 3;
const e_StateVerifyValid = 0x00000004;
const e_StateVerifyInvalid = 0x00000008;
const e_StateVerifyErrorData = 0x00000010;
const e_StateVerifyErrorByteRange = 0x00000040;
const e_StateVerifyChange = 0x00000080;
const e_SIG_VERIFY_ISSUER_UNCHECK = 0x00100000;
const e_SIG_VERIFY_ISSUER_VALID = 0x00010000;
const e_SIG_VERIFY_ISSUER_INVALID = 0x40000000;
const e_SIG_VERIFY_ISSUER_UNKNOW = 0x00020000;
const e_SIG_VERIFY_ISSUER_EXPIRE = 0x00080000;
const e_SIG_TIMESTAMP_TIMEBEFORE = 0x20000000;
const e_StateVerifyNoSupportWay = 0x00000020;


const FR_SIG_VERIFY_INCREDIBLE = 0x00000100
const FR_SIG_VERIFY_ISSUER_CURRENT = 0x00020000
const FR_SIG_VERIFY_ISSUER_NOVERIFY = 0x00000800
const FR_SIG_VERIFY_VALID =	0x00000004
const FR_SIG_VERIFY_ISSUER_UNCHECK = 0x00010000
const FR_SIG_VERIFY_ISSUER_VALID =0x00001000
const FR_SIG_VERIFY_INVALID = 0x00000008
const FR_SIG_TIMESTAMP_TIMEBEFORE = 0x02000000
const FR_SIG_VERIFY_ERRORBYTERANGE = 0x00000040
const FR_SIG_VERIFY_ERRORDATA =	0x00000010
const FR_SIG_VERIFY_CHANGE = 0x00000080
const FR_SIG_VERIFY_ISSUER_EXPIRE =	0x00008000

function getVerifyText(verifyInfo) {
    let { checkedReturn, revocationType } = verifyInfo;

    let isValidForAuditTrail = false;
    let isInValidForAuditTrail = false;
    let isUnKnowEffectForAuditTrail = false;
    let isErrorForAuditTrail = false;

    let isModifiedForAuditTrail = false;
    let isModifiedWithAllowedForAuditTrail = false;


    if (checkedReturn & e_StateVerifyValid) {
        if (!(checkedReturn & e_SIG_VERIFY_ISSUER_UNCHECK)) {
            if ((checkedReturn & e_SIG_VERIFY_ISSUER_VALID)
                && ( revocationType === REVOCATION_OK || revocationType === REVOCATION_NONE)) {
                isValidForAuditTrail = true
            } else {
                if (revocationType === REVOCATION_REVOCATION
                    || revocationType === REVOCATION_CHAIN_REVOCATION
                    || checkedReturn & e_SIG_VERIFY_ISSUER_INVALID) {

                    isInValidForAuditTrail = true;

                } else {
                    isUnKnowEffectForAuditTrail = true;
                }
            }
        } else {
            isInValidForAuditTrail = true;
        }

        isModifiedForAuditTrail = false;
    } else if (checkedReturn & e_StateVerifyInvalid) {

        isInValidForAuditTrail = true;

        if (!(revocationType === REVOCATION_REVOCATION || revocationType === REVOCATION_CHAIN_REVOCATION)
            && !(checkedReturn & e_SIG_TIMESTAMP_TIMEBEFORE)) {//如果是吊销了
            // wsStatus = 'IDS_SG_VS_INVALID';

            isModifiedForAuditTrail = true;

        }
        if (checkedReturn & e_SIG_TIMESTAMP_TIMEBEFORE) {

            isModifiedForAuditTrail = false;

        }
    } else if ((checkedReturn & e_StateVerifyErrorByteRange) || (checkedReturn & e_StateVerifyErrorData)) {

        isErrorForAuditTrail = true;
    } else if (checkedReturn & e_StateVerifyChange) {
        if (!(checkedReturn & e_SIG_VERIFY_ISSUER_UNCHECK)) {
            if (checkedReturn & e_SIG_VERIFY_ISSUER_VALID) {

                isValidForAuditTrail = true;

            } else {
                isUnKnowEffectForAuditTrail = true;
            }
        } else {
            isUnKnowEffectForAuditTrail = true;
        }

        isModifiedWithAllowedForAuditTrail = true;
    } else if (checkedReturn & e_StateVerifyNoSupportWay) {

        isUnKnowEffectForAuditTrail = true;

    } else {

        isUnKnowEffectForAuditTrail = true

        isModifiedForAuditTrail = false;

    }

    return {
        isUnKnowEffectForAuditTrail,
        isValidForAuditTrail,
        isInValidForAuditTrail,
        isErrorForAuditTrail,
        isModifiedForAuditTrail,
        isModifiedWithAllowedForAuditTrail
    }

}

function getIssuerText(verifyInfo) {
    let { checkedReturn, revocationType } = verifyInfo;

    let isIssuerValidForAuditTrail = false;
    let isIssuerInvalidForAuditTrail = false;
    let isIssuerUnKnowValidForAuditTrail = false;
    let isIssuerValidAndRevokeForAuditTrail = false;
    let isIssuerNotVerifiedForAuditTrail = false;

    if (!(checkedReturn & e_SIG_VERIFY_ISSUER_UNCHECK)) {
        if (checkedReturn & e_SIG_VERIFY_ISSUER_UNKNOW) {

            isIssuerUnKnowValidForAuditTrail = true;

        } else if (checkedReturn & e_SIG_VERIFY_ISSUER_INVALID) {

            isIssuerInvalidForAuditTrail = true

        } else if (revocationType === REVOCATION_REVOCATION || revocationType === REVOCATION_CHAIN_REVOCATION) {

            isIssuerInvalidForAuditTrail = true

        } else if (checkedReturn & e_SIG_VERIFY_ISSUER_VALID){

            isIssuerValidForAuditTrail = true;
            if (revocationType !== REVOCATION_OK) {
                isIssuerValidAndRevokeForAuditTrail = true
            }

        } else {

            isIssuerUnKnowValidForAuditTrail = true;

        }
        if ((checkedReturn & e_SIG_VERIFY_ISSUER_EXPIRE) && (verifyInfo.ltvState !== 1)) { //No ltv enabled and issuer expired.

            isIssuerUnKnowValidForAuditTrail = true;

        }
    } else {

        isIssuerNotVerifiedForAuditTrail = true;

    }
    return {
        isIssuerUnKnowValidForAuditTrail,
        isIssuerValidForAuditTrail,
        isIssuerInvalidForAuditTrail,
        isIssuerValidAndRevokeForAuditTrail,
        isIssuerNotVerifiedForAuditTrail
    }
}

export function formatVerifyInfo(verifyInfo) {
    let {
        isUnKnowEffectForAuditTrail, isValidForAuditTrail, isInValidForAuditTrail, isErrorForAuditTrail, isModifiedForAuditTrail, isModifiedWithAllowedForAuditTrail
    } = getVerifyText(verifyInfo);
    let {
        isIssuerValidForAuditTrail,
        isIssuerInvalidForAuditTrail,
        isIssuerUnKnowValidForAuditTrail,
        isIssuerValidAndRevokeForAuditTrail,
        isIssuerNotVerifiedForAuditTrail,
    } = getIssuerText(verifyInfo);

    // signValidStatus: 0 无效; 1 有效; 2: 未知; 3: 发生错误
    let signValidStatus;
    if (isInValidForAuditTrail) {
        signValidStatus = 0;
    } else if (isErrorForAuditTrail) {
        signValidStatus = 3;
    } else if (isUnKnowEffectForAuditTrail) {
        signValidStatus = 2;
    } else if (isValidForAuditTrail) {
        signValidStatus = 1;
    } else {
        signValidStatus = -1
    }

    // fileModifiedStatus:  0 被篡改; 1 被允许的修改; 2: 未被修改
    let fileModifiedStatus;
    if (isModifiedForAuditTrail) {
        fileModifiedStatus = 0;
    } else {
        fileModifiedStatus = 2;
    }
    if (isModifiedWithAllowedForAuditTrail){
        fileModifiedStatus = 1;
    }

    // issuerValidStatus: 0 无效； 1 未验证； 2：未知； 3：有效但无法校验吊销状态； 4：有效
    let issuerValidStatus;
    if (isIssuerInvalidForAuditTrail) {
        issuerValidStatus = 0;
    } else if (isIssuerNotVerifiedForAuditTrail) {
        issuerValidStatus = 1;
    } else if (isIssuerUnKnowValidForAuditTrail) {
        issuerValidStatus = 2;
    } else if (isIssuerValidForAuditTrail) {
        issuerValidStatus = 4;
        if (isIssuerValidAndRevokeForAuditTrail) {
            issuerValidStatus = 3;
        }
    }

    return {
        signValidStatus,
        fileModifiedStatus,
        issuerValidStatus
    }
}

function getVerifyByForPhantom({ dwVerifyState, szSigner, bCertifable }) {

    // signValidStatus: 0 无效; 1 有效; 2: 未知; 3: 发生错误
    let signValidStatus = 1;

    // fileModifiedStatus:  0 被篡改; 1 被允许的修改; 2: 未被修改
    let fileModifiedStatus = 2;

    if (dwVerifyState &  FR_SIG_VERIFY_VALID) {
        if (!(dwVerifyState &FR_SIG_VERIFY_ISSUER_UNCHECK)) {
            if (dwVerifyState & FR_SIG_VERIFY_ISSUER_VALID) {
                if (!!szSigner) {
                    if (!bCertifable) {
                        // strtmp = L"Signature is VALID, signed by %ls.";
                        // 签名有效
                        signValidStatus = 1;
                    } else {
                        // strtmp = L"Document certification is VALID, signed by %ls.";
                        // 签名有效
                        signValidStatus = 1;
                    }
                }
            } else {
                // strChkRes += L"Signature validity is UNKNOWN.";
                // 签名未知
                signValidStatus = 2;
            }
        }
        if (!bCertifable) {
            // szNotMod = L"The document has not been modified since this signature was applied.";
            // 文档未被修改
            fileModifiedStatus = 2;
        } else {
            // szNotMod = L"The document has not been modified since this signature was certified.";
            // 文档未被修改
            fileModifiedStatus = 2;
        }

    } else if (dwVerifyState & FR_SIG_VERIFY_INVALID) {
        // 签名无效
        // strChkRes += L"Signature is INVALID.";
        signValidStatus = 0;
        if (dwVerifyState & FR_SIG_TIMESTAMP_TIMEBEFORE) {
            // szNotMod = L"Signature seems to be signed in future.";
            // 文档未被修改
            fileModifiedStatus = 2;
        } else {
            // szNotMod = L"The document has been altered or corrupted since the Signature was applied.";
            // 文档被篡改
            fileModifiedStatus = 0;
        }
    } else if ((dwVerifyState & FR_SIG_VERIFY_ERRORBYTERANGE) |
        (dwVerifyState & FR_SIG_VERIFY_ERRORDATA)) {
        // strChkRes += L"Error during signature verification.";
        // 签名 验证发生错误
        signValidStatus = 3;
        if (dwVerifyState & FR_SIG_VERIFY_ERRORDATA) {
            // strChkRes += L"There are some errors in the formatting or information contained in the signature";

        } else {
            // strChkRes += L"Unexpected byte range values defining scope of signed data.";
            // strChkRes += L"Details: The signature byte range is invalid";
        }

    } else if (dwVerifyState & FR_SIG_VERIFY_CHANGE) {
        if (!(dwVerifyState & FR_SIG_VERIFY_ISSUER_UNCHECK)) {
            if (dwVerifyState & FR_SIG_VERIFY_ISSUER_VALID) {
                if (!!szSigner) {
                    if (!bCertifable) {
                        // szValid = L"Signature is VALID, signed by %ls.";
                        // 签名 有效
                        signValidStatus = 1;
                    } else {
                        // szValid = L"Document certification is VALID, signed by %ls.";
                        // 签名 有效
                        signValidStatus = 1;
                    }
                }
            } else {
                // strChkRes += L"Signature validity is UNKNOWN.";
                // 签名 未知
                signValidStatus = 2;
            }
        }
        if (bCertifable) {
            // szNotMod = L"This document has been modified since being certified, but these modifications are allowed and do not invalidate the signature.";
            // 签名 被允许的修改
            fileModifiedStatus = 1;
        } else {
            // szNotMod = L"The revision of the document that was covered by this signature has not been altered; however, there have been subsequent changes to the document.";
            // 签名 被允许的修改
            fileModifiedStatus = 1;
        }
    } else if (dwVerifyState & FR_SIG_VERIFY_INCREDIBLE) {
        // strChkRes += L"There is a leak in this digital signature document, please contact the document provider.";
        // 签名 无效
        signValidStatus = 0;
    }
    return {
        signValidStatus,
        fileModifiedStatus
    }
}

function getIssuerTextForPhantom({ dwVerifyState, bCertifable }) {

    // issuerValidStatus: 0 无效； 1 未验证； 2：未知； 3：有效但无法校验吊销状态； 4：有效
    let issuerValidStatus = 4;

    if (!(dwVerifyState & FR_SIG_VERIFY_ISSUER_UNCHECK)) {
        if (dwVerifyState & FR_SIG_VERIFY_ISSUER_CURRENT) {
            if (!bCertifable) {
                // szSignerValid = L"Signed by the current user.";
            } else {
                // szSignerValid = L"Certified by the current user.";
            }
        } else if (dwVerifyState & FR_SIG_VERIFY_ISSUER_VALID) {
            // std::wstring szSignerValid = L"The signer&apos;s identity is valid.";
            // 签名人 有效
            issuerValidStatus = 4;
        } else {
            // 签名人 未知
            issuerValidStatus = 2;
            if (!(dwVerifyState & FR_SIG_VERIFY_ISSUER_EXPIRE)) {
                if (dwVerifyState & FR_SIG_VERIFY_ISSUER_NOVERIFY) {
                    // szSignerValid = L"The signer&apos;s identity is unknown because of errors during validation.";
                } else {
                    // szSignerValid = L"The signer&apos;s identity is unknown because it has not been included in your list of trusted identities and none of its parent certificates are trusted identities.";
                }
            } else {
                // szSignerValid = L"The signer&apos;s identity is unknown.";
                // 签名人 未知
            }
        }
        if (dwVerifyState & FR_SIG_VERIFY_ISSUER_EXPIRE) {
            // std::wstring szSignerValid = L"The certificate has exceeded the time of validity.";
            // 签名人 未知
            issuerValidStatus = 2;
        }
    } else {
        // strChkRes += L"The signer&apos;s identity has not yet been verified.";
        // 签名人 未验证
        issuerValidStatus = 1;
    }
    return { issuerValidStatus }
}

export function formatVerifySigForPhantom({ dwVerifyState, szSigner, bCertifable }) {
    const { signValidStatus, fileModifiedStatus } = getVerifyByForPhantom({ dwVerifyState, szSigner, bCertifable });
    const { issuerValidStatus } = getIssuerTextForPhantom({ dwVerifyState, szSigner, bCertifable });
    return { signValidStatus, fileModifiedStatus, issuerValidStatus }
}