import styles from "./Agree.module.less";
import icon from "../../../../assets/images/esignbao.png";
import {Button} from "antd";
import {useEffect, useState} from "react";
import {getAuthUrl} from "../../../../request/esign";
import {resizeDialog} from "../../Model";

export default function Agree(props) {
    const [btnLoading, setBtnLading] = useState(false);
    const authParams = props.authParams;
    const isOrgAuth = authParams?.orgName;
    const toEsignbao = () =>{
        setBtnLading(true);
        getAuthUrl(authParams).then(url => {
            if (url) {
                window.location.href = url;
            }
        });
    }

    useEffect(() => {
        resizeDialog({ width: 840, height: 500 });
    }, [])

    useEffect(() => {
        if (authParams.orgName) {
            getAuthUrl(authParams).then(url => {
                if (url) {
                    window.location.href = url;
                }
            });
        }
    }, [authParams])

    return (
        <div className={styles.main}>
            {!isOrgAuth &&
                <>
                    <div className={styles.head}>
                        <div className={styles.title}>
                            请您知悉，本电子签章服务的部分功能由第三方机构e签宝为您提供。
                        </div>
                        <img src={icon} alt={"icon"} width="303" height="58" />
                    </div>
                    <div className={styles.box}>
                        为向您提供电子签章服务，您需授权使用您的福昕账号登录e签宝平台，或将您的福昕账号与e签宝账号绑定。
                        <br/><br/>
                        为保障您的文档内容的私密性，避免您或他人的个人隐私、商业秘密等内容泄露，福昕会将您的文档进行哈希加密，并由e签宝基于文档哈希值为您提供电子签章服务。福昕和e签宝平台不会获取、保存您的签署文档原文。因此，请您妥善保存签署文档。
                        <br/><br/>
                        在您使用本电子签章服务过程中，e签宝对您个人信息的收集、使用将遵循<a href="https://www.esign.cn/gw/fwxy/?current=1" rel="noreferrer" target='_blank'>e签宝隐私政策</a>，福昕对e签宝收集、使用您的个人信息不承担责任。请您在使用本电子签章服务前认真阅读e签宝隐私政策以了解e签宝如何收集、使用您的个人信息。
                        {/*<br/><br/>*/}
                        {/*本电子签章服务目前处于测试阶段。测试版可能存在缺陷或不完善之处。因此，您同意您对测试版的使用仅用于测试和评估的目的，不得将测试版用于任何商业、交易、管理、行政、法律或其他正式的场合，或用于创设、变更、消灭法律权利、法律义务或法律身份。否则，您需要自行承担由此给您本人、机构和其他相关方造成的一切风险和损失。*/}
                        {/*<br/><br/>*/}
                        {/*对测试版的使用适用<a href="src/pages/cert/components/Agree" rel="noreferrer" target='_blank'>《福昕软件测试版附加协议》</a> （ “附加协议”），且附加协议与《福昕软件服务协议》、《福昕软件隐私策略》的内容不一致的，以附加协议为准。*/}

                    </div>
                    <Button loading={btnLoading} onClick={()=>toEsignbao()} shape={"round"} type={"primary"} style={{width: '80%'}}>同意并继续</Button>
                </>
            }
            {
                isOrgAuth &&
                <div className={styles.loadingContainer}>
                    <div className={styles.loading}>
                        <div className={styles.loadingBg}>
                            <div className={styles.loadingIcon}></div>
                        </div>
                        <div className={styles.loadingText}>正在获取企业认证链接…</div>
                    </div>
                </div>
            }
        </div>
    )
}
