import React, { Component } from 'react';
import './index.less';
import {
  Modal,
  Button, Spin, message, Tooltip
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
// import applyingIcon from './assets/applying.svg'
// import successIcon from './assets/success.svg'
// import resendIcon from './assets/resend.svg'
// import failIcon from './assets/fail.svg'
import {getStore} from "../../../store";
import {applyOutStampUsePermission, resendApplyOutStampUsePermission} from "../../../request/esign";
import {shouldCallPhantomApi} from "../config";
import {closeESignCNDialogAPI} from "../../../request/phantom";
import {getSealsInPlugin} from "../service";

export default class ESignCNOutStampApplyDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      promiseStatus: null,
      stampList: [],
      loading: false,
      orgList: null,
      fetchStampListError: false,
      outSignUserExpired: false,
      applyLoading: false,
    };
    this.onCancel = this.onCancel.bind(this);
    this.showDialog = this.showDialog.bind(this);

  }
  showDialog(noCheckSubscription) {
    // this.fetchStampList(noCheckSubscription)
  }

  onCancel() {
    if (shouldCallPhantomApi()) {
      closeESignCNDialogAPI()
    } else {
      this.setState({
        visible: false
      })
    }
  }


  async handleApplyStamp(sealInfo) { // 申请企业印章使用权限
    const PROCESSING_STATUS = "1"
    try {
      const data = {
        orgId: getStore('userInfo').orgId,
        accountId: getStore('userInfo').accountId,
        sealId: sealInfo.sealId,
      }
      if (sealInfo.authStatus + "" === PROCESSING_STATUS) {

        Modal.confirm({
          title: 'PDF编辑器',
          icon: '',
          content: <span style={{display: 'flex', alignItems: 'center'}}><InfoCircleOutlined style={{fontSize: '22px', color: '#1890ff'}}/><span style={{flex: 1, marginLeft: '8px'}}>将向管理员再次发送申请通知，是否确认？</span></span>,
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            this.handleNotify('催办提交中...')
            // const httpErrorCallback = () => {
            //   this.handleNotify('eSignChinaUnite.applyStamp.networkError', resendIcon, 298)
            // }
            this.setState({
              applyLoading: true
            })
            resendApplyOutStampUsePermission(data).then(res => {
              this.handleNotify('催办提交成功！')
              this.fetchStampList();
            }).catch(() => {
              this.handleNotify('催办提交失败，请重试！')
            }).finally(() => {
              this.setState({
                applyLoading: false
              })
            })

          },
          onCancel: () => {}
        });
      } else {
        data.sealName = sealInfo.alias;
        data.applicantName = getStore('esignPerson').name;
        Modal.confirm({
          title: 'PDF编辑器',
          icon: <InfoCircleOutlined />,
          content: '将向管理员申请所选印章的使用权限，是否确认提交申请？',
          okText: '确认',
          cancelText: '取消',
          onOk: () => {
            this.handleNotify('申请提交中...')
            // const httpErrorCallback = () => {
            //   this.handleNotify('eSignChinaUnite.applyStamp.networkError', applyingIcon, 298)
            // }
            this.setState({
              applyLoading: true
            })
            applyOutStampUsePermission(data).then(() => {
              this.handleNotify('申请提交成功！')
              this.fetchStampList();
            }).catch(() => {
              this.handleNotify('申请提交失败，请重试！')
            }).finally(() => {
              this.setState({
                applyLoading: false
              })
            })
          },
          onCancel: () => {}
        });
      }
    } catch (e) {
      console.error(e);
      if (sealInfo.authStatus + "" === PROCESSING_STATUS) {
        this.handleNotify('催办提交失败，请重试！')
      } else {
        this.handleNotify('申请提交失败，请重试！')
      }
    }
  }

  handleNotify(msg) {
    message.info({ key: "ESIGN_APPLY_STAMP_MSG", content: msg })
  }

  fixCss(type = 'add') {
    const notifyEle = document.querySelector('.ant-notification')
    if (type === 'add') {
      notifyEle && notifyEle.classList.add("fix_css")
    } else {
      notifyEle && notifyEle.classList.remove('fix_css')
    }
  }

  async fetchStampList() {
    try {
      this.setState({
        loading: true
      })
      await getSealsInPlugin({ company: getStore('userInfo').enterpriseName, companyId: getStore('userInfo').enterpriseId, silent: true})
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  componentDidMount() {
    if (window.location.pathname === '/stamp-apply') {
      document.body.setAttribute('name', 'stamp-apply-container')
    }
    // store.appStore.addAppEventListener(APP_CONSTANTS.EVENT_UPDATE_OUT_STAMPS, this.listenOutStampUpdateEvent)
  }

  componentWillUnmount() {
    this.fixCss('remove')
    // store.appStore.removeAppEventListener(APP_CONSTANTS.EVENT_UPDATE_OUT_STAMPS);
    this.setState({
      promiseStatus: null,
      stampList: [],
      loading: false
    })
  }
  render() {
    // const me = this;
    const {
      visible,
      loading,
      fetchStampListError,
      applyLoading
    } = this.state;
    const stampList = getStore('plugin').outOrgSeals
    const orgStampList = stampList.filter(stamp => !stamp.usePermission)
    const buttons = [
      <Button key="cancel" onClick={this.onCancel}>关闭</Button>
    ]


    const stampListEle = orgStampList.map(item => {
      let stampTag;
      if (item.authStatus + "" === "1") {
        stampTag = "审批中"
      }
      return (
        <div key={item.id} className="sign-cn-stamp-apply__stamp-container org-stamp" style={{backgroundImage: `url(${item.base64})`}}>
          {stampTag && <div className='stamp-tag'>{stampTag}</div>}
          {item.alias &&
              (<Tooltip title={item.alias} placement='bottom' getPopupContainer={triggerNode => triggerNode.parentNode} overlayInnerStyle={{color: "#000"}} color="#FFF">
                <div className='stamp-alias' tooltip={item.alias}>{item.alias}</div>
              </Tooltip>)}
          <div className='no-permission_wraper'>
            <Button key="applyBtn" disabled={applyLoading} onClick={this.handleApplyStamp.bind(this, item)}>{item.authStatus + "" === "1" ? "催办" : "申请使用权限"}</Button>
          </div>
        </div>
      )
    })

    return (
      visible &&
      <Modal
        title="申请企业章权限"
        visible={visible}
        className="sign-cn-stamp-apply-dialog"
        width={600}
        centered
        footer={buttons}
        onCancel={this.onCancel}>
        <section style={{height: '383px'}}>
          <div className="stamp-apply__header">
            <div className="stamp-apply__header-left">
              <span>请选择需要申请使用权限的印章，并提交申请。</span>
            </div>
          </div>
          <section className="stamp-apply-container">
            { loading && <Spin className="spin-loading" />}
            { !loading && !fetchStampListError && stampList && stampList.length > 0 && stampListEle}
            { !loading && !fetchStampListError && (!stampList || stampList.length < 1) && <div className='stamp-list-msg'>该账号暂无印章</div>}
            { !loading && fetchStampListError && <div className='stamp-list-msg'>获取签章失败<div className='admin-btn btn-color btn-hover'></div></div> }
          </section>
        </section>
      </Modal>
    )
  }
}

