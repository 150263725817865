import styles from "../phantom.module.less";
import React, {Fragment} from "react";
import {Button, Skeleton} from "antd";
import {ExclamationCircleFilled} from "@ant-design/icons";

export default function PhantomViewer(props) {
    const {type, email, mobile} = props.querys;
    const {isLoading, tips, isError, codes, mobileFn, sendMsg, certFn, addOrgFn} = props;

    let title = '完善签署信息';
    let summary = `亲爱的用户，您还需要补充如下信息。`;
    const summary2 = type === 1 ? '' : `若您非当前流程签署者可忽略此提醒，或联系发起人。`;
    let btns = [];

    const closeFn = (relogin = false) => {
        sendMsg({relogin, type: 'close'});
    }

    if (codes.length > 0) {
        codes.forEach(code => {
            let img, text, handle;
            const seller = `<a href="https://www.foxitsoftware.cn/esign#sales" rel="noreferrer" target='_blank'>联系销售</a>`;
            switch (code) {
                case -1:
                    title = `企业签署服务未订阅或已过期，请${seller}开通`;
                    summary = '若您首次创建组织，可能存在网络延迟，请五分钟后重试';
                    img = 'error';
                    handle = closeFn;
                    break;
                case -2:
                    title = `您暂无签署授权，请联系您的企业管理员分配`;
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                case -15:
                    title = `当前企业授权过期，请联系企业管理员重新授权`;
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                case -4:
                case -11:
                case -12: {
                    const name = code === -4 ? '姓名': '企业名称';
                    title = `账户${name}与当前签署要求不符`;
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                }
                case -16:
                    title = `当前系统存在同名企业名称，请${seller}处理`;
                    summary = summary2;
                    img = 'error';
                    handle = closeFn;
                    break;
                case -6:
                case -8: {
                    const name = code === -6 ? '邮箱': '手机号';
                    const val = code === -6 ? email : mobile;
                    title = `当前账户${name}与签署要求不符`;
                    summary = `请您更换${name}为（${val}）的帐户重新登录，<br/>${summary2}`;
                    img = 'relogin';
                    text = '重新登录';
                    handle = () => closeFn(true);
                    break;
                }
                case -10:
                    title = `当前签署要求以企业身份登录`;
                    summary = `请您更换企业帐户重新登录，<br/>${summary2}`;
                    img = 'relogin';
                    text = '重新登录';
                    handle = () => closeFn(true);
                    break;
                case -9:
                    img = 'o-auth';
                    text = '企业认证/授权';
                    handle = () => certFn(2);
                    break;
                case -5:
                    img = 'email';
                    text = '完善邮箱';
                    handle = mobileFn;
                    break;
                case -7:
                    img = 'mobile';
                    text = '完善手机号';
                    handle = mobileFn;
                    break;
                case -13:
                    img = 'o-auth';
                    text = '加入企业组织';
                    handle = addOrgFn;
                    break;
                case -14:
                    img = 'o-auth';
                    text = '创建企业组织';
                    handle = () => certFn(3);
                    break;
                case -3:
                    img = 'p-auth';
                    text = '个人认证';
                    handle = () => certFn(1);
                    break;
                default:
                    break;
            }
            if (img) {
                btns.push({img, text, handle});
            }
        });
    }

    // PhantomViewer独有逻辑
    if(!isLoading && !isError) {
        if (codes.length === 0 || codes.every(code => code > -1)) {
            title = '校验通过';
            summary = `亲爱的用户，您的身份校验通过。`;
        }
    }

    return (
        <>
            {isLoading && <div className={styles.loadTxt}>
                正在进行签署身份校验<p className={styles.tips}>{tips}</p>
            </div>}
            {isError && !isLoading && <div className={styles.errorTxt}>
                <ExclamationCircleFilled /> &emsp;抱歉！身份校验发生错误，请关闭后重试</div>}
            {!isError && <Skeleton active loading={isLoading}  className={styles.skeleton}>
                <div className={styles.container}>
                    <h1 className={styles.title} dangerouslySetInnerHTML={{__html: title}}></h1>
                    <div className={styles.summary} >{summary && <i className={styles.hint}></i>}
                        <span dangerouslySetInnerHTML={{__html: summary}}></span>
                    </div>
                    <div className={styles.btns}>
                        {btns.map((btn, index) => {
                            return (
                                <Fragment key={index}>
                                    <div  className={index !== 0 ? styles.btn + ' ' + styles.disabled : styles.btn}
                                          onClick={() => index === 0 && btn.handle()}
                                    >
                                        <i className={styles['icon'] + ' ' + styles[btn.img]}></i>
                                        <p className={styles.text}>{btn.text}</p>
                                    </div>
                                    {
                                        index !== btns.length - 1 && <i className={styles.disabled + ' ' + styles.arrow}></i>
                                    }
                                </Fragment>
                            )
                        })}
                    </div>
                </div>
                <div className={styles.footer}>
                    <Button key="back" onClick={() => closeFn()} style={{width: '90px'}}>关闭</Button>
                </div>
            </Skeleton>}
        </>
    )
}
