import React, {useCallback, useEffect, useRef, useState} from "react";
import styles from "./Main.module.less";
import Pagination from "./components/Pagination";
import {initPdfSdk} from "../../initPdfViewer";
import eventManager, {
    PdfRefrashEvent, SetDeadlined,
    SetMyItemEvent,
    SetPdfInfoEvent,
    SetSealsEvent,
    SetTempStampEvent, ShowDateManageEvent,
    ShowStampManageEvent,
    ZoomToEvent
} from "../../utils/EventManager";
import ThumbList from "./components/ThumbList";
import Header from "../components/Header";
import {errorMessage, hideMessage, loadingMessage} from "../components/Message";
import {
    addEvidenceForSign,
    downloadPdf, formatSeals,
    getInnerSealList,
    getOrgSeals,
    getPersonSeals,
    innerSignFileDigest,
    signFileDigest,
    uploadSignedFileSlice
} from "../../request/esign";
import {getStore, setStore} from "../../store";
// import StampManage from "./components/StampManage";
import cuid from "cuid";
import {base64ToArrayBuffer, checkInerAuth, getMyJoinItem, getRect, getRect4Pdf} from "../../utils";
import {Button, Modal} from "antd";
import iconOk from "../../assets/images/auth-ok.png";
import {ExclamationCircleOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {getTicket} from "../../request/user";
import dayjs from "dayjs";
import {
    ERROR_ESIGN_CANCELLED,
    ERROR_ESIGN_DELETED,
    ERROR_ESIGN_NUM_LIMIT,
    ERROR_ESIGN_RISK_CONTROL,
    ERROR_ESIGN_STOCK_CONTROL
} from "../../request/error_container";
import IframeModal from "../components/IframeModal";
import {AUTH_PLUGIN_URI} from "../../App";
import {WILLAUTH_PLUGIN_URI} from "../WillAuthPlugin";
import {SEAL_PLUGIN_URI} from "../SealPlugin";
import DateFieldModal from "./components/DateFieldModal";
import RightTabs from "./components/RightTabs";
import {getPdfInfo} from "../../init";

const LeftWidth = 240;
const RightWidth = 300;
const PdfViewerTop = 56; // pdf dom距离顶部距离

const DateFields = []; // 日期域
const SealInfos = {}; // 印章信息
let SealFields = {}; // 自加印章域

export default function Main() {
    const viewRef = useRef(null);
    const pdfDocRenderRef = useRef(null);
    const [pdfInfo, setPdfInfo] = useState({});
    const [pageIndex, setPageIndex] = useState(-1);
    const [showLeft, setShowLeft] = useState(true);
    const [showRight, setShowRight] = useState(true);
    const [leftCss, setLeftCss] = useState(styles.left);
    const [rightCss, setRightCss] = useState(styles.right);
    const [redraw, setRedraw] = useState(null);
    const [showStampModal, setShowStampModal] = useState(0);
    const [sigDateInfo, setSigDateInfo] = useState({});
    const [showResult, setShowResult] = useState(false);
    // const [seals, setSeals] = useState([]);
    const [zoomTo, setZoomTo] = useState(1.0);
    const [iframeUrl, setIframeUrl] = useState('');
    const [isSealPlugin, setIsSealPlugin] = useState(false); // 是否为印章插件url
    const [hadAuth, setHadAuth] = useState(false);
    const [hasField, setHasField] = useState(true);
    const [flowStatus, setFlowStatus] = useState(0); // 0: 已完成 1: 最后一人 >1: 进行中
    const [sealFields, setSealFields] = useState(false);


    const updateSealInfo = useCallback(data => {
        if (!data) {
            return;
        }
        const {sealInfo, annotObjNum} = data;
        if (annotObjNum) {
            if (sealInfo && sealInfo.sealId) {
                SealInfos[annotObjNum] = sealInfo;
            } else {
                delete SealInfos[annotObjNum];
            }
        }
    }, []);

    const onShowDateModal = useCallback(data => {
        let item = DateFields.find(item => item.objNum === data.objNum);
        if (!item) {
            item = {
                objNum: data.objNum,
                eLabel: data.eLabel,
                pageIndex: data.pageIndex,
                isOrg: data.isOrg,
            }
        }
        setSigDateInfo(item);
    }, []);

    const onHideDateModal = useCallback(field => {
        setSigDateInfo({});
        if (!field || !field.objNum) {
            return;
        }
        const index = DateFields.findIndex(item => item.objNum === field.objNum);
        if (index >= 0) {
            if (field.base64) {
                DateFields[index] = field;
            } else {
                if (DateFields[index].dateDom) {
                    DateFields[index].dateDom.remove();
                }
                DateFields.splice(index, 1);
            }
        } else {
            if (field.base64) {
                DateFields.push(field);
            }
        }
    }, []);

    const onSetFields = useCallback((fields, sealInfo) => {
        updateSealInfo(sealInfo);
        SealFields = fields;
        setSealFields(fields);
    }, [updateSealInfo]);

    const serverInfo = getStore('serverPdfInfo');
    let isCreator = false;
    let stat = 1;
    let deadline = 0;
    let reject_info = {};
    if (serverInfo) {
        const {foxit_user_id, expire_time, status} = serverInfo;
        const {cUserID} = getStore('userInfo');
        isCreator = cUserID === foxit_user_id;
        stat = parseInt(status);
        deadline = parseInt(expire_time);
        reject_info = serverInfo.reject_info;
    }

    useEffect(() => {
        const dom = viewRef.current;

        const onScroll = () => {
            handleScroll(pdfDocRenderRef.current, PdfViewerTop, setPageIndex);
        }

        const onResize = () => {
            handleResize(pdfDocRenderRef.current, dom);
        }

        const onSetPdfInfo = (info) => {
            info = formatPdfInfo(info);
            if (!info) {
                return;
            }
            setFlowStatus(info.receipts.length - info.currOrder);
            setPdfInfo(info);

            if (![1,2].includes(stat)) {
                return;
            }

            // 当前签署人已完成签署，则不再验证签署权限
            // 如果是发起者，则不再验证签署权限
            if (info.myTempIndex < 0) {
                return;
            }

            const url = _getAuthUrl(info, 'init');
            if (url) {
                setIframeUrl(url);
            }
        }

        const onRedraw = (data) => {
            setRedraw(data);
            updateSealInfo(data);
        };

        const onZoomTo = (scale, index) => {
            setZoomTo(scale);
            _changeDateFieldsRect(scale, index, pdfDocRenderRef.current);
        };

        loadingMessage('正在加载文档…');
        const docId = getStore('serverPdfInfo').doc_id;


        const showSealModal = (objNumber, isOrg = true) => {
            const url = _getSealPluginUrl(objNumber > 1, isOrg)
            if (url) {
                setShowStampModal(objNumber);
                setIsSealPlugin(true);
                setIframeUrl(url);
            }
        };
        eventManager.emit(SetDeadlined, ![1,2].includes(stat));
        downloadPdf({docId}).then(data => {
            const blob = new Blob([data], {type: "application/pdf;charset=utf-8"});
            return initPdfSdk({viewer: dom, file: blob});
            // const url = 'http://localhost:3000/testPdf/d.pdf';
            // return initPdfSdk({viewer: dom, url});
        }).then(({workerFetch, pdfViewer, pdfDocRender}) => {
            pdfDocRenderRef.current = pdfDocRender;
            setPageIndex(0);
            pdfDocRender.zoomTo(1.0);

            dom.addEventListener('scroll', onScroll);
            window.addEventListener('resize', onResize);
            eventManager.on(SetPdfInfoEvent, onSetPdfInfo);
            eventManager.on(PdfRefrashEvent, onRedraw);
            eventManager.on(ShowStampManageEvent, showSealModal);
            // eventManager.on(SetSealsEvent, setSeals);
            eventManager.on(ZoomToEvent, onZoomTo);
            eventManager.on(ShowDateManageEvent, onShowDateModal);
        }).catch(e => {
            console.error('pdf init error: ', e);
        }).finally(() => {
            hideMessage();
            if (stat === 1) { // 签署中状态
                const leftCss = `${styles.left} ${styles.hideLeft}`;
                const rightCss = `${styles.right} ${styles.hideRight}`;
                setLeftCss(leftCss);
                setShowLeft(false);
                setRightCss(rightCss);
                setShowRight(false);
            }
        });

        return () => {
            dom.removeEventListener('scroll', onScroll);
            window.removeEventListener('resize', onResize);
            eventManager.off(SetPdfInfoEvent, onSetPdfInfo);
            eventManager.off(PdfRefrashEvent, onRedraw);
            eventManager.off(ShowStampManageEvent, showSealModal);
            // eventManager.off(SetSealsEvent, setSeals);
            eventManager.off(ZoomToEvent, onZoomTo);
            eventManager.off(ShowDateManageEvent, onShowDateModal);
        }
    }, [updateSealInfo, onShowDateModal, stat]);

    useEffect(() => {
        if (pdfInfo.myItem) {
            const has = pdfInfo.myItem.annots.length > 0;
            setHasField(has);
            setShowRight(!has);
        }
    }, [pdfInfo.myItem, setHasField, setShowRight]);

    const headerCheckAuth = () => {
        const url = _getAuthUrl(pdfInfo, 'header');
        if (url) {
            setIframeUrl(url);
        }
    }

    const toCollapsed = (type) => {
        let leftCss = styles.left;
        let rightCss = styles.right;
        if (type === 'left') {
            if (!showLeft) {
                leftCss += ` ${styles.showLeft}`;
            } else {
                leftCss += ` ${styles.hideLeft}`;
            }
            setLeftCss(leftCss);
            setShowLeft(!showLeft);
        } else if (type === 'right') {
            if (!showRight) {
                rightCss += ` ${styles.showRight}`;
            } else {
                rightCss += ` ${styles.hideRight}`;
            }
            setRightCss(rightCss);
            setShowRight(!showRight);
        }
    }

    const onFieldClick = useCallback((annot) => {
        handleFieldClick(pageIndex, pdfDocRenderRef.current, annot);
    }, [pageIndex]);

    const closeStampModal = (data) => {
        if (!data) {
            _getSeals(pdfInfo.myItem).then(res => {
                const nextItem = getMyJoinItem(pdfInfo);
                if (nextItem) {
                    _getSeals(nextItem).then(res => {
                        if (res) {
                            setPdfInfo({...pdfInfo, tips: res})
                        }
                    });
                } else {
                    if (res) {
                        setPdfInfo({...pdfInfo, tips: res})
                    }
                }
            });
        } else {
            if (showStampModal > 1 && data.base64) {
                eventManager.emit(SetTempStampEvent, data.base64, showStampModal);
            }
        }
        setShowStampModal(0);
    };

    const closeWillAuthModal = (willData) => {
        // setShowWillAuthModal(false);
        if (willData && willData.willAuthId) {
            addOutSign(_getFileName(flowStatus), willData, pdfInfo.myItem, pdfDocRenderRef.current).then(({blob, firstPageRender}) => {
                if (blob) {
                    const nextItem = getMyJoinItem(pdfInfo);
                    if (nextItem) {
                        const dom = viewRef.current;
                        initPdfSdk({viewer: dom, file: blob}).then(({pdfDocRender}) => {
                            pdfDocRenderRef.current = pdfDocRender;
                            getPdfInfo().then(() => {
                                addOutSign(_getFileName(flowStatus-1), willData, nextItem, pdfDocRender, firstPageRender).then(({blob}) => {
                                    if (blob) {
                                        setShowResult(true);
                                    }
                                });
                            });
                        });
                        return;
                    }
                    setShowResult(true);
                }
            });
        }
    };

    const onSubmit = () => {
        if (!pdfInfo.myItem) {
            return;
        }

        const serverPdfInfo = getStore('serverPdfInfo');
        if (!serverPdfInfo) {
            return;
        }

        const {ward_type} = serverPdfInfo;
        const wardType = parseInt(ward_type);

        Modal.confirm({
            title: '提交签署',
            icon: <ExclamationCircleOutlined/>,
            content: '提交后将不可再编辑，是否确认提交签署？',
            okText: "确认",
            cancelText: "取消",
            okButtonProps: {shape: 'round'},
            cancelButtonProps: {shape: 'round'},
            onOk() {
                if (wardType === 0) {
                    // test
                    // const willData = {
                    //     willAuthType: 'CODE_SMS',
                    //     willAuthTime: 1701852241000,
                    //     willingnessBizId: 'will_bbc8137af2a34528ba1179b03ac31e96',
                    //     willAuthId: 'b1a4d497cbcf4f3a9bee4699ec03f9ce'
                    // };
                    // addOutSign(_getFileName(flowStatus), willData, pdfInfo.myItem, pdfDocRenderRef.current);
                    // return;

                    // addOutSign(_getFileName(flowStatus), willData, pdfInfo.myItem, pdfDocRenderRef.current).then(blob => {
                    //     if (blob) {
                    //         const nextItem = getMyJoinItem(pdfInfo);
                    //         if (nextItem) {
                    //             addOutSign(_getFileName(flowStatus), willData, nextItem, pdfDocRenderRef.current).then(blob => {
                    //                 if (blob) {
                    //                     setShowResult(true);
                    //                 }
                    //             });
                    //
                    //
                    //             // 需要使用最新的buffer重新渲染获取docRender
                    //             // const dom = viewRef.current;
                    //             // initPdfSdk({viewer: dom, file: blob}).then(({pdfDocRender}) => {
                    //             //     pdfDocRenderRef.current = pdfDocRender;
                    //             //     addOutSign(_getFileName(flowStatus), willData, nextItem, pdfDocRender).then(blob => {
                    //             //         if (blob) {
                    //             //             setShowResult(true);
                    //             //         }
                    //             //     });
                    //             // });
                    //             return;
                    //         }
                    //         setShowResult(true);
                    //     }
                    // });
                    // return;

                    const url = _getWillAuthUrl();
                    if (url) {
                        setIframeUrl(url);
                    }
                } else {
                    addInSign(_getFileName(flowStatus), pdfInfo.myItem, pdfDocRenderRef.current).then(res => {
                        if (res) {
                            setShowResult(true);
                        }
                    });
                }
            },
            onCancel() {
            },
        });
    };

    const closeIframe = (data) => {
        setIframeUrl('')
        const {success, person, company, from, enterpriseId, personSeals, orgSeals, willData, sealPlugin, sealData} = data;
        if (sealPlugin) {
            setIsSealPlugin(false);
            let data;
            if (sealData) {
                data = {base64: sealData};
            }
            closeStampModal(data);
            return;
        }

        if (success) {
            if (willData) {
                closeWillAuthModal(willData);
                return;
            }

            setHadAuth(!!success);
            let info = getStore('userInfo');
            if (person) {
                info = {
                    ...info,
                    trueName: person.name,
                    accountId: person.accountId,
                    orgName: company.name,
                    orgId: company.orgId
                };
                setStore('esignPerson', person);
                setStore('esignOrg', company || {});
            }

            if (enterpriseId) {
                sessionStorage.setItem('enterpriseId', enterpriseId);
                info = {...info, enterpriseId};
            }

            setStore('userInfo', {...info});

            if (pdfInfo && pdfInfo.receipts && pdfInfo.currOrder >= 0) {

                const item = pdfInfo.receipts[pdfInfo.currOrder];
                if (item) {
                    if (item.companyId) {
                        _getSeals(item).then(() => {
                            if (from === 'header') {
                                onFieldClick(item.annots[0]);
                            }
                        });
                    } else {
                        formatSeals(personSeals).then(personSeals => {
                            formatSeals(orgSeals).then(orgSeals => {
                                eventManager.emit(SetSealsEvent, {personSeals, orgSeals});
                                if (from === 'header') {
                                    onFieldClick(item.annots[0]);
                                }
                            });
                        })
                    }


                    pdfInfo.myItem = item;
                    const nextItem = getMyJoinItem(pdfInfo);
                    const keys = [item.uniqueKey];
                    if (nextItem) {
                        keys.push(nextItem.uniqueKey);
                    }
                    eventManager.emit(SetMyItemEvent, keys);
                }
            }
        }
    };

    let resultTips, centerWidth;
    if (!showResult) {
        if (viewRef.current && pdfDocRenderRef.current) {
            setPdfDomWidth(viewRef.current, pdfDocRenderRef.current.pdfViewer.width, showLeft, showRight);
        }
        centerWidth = getCenterWidth(showLeft, showRight);
    } else {
        const info = getStore('serverPdfInfo');
        const {name, creator_name: creator, recipients} = info;


        const first = recipients[0];
        let companyName = first.company.split('-')
        if (Array.isArray(companyName) && companyName.length === 2 && companyName[1] !== 'undefined') {
            companyName = companyName[1]
        } else {
            companyName = ''
        }

        if (flowStatus > 1) {
            resultTips = `您已完成${companyName}${creator}发起的签署"${name}"，系统会在签署流程全部完成后，给您发送通知。`;
        } else {
            resultTips = `${companyName}${creator}发起的签署"${name}"已全部完成。`;
        }
    }

    return !showResult ? (
        <>
            <Header pdfInfo={pdfInfo} serverInfo={serverInfo} redraw={redraw} stat={stat}
                    handleFieldClick={onFieldClick} handleSubmit={onSubmit} sealFields={sealFields}
                    goOnline={goOnline} checkAuth={hadAuth ? undefined : headerCheckAuth}
            />
            <div className={styles.main}>
                <div className={leftCss}>
                    <ThumbList pdfDocRender={pdfDocRenderRef.current} pageIndex={pageIndex} redraw={redraw}/>

                </div>
                <div className={styles.center} style={{width: centerWidth}}>
                    <div className={styles.pdf}>
                        <div className={`${styles.btn} ${styles.btnLeft}`} onClick={() => toCollapsed('left')}>
                            {showLeft ? <LeftOutlined/> : <RightOutlined/>}
                        </div>
                        <div ref={viewRef}></div>
                        <div className={`${styles.btn} ${styles.btnRight}`} onClick={() => toCollapsed('right')}>
                            {!showRight ? <LeftOutlined/> : <RightOutlined/>}
                        </div>
                    </div>
                    <Pagination pdfDocRender={pdfDocRenderRef.current}
                                pageIndex={pageIndex} zoomTo={zoomTo} showLeft={showLeft} showRight={showRight}/>
                </div>
                <div className={rightCss}>
                    <RightTabs hasField={hasField} pdfInfo={pdfInfo} fieldClick={onFieldClick} redraw={redraw}
                               scale={zoomTo} deadline={deadline} stat={stat}
                               isCreator={isCreator} reject_info={reject_info}
                               delDateField={onHideDateModal} showDateModal={onShowDateModal} setFields={onSetFields}/>
                </div>
            </div>
            {sigDateInfo.objNum &&
                <DateFieldModal {...sigDateInfo} scale={zoomTo} close={onHideDateModal}></DateFieldModal>}
            {iframeUrl && <IframeModal url={iframeUrl} closeFn={closeIframe} isSealPlugin={isSealPlugin}/>}
        </>
    ) : (
        <>
            <Header/>
            <div className={styles.result}>
                <img src={iconOk} alt={"icon"} width="355"/>
                <div className={styles.title}>您已完成签署</div>
                <div className={styles.tips}>
                    {resultTips}
                </div>
                <Button onClick={() => window.location.reload()} shape={"round"} type={"primary"}
                        style={{width: '60%'}}>
                    查看文档</Button>
                <Button shape='round' type="default" onClick={goOnline}
                        style={{marginTop: '20px', width: '60%'}}>签署记录</Button>
            </div>
        </>
    )
}


export const goOnline = () => {
    let {phantomOnlineBaseUrl} = getStore("configUrls")
    phantomOnlineBaseUrl += '/?openEsignDoc=1' // online的跳转登录存在变化，此处有时需要带参数，有时又不能带，关联270 271行
    getTicket(phantomOnlineBaseUrl).then(ticket => {
        const userInfo = getStore('userInfo');
        let {enterpriseId} = userInfo;
        enterpriseId = enterpriseId || ''
        //phantomOnlineBaseUrl += `?ticket=${ticket}&enterpriseId=${enterpriseId}&openEsignDoc=1`
        phantomOnlineBaseUrl += `&ticket=${ticket}&enterpriseId=${enterpriseId}`
        // console.log(phantomOnlineBaseUrl)
        window.open(phantomOnlineBaseUrl, '_blank')
    })

}

// 检查是否为当前签署参与者
function formatPdfInfo(info, callback) {
    if (!info || !info.receipts || info.receipts.length < 1) {
        return;
    }
    const {userEmail, userMobile} = getStore('userInfo');
    const {receipts} = info;
    const steps = [];
    let signOrder = 100, currIndex = receipts.length;
    receipts.forEach((item, index) => {
        if (item.finished) {
            const {recipients} = getStore("serverPdfInfo")
            const {item: servItem} = findServItem(item.email, item.phoneNumber, item.company, recipients);
            if (servItem && servItem.status === '4') {
                item.updated = dayjs(parseInt(servItem.update_time) * 1000).format('YYYY.MM.DD HH:mm:ss');
            }
            const isMine = (item.email && item.email.toLowerCase() === userEmail.toLowerCase()) || (item.phoneNumber && item.phoneNumber === userMobile);
            info.myFinished = isMine; // 用于头部是否显示签署记录按钮
        } else {
            if (item.signOrder < signOrder) {
                signOrder = item.signOrder;
                currIndex = index;
            }
        }
        steps.push(item);
    })

    let myIndex = -1;
    if (currIndex !== receipts.length) {
        const enterpriseId = sessionStorage.getItem('enterpriseId') || '';
        steps.some((item, index) => {
            if (item.finished) {
                return false;
            }
            if (item.signOrder !== signOrder) {
                return false;
            }
            const isMine = (item.email && item.email.toLowerCase() === userEmail.toLowerCase()) || (item.phoneNumber && item.phoneNumber === userMobile);
            if (!isMine) {
                return false;
            }
            myIndex = index;
            const companyName = item.company || item.Enterprise || '';
            if (!enterpriseId && !companyName) {
                return true;
            }

            if (enterpriseId && companyName) {
                return true;
            }
            return false;
        });

        if (myIndex >= 0 && myIndex !== currIndex) {
            const item = steps[myIndex];
            steps.splice(myIndex, 1);
            steps.splice(currIndex, 0, item);
        }
    }


    info.receipts = steps;
    info.currOrder = currIndex;
    info.myTempIndex = myIndex;
    info.myItem = null;


    let title = '此文档为';
    if (info.receipts[0].freeRectItem) {
        title += '未指定区域';
    } else {
        title += '指定区域';
    }
    if (info.isNoOrder) {
        title += '无顺序限制';
    } else {
        title += '指定顺序';
    }
    if (!info.receipts[0].companyId) {
        title += '的';
    } else {
        title += '的内部';
    }
    if (currIndex === steps.length) {
        title += '签署流程，<span style="">已全部签署完成</span>。';
    } else {
        title += `签署流程，当前待 <span style="color:#ff8925; font-weight: bold">${steps[currIndex].userName}</span> 签署。`;
    }
    info.tips = title;
    return info;
}


// 查询对应的serverPdfInfo项
export function findServItem(email = '', phone = '', company = '', recipients = []) {
    const servItem = recipients.slice(1).find(it => (it.company === company)
        && ((it.email && it.email.toLowerCase() === email.toLowerCase())
            || (it.phone && it.phone === phone)));
    return {item: servItem, index: parseInt(servItem.order)};
}

// 获取印章列表
function _getSeals(myItem) {
    const {company, companyId} = myItem;
    const {orgId, accountId} = getStore('userInfo');
    loadingMessage('正在载入印章…');
    return _requestSeals(accountId, orgId, company, companyId).then(res => {
        const {seals, msg} = res;
        if (seals) {
            eventManager.emit(SetSealsEvent, !company ? {personSeals: seals} : {orgSeals: seals});
        }
        if (msg) {
            delete res.seals;
            delete res.sealsError;
            return res;
        }
        return null;
    }).finally(() => {
        hideMessage();
    });
}

function _requestSeals(accountId, orgId, company, companyId) {
    const result = {
        msg: null,
        link: null,
        linkTxt: null,
        linkTarget: '_blank',
        seals: [],
        sealsError: false,
    }
    if (companyId) {
        return getInnerSealList().then(seals => {
            if (!seals || seals.length < 1) {
                // result.msg = '提示：无可用的印章，请在[印章管理]功能中添加。';
            } else {
                result.seals = seals;
            }
            return result;
        }).catch(key => {
            // if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
            //     result.msg = '您的对内签署订阅已过期或您所在的组织未采购对内签署服务，请联系福昕销售。';
            //     result.link = 'https://www.foxitsoftware.cn/esign#sales';
            //     result.linkTxt = '联系销售';
            // } else if (ERROR_AC_PERMISSION_FAIL === key) {
            //     result.msg = '提示：您尚未被分配对内签署的授权，请联系IT管理员分配授权后重试。';
            // } else {
            //     result.msg = '提示：获取印章列表错误，请刷新页面重试。';
            // }
            result.sealsError = true;
            return result;
        });
    } else {
        if (orgId && company) {
            return getOrgSeals({orgId, accountId}).then(seals => {
                if (!seals || seals.length < 1) {
                    // result.msg = '提示：无可用的印章，请在[印章管理]功能中添加。';
                } else {
                    result.seals = seals;
                }
                return result;
            }).catch(key => {
                // if (ERROR_EQB_AUTH_EXPIRED === key) {
                //     result.msg = '提示：您的授权过期，请重新完成企业认证/授权。';
                //     result.link = "/certification?orgName=" + company;
                //     result.linkTxt = '点击认证/授权';
                //     result.linkTarget = '_self';
                // }
                // else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                //     result.msg = '您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。';
                //     result.link = 'https://www.foxitsoftware.cn/esign#sales';
                //     result.linkTxt = '联系销售';
                // }
                // else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                //     result.msg = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                // }
                // else if (ERROR_AC_PERMISSION_FAIL === key) {
                //     result.msg = '提示：您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。';
                // } else {
                //     result.msg = '提示：获取印章列表错误，请刷新页面重试。';
                // }
                result.sealsError = true;
                return result;
            })
        } else {
            return getPersonSeals({accountId}).then(seals => {
                if (!seals || seals.length < 1) {
                    //result.msg = '提示：无可用的印章，请在[印章管理]功能中添加。';
                } else {
                    result.seals = seals;
                }
                return result;
            }).catch(key => {
                // if (ERROR_EQB_AUTH_EXPIRED === key) {
                //     result.msg = '提示：您的授权过期，请重新完成个人认证。';
                //     result.link = "/certification";
                //     result.linkTxt = '点击认证';
                //     result.linkTarget = '_self';
                // } else {
                //     result.msg = '提示：获取印章列表错误，请刷新页面重试。';
                // }
                result.sealsError = true;
                return result;
            })
        }
    }
}

// 改变窗口尺寸
let resizeTimer = null;

function handleResize(pdfDocRender, dom) {
    const fn = () => {
        dom = dom.parentNode;
        pdfDocRender.pdfViewer.fitLayout(dom.offsetWidth, dom.offsetHeight);
        pdfDocRender.fitLayout();
        pdfDocRender.redraw();
    };
    if (resizeTimer) clearTimeout(resizeTimer);
    resizeTimer = setTimeout(fn, 200);
}

// pdf页面滑动回调
let scrollTimer = null;

function handleScroll(pdfDocRender, pdfViewerTop, callback) {
    const changePageIndex = () => {
        const pages = pdfDocRender.getVisiblePDFPageRenders();
        if (!pages || pages.length < 1) {
            return;
        }
        pages.sort((a, b) => a.index - b.index);
        let pageIndex = pages[0].index || 0
        if (pages.length > 1) {
            const ePage = pages[0].ePage;
            const rect = ePage.getBoundingClientRect()
            const top = -(rect.top - pdfViewerTop); // pdf viewer 距离顶部
            const height = rect.height;
            if (top / height > 0.8) {
                pageIndex = pages[1].index;
            }
        }
        callback(pageIndex);
    }
    if (scrollTimer) clearTimeout(scrollTimer);
    scrollTimer = setTimeout(changePageIndex, 200);
}

// pdf父容器宽度
function getCenterWidth(showLeft, showRight) {
    let centerWidth = 0;
    if (showLeft) {
        centerWidth += 240;
    }
    if (showRight) {
        centerWidth += 300;
    }
    centerWidth = `calc(100% - ${centerWidth}px)`;
    return centerWidth;
}

// pdf容器宽度
function setPdfDomWidth(dom, width, showLeft, showRight) {
    if (dom && width > 0) {
        if (!showLeft) {
            width += LeftWidth;
        }
        if (!showRight) {
            width += RightWidth;
        }
        dom.style.width = width + 'px';
    }
}

// 域点击
function handleFieldClick(currIndex, pdfDocRender, {annotObjNum, pageIndex}) {
    // pdfDocRender.goToPage(pageIndex).then(() => {
    //     setTimeout(() => { // 页面跳转完成后，dom初始化可能未完成，等待300ms
    //         fn()
    //     }, 300);
    // })
    if (currIndex === pageIndex) {
        fn()
    } else {
        pdfDocRender.goToPage(pageIndex).then(() => {
            setTimeout(() => { // 页面跳转完成后，dom初始化可能未完成，等待300ms
                fn()
            }, 300);
        })
    }


    function fn() {
        const list = window.signCNDoms;
        if (list && list.length > 0) {
            const dom = list[annotObjNum];
            if (dom) {
                dom.focus();
                dom.click();
                return true;
            }
        }
    }
}

// 获取签章使用记录 重复章只记录一次
function _getSealInfo(list) {
    const infoList = [];
    Object.values(list).forEach(item => {
        if (!item) {
            return;
        }
        const res = infoList.some(it => {
            if (it.sealId === item.sealId) {
                return true;
            }
            return false;
        })
        if (!res) {
            infoList.push(item);
        }
    })
    return infoList;
}

// 对外签署
function addOutSign(fileName, willData, myItem, pdfDocRender, firstPageRender) {
    // 请求Pdf接口，判断是否全部填写完成 未完成给出提示，已完成给出确认提交提示
    // 请求api接口，确认当前签署流程order

    // 意愿认证结果
    if (!willData || !myItem || !pdfDocRender) {
        return Promise.reject();
    }

    // 构建两种接口所需要的参数
    const userInfo = getStore('userInfo');
    const serverPdfInfo = getStore('serverPdfInfo');
    const esignPerson = getStore('esignPerson');
    const esignOrg = getStore('esignOrg');
    const {willAuthType, willAuthTime, willingnessBizId, willAuthId} = willData;

    const {company} = myItem;
    const {trueName, userEmail, userMobile, orgName, orgId, accountId, enterpriseId} = userInfo;
    const {process_id: processId, version: oldVersion, recipients} = serverPdfInfo;
    const {flowId: operatorFlowId} = esignPerson;
    const workerFetch = pdfDocRender.pdfViewer.workerFetch;
    const signId = cuid();
    let version = oldVersion;

    let signAccountId, signerName, chapterType, tenantId, authObj;
    let pdfFile, pdfBuffers, pdfSignedDataOffset;

    const {index: recipientOrder} = findServItem(myItem.email, myItem.phoneNumber, company, recipients);
    if (recipientOrder < 0) {
        return Promise.reject();
    }

    if (enterpriseId && company) {
        signerName = orgName;
        signAccountId = orgId;
        authObj = esignOrg;
        chapterType = 2;
        tenantId = enterpriseId;
    } else {
        signerName = trueName;
        signAccountId = accountId;
        authObj = esignPerson;
        chapterType = 1;
        tenantId = '';
    }
    const {authTime, authType, flowId: signerFlowId} = authObj;
    const evidenceParams = {
        signId,
        willAuthId,
        operatorFlowId,
        signerFlowId,
        version,
        signLogId: '',
        oriHash: '',
        signedHash: ''
    }

    const {signInfo,firstPageRender: pageRender} = _createSignInfo(
        {
            signType: 'out',
            authTime,
            authType,
            signerName,

            fileName,
            signAccountId,
            signId,
            userMobile,
            userEmail,
            pdfDocRender,
            myItem,
            firstPageRender
        }, {
            operatorName: trueName,
            willAuthTime,
            willAuthType,
        });

    if (!signInfo) {
        return Promise.reject("签署信息错误");
    }
    firstPageRender = pageRender;

    // 调用pdf接口，获取文档hash
    loadingMessage('正在签署…');
    let blob;
    return workerFetch.request('doc/startSign', {
        doc: pdfDocRender.docId,
        signInfo,
    }).then(res => {
        const {file, hash, signedDataOffset} = res;
        pdfFile = file;
        pdfSignedDataOffset = signedDataOffset;
        evidenceParams.oriHash = hash;
        const sealInfo = _getSealInfo(SealInfos);
        const data = {
            hash,
            willingnessBizId,
            chapterType,
            tenantId,
            signId,
            processId,
            recipientOrder,
            version,

            operatorId: accountId,
            signerId: signAccountId,

            docId: pdfDocRender.docId,
            sealInfo,
        };
        // 调用api签署接口，获取签名hash
        return signFileDigest(data);
    }).then(res => {
        const {signature, signLogId} = res;
        evidenceParams.signLogId = signLogId;
        pdfBuffers = [];
        // 调用pdf接口，保存签名hash
        return workerFetch.request('doc/fillSignedData', {
            doc: pdfDocRender.docId,
            signedDataOffset: pdfSignedDataOffset,
            file: pdfFile,
            signData: base64ToArrayBuffer(signature),
            progress: workerFetch.register(function (buffer) {
                pdfBuffers.push(buffer)
            })
        })
    }).then(res => {
        // 调用pdf接口，获取签名后的文档hash
        const {signedFile} = res;
        return workerFetch.request('doc/generateHashData', {
            doc: pdfDocRender.docId,
            signedFile
        })
    }).then(res => {
        // 调用api存证接口
        const {hash} = res;
        loadingMessage('正在签名存证…');
        evidenceParams.signedHash = hash;
        return addEvidenceForSign(evidenceParams);
    }).then(() => {
        // 调用api上传接口
        loadingMessage('正在上传文档…');
        blob = new Blob(pdfBuffers, {type: "application/pdf;charset=utf-8"});
        const data = {fileName, blob, processId, recipientOrder, version}
        return uploadSignedFileSlice(data)
        // return uploadSignedFile(data);
    }).then(() => {
        // successMessage('恭喜，文档签署成功！')
        hideMessage();
        return {blob, firstPageRender};
    }).catch(e => {
        console.error(e);
        _signError(e);
        return false;
    });
}

// 对内签署
function addInSign(fileName, myItem, pdfDocRender) {
    if (!myItem || !pdfDocRender) {
        return Promise.reject();
    }

    // 构建两种接口所需要的参数
    const userInfo = getStore('userInfo');
    const serverPdfInfo = getStore('serverPdfInfo');
    const {fullname, userEmail, userMobile, enterpriseId} = userInfo;
    const {process_id: processId, version, recipients} = serverPdfInfo;
    const workerFetch = pdfDocRender.pdfViewer.workerFetch;
    const signId = cuid();

    let pdfFile, pdfBuffers, pdfSignedDataOffset;

    const {index: recipientOrder} = findServItem(myItem.email, myItem.phoneNumber, '', recipients);;
    if (recipientOrder < 0) {
        return Promise.reject();
    }

    loadingMessage('正在验证权限…');
    return checkInerAuth(enterpriseId).then(res => {
        const {
            state,
            samlResponse,
            jwtToken,
            alert,
            userSubscription
        } = res;
        if (state > 0) {
            return sign(samlResponse, jwtToken, userSubscription);
        } else {
            errorMessage(alert.content);
            return Promise.reject();
        }
    });

    function sign(samlResponse, jwtToken, userSubscription) {
        loadingMessage('正在签署…');
        let signerId;

        const {cert, tenantName} = userSubscription;
        if (!cert) {
            return Promise.reject('未查询到证书');
        }
        const {signInfo} = _createSignInfo(
            {
                signType: 'in',
                authTime: '',
                authType: samlResponse ? 'SAML' : 'JWT',
                signerName: fullname,

                fileName,
                signId,
                userMobile,
                userEmail,
                pdfDocRender,
                myItem,
            }, undefined, {
                companyId: enterpriseId,
                companyName: tenantName,
                samlResponse: samlResponse ? samlResponse : jwtToken,
            });

        if (!signInfo) {
            return Promise.reject("签署信息错误");
        }

        // console.log(signInfo, _getSealInfo(SealInfos));
        // return Promise.reject();

        signerId = cert;
        // 调用pdf接口，获取文档hash
        return workerFetch.request('doc/startSign', {
            doc: pdfDocRender.docId,
            signInfo,
        }).then(res => {
            const {file, hash, signedDataOffset} = res;
            pdfFile = file;
            pdfSignedDataOffset = signedDataOffset;
            const sealInfo = _getSealInfo(SealInfos);
            const data = {
                hash,
                processId,
                recipientOrder,
                signerId,
                signId,
                version,
                sealInfo
            };
            return innerSignFileDigest(data);
        }).then(res => {
            const {signature} = res;
            pdfBuffers = [];
            // 调用pdf接口，保存签名hash
            return workerFetch.request('doc/fillSignedData', {
                doc: pdfDocRender.docId,
                signedDataOffset: pdfSignedDataOffset,
                file: pdfFile,
                signData: base64ToArrayBuffer(signature),
                progress: workerFetch.register(function (buffer) {
                    pdfBuffers.push(buffer)
                })
            })
        }).then(() => {
            // 调用api上传接口
            loadingMessage('正在上传文档…');
            const blob = new Blob(pdfBuffers);
            const data = {fileName, blob, processId, recipientOrder, version}
            return uploadSignedFileSlice(data)
            // return uploadSignedFile(data);
        }).then(() => {
            hideMessage();
            return true;
        }).catch(e => {
            _signError(e);
            return false;
        });
    }
}

function _signError(key) {
    let msg;
    if (key) {
        if (ERROR_ESIGN_DELETED === key) {
            window.location.href = '/result?errCode=' + key // 已删除
            return;
        } else if (ERROR_ESIGN_CANCELLED === key) {
            window.location.reload() // 已撤销
            return;
        } else if (ERROR_ESIGN_RISK_CONTROL === key) {
            msg = '您的账户因存在安全风险已被限制，请联系客服（support@foxitsoftware.cn）';
        } else if (ERROR_ESIGN_NUM_LIMIT === key) {
            msg = '操作过于频繁，请5分钟后再重试';
        } else if (ERROR_ESIGN_STOCK_CONTROL === key) {
            msg = '签署额度扣除失败，请稍后重试';
        } else {
            msg = '签署失败，请稍后重试';
            console.error(key)
        }
    }
    if (msg) {
        errorMessage(msg);
    }
    // 刷新界面（过程中出错皆刷新）
    setTimeout(() => {
        if (window.location.host.includes('localhost')) {
            return;
        }
        window.location.reload()
    }, 2000);
}


function _getFileName(flowStatus) {
    let fileName;
    const serverPdfInfo = getStore('serverPdfInfo');
    const {file_name} = serverPdfInfo;
    if (flowStatus > 1) {
        fileName = file_name;
    } else {
        fileName = file_name.replace('待签署', '已签署');
    }
    return fileName;
}


function _getAuthUrl(res, from) {
    if (!res || res.currOrder === res.receipts.length) {
        return;
    }

    const item = res.receipts[res.currOrder];
    if (!item) {
        return;
    }

    let companyId = item.companyId || '';
    let companyName = item.company || item.Enterprise || ''; // 对外对内都可能会加入组织，所以皆取

    if (!companyId && !companyName) {
        const nextItem = getMyJoinItem(res);
        if (nextItem) {
            companyId = nextItem.companyId || '';
            companyName = nextItem.company || nextItem.Enterprise || '';
        }
    }

    const token = sessionStorage.getItem('token') || '';
    const enterpriseId = sessionStorage.getItem('enterpriseId') || '';
    const processId = sessionStorage.getItem('processId') || '';
    const name = item.userName;
    const email = item.email || '';
    const mobile = item.phoneNumber || '';
    const isOut = !item.companyId ? 1 : 0;
    return AUTH_PLUGIN_URI + `?pi=${processId}&from=${from}&t=2&tk=${token}&ei=${enterpriseId}&e=${email}&m=${mobile}&o=${isOut}&n=${name}&ci=${companyId}&cn=${companyName}`;
}

function _getSealPluginUrl(isTemp, isOrg = true) {
    const info = getStore('userInfo');
    const serverInfo = getStore('serverPdfInfo');
    const wardType = parseInt(serverInfo.ward_type);
    const isOut = wardType === 0 ? 1 : 0;
    const listType = isOut ? (info.orgId ? 2 : 1) : 0;

    const token = sessionStorage.getItem('token') || '';
    const enterpriseId = info.enterpriseId;
    const accountId = info.accountId || '';
    const orgId = isOrg && info.orgId ? info.orgId : '';
    const trueName = info.trueName || '';
    const from = 'pc';
    const type = isTemp ? 1 : 0;
    isTemp = isTemp ? 1 : 0;

    return SEAL_PLUGIN_URI + `?isTemp=${isTemp}&type=${type}&isOut=${isOut}&listType=${listType}&trueName=${trueName}&from=${from}&enterpriseId=${enterpriseId}&token=${token}&accountId=${accountId}&orgId=${orgId}`;
}

function _getWillAuthUrl(from = '') {
    const token = sessionStorage.getItem('token') || '';
    const {accountId} = getStore('userInfo');
    // return WILLAUTH_PLUGIN_URI + `?willAuthId=123&tk=64e802ae1bb06800017c261e`;
    return WILLAUTH_PLUGIN_URI + `?from=${from}&token=${token}&accountId=${accountId}`;
}

function _changeDateFieldsRect(scale, index, pdfDocRender) {
    if (DateFields && DateFields.length > 0) {
        DateFields.forEach(item => fn(item, 'dateDom'))
    }

    if (Object.values(SealFields).length > 0) {
        Object.values(SealFields).forEach(item => fn(item, 'eLabel'));
    }

    function fn(item, domName) {
        if (!item || !item[domName]) {
            return;
        }
        if (item.pageIndex !== index) {
            return;
        }
        const rect = getRect(item[domName]);
        if (!rect) {
            return;
        }
        const {left, top, right, bottom} = rect;
        item[domName].style.left = (left * scale / item.scale) + 'px';
        item[domName].style.top = (top * scale / item.scale) + 'px';
        item[domName].style.width = ((right - left) * scale / item.scale) + 'px';
        item[domName].style.height = ((bottom - top) * scale / item.scale) + 'px';
        item.scale = scale;
    }
}

// 创建签名信息，写入pdf
function _createSignInfo(
    {
        signType = 'out',
        authTime = '',
        authType = '',
        signerName = '',

        fileName,
        signAccountId = null,
        signId,
        userMobile,
        userEmail,
        pdfDocRender,
        myItem,
        firstPageRender
    }, outer = {
        operatorName: '',
        willAuthTime: '',
        willAuthType: '',
    }, inner = {
        companyId: '',
        companyName: '',
        samlResponse: '',
    }) {
    const signInfo = {
        config: {
            filter: "Adobe.PPKLite",
            subfilter: "adbe.pkcs7.detached",
            flag: 128,
            inkSign: null,
            location: '',
            reason: null,

            fileName,
            signAccountId,
            signId,
            phone: userMobile,
            signer: userEmail,
            signIndex: myItem.sort
        },
        fields: []
    }

    const myFields = myItem.annots;
    const isOrg = !!myItem.company;

    if (!myFields || myFields.length < 1) {
        if (SealFields) {
            Object.values(SealFields).filter(item => item.isOrg === isOrg).filter(item => item.eLabel).forEach(item => {
                const {pageIndex, eLabel, base64} = item;
                const {rect, pageRender} = getRect4Pdf(eLabel, pageIndex, pdfDocRender, firstPageRender);
                if (!rect) {
                    return;
                }
                if (!firstPageRender) {
                    firstPageRender = pageRender;
                }
                const inkSign = base64ToArrayBuffer(base64);
                const field = _getSignField({type: 2, pageIndex, rect, inkSign});
                signInfo.fields.push(field);
            })
        }
    } else {
        myFields.filter(annot => annot.type === "FullSignature").forEach(annot => {
            const field = _getSignField(
                {
                    type: 0,
                    objNum: annot.annotObjNum,
                    pageIndex: annot.pageIndex,
                });
            signInfo.fields.push(field);
        })
    }
    if (signInfo.fields.length < 1) {
        return;
    }
    if (DateFields && DateFields.length > 0) {
        DateFields.filter(item => item.isOrg === isOrg).filter(item => item.dateDom).forEach(item => {
            const {pageIndex, dateDom, base64} = item;
            const {rect, pageRender} = getRect4Pdf(dateDom, pageIndex, pdfDocRender, firstPageRender);
            if (!rect) {
                return;
            }
            if (!firstPageRender) {
                firstPageRender = pageRender;
            }
            const inkSign = base64ToArrayBuffer(base64);
            const field = _getSignField({type: 1, pageIndex, rect, inkSign});
            signInfo.fields.push(field);
        })
    }
    return {signInfo, firstPageRender};

    function _getSignField(
        {
            type = 0,
            pageIndex = 0,
            objNum = null,
            rect = null,
            inkSign = null,
        }) {
        return {
            foxitSignFieldType: type, // 0 普通签名域；1 签署日期签名域； 2 参与人自己创建的签名域
            annotObjNum: objNum,
            pageIndex,
            rect,
            inkSign,
            signType,

            authTime,
            authType,
            signerName,

            ...outer,
            ...inner
        }
    }
}

