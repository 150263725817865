import {observer} from "mobx-react";
import {getStore} from "@/store";
import {useCallback, useEffect, useState} from "react";
import styles from './StampDropDown.module.less'
import {openUrlByBrowser, setSigAPForPhantom, showESignCNDialogAPI} from "@/request/phantom";
import {getOrgAdminUrl, getPersonAdminUrl} from "@/request/esign";
import {loadingMessage} from "@/pages/components/Message";
import {getSealsInPlugin} from "../../service";
import {
    ERROR_AC_ORG_PERMISSION_FAIL, ERROR_AC_PERMISSION_FAIL,
    ERROR_EQB_AUTH_EXPIRED,
    ERROR_EQB_ORG_TOKEN_EXPIRED, ERROR_EQB_SEAL_PERMISSION_FAIL
} from "@/request/error_container";
import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {getPhantomVersion} from "../../utils/util";

function PhantomStampDropDown() {

    const [seals, setSeals] = useState([])
    const [sealsLoading, setSealsLoading] = useState(false)
    const [adminLoading, setAdminLoading] = useState(false)
    const [sealsLoadingError, setSealsLoadingError] = useState(false)
    const [settingIconClassList, setSettingIconClassList] = useState([styles.settingIcon])
    const [noDataReason, setNoDataReason] = useState('')

    const initFn = useCallback(async (refresh = true) => {
        const wardType = parseInt(getQueryVariable('wardType'))
        const isOrg = getQueryVariable('isOrg')
        setNoDataReason('');
        try {
            if (refresh) {
                if (sealsLoading) return;
                setSealsLoading(true)
                await getSealsInPlugin({ company: getStore('userInfo').enterpriseName, companyId: getStore('userInfo').enterpriseId})
            }
            let data;
            if (wardType === 1) {
                data = getStore('plugin').inSeals
            } else {
                if (isOrg === 'true') {
                    data = getStore('plugin').outOrgSeals ? getStore('plugin').outOrgSeals.filter(stamp => stamp.usePermission) : []
                    if ((!data || data.length === 0) && getStore('plugin').outOrgSeals && getStore('plugin').outOrgSeals.length > 0) {
                        setNoDataReason('您暂无权限使用该企业任何公章')
                    }
                } else {
                    data = getStore('plugin').outPersonSeals
                }
            }
            setSeals(data);
            setSealsLoadingError(false)
        } catch (e) {
            console.error('phantom stamp dropdown init error', e)
            setSealsLoadingError(true)
        } finally {
            setSealsLoading(false)
        }
    }, [sealsLoading])

    useEffect(() => {
        initFn(false)
        window.eSignCN_initStampDropDownFn = initFn;
    }, [initFn])

    const enterPersonAdmin = async () => {
        try {
            if (adminLoading) {
                return;
            }
            setAdminLoading(true)
            setSettingIconClassList([styles.settingIcon, styles.adminLoading])
            const url = await getPersonAdminUrl({ accountId: getStore('userInfo').accountId });
            const webUrl = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(url)}&wardType=0&width=1217&height=600`
            showESignCNDialogAPI({ title: '个人印章管理', id: "ESIGNCN_PERSON_STAMP_MANAGE", type: 'Window', webUrl, width: 600, height: 600 })
        } catch (e) {
            if (e.data && e.data.msg) {
                loadingMessage(e.data.msg, 2)
            }
        }
        finally {
            setAdminLoading(false)
            setSettingIconClassList([styles.settingIcon])
        }
    }
    const enterOrgAdmin = async () => {
        try {
            if (adminLoading) {
                return;
            }
            setAdminLoading(true)
            setSettingIconClassList([styles.settingIcon, styles.adminLoading])
            const url = await getOrgAdminUrl({ orgId: getStore('userInfo').orgId });
            const webUrl = `${window.location.origin}/iframe-dialog?targetUrl=${encodeURIComponent(url)}&wardType=0`
            showESignCNDialogAPI({ title: '企业印章管理', id: "ESIGNCN_ORG_STAMP_MANAGE", type: 'Window', webUrl, width: 600, height: 600 })
        } catch (key) {
            const phantomVersion = getPhantomVersion();
            const mainVersion = phantomVersion[0];
            if (mainVersion === 12) {
                if (ERROR_EQB_AUTH_EXPIRED === key) {
                    Modal.confirm({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的授权过期，请重新完成企业认证/授权。</span></span>,
                        zIndex: 99999,
                        okText: "认证/授权",
                        cancelText: "取消",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container",
                        onOk() {
                            showESignCNDialogAPI({ title: '企业实名认证', id: 'ESIGNCN_ORG_AUTH', type: 'Window', webUrl: `${window.location.origin}/eSignCN-cert?orgName=${getStore('userInfo').enterpriseName}&wardType=0`})
                        }
                    })
                } else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    Modal.confirm({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。</span></span>,
                        zIndex: 99999,
                        okText: "联系销售",
                        cancelText: "取消",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container",
                        onOk() {
                            openUrlByBrowser('https://www.foxitsoftware.cn/esign#sales')
                        }
                    })
                } else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    // result.msg = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                    Modal.info({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您所在的企业授权过期，请联系管理员重新进行企业认证/授权。</span></span>,
                        zIndex: 99999,
                        okText: "确认",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container"
                    })
                } else if (ERROR_AC_PERMISSION_FAIL === key) {
                    Modal.info({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。</span></span>,
                        zIndex: 99999,
                        okText: "确认",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container"
                    })
                } else if (ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    Modal.info({
                        title: '福昕电子签章服务',
                        icon: "",
                        content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您目前没有企业签章管理权限，如需要管理请联系企业签章管理员。</span></span>,
                        zIndex: 99999,
                        okText: "确认",
                        mask: false,
                        closable: true,
                        className: "eSignCN_funcDialog_container"
                    })
                }
            } else {
                if (ERROR_EQB_AUTH_EXPIRED === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_ORG_AUTH_CONFIRM",
                        msg: "您的授权过期，请重新完成企业认证/授权。"
                    })
                }
                else if (ERROR_AC_ORG_PERMISSION_FAIL === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_APP_STORE_EXPIRE",
                        msg: "您的对外签署订阅已过期或您所在的组织未采购对外签署服务，请联系福昕销售。"
                    })
                }
                else if (ERROR_EQB_ORG_TOKEN_EXPIRED === key) {
                    // result.msg = '提示：您所在的企业授权过期，请联系管理员重新进行企业认证/授权。';
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_EXPIRE",
                        msg: "您所在的企业授权过期，请联系管理员重新进行企业认证/授权。"
                    })
                }
                else if (ERROR_AC_PERMISSION_FAIL === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_LACK",
                        msg: "您尚未被分配对外签署的授权，请联系IT管理员分配授权后重试。"
                    })
                } else if (ERROR_EQB_SEAL_PERMISSION_FAIL === key) {
                    showESignCNDialogAPI({
                        title: "福昕电子签章服务",
                        type: "Dialog",
                        id: "ESIGNCN_OUT_SIGN_AC_AUTH_LACK",
                        msg: "您目前没有企业签章管理权限，如需要管理请联系企业签章管理员。"
                    })
                    // Modal.info({
                    //     title: '福昕电子签章服务',
                    //     icon: "",
                    //     content: <span style={{display: 'flex', alignItems: 'center'}}><ExclamationCircleOutlined style={{fontSize: '22px', color: '#faad14'}}/><span style={{flex: 1, marginLeft: '8px'}}>您目前没有企业签章管理权限，如需要管理请联系企业签章管理员。</span></span>,
                    //     zIndex: 99999,
                    //     okText: "确认",
                    //     mask: false,
                    //     closable: true,
                    //     className: "eSignCN_funcDialog_container"
                    // })
                }
            }
        }
        finally {
            setAdminLoading(false)
            setSettingIconClassList([styles.settingIcon])
        }

    }

    const setSignatureAP = (base64AP) => {
        const base64 = base64AP.replace(/^data:.*?base64,/, '')
        setSigAPForPhantom({ base64AP: base64 })
    }

    const loadStampAdminPage = async () => {
        const wardType = parseInt(getQueryVariable('wardType'))
        const isOrg = getQueryVariable('isOrg')
        if (wardType === 1) {
            showESignCNDialogAPI({ title: "管理签名", type: "Window", id: "ESIGNCN_INSTAMP_MANAGE", webUrl: `${window.location.origin}/stamp-manage?wardType=1`, width: 600, height: 600 })
        } else {
            if (isOrg === 'true') {
                await enterOrgAdmin()
            } else {
                await enterPersonAdmin()
            }
        }
    }

    const sealList = (seals) => {
        if (!seals || seals.length < 1) {
            return <div className={styles.noData}>
                <div className={styles.msg}>
                    <div>暂无数据</div>
                    <div>{noDataReason}</div>
                </div>
            </div>
        }
        return <div className={styles.sealsWarp}>
            {
                seals.map((seal) => {
                    return <div className={styles.signatureItem} support-outline='true' key={`${seal.sealId}-${seal.id}`}>
                        <img src={seal.base64} className={styles.eSignCNStamp} alt='eSignCNStamp' onClick={() => {setSignatureAP(seal.base64)}}/>
                    </div>
                })
            }
        </div>
    }

    return <div className={styles.stampDropDownContainer}>
        <div className={styles.toolBarContainer} name="toolBarContainer">
            <div name='settingIconBtn' className={styles.settingIconBtn} onClick={loadStampAdminPage}>
                <span name='settingIconMsg'>印章管理</span><i className={settingIconClassList.join(' ')} name='settingIcon'></i>
            </div>
        </div>
        {
            !sealsLoading && !sealsLoadingError &&
            <div className={styles.stampListContainer}>
                {sealList(seals)}
            </div>
        }
        {  sealsLoading && !sealsLoadingError &&
            <div className={styles.dropdownBoxLoading} name="dropdown-box-loading">
              <span className={styles['e-sign-spin-dot-spin']}>
                <i className={styles['e-sign-spin-dot-item']}></i>
                <i className={styles['e-sign-spin-dot-item']}></i>
                <i className={styles['e-sign-spin-dot-item']}></i>
                <i className={styles['e-sign-spin-dot-item']}></i>
              </span>
            </div>
        }
        {   sealsLoadingError &&
            <div className={styles.dropdownBoxMsgContainer} name="msg-panel">
                <div name="msg-content">网络异常，</div>
                <div className={styles.refreshBtn} name="e-sign-cn__refresh-btn" onClick={initFn}>点击刷新</div>
            </div>
        }
    </div>
}

function getQueryVariable(variable) {
    let query = window.location.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split('=');
        if (pair[0] === variable) {
            let value = decodeURIComponent(pair[1]);
            return value;
        }
    }
    return null;
}

export default observer(PhantomStampDropDown)