import ConfigForm from "./configForm";
import {useEffect, useState, useCallback, useRef} from "react";
import SignerInfoForm from "./signerInfoForm";
import {observer} from "mobx-react";
import {getStore, setStore} from "@/store";
import {getInitFlowInfoCacheFn, setInitFlowInfoCacheFn} from "./service";
import {
    getDocInfoForPhantom,
    setCurReceiptItemConfigInfoForPhantom, deleteFieldsForPhantom
} from "@/request/phantom";
import {InitSignGuideView} from "./InitSignGuideView";

const colorList = ["#A0C802", "#E51CE9", "#34B6FF", "#FFA234",
    "#001AFF", "#FF0000", "#40A710", "#A66816",
    "#1F78D3", "#C6B30C", "#CE5700", "#2EC3A0",
    "#6480E8", "#FF71CF", "#FF5D00", "#7D51AA",
    "#547B46", "#008BA9", "#0300A9", "#B53B3B"];

function PhantomInitFlowPanelV3() {

    const initFlowInfo = getStore('plugin').initFlowInfo;
    const [signerType, setSignerType] = useState('configForm')
    const [docInfo, setDocInfo] = useState({})
    const [showGuideView, setShowGuideView] = useState(false)
    const signerInfoComRef = useRef()

    // 获取颜色
    const getColor = useCallback(() => {
        let currColor = null;
        colorList.some(color => {
            const res = initFlowInfo.receipts.every(item => item.color !== color);
            if (res) {
                currColor = color;
                return true;
            } else {
                return false;
            }
        });
        return currColor;
    }, [initFlowInfo.receipts])

    const onFinishConfigForm = async (signerType) => {
        setSignerType(signerType)
        initFlowInfo.showFormType = 'signerInfoForm'
        initFlowInfo.curReceiptsOrder = -1
        await setCurReceiptItemConfigInfoForPhantom({isOrg: signerType === 'orgSigner', color: getColor()}); // 开始当前签署环节
        setStore('plugin', {...getStore('plugin'), initFlowInfo: {...initFlowInfo}})
    }

    const onFinishSignerInfoForm = async () => {
        initFlowInfo.showFormType = 'configForm'
        await setCurReceiptItemConfigInfoForPhantom(); // 结束当前签署环节
        setStore('plugin', {...getStore('plugin'), initFlowInfo: {...initFlowInfo}})
    }

    const handleEditSignerInfoForm = async (curOrder) => {
        initFlowInfo.showFormType = 'signerInfoForm'
        initFlowInfo.curReceiptsOrder = curOrder
        await setCurReceiptItemConfigInfoForPhantom({isOrg: initFlowInfo.receipts[curOrder].signerType === 'orgSigner', color: initFlowInfo.receipts[curOrder].color}); // 开始当前签署环节
        setStore('plugin', { ...getStore('plugin'), initFlowInfo: { ...initFlowInfo }})
    }

    // eslint-disable-next-line
    const handleUpdateAnnotsAction = (params) => {
        if (initFlowInfo.showFormType === 'signerInfoForm') {
            signerInfoComRef.current && signerInfoComRef.current.eSignCN_annotUpdateNotifier(params);
        }
        const { annots, type } = params;
        if (type === "ADD") {
            return;
        } else if (type === "REMOVE") {
            initFlowInfo.receipts.forEach((item, index) => {
                item.annots = item.annots.filter(it => {
                    let ret = true;
                    annots.forEach(annot => {
                        if (annot.objNumber === it.objNumber && annot.pageIndex === it.pageIndex) {
                            ret = false;
                        }
                    })
                    return ret;
                })
            })
        } else if (type === "UPDATE") {
            initFlowInfo.receipts.forEach(item => {
                item.annots = item.annots.map(it => {
                    annots.forEach(annot => {
                        if (annot.objNumber === it.objNumber && annot.pageIndex === it.pageIndex) {
                            it = annot;
                        }
                    })
                    return it;
                })
            })
        }
        if (!initFlowInfo.configInfo.freeRectItem) {
            initFlowInfo.receipts = initFlowInfo.receipts.filter(item => item.annots.length > 0);
        }
        setStore('plugin', { ...getStore('plugin'), initFlowInfo: { ...initFlowInfo }})
    }

    // eslint-disable-next-line
    const initFlowSuccessCallback = ({ processId }) => {
        console.log('processId', processId)
    }

    const clearAllReceiptItemFields = useCallback(() => {
        const fields = [];
        initFlowInfo.receipts.forEach(item => {
            item.annots && item.annots.forEach(annot => {
                const obj = { objNumber: annot.objNumber, pageIndex: annot.pageIndex};
                fields.push(obj);
            })
        })
        deleteFieldsForPhantom(fields)
    }, [initFlowInfo.receipts])

    const setDeadlineCCInfo = useCallback((data) => {
        const { type, expireTime, ccList } = data;
        if (type === 'deadline') {
            const configInfo = initFlowInfo.configInfo;
            configInfo.expireTime = expireTime;
            setStore('plugin', {...getStore('plugin'), initFlowInfo: {...initFlowInfo, configInfo }})
        } else {
            const configInfo = initFlowInfo.configInfo;
            configInfo.ccList = ccList;
            setStore('plugin', {...getStore('plugin'), initFlowInfo: {...initFlowInfo, configInfo }})
        }
    }, [initFlowInfo])

    useEffect(() => {
        async function fetchData() {
            const docInfoData = Object.keys(docInfo).length > 0 ? docInfo : await getDocInfoForPhantom();
            if (typeof docInfoData.fileSize === 'number') {
                setShowGuideView(false)
                setDocInfo(docInfoData)
            } else {
                setShowGuideView(true)
            }
            const cacheId = docInfoData.uuid;
            if (cacheId && !initFlowInfo.hasInitStore) {
                const cacheInitFlowInfo = getInitFlowInfoCacheFn(cacheId)
                if (cacheInitFlowInfo) {
                    setStore('plugin', { ...getStore('plugin'), initFlowInfo: { ...cacheInitFlowInfo, hasInitStore: true }})
                } else {
                    setStore('plugin', { ...getStore('plugin'), initFlowInfo: { ...initFlowInfo, hasInitStore: true }})
                }
            }
            if (cacheId && initFlowInfo.hasInitStore) {
                setInitFlowInfoCacheFn(cacheId, initFlowInfo)
            }
        }
        fetchData();
    }, [initFlowInfo, docInfo])

    // useEffect(() => {
    //     const docInfoData = getDocInfoForPhantom();
    //     console.log('docInfoData', docInfoData)
    //     if (typeof docInfoData.fileSize === 'number') {
    //         setShowGuideView(false)
    //         setDocInfo(docInfoData)
    //     } else {
    //         setShowGuideView(true)
    //     }
    // }, [])

    useEffect(() => {
        window.eSignCN_annotUpdateNotifier = handleUpdateAnnotsAction; // 订阅客户端的annot更新消息
        window.eSignCN_initFlowFreeRectItemError1Callback = clearAllReceiptItemFields;
        window.eSignCN_initFlowFreeRectItemError1CancelCallback = () => {};
        window.eSignCN_setDeadlineCCInfo = setDeadlineCCInfo;
        // return () => {
        //     console.log('window.annotUpdateNotifierForESignCN = null')
        //     window.annotUpdateNotifierForESignCN = null; // 取消订阅客户端的annot更新消息
        // }
    }, [handleUpdateAnnotsAction, clearAllReceiptItemFields, setDeadlineCCInfo])

    useEffect(() => {
        window.eSignCN_initFlowSuccessCallback = initFlowSuccessCallback;
    }, [initFlowSuccessCallback])

    const sealsError = getStore('plugin').sealsError;

    return (
        <>
            {
                (showGuideView || sealsError) &&
                <InitSignGuideView/>
            }
            {
                !showGuideView &&
                !sealsError &&
                <>
                    {
                        initFlowInfo.showFormType === 'configForm' &&
                        <ConfigForm
                            docInfo={docInfo}
                            handleEditSignerInfoForm={handleEditSignerInfoForm}
                            onFinishForm={onFinishConfigForm}>
                        </ConfigForm>
                    }
                    {
                        initFlowInfo.showFormType === 'signerInfoForm' &&
                        <SignerInfoForm
                            ref={signerInfoComRef}
                            initSignerType={signerType}
                            onFinishForm={onFinishSignerInfoForm}>
                        </SignerInfoForm>
                    }
                </>
            }
        </>
    )
}

export default observer(PhantomInitFlowPanelV3);